import { useQuery } from "react-query";
import { GetAccountBySecurityStampResponse } from "../Responses/GetSellerByKeyResponse.ts";
import { RegistrationResponse } from "../Responses/RegistrationResponse.ts";
import { RegistrationBySecurityStampRequest } from "../Requests/RegistrationBySecurityStampRequest.ts";
import { SendEmailToResetPasswordRequest } from "../Requests/SendEmailToResetPasswordRequest.ts";
import { ResetPasswordRequest } from "../Requests/ResetPasswordRequest.ts";
import { SetReturnsTypeRequest } from "../Requests/SetReturnsTypeRequest.ts";
import { SetResaleRequest } from "../Requests/SetResaleRequest.ts";
import { AccountResponse } from "../Responses/AccountResponse.ts";
import { UpdateProfileRequest } from "../Requests/UpdateProfileRequest.ts";
import { sellerApiClient, getParams } from "./sellerApiClient";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { AxiosResponse } from 'axios';
import { DeliveryInfo } from "./types";

export {
  isApiError,
  isApiErrorWithResponse,
  getErrorCode,
  getErrorArguments,
  getErrorMessage,
} from "./sellerApiClient";

export const getAccountBySecurityStamp = async (
  securityStamp: string,
): Promise<GetAccountBySecurityStampResponse> => {
  const response = await sellerApiClient.get<GetAccountBySecurityStampResponse>(
    `/v1/accounts?securityStamp=${securityStamp}`,
  );
  return response.data;
};

export const registrationBySecurityStamp = async (
  request: RegistrationBySecurityStampRequest,
): Promise<RegistrationResponse> => {
  const response =
    await sellerApiClient.post<RegistrationBySecurityStampRequest>(
      `/v1/registration/by-security-stamp`,
      request,
    );
  return response.data;
};

export const hardConfirmPhone = async (phone: string): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    `/v1/accounts/confirm-phone`,
    {
      phone,
    },
  );
  return response.data;
};

export const getAccountById = async (): Promise<AccountResponse> => {
  const response =
    await sellerApiClient.get<AccountResponse>(`/v1/accounts/id`);
  localStorage.setItem('region', response.data.region);
  return response.data;
};

export const sendEmailToResetPassword = async (
  request: SendEmailToResetPasswordRequest,
): Promise<RegistrationResponse> => {
  const response =
    await sellerApiClient.post<RegistrationBySecurityStampRequest>(
      `/v1/accounts/send-email-reset-password`,
      request,
    );
  return response.data;
};

export const updateProfile = async (
  request: UpdateProfileRequest,
): Promise<RegistrationResponse> => {
  const response = await sellerApiClient.post<UpdateProfileRequest>(
    `/v1/accounts/profile`,
    request,
  );
  return response.data;
};

export const sendPin = async (phone: string): Promise<RegistrationResponse> => {
  const response = await sellerApiClient.post<UpdateProfileRequest>(
    `/v1/accounts/send-pin`,
    { phone },
  );
  return response.data;
};

export const verifyPin = async (
  pinCode: number,
): Promise<RegistrationResponse> => {
  const response = await sellerApiClient.post<UpdateProfileRequest>(
    `/v1/accounts/verify-pin`,
    { pinCode },
  );
  return response.data;
};

export const resetPassword = async (
  request: ResetPasswordRequest,
): Promise<RegistrationResponse> => {
  const response =
    await sellerApiClient.post<RegistrationBySecurityStampRequest>(
      `/v1/accounts/reset-password`,
      request,
    );
  return response.data;
};
0;

export const setResale = async (request: SetResaleRequest): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    "/v1/returns/resale",
    request,
  );
  return response.data;
};

export const cancelReturn = async (id: string): Promise<void> => {
  const response = await sellerApiClient.post<void>(`/v1/returns/cancel`, {
    parcelId: id,
  });
  return response.data;
};

export const getResale = async (): Promise<ResaleDto[]> => {
  const response = await sellerApiClient.get<ResaleDto[]>(`/v1/returns/resale`);
  return response.data;
};

export enum ReturnTypes {
  Disposal = "Disposal",
  DeliverToRussia = "DeliverToRussia",
  DeliverToSeller = "DeliverToSeller",
  NotSelected = "NotSelected",
  Resale = "Resale",
}

export interface GetReturnsPageResult {
  total: number;
  items: Return[];
}

export const getReturnsPage = async ({ page, filters }: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<GetReturnsPageResult> => {
  const response = await sellerApiClient.get<GetReturnsPageResult>("/v1/returns/page", {
    params: getParams(page, filters),
    paramsSerializer: { indexes: null },
  });

  return response.data;
};

export const getParcelResaleLink = async (ids: string[]): Promise<{ items: ParcelResale[] }> => {
  const response = await sellerApiClient.get<{ items: ParcelResale[] }>("/v1/returns/parcel-resale-link", {
    params: { ParcelIds: ids },
    paramsSerializer: { indexes: null },
  });

  return response.data;
};

export function useReturnsCounter() {
  const { data, refetch } = useQuery({
    queryKey: [
      "returns-page",
      [ReturnTypes.DeliverToRussia, ReturnTypes.DeliverToSeller],
      1,
      1,
    ],
    queryFn: () => getReturnsPage({
      filters: [{
        name: "ReturnTypes",
        value: [ReturnTypes.DeliverToRussia, ReturnTypes.DeliverToSeller],
      }],
      page: { page: 1, pageSize: 1 },
    }),
    retry: 1,
    select: ({ total }) => total ?? 0,
  });

  return { data, refetch };
}

export function useHasReturns() {
  const { data, isLoading, isFetched, refetch } = useQuery({
    queryKey: [
      "returns-page",
      [
        ReturnTypes.Disposal,
        ReturnTypes.DeliverToRussia,
        ReturnTypes.DeliverToSeller,
        ReturnTypes.NotSelected,
        ReturnTypes.Resale,
      ],
      1,
      1,
    ],
    queryFn: () => getReturnsPage({
      filters: [{
        name: "ReturnTypes",
        value: [
          ReturnTypes.Disposal,
          ReturnTypes.DeliverToRussia,
          ReturnTypes.DeliverToSeller,
          ReturnTypes.NotSelected,
          ReturnTypes.Resale,
        ],
      }],
      page: { page: 1, pageSize: 1 },
    }),
    retry: 1,
    select: ({ total }) => Boolean(total),
  });

  return {
    hasReturns: data,
    hasNoReturns: isFetched && !data,
    isLoading,
    refetch,
  };
}

export function useHasParcels() {
  const { data, isLoading, isFetching, isFetched, refetch } = useQuery({
    queryKey: ["parcels-page", [], 1, 1],
    queryFn: () => getParcels({
      filters: [],
      page: { page: 1, pageSize: 1 },
    }),
    retry: 1,
    select: ({ total }) => Boolean(total),
  });

  return {
    hasParcels: data,
    hasNoParcels: isFetched && !data,
    isLoading,
    isFetching,
    refetch,
  };
}

export function useResaleCounter() {
  const { data, refetch } = useQuery({
    queryKey: ["resales-page", 1, 1],
    queryFn: () => getResalesPage({
      filters: [],
      page: { page: 1, pageSize: 1 },
    }),
    retry: 1,
    select: ({ total }) => total ?? 0,
  });

  return { data, refetch };
}

export function useDisposalCounter() {
  const { data, refetch } = useQuery({
    queryKey: [
      "returns-page",
      [ReturnTypes.Disposal],
      1,
      1,
    ],
    queryFn: () => getReturnsPage({
      filters: [{ name: "ReturnTypes", value: [ReturnTypes.Disposal]}],
      page: { page: 1, pageSize: 1 },
    }),
    retry: 1,
    select: ({ total }) => total ?? 0,
  });

  return { data, refetch };
}

export interface GetResalesPageResult {
  total: number;
  items: ResaleDto[];
}

export const getResalesPage = async ({
  page,
  filters,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<GetResalesPageResult> => {
  const response = await sellerApiClient.get<GetResalesPageResult>("/v1/returns/resale-page", {
    params: getParams(page, filters),
    paramsSerializer: { indexes: null },
  });

  return response.data;
};

export const getAllReturns = async (): Promise<SellerReturns> => {
  const response1 = await sellerApiClient.get<SellerReturns>(`/v1/returns?returnType=2`);
  const response2 = await sellerApiClient.get<SellerReturns>(`/v1/returns?returnType=3`);
  response1.data.returns.push(...response2.data.returns);
  return response1.data;
};

export const getAddresses = async (): Promise<GetAddressTemplatesResponse> => {
  const response = await sellerApiClient.get<GetAddressTemplatesResponse>(
    `/v1/address-templates`,
  );

  return response.data;
};

export const addAddresses = async (address: Address): Promise<void> => {
  await sellerApiClient.post<GetAddressTemplatesResponse>(
    `/v1/address-templates`,
    address,
  );
};

export const rewriteAddress = async (data: { address: Address, id: string}): Promise<void> => {
  const { address, id } = data;
  await sellerApiClient.put<GetAddressTemplatesResponse>(
    `/v1/address-templates/${id}`,
    address
  );
};

export async function updateVisit() {
  await sellerApiClient.post<GetAddressTemplatesResponse>(
    `/v1/accounts/last-visit`,
  );
}

export const setReturnsType = async (
  request: SetReturnsTypeRequest,
): Promise<void> => {
  const response = await sellerApiClient.post(`/v1/returns`, request);
  return response.data;
};

export const getParcel = async (parcelId: string): Promise<Parcel> => {
  const response = await sellerApiClient.get<Parcel>(`/v1/parcels/${parcelId}`);

  return response.data;
};

interface ParcelItemShort {
  id: string;
  name: string;
  url: string;
  quantity: number;
}

export interface ParcelPageItem {
  id: string;
  trackNumber: string;
  externalNumber: string;
  serviceName: string;
  items: ParcelItemShort[];
  createdAt: Date;
  sellerId: string;
  sellerName: string;
  declaredValue: DeclaredValue;
  weight: Weight;
  trackEvents: TrackEvent[];
}

export interface GetParcelsResult {
  items: ParcelPageItem[];
  total: number;
}

export const getParcels = async ({
  page,
  filters,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<GetParcelsResult> => {
  const response = await sellerApiClient.get<GetParcelsResult>("/v1/parcels/page", {
    params: getParams(page, filters),
    paramsSerializer: { indexes: null },
  });

  return response.data;
};

export const getParcelImages = async (
  parcelId: string,
): Promise<ParcelImagesResponse> => {
  const response = await sellerApiClient.get<ParcelImagesResponse>("/v1/returns/images-links", {
    params: {
      parcelId,
    },
  });

  return response.data;
};

export interface ParcelImagesResponse {
  imageLinks: string[];
}

export interface TrackEvent {
  id: string;
  moment: Date;
  type: number;
  cancellationReasonType: string;
  message: string;
  isConfirmed: boolean;
  confirmedAt: Date;
  ownerName: string;
  isCorrect: boolean;
  typeCode: number;
}

export interface ParcelItem {
  id: string;
  vendorCode: string;
  name: string;
  weight: Weight;
  dimensions: {
    length: Weight;
    width: Weight;
    height: Weight;
  };
  declaredValue: DeclaredValue;
  quantity: number;
  hsCodeUe: string;
  hsCodeIs: string;
  hsCodeRu: string;
  trackEvents: TrackEvent[];
  url: string;
}

export interface Parcel {
  id: string;
  externalId: string;
  trackNumber: string;
  weight: Weight;
  dimensions: {
    length: Weight;
    width: Weight;
    height: Weight;
  };
  declaredValue: DeclaredValue;
  status: string;
  items: ParcelItem[];
  trackEvents: TrackEvent[];
  serviceName: string;
  returnDeliveryInfo: DeliveryInfo;
}

export interface ReturnItem {
  id: string;
  name: string;
  url: string;
  quantity: number;
}

interface Weight {
  value: number;
  unit: string;
}

interface DeclaredValue {
  amount: number;
  currency: string;
}

export interface Return {
  id: string;
  trackNumber: string;
  externalNumber: string;
  weight: Weight;
  declaredValue: DeclaredValue;
  items: ReturnItem[];
  isPaid: boolean;
  createAt: Date;
  arrivedToDropOffAt: Date;
  serviceName: string;
  isCancelable: boolean;
  resales: Resale[];
  returnAt: string;
  sellerId: string;
  sellerName: string;
  externalId: string;
  isExtraSmall: boolean;
  estimatedUtilizationDate: Date;
  isDeparture: boolean;
  hasLinkedResales: boolean,
}

export interface SellerReturns {
  sellerName: string;
  returns: Return[];
}

export interface GetAddressTemplatesResponse {
  addressTemplates: AddressTemplate[];
}

export interface AddressTemplate {
  address: Address;
  fullName?: string;
  id: string;
  phone?: string;
  rawLineEnglish?: string;
}

export interface Address {
  area?: string;
  city?: string;
  cityDistrict?: string;
  country?: string;
  countryCode?: string;
  entrance?: string;
  fias?: FIAS,
  flat?: string;
  floor?: string;
  house?: string;
  latitude?: number;
  longitude?: number;
  rawLine?: string;
  region?: string;
  settlement?: string;
  street?: string;
  zipCode?: string;
}

export interface FIAS {
  regionId?: string;
  areaId?: string;
  cityId?: string;
  cityDistrictId?: string;
  settlementId?: string;
  streetId?: string;
  houseId?: string;
  flatId?: string;
}

export type TLabelSelectedItem = Pick<AddressTemplate, 'address' | 'id'>;

export interface Resale {
  id: string;
  createAt: string;
  trackNumber: string;
  externalId: string;
  sellerName: string;
  sellerId: string;
}

export interface ResaleItem {
  id: string;
  name: string;
  quantity: number;
  url: string;
  vendorCodeGroup?: {
    id: string;
    name: string;
  };
}

export interface ParcelResaleLink {
  resale: {
    id: string;
    trackNumber: string;
    externalId: string;
    sellerId: string;
    sellerName: string;
    resaleItems: ResaleItem[];
    createdAt: string;
  };
  excessItems: ResaleItem[];
}

export interface ParcelResale {
  parcel: {
    id: string;
    createdAt: string;
    trackNumber: string;
    externalId: string;
    sellerName: string;
    sellerId: string;
    parcelItems: ResaleItem[];
  };
  parcelResaleLinks: ParcelResaleLink[];
}

export interface ResaleDto {
  id: string;
  oldTrackNumber: string;
  newTrackNumber: string;
  setupAt: Date;
  isCancelable: boolean;
  items: ResaleItemDto[];
  sellerName: string;
  sellerId: string;
  estimatedUtilizationDate: Date;
}

export interface ResaleItemDto {
  id: string;
  name: string;
  quantity: number;
}

export interface LinkSendCodeResponse {
  ticketId: string;
  foundCompanies: string[];
}

export interface Seller {
  id: string;
  name: string;
  ozonApiExists?: boolean;
  email?: string;
  [key: string]: any;
}

export interface LinkVerifyResponse {
  sellers: Seller[];
}

export interface LinkVerifyData {
  code: string;
  ticketId: string;
  clientId?: string;
  apiKey?: string;
  name?: string;
}

export interface GetSellersResponse {
  items: Seller[];
}

export interface GetZendeskResponse {
  jwt: string;
  parcelsCount: number;
}

export const linkSendCode = async (data: {
  email: string,
  clientId?: string,
  apiKey?: string,
}): Promise<LinkSendCodeResponse> => {
  const response = await sellerApiClient.post<LinkSendCodeResponse>(
    "/v1/accounts/link/send-code",
    data,
  );
  return response.data;
};

export const linkVerify = async (
  data: LinkVerifyData,
): Promise<LinkVerifyResponse> => {
  const response = await sellerApiClient.post<LinkVerifyResponse>(
    "/v1/accounts/link/verify",
    data,
  );
  return response.data;
};

export const getSellers = async (): Promise<GetSellersResponse> => {
  const response = await sellerApiClient.get<GetSellersResponse>("/v1/sellers");
  return response.data;
};

export function useSellersFound() {
  const { data, refetch, isFetching, isFetched } = useQuery({
    queryKey: ["sellers"],
    queryFn: () => getSellers(),
    retry: 1,
  });

  return {
    isFetching,
    sellersFound: Boolean(data?.items?.length),
    sellersNotFound: isFetched && !data?.items?.length,
    refetch,
  };
}

export interface CommpanyApiData {
  sellerId: string;
  apiKey?: string;
  apiClientId?: string;
}

export const addOzonApiData = async (data: CommpanyApiData): Promise<void> => {
  const { sellerId, apiKey, apiClientId } = data;
  const response = await sellerApiClient.post(
    `/v1/sellers/${sellerId}/ozon-api-data`,
    {
      apiKey,
      apiClientId,
    },
  );
  return response.data;
};

export const deleteOzonApiData = async (data: CommpanyApiData): Promise<void> => {
  const { sellerId } = data;
  const response = await sellerApiClient.delete(`/v1/sellers/${sellerId}/ozon-api-data`);
  return response.data;
};

export const getZendeskInfo = async (): Promise<GetZendeskResponse> => {
  const response = await sellerApiClient.get<GetZendeskResponse>("/v1/accounts/zendesk-info");
  return response.data;
};

export type getReturnsXLSXFileRequest = {
  FileType: string;
  LocalTimeUtcOffsetMinute: number;
  ParcelIds: string[];
  ReturnParcelViewType: string;
};

export const getReturnsXLSXFile = async (data: getReturnsXLSXFileRequest): Promise<AxiosResponse> => {
  return await sellerApiClient.get("/v1/returns/export", {
    params: { ...data },
    paramsSerializer: { indexes: null },
    responseType: 'blob'
  });
};

export const getPickUpXLSXFile = async (id: string): Promise<AxiosResponse> => {
  return await sellerApiClient.get(`/v1/pick-up/request-forms/${id}/export`, {
    responseType: 'blob'
  });
};
