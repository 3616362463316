import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { signUp, getPromo } from "@api/apiClient/signUp";
import { isApiErrorWithResponse, getErrorCode } from "@api/apiClient";
import { Text, TextSize, TextType } from "@components/Text";
import { Grid, Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import ErrorIcon from "@assets/icons/request-error.svg";
import EmailIcon from "@assets/icons/email.svg";
import BackIcon from "@assets/icons/back-arrow.svg?react";
import SignUpForm, { SignUpFormType } from "./SignUpForm";
import ValidateStamp from "./ValidateStamp";
import Promo from "./Promo";

enum STEPS {
  EMAIL="EMAIL",
  INFO="INFO",
  CONFIRM="CONFIRM",
  SUCCESS="SUCCESS",
  ERROR="ERROR",
}

const SignUpPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const key = params.get("key");
  const promo = params.get("promo");
  const [email, setEmail] = useState<string>("");
  const [step, setStep] = useState<STEPS>(key ? STEPS.INFO : STEPS.EMAIL);
  const promoQuery = useQuery({
    queryKey: ["getPromo", promo],
    queryFn: () => getPromo(promo || ""),
    retry: 1,
    enabled: Boolean(promo),
  });

  const {
    error,
    isLoading,
    mutate,
    reset,
    isError,
  } = useMutation({
    mutationFn: signUp,
    onSuccess: () => setStep(STEPS.SUCCESS),
  });

  const handleSubmit = async (form: SignUpFormType) => {
    mutate({
      email: form.email,
      password: form.password,
      countryCode: form.countryCode,
      token: form.token,
      promoId: (promoQuery.data?.isActive && promo) ? promo : undefined,
    });
    setEmail(form.email);
  };

  const promoNotFound = Boolean(promoQuery.error) && getErrorCode(promoQuery.error) === "promo-not-found";

  if (isError) {
    let errorMessage = t(`signUp.error.defaultTitle`);
    if (isApiErrorWithResponse(error)) {
      errorMessage = t(
        `signUp.error.${error.response.data.code || "defaultTitle"}`,
        { defaultValue: errorMessage },
      );
    }

    return (
      <>
        {(promoQuery.isSuccess || promoNotFound) ? (
          <Promo
            isLoading={false}
            isActive={promoQuery.data?.isActive ?? false}
            maxAccounts={1000} // promoQuery.data?.maxAccounts ?? 0}
            notFound={promoNotFound}
          />
        ) : null}
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={4}>
                <Grid>
                  <Row align="center">
                    <Column phone={2}>
                      <img alt="Icon" src={ErrorIcon} width="100%" />
                    </Column>
                    <Column phone={10}>
                      <Box pl={4}>
                        <Text type={TextType.H1}>
                          {errorMessage}
                        </Text>
                      </Box>
                    </Column>
                  </Row>
                </Grid>
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <Text type={TextType.P} size={TextSize.M}>
                  {t("signUp.error.description")}
                </Text>
              </Box>
              <Box pb={2}>
                <Text type={TextType.P} size={TextSize.M}>
                  {t("signUp.error.link")}
                </Text>
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Button
                title={t("signUp.back")}
                icon={BackIcon}
                type={ButtonType.ROUNDED_SKELETON}
                size={ButtonSize.S}
                onClick={reset}
              />
            </Column>
          </Row>
        </Grid>
      </>
    )
  }

  if (step === STEPS.SUCCESS) {
    return (
      <>
        {(promoQuery.isSuccess || promoNotFound) ? (
          <Promo
            isLoading={false}
            isActive={promoQuery.data?.isActive ?? false}
            maxAccounts={1000}
            notFound={promoNotFound}
          />
        ) : null}
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={4}>
                <Grid>
                  <Row align="center">
                    <Column phone={2}>
                      <img alt="Icon" src={EmailIcon} width="100%" />
                    </Column>
                    <Column phone={10}>
                      <Box pl={4}>
                        <Text type={TextType.H1}>
                          {t("signUp.success.title")}
                        </Text>
                      </Box>
                    </Column>
                  </Row>

                  <Row>
                    <Column phone={12}>
                      <Box pt={7}>
                        <Text
                          size={TextSize.M}
                          dangerouslySetInnerHTML={{
                            __html: t(
                              "signUp.success.description",
                              { email: `<b>${email}</b>` }
                            )
                          }}
                        />
                      </Box>
                    </Column>
                  </Row>
                </Grid>
              </Box>
            </Column>
          </Row>
        </Grid>
      </>
    );
  }

  if (step === STEPS.INFO) {
    return (
      <>
        {(promoQuery.isSuccess || promoNotFound) ? (
          <Promo
            isLoading={false}
            isActive={promoQuery.data?.isActive ?? false}
            maxAccounts={1000}
            notFound={promoNotFound}
          />
        ) : null}
        <ValidateStamp
          onBack={() => {
            setStep(STEPS.EMAIL);
            navigate("/signup");
          }}
        />
      </>
    )
  }

  return (
    <>
      {(promoQuery.isSuccess || promoNotFound) ? (
        <Promo
          isLoading={false}
          isActive={promoQuery.data?.isActive ?? false}
          maxAccounts={1000}
          notFound={promoNotFound}
        />
      ) : null}
      <SignUpForm
        pending={isLoading}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default SignUpPage;
