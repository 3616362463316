import { sellerApiClient } from "./sellerApiClient";

export const getDropShippingParcelCount =
  async (sellerId: string): Promise<DropShippingParcelCount> => {
    const response = await sellerApiClient.get<DropShippingParcelCount>(
      "/v1/drop-shipping/parcel/count",
        {
          params: {
            sellerId: sellerId
          }
        }
    );

    return response.data;
  };

export const getDropShippingOzon = async (sellerId: string): Promise<
  DropShippingOzonParcel[]
> => {
  const response = await sellerApiClient.get<{ items: DropShippingOzonParcel[] }>(
    "/v1/drop-shipping/parcel/ozon",
      {
        params: {
          sellerId: sellerId
        }
      }
  );

  return response.data?.items;
};

export const getDropShipping = async (
  // companyIds,
  isComplete?: boolean,
  sellerId?: string
): Promise<DropShippingParcel[]> => {
  const response = await sellerApiClient.get<{ items: DropShippingParcel[] }>(
    "/v1/drop-shipping/parcel",
    {
      params: {
        // CompanyIds:
        IsComplete: Boolean(isComplete),
        sellerId: sellerId
      },
    },
);

  return response.data?.items;
};

export const getAuthorized = async (): Promise<AuthorizedAPIs[]> => {
  const response = await sellerApiClient.get<{ items: AuthorizedAPIs[] }>(
    "/v1/drop-shipping/authorized",
  );

  return response.data?.items;
};

export const addApiData = async (data: CommpanyApiData[]): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    "/v1/drop-shipping/api-data",
    { commpanyApiData: data },
  );

  return response.data;
};

export const addDropShippingMatching = async (data: {
  externalParcelCode: string[],
  parcelExternalNumber: string[],
  message: string,
}): Promise<DropShippingMatchingResult> => {
  const response = await sellerApiClient.post<DropShippingMatchingResult>(
    "/v1/drop-shipping/matching",
    data,
  );

  return response.data;
};

export interface CommpanyApiData {
  companyId: string;
  apiKey: string;
  clientId: string;
}

export interface DropShippingMatchingResult {
  сompleted: string[];
  notFound: string[];
}

export interface AuthorizedAPIs {
  id: string;
  name: string;
  isAuthorizedApi: boolean;
}

export interface DropShippingParcelCount {
  completed: number;
  progress: number;
}

export interface DropShippingParcel {
  externalNumber: string;
  items: {
    name: string;
    url: string;
    imageUrl: string;
  }[],
  message: string;
  createAt: string;
  code: string;
  itemsCount: number;
}

interface DropShippingParcelItem {
  id: string;
  name: string;
  url: string;
  quantity: number;
  imageUrl: string;
}

export interface DropShippingOzonParcel {
  id: string;
  externalNumber: string;
  createdAt: string;
  items: DropShippingParcelItem[];
  // itemsCount: number;
  attachmentName: string;
  message: string;
}
