import styles from './PickUpOrderActions.module.scss';
import IconCross from '@assets/icons/cross-pink-16.svg';
import { Button, ButtonType } from '../../../../components/Button';
import { IPickUpOrderActionsProps } from '../../types';
import { useTranslation } from 'react-i18next';

const PickUpOrderActions: React.FC<IPickUpOrderActionsProps> = (props: IPickUpOrderActionsProps): React.ReactElement => {
  const {
    currentTypeModalWindow,
    handlerSetCurrentTypeModalWindow,
    handlerSetIsOpenPickUpCancelWindow,
    handlerSetIsOpenPickUpOrderWindow,
    handlerSetSelectedDate,
    handleSubmit,
    isSavingPickUpImpossible,
    isLoading,
    reset,
  } = props;

  const { t } = useTranslation();

  const onClickBtnCancel = (): void => {
    currentTypeModalWindow === 'options' && handlerSetSelectedDate!();
    reset();
    handlerSetCurrentTypeModalWindow('order');
  };

  return (
      <div className={styles.actions}>
        <div className={styles.actionsCol}>
          {(currentTypeModalWindow === 'options' || currentTypeModalWindow === 'parcels') && (
              <>
                <div className={styles.actionsItem}>
                  <Button
                      onClick={() => onClickBtnCancel()}
                      title={t('pickUpOrderModalWindow.cancel')}
                      type={ButtonType.LIGHT}
                  />
                </div>
                <div className={styles.actionsItem}>
                  <Button
                    disabled={isSavingPickUpImpossible}
                    onClick={handleSubmit}
                    pending={isLoading}
                    title={t('pickUpOrderModalWindow.save')}
                  />
                </div>
              </>
          )}
          {currentTypeModalWindow === 'parcels' && isSavingPickUpImpossible && (
              <span className={styles.actionsClue}>
                {(t('pickUpOrderModalWindow.clue'))}
              </span>
          )}
        </div>
        <div className={styles.actionsCol}>
          <Button
              icon={IconCross}
              onClick={() => {
                handlerSetIsOpenPickUpOrderWindow(false);
                handlerSetIsOpenPickUpCancelWindow(true);
              }}
              title={t('pickUpOrderModalWindow.cancelPickUp')}
              type={ButtonType.CANCEL}
          />
        </div>
      </div>
  );
};

export default PickUpOrderActions;
