import { useTranslation } from "react-i18next";
import SelectDropdown from "@components/SelectDropdown";
import Ru from "@assets/icons/russia.png";
import Cn from "@assets/icons/china.png";
import Tr from "@assets/icons/turkey.png";
import Hk from "@assets/icons/HongKong.png";
import styles from "./CountrySelect.module.scss";

export interface CountryType {
  id: string;
  img: string;
}

export const getAllowedCountry = (value?: string): string | undefined => (
  countries.find(v => v.id === value) ? value : undefined
)

export const countries: CountryType[] = [
  { id: "CN", img: Cn },
  { id: "RU", img: Ru },
  { id: "TR", img: Tr },
  { id: "HK", img: Hk },
]

export function CountrySelectValue({ item }: { item: CountryType | null }): React.ReactNode {
  const { t } = useTranslation();

  if (!item) return null;

  return (
    <div className={styles.value}>
      <img src={item?.img} alt={item.id} />
      <div>{t(`countries.${item.id}`)}</div>
    </div>
  );
}

export function CountrySelectOption({ item }: { item: CountryType }): React.ReactNode {
  const { t } = useTranslation();
  return (
    <div className={styles.option}>
      <img src={item?.img} alt={item.id} />
      <div>{t(`countries.${item.id}`)}</div>
    </div>
  );
}

export const countryKeyExtractor = (item: CountryType) => item.id

interface CountrySelectDropdownProps {
  label: string;
  errorMessage?: string;
  hasError?: boolean;
  disabled?: boolean;
  defaultSelectedItem?: CountryType;
  onItemSelect: (v: string) => void;
  value?: string;
}

function CountrySelect(props: CountrySelectDropdownProps) {
  return (
    <SelectDropdown<CountryType>
      {...props}
      renderSelectedItem={CountrySelectValue}
      renderItem={CountrySelectOption}
      items={countries}
      keyExtractor={countryKeyExtractor}
    />
  );
}

export default CountrySelect;
