import "./Grid.css";
import classNames from "classnames";

interface RowProps {
  children: React.ReactNode;
  align?: "center";
  justify?: "center" | "right";
  noWrap?: boolean;
  gap?: number;
}

const Row = ({ align, justify, noWrap, gap, children }: RowProps) => (
  <div
    className={classNames(
      "row",
      align === "center" && "row--align-center",
      justify === "center" && "row--justify-center",
      justify === "right" && "row--justify-right",
      noWrap && "row--no-wrap",
      gap && `row--gap-${gap}`,
    )}
  >
    {children}
  </div>
);

export default Row;
