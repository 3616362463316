import styles from './ReturnsPage.module.css';
import AwaitingTab from './tabs/AwaitingTab.tsx';
import cn from 'classnames';
// import DisposalTab from './tabs/DisposalTab.tsx';
import FilterIcon from '@assets/icons/filter.svg';
import Page from '@components/Page';
import ProcessingTab from './tabs/ProcessingTab.tsx';
// import ResaleTab from './tabs/ResaleTab.tsx';
// import ReturnsTab from './tabs/ReturnsTab.tsx';
import useTab from '@hooks/useTab';
import { Button, ButtonSize, ButtonType } from '@components/Button';
import { TabsList, TabsContent } from '@components/Tabs/Tabs';
import {
  useAwaitingCounter,
  // useDisposalCounter,
  useProcessingCounter,
  // useResaleCounter,
  // useReturnsCounter
} from '@api/apiClient/returns';
import { useEffect, useState } from 'react';
import { useSellersFound } from '@api/apiClient';
import { useTranslation } from 'react-i18next';

const ReturnsPage: React.FC = (): React.ReactElement => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const { sellersNotFound } = useSellersFound();

  const { data: awaitingTotal, refetch: awaitingRefetch } = useAwaitingCounter();
  // const { data: disposalTotal, refetch: disposalRefetch } = useDisposalCounter();
  const { data: processingTotal, refetch: processingRefetch } = useProcessingCounter();
  // const { data: resaleTotal, refetch: resaleRefetch } = useResaleCounter();
  // const { data: returnsTotal, refetch: returnsRefetch } = useReturnsCounter();

  const tabs = [
    {
      id: 'ProcessingTab',
      title: t('returnsPage.processingTab.title'),
      content: (
          <ProcessingTab
              isFilterOpen={isFilterOpen}
              onUpdate={processingRefetch}
              sellersNotFound={sellersNotFound}
          />
      ),
      count: processingTotal,
      disable: sellersNotFound
    },
    {
      id: 'AwaitingTab',
      title: t('returnsPage.awaitingTab.title'),
      content: (
          <AwaitingTab
              isFilterOpen={isFilterOpen}
              onUpdate={awaitingRefetch}
              sellersNotFound={sellersNotFound}
          />
      ),
      count: awaitingTotal
    },
    // {
    //   id: 'ReturnsTab',
    //   title: t('returnsPage.returnsTab.title'),
    //   content: (
    //       <ReturnsTab
    //           isFilterOpen={isFilterOpen}
    //           onUpdate={returnsRefetch}
    //           sellersNotFound={sellersNotFound}
    //       />
    //   ),
    //   count: returnsTotal,
    //   disable: sellersNotFound
    // },
    // {
    //   id: 'ResaleTab',
    //   title: t('returnsPage.resaleTab.title'),
    //   content: (
    //       <ResaleTab
    //           isFilterOpen={isFilterOpen}
    //           onUpdate={resaleRefetch}
    //           sellersNotFound={sellersNotFound}
    //       />
    //   ),
    //   count: resaleTotal,
    //   disable: sellersNotFound
    // },
    // {
    //   id: 'DisposalTab',
    //   title: t('returnsPage.disposalTab.title'),
    //   content: (
    //       <DisposalTab
    //           isFilterOpen={isFilterOpen}
    //           onUpdate={disposalRefetch}
    //           sellersNotFound={sellersNotFound}
    //       />
    //   ),
    //   count: disposalTotal,
    //   disable: sellersNotFound
    // }
  ];

  const { activeTab, setActiveTab } = useTab(tabs);

  useEffect(() => {
    setActiveTab('AwaitingTab');
  }, []);

  return (
      <Page title={t('returnsPage.title')}>
        <div className={styles.tabsRow}>
          <TabsList
              tabs={tabs}
              onChange={setActiveTab}
              value={activeTab}
          />
          {!sellersNotFound && (
              <div className={styles.filters}>
                <Button
                    className={cn(isFilterOpen ? styles.filterBtnActive : styles.filterBtnInactive)}
                    icon={FilterIcon}
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                    size={ButtonSize.S}
                    type={ButtonType.ROUNDED}
                    title={t('returnsPage.filters')}
                />
              </div>
          )}
        </div>
        <TabsContent
            tab={tabs.find((tab) => (tab.id === activeTab))}
        />
      </Page>
  );
};

export default ReturnsPage;