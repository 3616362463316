import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./Checkbox.css";
import checkmark from "./checkmark.svg";
import partial from "./partial.svg";
import classNames from "classnames";

interface CheckboxProps {
  id: string;
  checked: boolean;
  partial?: boolean;
  onChange: (checked: boolean) => void;
}

const Checkbox = (props: CheckboxProps) => {
  const checkRef = useRef(null);
  const partialRef = useRef(null);

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id={props.id}
        checked={props.checked}
        onChange={() => props.onChange(!(props.checked || props.partial))}
      />
      <label
        htmlFor={props.id}
        className={classNames({
          "checkbox-label": true,
          "checkbox-label--checked": props.checked,
          "checkbox-label--partial": !props.checked && props.partial,
        })}
      >
        <CSSTransition
          nodeRef={checkRef}
          in={Boolean(props.checked)}
          out="true"
          timeout={500}
          classNames="checkbox-mark"
          mountOnEnter
        >
          <span ref={checkRef} className="checkbox-mark">
            <img alt={"s"} src={checkmark} />
          </span>
        </CSSTransition>
        <CSSTransition
          nodeRef={partialRef}
          in={Boolean(props.partial)}
          timeout={500}
          classNames="checkbox-mark"
          mountOnEnter
        >
          <span ref={partialRef} className="checkbox-mark">
            <img alt={"s"} src={partial} />
          </span>
        </CSSTransition>
      </label>
    </div>
  );
};

export default Checkbox;
