import avatar from "../../assets/icons/avatar.svg";
import "./Photo.css";

const Photo = () => {
  return (
    <div className={"photo"}>
      <img src={avatar} alt={"photo"} />
    </div>
  );
};

export default Photo;
