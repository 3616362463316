import cn from "classnames";
import styles from './Switcher.module.scss';

interface ISwitcherProps {
  isActive: boolean;
  onSwitch: () => void;
  text: string;
}

const Switcher: React.FC<ISwitcherProps> = (props: ISwitcherProps): React.ReactElement => {
  const { isActive, onSwitch, text } = props;

  return (
      <div
          className={styles.switcher}
          onClick={onSwitch}
      >
        <span className={cn(
          styles.switcher__self,
          isActive && styles.switcher__self_active
        )} />
        <input
            checked={isActive}
            className={styles.switcher__input}
            onChange={onSwitch}
            type="checkbox"
        />
        <span className={styles.switcher__text}>
          {text}
        </span>
      </div>
  );
};

export default Switcher;
