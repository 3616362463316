import { KeycloakTokenResponse } from "@api/Responses/KeycloakTokenResponse.ts";

export const storeToken = (tokenResponse: KeycloakTokenResponse) => {
  localStorage.setItem("token", JSON.stringify(tokenResponse));
};

export const getStoredToken = (): KeycloakTokenResponse | null => {
  const tokenDataString = localStorage.getItem("token");
  if (!tokenDataString) return null;

  return JSON.parse(tokenDataString) as KeycloakTokenResponse;
};

export const isTokenValid = (tokenResponse: KeycloakTokenResponse): boolean => {
  if (!tokenResponse.received_at) return false;

  const now = new Date().getTime();
  const expiryTime =
    tokenResponse.received_at + tokenResponse.expires_in * 1000 - 60000;
  return now < expiryTime;
};

export const isRefreshTokenValid = (tokenResponse: KeycloakTokenResponse): boolean => {
  if (!tokenResponse.received_at) return false;

  const now = new Date().getTime();
  const expiryTime =
    tokenResponse.received_at + tokenResponse.refresh_expires_in * 1000;
  return now < expiryTime;
};


export const clearToken = () => {
  localStorage.removeItem("token");
};
