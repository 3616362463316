import { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Portal } from "../Portal";
import "./Modal.css";
import cn from 'classnames';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onClickOverlay?: () => void;
  width?: number;
  bodyWithoutIndentations?: boolean;
  noScroll?: boolean;
}

const Modal = (props: ModalProps) => {
  const nodeRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    if (props.isOpen) {
      document.getElementById("root")?.classList.add("blurred");
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.getElementById("root")?.classList.remove("blurred");
      document.body.style.overflow = "auto";
    };
  }, [props.isOpen]);

  return (
    <Portal>
      <CSSTransition
        in={props.isOpen}
        timeout={300}
        classNames="modal"
        mountOnEnter
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div
            className="modal__wrapper"
            onClick={(e) => {
              e.stopPropagation();
              if (e.target !== overlayRef.current) return;
              props.onClickOverlay ? props.onClickOverlay() : props.onClose();
            }}
            ref={overlayRef}
        >
          <div className="modal__close" onClick={props.onClose}></div>
          <div
            className={cn('modal__body', {
              'modal__body_clear': props.bodyWithoutIndentations,
              'modal__body--no-scroll': props.noScroll,
            })}
            ref={nodeRef}
            style={{ maxWidth: `${props.width || 600}px` }}
          >
            <div
              className={cn('modal__content', {
                'modal__content--no-scroll': props.noScroll,
              })}
            >
              {props.children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default Modal;
