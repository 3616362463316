import { ReactNode } from "react";
import "./Popup.css";

interface PopupProps {
  children: ReactNode;
  text?: string;
}

const Popup = (props: PopupProps) => {
  return (
    <div className={"popup"}>
      <div className={"popup__body"}>{props.children}</div>
      <div className={"popup__noty"}>{props.text}</div>
    </div>
  );
};

export default Popup;
