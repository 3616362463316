import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from 'react-query';
import { Button } from "@components/Button";
import Page from "@components/Page";
import { TabsList, TabsContent } from "@components/Tabs/Tabs";
import useTab from "@hooks/useTab";
import {
  getLinkedWarhauses,
  getWarehouseRefillRequests,
  getWarehause,
  getDiscrepancies,
} from "@api/apiClient/fulfillment";
import AvailableTab from "./tabs/AvailableTab";
import DiffTab from "./tabs/DiffTab";
import OrdersTab from "./tabs/OrdersTab";
import RefillWarehouseModal from "./components/RefillWarehouseModal";

const FulfillmentStore = ({ warehouseId, HeaderFilters, sellerId }: {
  warehouseId: string,
  HeaderFilters: React.FC<{ disabled?: boolean }>,
  sellerId: string,
}) => {
  const { t } = useTranslation();
  const [isAddWindowOpen, setAddWindowOpen] = useState<boolean>(false);

  const {
    data: warehouse,
  } = useQuery({
    queryKey: ["linkedWarehouses", sellerId],
    queryFn: () => getLinkedWarhauses(sellerId),
    retry: 1,
    enabled: Boolean(sellerId),
    select: ({ items }) => items.find(v => (v.id === warehouseId)),
  });

  const defaultPage = { page: 1, pageSize: 10 };
  const { data: requestsTotal } = useQuery({
    queryKey: [`getWarehouseRefillRequests-${warehouseId}`, defaultPage],
    queryFn: () => getWarehouseRefillRequests({
      page: defaultPage,
      warehouseId,
    }),
    retry: 1,
    select: ({ total }) => total,
  });

  const { data: warehouseProductsTotal } = useQuery({
    queryKey: [`getWarehause-${warehouseId}`, defaultPage],
    queryFn: () => getWarehause({ warehouseId }),
    retry: 1,
    select: ({ total }) => total,
  });

  const { data: discrepanciesTotal } = useQuery({
    queryKey: [`getDiscrepancies-${warehouseId}`, defaultPage],
    queryFn: () => getDiscrepancies({
      page: defaultPage,
      filters: [{
        name: "SellerId",
        value: sellerId,
      }],
      warehouseId,
    }),
    retry: 1,
    select: ({ total }) => total,
  });

  const tabs = [
    {
      id: "AvailableTab",
      title: t("fulfillmentPage.tabAvailable.title"),
      content: (
        <AvailableTab
          warehouseId={warehouseId}
          onCreateOrder={() => setAddWindowOpen(true)}
        />
      ),
      count: warehouseProductsTotal ?? 0,
    },
    {
      id: "OrdersTab",
      title: t("fulfillmentPage.tabOrders.title"),
      content: (
        <OrdersTab
          warehouseId={warehouseId}
        />
      ),
      count: requestsTotal ?? 0,
    },
    {
      id: "DiffTab",
      title: t("fulfillmentPage.tabDiff.title"),
      content: (
        <DiffTab warehouseId={warehouseId} sellerId={sellerId} />
      ),
      count: discrepanciesTotal ?? 0,
    },
  ];
  const { activeTab, setActiveTab } = useTab(tabs);

  const isEmpty = (activeTab === "AvailableTab") && !warehouseProductsTotal;

  return (
    <Page
      backLink="/fulfillment"
      title={warehouse?.name ?? ""}
      actions={(
        <>
          <HeaderFilters disabled />
          {isEmpty ? null : (
            <Button
              onClick={() => setAddWindowOpen(true)}
              title={`+ ${t("fulfillmentPage.order.createOrder")}`}
            />
          )}
        </>
      )}
    >
      <TabsList
        tabs={tabs}
        onChange={setActiveTab}
        value={activeTab}
      />

      <TabsContent
        tab={tabs.find((tab) => (tab.id === activeTab))}
      />

      {isAddWindowOpen ? (
        <RefillWarehouseModal
          onClose={() => setAddWindowOpen(false)}
          sellerId={sellerId}
          warehouseId={warehouseId}
        />
      ) : null}
    </Page>
  )
};

export default FulfillmentStore;
