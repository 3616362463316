import styles from './CardParcelModalWindow.module.scss';
import cn from 'classnames';
import CopyTextToClipboard from '@components/CopyTextToClipboard';
import InfoIcon from '@assets/icons/info-grey-18.svg';
// import ParcelIcon from '@assets/img/posylka.jpg';
import { Button, ButtonType } from '@components/Button';
import { ErrorResponse } from '@api/Responses/ErrorResponse';
import { formatSumWithCurrency } from '@utils/formatValues';
import { getParcel, TParcelResponse } from '@api/apiClient/returns';
import { ICardParcelModalWindowProps } from '../../types';
import { Loader } from '@components/Loader';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { VITE_APP_DEV } from '@utils/config';

const CardParcelModalWindow: React.FC<ICardParcelModalWindowProps> = (props: ICardParcelModalWindowProps): React.ReactElement => {
  const {
    callFrom,
    handlerSetIsOpenParcelWindow,
    handlerSetIsOpenReturnProcessingWindow,
    parcel
  } = props;

  const { t } = useTranslation();

  const query = useQuery<TParcelResponse, ErrorResponse>({
    queryKey: ['parcel', parcel.id],
    queryFn: () => getParcel(parcel.id),
    retry: 1
  });

  const tooltipText: string = `<b>${t('processingParcelModalWindow.dispose')}</b><br/><br/>${t('processingParcelModalWindow.disposeText')}<br/><br/><b>${t('processingParcelModalWindow.resell')}</b><br/><br/>${t('processingParcelModalWindow.resellText')}<br/><br/><b>${t('processingParcelModalWindow.return')}</b><br/><br/>${t('processingParcelModalWindow.returnText')}`;

  return (
      <div className={styles.filling}>
        {query.isFetching ? (
            <>
              <div className={styles.header}/>
              <div className={styles.body}>
                <Loader size="s"/>
              </div>
            </>
        ) : (
            <>
              <div className={styles.header}>
                <div className={styles.tracks}>
                  <CopyTextToClipboard
                      text={query?.data?.item.returnPostingNumber || ''}
                      visibleOnHover
                  >
                    <Text
                        color={TextColor.PRIMARY}
                        size={TextSize.L}
                        weight={TextWeight.BOLD}
                    >
                      {query?.data?.item.returnPostingNumber}
                    </Text>
                  </CopyTextToClipboard>
                  <CopyTextToClipboard
                      text={query?.data?.item.trackingNumber || ''}
                      visibleOnHover
                  >
                    <Text
                        color={TextColor.DARK_MUTED}
                        size={TextSize.S}
                    >
                      {query?.data?.item.trackingNumber}
                    </Text>
                  </CopyTextToClipboard>
                </div>
                {callFrom === 'awaitingTab' && (
                    <div className={styles.actions}>
                      <Tooltip
                          action={
                            <img
                                className={styles.icon}
                                alt="Info"
                                src={InfoIcon}
                            />
                          }
                          left={9}
                          text={tooltipText}
                          top={37}
                      />
                      <div className={styles.buttons}>
                        <Button
                            disabled={!VITE_APP_DEV}
                            onClick={() => handlerSetIsOpenParcelWindow(false)}
                            title={t('processingParcelModalWindow.dispose')}
                            type={ButtonType.RED_SKELETON}
                        />
                        <Button
                            disabled={!VITE_APP_DEV}
                            onClick={() => handlerSetIsOpenParcelWindow(false)}
                            title={t('processingParcelModalWindow.resell')}
                            type={ButtonType.GREEN_SKELETON}
                        />
                        <Button
                            disabled={!VITE_APP_DEV}
                            onClick={() => {
                              handlerSetIsOpenParcelWindow(false);
                              handlerSetIsOpenReturnProcessingWindow!(true);
                            }}
                            title={t('processingParcelModalWindow.returnFor') + ` ${formatSumWithCurrency(query?.data?.item?.reexportPrice?.amount, '¥')}`}
                        />
                      </div>
                    </div>
                )}
              </div>
              <div className={styles.body}>
                <div className={styles.info}>
                  <div className={styles.col}>
                    <Text
                        color={TextColor.DARK_MUTED}
                        ellipsis
                        size={TextSize.XS}
                    >
                      {t('processingParcelModalWindow.article')}
                    </Text>
                    <Text
                        color={TextColor.PRIMARY}
                        size={TextSize.S}
                        weight={TextWeight.BOLD}
                    >
                      ???
                    </Text>
                  </div>
                  <div className={styles.col}>
                    <Text
                        color={TextColor.DARK_MUTED}
                        size={TextSize.XS}
                    >
                      {t('processingParcelModalWindow.name')}
                    </Text>
                    <a
                        href={query?.data?.item.url}
                        rel="noreferrer"
                        target="_blank"
                    >
                      {query?.data?.item.title}
                    </a>
                  </div>
                  <div className={styles.col}>
                    <Text
                        color={TextColor.DARK_MUTED}
                        size={TextSize.XS}
                    >
                      {t('processingParcelModalWindow.price')}
                    </Text>
                    <Text
                        color={TextColor.PRIMARY}
                        ellipsis
                        size={TextSize.S}
                    >
                      {formatSumWithCurrency(query?.data?.item.priceCny, '¥')}
                    </Text>
                  </div>
                </div>
                {callFrom === 'awaitingTab' && (
                    <div className={styles.block}>
                      <Text
                          color={TextColor.PRIMARY}
                          size={TextSize.S}
                          weight={TextWeight.BOLD}
                      >
                        {t('processingParcelModalWindow.conditionGoods')}
                      </Text>
                      <div className={styles.status}>
                    <span className={styles.type}>
                      <span
                          className={cn(styles.mark,
                              query?.data?.item.productCondition === 'Bad' && styles.red,
                              query?.data?.item.productCondition === 'Good' && styles.green
                          )}
                      />
                      <Text
                          color={TextColor.PRIMARY}
                          size={TextSize.S}
                          weight={TextWeight.MEDIUM}
                      >
                        {t(`returnsPage.awaitingTab.states.${query?.data?.item.productCondition}`)}
                      </Text>
                    </span>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                        >
                          {query?.data?.item.returnReason}
                        </Text>
                      </div>
                    </div>
                )}
                {query?.data?.item.returnPhotos && query?.data?.item.returnPhotos.length > 0 && (
                    <div className={styles.block}>
                      <Text
                          color={TextColor.PRIMARY}
                          size={TextSize.S}
                          weight={TextWeight.BOLD}
                      >
                        {t('processingParcelModalWindow.photoBuyer')}
                      </Text>
                      <div className={styles.photos}>
                        {query?.data?.item.returnPhotos.map((item: { fileUrl: string; }, index: number) => (
                            <span
                                className={styles.photo}
                                key={index}
                            >
                              <img
                                  className={styles.image}
                                  alt="Photo"
                                  src={item.fileUrl}
                              />
                            </span>
                        ))}
                      </div>
                    </div>
                )}
                {/*{callFrom === 'awaitingTab' && (*/}
                {/*    <div className={styles.block}>*/}
                {/*      <Text*/}
                {/*          color={TextColor.PRIMARY}*/}
                {/*          size={TextSize.S}*/}
                {/*          weight={TextWeight.BOLD}*/}
                {/*      >*/}
                {/*        {t('processingParcelModalWindow.photoVerification')}*/}
                {/*      </Text>*/}
                {/*      <div className={styles.photos}>*/}
                {/*    <span className={styles.photo}>*/}
                {/*      <img*/}
                {/*          className={styles.image}*/}
                {/*          alt="Photo"*/}
                {/*          src={ParcelIcon}*/}
                {/*      />*/}
                {/*    </span>*/}
                {/*        <span className={styles.photo}>*/}
                {/*      <img*/}
                {/*          className={styles.image}*/}
                {/*          alt="Photo"*/}
                {/*          src={ParcelIcon}*/}
                {/*      />*/}
                {/*    </span>*/}
                {/*        <span className={styles.photo}>*/}
                {/*      <img*/}
                {/*          className={styles.image}*/}
                {/*          alt="Photo"*/}
                {/*          src={ParcelIcon}*/}
                {/*      />*/}
                {/*    </span>*/}
                {/*        <span className={styles.photo}>*/}
                {/*      <img*/}
                {/*          className={styles.image}*/}
                {/*          alt="Photo"*/}
                {/*          src={ParcelIcon}*/}
                {/*      />*/}
                {/*    </span>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*)}*/}
              </div>
            </>
        )}
      </div>
  );
};

export default CardParcelModalWindow;