import { useTranslation } from "react-i18next";
import Box from "@components/Box";
import { Row, Column } from "@components/Grid";
import { BeforeProps } from "@containers/DataTable";
import { Text, TextColor, TextSize, TextWeight } from "@components/Text";

export function SearchInfo<T>({
  items, total, isLoading, filters
}: BeforeProps<T>): React.ReactNode {
  const { t } = useTranslation();
  if (isLoading || !items.length) return null;

  const filter = (filters || []).find((v) => v.name === "trackOrExternalNumbers" || v.name === "trackNumbers");
  if (!filter?.value[0]?.length) return null
  const count = filter?.value[0].split(",")?.length;
  if (!count) return null

  return (
    <Box pt={4}>
      <Column phone={12}>
        <Row justify="right">
          <Text
            color={TextColor.DARK_MUTED}
            size={TextSize.S}
            weight={TextWeight.NORMAL}
            dangerouslySetInnerHTML={{
              __html: t(
                "filters.searchResult",
                {
                  found: `<b>${total}</b>`,
                  total: `<b>${count}</b>`,
                }
              )
            }}
          />
        </Row>
      </Column>
    </Box>
  );
}
