import { useState, ReactElement } from 'react';
import DataTable from "@containers/DataTable";
import Avatar from "@components/Avatar";
import { getReturnsPage, Return, ReturnTypes } from "@api/apiClient";
import { useTranslation } from "react-i18next";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import { AddSellerPanel } from "@components/AddSeller";
import NoItems from "@components/NoItems";
import { Row } from "@components/Grid";
import Box from "@components/Box";
import { Button, ButtonSize } from "@components/Button";
import Popup from "@components/Popup";
import { Modal } from "@components/Modal";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import CancelModalWindow from "../modals/CancelModalWindow.tsx";
import CopyTextToClipboard from "@components/CopyTextToClipboard";
import { formatDate, formatSumWithCurrency } from "@utils/formatValues";
import styles from "./returnsPageTabs.module.css";
import TableSelectionPanel from '@components/Table/TableSelectionPanel';

const DisposalTab = ({
  filters,
  pending,
  downloadingBtn,
  handlerSetSavedDisposalSelection,
  savedDisposalSelection,
  refreshData,
  sellersNotFound,
  noData,
}: {
  filters: FilterModel[],
  pending: boolean,
  downloadingBtn: ReactElement,
  handlerSetSavedDisposalSelection: (value: (((prevState: string[]) => string[]) | string[])) => void,
  savedDisposalSelection: string[],
  refreshData: () => void,
  sellersNotFound: boolean,
  noData: boolean,
}) => {
  const [isOpenCancelWindow, setIsOpenCancelWindow] = useState(false);
  const [cancelId, setIsCancelId] = useState("");
  const { t } = useTranslation();

  const columns: TableColumn<Return>[] = [
    {
      header: t("disposalTable.parcel_number"),
      accessor: (row: TableRowType<Return>) => (
        <CopyTextToClipboard text={row.data?.trackNumber}>
          <b>{row.data?.trackNumber}</b>
        </CopyTextToClipboard>
      ),
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("disposalTable.internal_number"),
      accessor: (row: TableRowType<Return>) => (
        <CopyTextToClipboard text={row.data?.externalId}>
          {row.data?.externalId ?? ""}
        </CopyTextToClipboard>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("disposalTable.service"),
      accessor: (row: TableRowType<Return>) => row.data.serviceName,
      alignment: "left",
      width: "200px",
    },
    {
      header: t("disposalTable.attachment"),
      accessor: (row: TableRowType<Return>) => {
        const firstItem = row.data.items?.[0];
        return firstItem ? (
          <a href={firstItem.url ?? ""} target="_blank" rel="noreferrer">
            {firstItem.name ?? ""}
          </a>
        ) : null;
      },
      alignment: "left",
      width: "250px",
    },
    {
      header: t("disposalTable.cost"),
      accessor: (row: TableRowType<Return>) =>
        formatSumWithCurrency(row.data?.declaredValue?.amount, "₽"),
      alignment: "left",
      width: "80px",
    },
    {
      header: t("disposalTable.weight"),
      accessor: (row: TableRowType<Return>) =>
        new Intl.NumberFormat().format(row.data?.weight.value) ?? "",
      alignment: "left",
      width: "80px",
    },
    {
      header: t("disposalTable.seller"),
      accessor: (row: TableRowType<Return>) => (
        <Row noWrap align="center">
          <Avatar
            className={styles.avatar}
            name={row.data.sellerName}
            id={row.data.sellerId}
          />
          <span className={styles.sellerText}>
            {row.data.sellerName}
          </span>
        </Row>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "220px",
    },
    {
      header: t("disposalTable.returnAt"),
      accessor: (row: TableRowType<Return>) => (
          <div>
            {formatDate(row.data.returnAt, "dd.MM.yy HH:mm")}
          </div>
      ),
      alignment: "left",
      width: "130px",
    },
    {
      header: "",
      accessor: (row: TableRowType<Return>) => (
        <Box beforeSpace pr={10}>
          {row.data.isCancelable ? (
            <Button
              onClick={() => {
                setIsCancelId(row.data.id);
                setIsOpenCancelWindow(true);
              }}
              size={ButtonSize.XS}
              title={t("disposalTable.resetButton")}
            />
          ) : (
            <Popup text={t("disposalTable.resetButtonNoty")}>
              <Button
                disabled
                onClick={() => {}}
                size={ButtonSize.XS}
                title={t("disposalTable.resetButton")}
              />
            </Popup>
          )}
        </Box>
      ),
      alignment: "left",
      noOverflow: true,
      width: "200px",
    },
  ];

  if (sellersNotFound) {
    return (
      <AddSellerPanel isOzonSeller />
    );
  }

  if (noData) {
    return (
      <NoItems
        title={t("noItems.returns.title")}
        description={t("noItems.returns.description")}
      />
    )
  }

  return (
    <TableSelectionPanel
        isShown={savedDisposalSelection.length > 0}
        items={[
          {
            label: t("awaitingTableSelectionPanel.selected"),
            value: savedDisposalSelection.length,
          }
        ]}
        buttons={[downloadingBtn]}
    >
      <DataTable<Return>
        queryKey="returns-page"
        pending={pending}
        getData={getReturnsPage}
        columns={columns}
        selectable
        selectedIds={savedDisposalSelection}
        onSelectionChange={handlerSetSavedDisposalSelection}
        notFound={t("disposalTab.notDisposal")}
        filters={[
          ...filters,
          { name: "ReturnTypes", value: [ReturnTypes.Disposal] },
        ]}
      />

      <Modal
        isOpen={isOpenCancelWindow}
        onClose={() => {
          setIsOpenCancelWindow(false);
        }}
        onClickOverlay={() => {
          setIsOpenCancelWindow(false);
        }}
      >
        <CancelModalWindow
          parcelId={cancelId}
          onSuccess={() => {
            handlerSetSavedDisposalSelection([]);
            refreshData()
            setIsCancelId("");
            setIsOpenCancelWindow(false);
          }}
          onClose={() => {
            setIsOpenCancelWindow(false);
            setIsCancelId("");
          }}
        />
      </Modal>
    </TableSelectionPanel>
  );
};

export default DisposalTab;
