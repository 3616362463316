import { Control, FieldValues, Path, useController } from "react-hook-form";
import StrengthPassword from "../components/StrengthPassword/StrengthPassword.tsx";

interface StrengthPasswordContainer<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
}

const StrengthPasswordContainer = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: StrengthPasswordContainer<TFieldValues>,
) => {
  const { field } = useController<TFieldValues>({
    control: props.control,
    name: props.name,
  });

  return <StrengthPassword password={field.value} />;
};

export default StrengthPasswordContainer;
