import { useTranslation } from "react-i18next";
import { getErrorCode, getErrorArguments, isApiErrorWithResponse } from "@api/apiClient";
import { Text, TextColor } from "@components/Text";
import { Row } from "@components/Grid";
import Box from "@components/Box";
import { isAdmin } from "@utils/auth";

function FormErrorMessage({ errorPrefix, error, defaultError, black, context }: {
  errorPrefix?: string,
  defaultError?: string,
  error: any,
  black?: boolean,
  context?: Record<string, string | ((data: Record<string, string>) => string)>,
}) {
  const { t } = useTranslation();
  const prefix = errorPrefix ?? "apiErrors";
  const isThisAdmin = isAdmin();

  let errorMessage = t(
    `${prefix}.default${isThisAdmin ? "-isAdmin" : ""}`,
    { defaultValue: t(defaultError ?? "defaultFormErrors.default") },
  );
  if (isApiErrorWithResponse(error)) {
    const args = getErrorArguments(error);
    const stringArgs = Object.keys(context ?? {})
      .reduce((acc: Record<string, string>, key: string) => {
        const item = context?.[key] ?? "";
        acc[key] = String(typeof item === "function" ? item(args) : item);
        return acc;
      }, {} as Record<string, string>);
    errorMessage = t(
      `${prefix}.${getErrorCode(error, "")}`,
      {
        defaultValue: errorMessage,
        ...args,
        ...stringArgs,
      },
    );
  } else if (typeof error === "string") {
    errorMessage = error;
  }

  return (
    <Row>
      <Box pt={2} flex="column">
        <Text
          color={black ? TextColor.PRIMARY : TextColor.ERROR}
          dangerouslySetInnerHTML={{
            __html: errorMessage,
          }}
        />
        {isThisAdmin ? (
          <Text
            color={black ? TextColor.PRIMARY : TextColor.ERROR}
            dangerouslySetInnerHTML={{
              __html: t(`${prefix}.defaultAdminDescription`, {
                defaultValue: "",
              }),
            }}
          />
        ) : null}
      </Box>
    </Row>
  )
}

export default FormErrorMessage
