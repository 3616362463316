import { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Avatar from "@components/Avatar";
import { Text, TextColor, TextSize, TextType, TextWeight } from "@components//Text";
import { Row, Column } from "@components/Grid";
import Box from "@components/Box/Box";
import Radio from "@components/Radio";
import { formatDate } from "@utils/formatValues";
import { ResaleItem } from "@api/apiClient";
import styles from "./ParcelListSelector.module.css";

interface ParcelListSelectorProps {
  onClick: (id: string) => void;
  parcels: {
    externalId: string,
    trackingNumber: string;
    createdAt: string;
    id: string;
    sellerName: string;
    sellerId: string;
    resaleItems: ResaleItem[];
    excessItems: ResaleItem[];
  }[];
}

const ParcelListSelector = (props: ParcelListSelectorProps) => {
  const [selected, setSelected] = useState<string>("");
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {props.parcels.map((parcel) => {
        return (
          <div
            key={parcel.id}
            onClick={() => {
              setSelected(parcel.id);
              props.onClick(parcel.id);
            }}
            className={classNames({
              [styles.item]: true,
              [styles.itemSelected]: selected === parcel.id,
            })}
          >
            <Radio
              id={parcel.id}
              className={styles.radio}
              checked={selected === parcel.id}
              markOnly
            />
            <Column phone={12}>
              <Row gap={2}>
                <Text
                  size={TextSize.M}
                  color={TextColor.PRIMARY}
                  type={TextType.SPAN}
                  weight={TextWeight.BOLD}
                >
                  {parcel.trackingNumber}
                </Text>
                <Text
                  size={TextSize.M}
                  color={TextColor.DARK_MUTED}
                  type={TextType.SPAN}
                  weight={TextWeight.BOLD}
                >
                  {parcel.externalId}
                </Text>
                <div className={styles.spacer} />
                <Text
                  size={TextSize.M}
                  color={TextColor.DARK_MUTED}
                  type={TextType.SPAN}
                >
                  {formatDate(parcel.createdAt, "dd.MM.yyyy")}
                </Text>
              </Row>
              <Box pt={2}>
                <Row gap={2} align="center">
                  <Avatar
                    id={parcel.sellerId}
                    name={parcel.sellerName}
                    className={styles.avatar}
                  />
                  <Text size={TextSize.XS}>
                    {parcel.sellerName}
                  </Text>
                </Row>
              </Box>
              {parcel.excessItems?.length ? (
                <Box pt={2}>
                  <Text
                    size={TextSize.XS}
                    color={TextColor.ERROR}
                    weight={TextWeight.BOLD}
                  >
                    {t("resaleModalWindow.warning", {
                      name: (parcel.resaleItems || []).map((v: ResaleItem) => v.name).join(", "),
                    })}
                  </Text>
                </Box>
              ) : null}
            </Column>
          </div>
        );
      })}
    </div>
  );
};

export default ParcelListSelector;
