import { sellerApiClient, getParams } from "./sellerApiClient";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";

export interface BunchItemNew {
  name: string,
  vendorCodeValues: string[],
}

export interface BunchItem {
  id: string,
  name: string,
  vendorCodeValues: string[],
}

export const getBunchList = async ({
  page,
  filters,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<ResponsePages<BunchItem>> => {
  const response = await sellerApiClient.get<ResponsePages<BunchItem>>(
    `v1/vendor-codes-groups/page`,
    {
      params: getParams(page, filters),
      paramsSerializer: { indexes: null },
    },
  );

  return response.data;
};

export const validateVendorCodes = async ({
  id,
  vendorCodeValues,
}: {
  id: string;
  vendorCodeValues: string[];
}): Promise<void> => {
  const response = await sellerApiClient.get<void>(
    `v1/vendor-codes-groups/validate-vendor-codes`,
    {
      params: {
        vendorCodeGroupId: id,
        vendorCodeValues,
      },
      paramsSerializer: { indexes: null },
    },
  );

  return response.data;
};

export const addVendorCodes = async ({
  id,
  vendorCodeValues,
}: {
  id: string;
  vendorCodeValues: string[];
}): Promise<void> => {
  if (!vendorCodeValues.length) return;
  const response = await sellerApiClient.post<void>(
    `v1/vendor-codes-groups/add-vendor-codes`,
    {
      vendorCodeGroupId: id,
      vendorCodeValues,
    },
  );

  return response.data;
};

export const removeVendorCodes = async ({
  id,
  vendorCodeValues,
}: {
  id: string;
  vendorCodeValues: string[];
}): Promise<void> => {
  if (!vendorCodeValues.length) return;
  const response = await sellerApiClient.post<void>(
    `v1/vendor-codes-groups/remove_vendor-codes`,
    {
      vendorCodeGroupId: id,
      vendorCodeValues,
    },
  );

  return response.data;
};

export const createBunch = async (data: BunchItemNew): Promise<{ vendorCodeGroupId: string }> => {
  const response = await sellerApiClient.post<{ vendorCodeGroupId: string }>(
    `/v1/vendor-codes-groups/create`,
    data,
  );

  return response.data;
};

export const removeBunch = async (id: string): Promise<void> => {
  const response = await sellerApiClient.delete<void>(
    `/v1/vendor-codes-groups/${id}`,
  );

  return response.data;
};

export interface UpdateBunchData {
  id: string;
  newName: string;
  addedVendorCodeValues: string[];
  removedVendorCodeValues: string[];
}

export const updateBunch = async ({
  id,
  newName,
  addedVendorCodeValues,
  removedVendorCodeValues,
}: UpdateBunchData): Promise<void> => {
  const response = await sellerApiClient.patch<void>(
    `/v1/vendor-codes-groups/${id}/update`,
    {
      newName,
      addedVendorCodeValues,
      removedVendorCodeValues,
    },
  );

  return response.data;
};

export interface ResponsePages<T> {
  total: number;
  items: T[];
}
