import styles from "./ButtonIcon.module.scss";
import cn from "classnames";
import { Loader } from "../Loader";

interface ButtonIconProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  icon: string | React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  pending?: boolean;
  disabled?: boolean;
  className?: string;
}

export const ButtonIcon = ({
  disabled,
  className,
  icon,
  pending,
  onClick,
}: ButtonIconProps) => {
  return (
    <div
      onClick={disabled ? (() => {}) : onClick}
      className={cn(
        styles.root,
        disabled && styles.disabled,
        className,
      )}
    >
      {pending ? (
        <div className={styles.loader}>
          <Loader size="xs" dark />
        </div>
      ) : (
        <>
          {typeof icon === "string" && (
            <img alt={"icon"} src={icon} />
          )}
          {typeof icon === "function" && icon({})}
        </>
      )}
    </div>
  );
};
