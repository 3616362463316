import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getSellers, Seller } from '@api/apiClient';
import { Text, TextColor, TextType } from "@components/Text";
import Grid from "@components/Grid/Grid.tsx";
import Column from "@components/Grid/Column.tsx";
import Row from "@components/Grid/Row.tsx";
import { Loader } from "@components/Loader";
import styles from "./storetabs.module.css";
import Store from '../components/Store/Store';

const StoreTab = () => {
  const { t } = useTranslation();

  const { isFetching, data, refetch } = useQuery({
    queryKey: ["sellers"],
    queryFn: () => getSellers(),
    retry: 1,
  });

  return (
    <div className={styles.root}>
      {isFetching ? (
        <Grid>
          <Row>
            <Column phone={12}>
              <Loader alignment={"center"} size={"s"} />
            </Column>
          </Row>
        </Grid>
      ) : (
        data?.items.length ? (
          data?.items.map((item: Seller, index: number) => (
            <Store
                key={index}
                refetchSellers={refetch}
                seller={item}
            />
          ))
        ) : (
          <Column phone={12}>
            <Text color={TextColor.DISABLED} type={TextType.H2}>
              {t("profileShops.noSellers")}
            </Text>
          </Column>
        )
      )}
    </div>
  );
};

export default StoreTab;
