import { useState, useRef, ComponentType } from "react";
import { CSSTransition } from "react-transition-group";
import useOutsideClick from "@hooks/useOutsideClick";
import cn from "classnames";
import styles from "./MultiSelect.module.css";

export interface MultiSelectOption<T> {
  id: string;
  value?: T;
}

export interface MultiSelectOptionProps<T = string> extends MultiSelectOption<T> {
  isSelected: boolean;
}

export interface ValueComponentProps<T = string> {
  values: MultiSelectOption<T>[];
  isOpen: boolean;
  options: MultiSelectOption<T>[];
}

export interface MultiSelectProps<T = string> {
  options: MultiSelectOption<T>[];
  value: MultiSelectOption<T>[];
  onChange: (value: MultiSelectOption<T>[]) => void;
  onClose?: () => void;
  ValueComponent?: ComponentType<ValueComponentProps<T>>;
  OptionComponent?: ComponentType<MultiSelectOptionProps<T>>;
  menuAlign?: "left" | "right";
  MenuFooter?: ComponentType<{ onClose: () => void }>;
}

const MultiSelect = <T = string>({
  value,
  options,
  onChange,
  onClose,
  ValueComponent,
  OptionComponent,
  menuAlign,
  MenuFooter,
}: MultiSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = () => {
    onClose && onClose();
    setIsOpen(false);
  }

  useOutsideClick<HTMLDivElement>(rootRef, handleOutsideClick);

  const handleOptionClick = (option: MultiSelectOption<T>) => {
    const idx = value.findIndex(v => v.id === option.id);
    let res;
    if (idx > -1) {
      res = [...value.slice(0, idx), ...value.slice(idx + 1)];
    } else {
      res = [...value, option];
    }
    onChange && onChange(res);
  };

  return (
    <div className={cn(styles.root)} ref={rootRef}>
      <div
        className={cn(styles.selected)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {ValueComponent ? (
          <ValueComponent
            values={value}
            isOpen={isOpen}
            options={options}
          />
        ) : (typeof value[0]?.value === "string" ? value[0]?.value : "")}
      </div>

      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames={{
          enter: styles.menuEnter,
          enterActive: styles.menuEnterActive,
          exit: styles.menuExit,
          exitActive: styles.menuExitActive,
        }}
        mountOnEnter
        unmountOnExit
        nodeRef={menuRef}
      >
        <div
          className={cn(
            styles.menu,
            menuAlign === "left" ? styles.menuLeft : styles.menuRight,
          )}
          ref={menuRef}
        >
          <div className={styles.menuMain}>
            {options.map((option) => (
              OptionComponent ? (
                <div
                  id={option.id}
                  key={option.id}
                  onClick={() => handleOptionClick(option)}
                >
                  <OptionComponent
                    {...option}
                    isSelected={Boolean(value.find(v => v.id === option.id))}
                  />
                </div>
              ) : (
                <div
                  key={option.id}
                  className={cn(styles.menuItem)}
                  onClick={() => handleOptionClick(option)}
                >
                  {typeof option?.value === "string" ? option?.value : ""}
                </div>
              )
            ))}
          </div>
          {MenuFooter ? (
            <MenuFooter onClose={() => setIsOpen(false)} />
          ) : null}
        </div>
      </CSSTransition>
    </div>
  );
};

export default MultiSelect;
