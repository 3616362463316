import { useTranslation } from "react-i18next";
import { TimelineItem, EventStatus } from './TimelineItem';
import { TrackEvent } from "@api/apiClient";
import { events, returnEvents, EventInfo } from "@utils/trackEvents";
import "./ParcelModalTimeline.css";

interface ParcelModalTimelineProps {
  items: TrackEvent[],
  countryCode: string;
  isReturn: boolean,
}

type ExtendedTrackEvent = Partial<TrackEvent> & {
  id: string;
  status: EventStatus;
  name: string;
  statuses: TrackEvent[];
}

const ParcelModalTimeline = ({ items, countryCode, isReturn }: ParcelModalTimelineProps) => {
  const { t } = useTranslation();
  const data: ExtendedTrackEvent[] = (isReturn ? returnEvents : events)
    .map((item: EventInfo, eventIdx: number) => {
      const event = items.find(
        v => item.ids ? item.ids.includes(v.type) : (String(v.type) === item.id)
      );
      return {
        ...event,
        id: event?.id ? String(event.type) : `empty-${item.id}-${eventIdx}`,
        name: t(`trackEventTypes.${String(event?.type || item.id)}`, { context: countryCode }),
        status: (event || (item.id === "0")) ? EventStatus.DONE : EventStatus.WAIT,
        statuses: item.childIds?.length
          ? items.filter(v => (
            (v.type > (item?.childIds?.[0] ?? 0))
            && (v.type < (item?.childIds?.[1] ?? 99999))
          ))
          : [],
      };
    });

  let lastDoneIdx = -1;
  data.forEach((item: ExtendedTrackEvent, idx: number) => {
    if (item.status === EventStatus.DONE) lastDoneIdx = idx;
  });
  if (lastDoneIdx > -1) {
    for (let idx = 0; idx < lastDoneIdx; idx++) {
      data[idx].status = EventStatus.DONE;
    }
    data[lastDoneIdx].status = EventStatus.ACTIVE;
  }

  return (
    <div className="parcel-modal-pickup-items">
      {data.map((v) => (
        <TimelineItem
          key={v.id}
          {...v}
        />
      ))}
    </div>
  )
}

export default ParcelModalTimeline;
