import { Link, useLocation } from "react-router-dom";
import {
  getAccountBySecurityStamp,
  registrationBySecurityStamp,
  isApiErrorWithResponse,
} from "../../api/apiClient";
import { useQuery, useMutation } from 'react-query';
import RegistrationForm, { RegistrationFormType } from "./RegistrationForm.tsx";
import { Loader } from "../../components/Loader";
import Column from "../../components/Grid/Column.tsx";
import Grid from "../../components/Grid/Grid.tsx";
import Row from "../../components/Grid/Row.tsx";
import Icon from "../../assets/icons/request-error.svg";
import { Text, TextColor, TextSize, TextType } from "../../components/Text";
import Box from "../../components/Box/Box.tsx";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAuth } from "../../contexts/AuthenticationContext";

const RegistrationPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const key = params.get("key");
  const { t } = useTranslation();
  const query = useQuery({
    queryKey: ["account", key],
    queryFn: ({ queryKey }) => getAccountBySecurityStamp(queryKey[1] ?? ""),
    retry: 1,
    enabled: true,
  });
  const [registrationError, setRegistrationError] = useState<string | null>(
    null,
  );
  const [registrationADError, setRegistrationAdError] = useState<string | null>(
    null,
  );
  const auth = useAuth();
  const {
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: registrationBySecurityStamp,
    onMutate: () => {
      setRegistrationError("");
      setRegistrationAdError("");
    },
    onError: (e) => {
      if (isApiErrorWithResponse(e)) {
        const index = e.response.data.message.indexOf("exists");

        if (index !== -1) {
          setRegistrationError("registrationPage.user-exists");
        } else {
          setRegistrationError("registrationPage.contact-support");
          setRegistrationAdError(e.response.data.message);
        }
      } else {
        setRegistrationError("registrationPage.user-exists");
      }
    }
  });

  const clean = (input: string): string => {
    const decodedUrl = input.replace(/%[0-9A-Fa-f]{2}/g, "");

    // Фильтрация URL для оставления только поддерживаемых символов
    const regex = /[a-zA-Z0-9/+?=]/g;
    const matches = decodedUrl.match(regex);

    return matches ? matches.join("") : "";
  };

  const handleSubmit = (form: RegistrationFormType) => {
    mutate({
      email: form.email,
      name: form.name,
      password: form.password,
      phone: form.phone,
      securityStamp: key ?? "",
    }, {
      onSuccess: () => {
        auth.login({ username: form.email, password: form.password });
      },
    });
  };

  return (
    <div>
      {registrationError && (
        <Grid>
          <Row>
            <Column phone={3}>
              <img alt={"Icon"} src={Icon} />
            </Column>
            <Column phone={8}>
              <Box pb={12}>
                <Text type={TextType.H1}>Oops, something went wrong</Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={5}>
                <Text
                  size={TextSize.M}
                  color={TextColor.DISABLED}
                  type={TextType.BLOCK}
                >
                  {t(registrationError)}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={5}>
                <Text
                  size={TextSize.M}
                  color={TextColor.DISABLED}
                  type={TextType.BLOCK}
                >
                  {registrationADError}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={5}>
                <Text
                  size={TextSize.M}
                  color={TextColor.DISABLED}
                  type={TextType.BLOCK}
                >
                  <Link
                    to={location.pathname + location.search}
                    onClick={() => {
                      setRegistrationError("");
                      setRegistrationAdError("");
                    }}
                  >
                    {t("registrationPage.reasonLink1")}
                  </Link>
                </Text>
              </Box>
            </Column>
          </Row>
        </Grid>
      )}

      {query.isError && (
        <div>
          <Grid>
            <Row>
              <Column phone={3}>
                <img alt={"Icon"} src={Icon} />
              </Column>
              <Column phone={8}>
                <Box pb={12}>
                  <Text type={TextType.H1}>
                    {t("registrationPage.errorLinkTitle")}
                  </Text>
                </Box>
              </Column>
            </Row>
            <Row>
              <Column phone={12}>
                <Box pb={5}>
                  <Text
                    size={TextSize.M}
                    color={TextColor.DISABLED}
                    type={TextType.BLOCK}
                  >
                    {location.pathname}+{location.search}
                  </Text>
                </Box>
              </Column>
            </Row>
            {location.pathname + location.search !==
              clean(location.pathname + location.search) && (
              <>
                <Row>
                  <Column phone={12}>
                    <Box pb={2}>
                      <Text size={TextSize.M} type={TextType.BLOCK}>
                        {t("registrationPage.reasonProblem1")}
                      </Text>
                    </Box>
                  </Column>
                </Row>
                <Row>
                  <Column phone={12}>
                    <Box pb={5}>
                      <Text size={TextSize.M} type={TextType.BLOCK}>
                        <Link to={clean(location.pathname + location.search)}>
                          {t("registrationPage.reasonLink1")}
                        </Link>
                      </Text>
                    </Box>
                  </Column>
                </Row>
              </>
            )}
            <Row>
              <Column phone={12}>
                <Box pb={2}>
                  <Text size={TextSize.M} type={TextType.BLOCK}>
                    {t("registrationPage.reasonProblem2")}
                  </Text>
                </Box>
              </Column>
            </Row>
            <Row>
              <Column phone={12}>
                <Box pb={5}>
                  <Text size={TextSize.M} type={TextType.BLOCK}>
                    <Link to={"login"}>
                      {t("registrationPage.reasonLink2")}
                    </Link>
                  </Text>
                </Box>
              </Column>
            </Row>
            <Row>
              <Column phone={12}>
                <Box pb={2}>
                  <Text size={TextSize.M} type={TextType.BLOCK}>
                    {t("registrationPage.support")}
                  </Text>
                </Box>
              </Column>
            </Row>
          </Grid>
        </div>
      )}
      {query.isLoading && (
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={2} textAlign={"center"}>
                <Loader />
              </Box>
            </Column>
          </Row>
        </Grid>
      )}
      {query.isSuccess && !registrationError && (
        <RegistrationForm
          companyName={query.data.company.name}
          name={query.data.name}
          email={query.data.email}
          phone={query.data.phone}
          onSubmit={handleSubmit}
          pending={isLoading}
        />
      )}
    </div>
  );
};

export default RegistrationPage;
