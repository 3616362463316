import { Link } from "react-router-dom";
import {
  Text,
  TextColor,
  TextSize,
  TextWeight,
} from "@components/Text";
import styles from "./DocumentLink.module.css";

const DocumentLink = ({ icon, text, url, fileSize }: {
  icon: string,
  text: string,
  url: string,
  fileSize?: string,
}) => {
  const format: string = (url || "").slice(url.lastIndexOf('.') + 1).toUpperCase();

  return (
    <Link
      className={styles.docsLink}
      to={url}
      target="_blank"
    >
      <img className={styles.docsLinkIcon}
        src={icon}
        alt="icon"
      />
      <Text
        color={TextColor.BLUE}
        weight={TextWeight.MEDIUM}
        size={TextSize.L}
      >
        {text}
      </Text>
      <Text
        color={TextColor.DARK_MUTED}
        weight={TextWeight.NORMAL}
        size={TextSize.L}
      >
        {format} {fileSize ? `, ${fileSize}` : ""}
      </Text>
    </Link>
  );
}

export default DocumentLink;
