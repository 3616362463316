import { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Text, TextType, TextSize } from "@components/Text";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import { DataViewHandle } from "@containers/DataTable";
import {
  getBunchList,
  BunchItem,
} from '@api/apiClient/bunch';
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import Page from "@components/Page";
import PlusIcon from "@assets/icons/plus.svg";
import DataTable from "@containers/DataTable";
import EditBunchModal from "./EditBunchModal";
import styles from "./BunchPage.module.scss";

const BunchPage = () => {
  const [activeBunch, setActiveBunch] = useState<BunchItem | null>(null);
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [isEditBunchOpen, setEditBunchOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const dataRef = useRef<DataViewHandle>(null);

  const columns: TableColumn<BunchItem>[] = [
    {
      header: t("bunchPage.product"),
      accessor: (row: TableRowType<BunchItem>) => (
        <span
          className={styles.product}
        >
          {row.data.name}
        </span>
      ),
      alignment: "left",
      width: "425px"
    },
    {
      header: t("bunchPage.count"),
      accessor: (row: TableRowType<BunchItem>) => row.data.vendorCodeValues.length,
      type: TableColumnType.TERTIARY,
      alignment: "right",
      width: "auto"
    },
    {
      header: t("bunchPage.articles"),
      accessor: (row: TableRowType<BunchItem>) => row.data.vendorCodeValues.join(", "),
      alignment: "left",
      width: "425px"
    },
  ];

  return (
    <Page
      title={t("bunchPage.title")}
      actions={(
        <>
          <Filters
            items={[
              {
                name: "SearchWords",
                type: FilterTypes.MULTI_SEARCH_OR_NAME,
                placeholder: t("bunchPage.trackNumberSearch"),
              },
            ]}
            onChange={(v) => setFilters(v)}
            values={filters}
          />
          <Button
            icon={PlusIcon}
            onClick={() => {
              setActiveBunch(null);
              setEditBunchOpen(true);
            }}
            size={ButtonSize.S}
            type={ButtonType.ROUNDED}
            title={t("bunchPage.createBunch")}
          />
        </>
      )}
    >
      <Box pb={5}>
        <Row>
          <Column phone={12}>
            <Text size={TextSize.M} type={TextType.BLOCK}>
              {t("bunchPage.description")}
            </Text>
          </Column>
        </Row>
      </Box>

      <DataTable<BunchItem>
        queryKey="bunch-list"
        getData={getBunchList}
        columns={columns}
        notFound={t("bunchPage.notReturns")}
        filters={filters}
        dataRef={dataRef}
        rowOnClick={(data) => {
          setActiveBunch(data);
          setEditBunchOpen(true);
        }}
        className={styles.table}
      />

      {isEditBunchOpen ? (
        <EditBunchModal
          data={activeBunch}
          onClose={() => setEditBunchOpen(false)}
          onSuccess={() => {
            if (typeof dataRef.current?.refresh === "function") {
              dataRef.current?.refresh();
            }
            setEditBunchOpen(false);
          }}
        />
      ) : null}
    </Page>
  );
};

export default BunchPage;
