import { useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import cn from "classnames";
import { getWarehouseRefillRequests, RefillRequest } from "@api/apiClient/fulfillment";
import { weightKg, formatDate } from "@utils/formatValues";
import { Text, TextColor, TextSize, TextWeight } from "@components/Text";
import DataList, { ListField, DataViewHandle } from "@containers/DataList";
import styles from "./OrdersTab.module.css";

function getStatusClassName(status: string) {
  if (status === "Cancelled") return styles.statusMarkError
  if (status === "Created") return styles.statusMarkInProcess
  if (status === "PartiallyApproved") return styles.statusMarkInProcess
  if (status === "FullyApproved") return styles.statusMarkSuccess
  return null
}

const OrdersTab = ({ warehouseId }: { warehouseId: string }) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const dataRef = useRef<DataViewHandle>(null);
  const navigate = useNavigate();

  const fields: ListField<RefillRequest>[] = useMemo(() => [
    {
      label: t("fulfillmentPage.refillOrders.number"),
      value: (data) => (
        <Text
          size={TextSize.L}
          color={TextColor.LIGHT_BLUE}
          weight={TextWeight.MEDIUM}
        >
          {data.number}
        </Text>
      ),
    },
    {
      label: t("fulfillmentPage.refillOrders.count"),
      value: (data) => (data.productsCount),
    },
    {
      label: t("fulfillmentPage.refillOrders.weight"),
      value: (data) => {
        if (+(weightKg(data.totalWeight) || 0) >= 1) {
          return (`${weightKg(data.totalWeight)} ${t(`units.weight.kg`)}`)
        } else {
          const unit = t(
            `units.weight.${data.totalWeight?.unit}`,
            { defaultValue: data.totalWeight?.unit || "" },
          );
          return `${data.totalWeight?.value} ${unit}`;
        }
      },
    },
    {
      label: t("fulfillmentPage.refillOrders.date"),
      value: (data) => (formatDate(data.createdAt , "dd.MM.yy")),
    },
    {
      label: t("fulfillmentPage.refillOrders.status"),
      value: (data) => (
        <div>
          <div className={cn(styles.statusMark, getStatusClassName(data.status))} />
          {t(`fulfillmentPage.requestStatuses.${data.status}`)}
        </div>
      ),
    },
  ], [currentLanguage]);

  const getRefillRequests = useCallback(
    ({ page, filters }: {
      page: PaginationModel,
      filters?: FilterModel[],
    }) => getWarehouseRefillRequests({ page, filters, warehouseId }),
    [warehouseId]
  )

  return (
    <div>
      <DataList<RefillRequest>
        queryKey={`getWarehouseRefillRequests-${warehouseId}`}
        getData={getRefillRequests}
        notFound={t("fulfillmentPage.tabOrders.noOrders")}
        filters={[]}
        viewProps={{
          onClick: (data) => navigate(`/fulfillment/${warehouseId}/request/${data.id}`),
          getItemKey: (data: RefillRequest) => data.id,
          fields,
        }}
        dataRef={dataRef}
      />
    </div>
  );
};

export default OrdersTab;
