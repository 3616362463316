import { useState, useRef, ComponentType } from "react";
import { CSSTransition } from "react-transition-group";
import useOutsideClick from "@hooks/useOutsideClick";
import cn from "classnames";
import "./Dropdown.css";

type ValueDefaultType = string | undefined

interface SelectedOption<V> {
  id: string | symbol | number;
  value?: V;
}

export interface DropdownOptionProps<T, V = ValueDefaultType> {
  id: T;
  value?: V;
}

export interface DropdownValueProps<T, V = ValueDefaultType> {
  id: T;
  value: SelectedOption<V>;
  isOpen: boolean;
  disabled?: boolean;
}

interface DropdownProps<T, V> {
  options: DropdownOptionProps<T, V>[];
  defaultOption: DropdownOptionProps<T, V>;
  onChange?: (id: T, value?: V) => void;
  ValueComponent?: ComponentType<DropdownValueProps<T, V>>;
  OptionComponent?: ComponentType<DropdownOptionProps<T, V>>;
  menuAlign?: "left" | "right";
  menuPosition?: "top" | "bottom";
  disabled?: boolean;
}

function Dropdown<T extends string | symbol | number = string, V = ValueDefaultType>({
  defaultOption,
  options,
  onChange,
  ValueComponent,
  OptionComponent,
  menuAlign,
  menuPosition,
  disabled,
}: DropdownProps<T, V>) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOptionProps<T, V>>(defaultOption);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useOutsideClick<HTMLDivElement>(dropdownRef, () => setIsOpen(false));

  const handleOptionClick = (option: DropdownOptionProps<T, V>) => {
    setIsOpen(false); // Закрываем дропдаун после выбора
    setSelectedOption(option);
    onChange && onChange(option.id, option.value);
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className={cn("dropdown__selected", disabled && "dropdown__selected--disabled")}
        onClick={() => (!disabled && setIsOpen(!isOpen))}
      >
        {ValueComponent ? (
          // @ts-ignore: todo
          <ValueComponent
            {...selectedOption}
            value={selectedOption}
            isOpen={isOpen}
            disabled={disabled}
          />
        ) : (<>{selectedOption?.value}</>)}
      </div>

      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="dropdown__menu"
        mountOnEnter
        unmountOnExit
        nodeRef={dropdownMenuRef}
      >
        <div
          className={cn(
            "dropdown__menu",
            `dropdown__menu--${menuAlign === "left" ? "left" : "right"}`,
            `dropdown__menu--${menuPosition === "top" ? "top" : "bottom"}`,
          )}
          ref={dropdownMenuRef}
        >
          {options.map((option) => (
            OptionComponent ? (
              <div
                key={String(option.id)}
                onClick={() => handleOptionClick(option)}
              >
                <OptionComponent {...option} />
              </div>
            ) : (
              <div
                className="dropdown__item"
                key={String(option.id)}
                onClick={() => handleOptionClick(option)}
              >
                <>{option?.value}</>
              </div>
            )
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default Dropdown;
