import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";
import Box from "@components/Box";
import { Column, Grid, Row } from '@components/Grid';
import { Button, ButtonType } from "@components/Button";
import { cancelReturn } from "@api/apiClient";
import { Text, TextColor, TextSize, TextType } from "@components/Text";
import FormErrorMessage from "@components/FormErrorMessage";

interface DisposalQueueModalWindowProps {
  onClose: () => void;
  parcelId: string;
  onSuccess: () => void;
  tabType: string;
}

const DisposalQueueModalWindow = (props: DisposalQueueModalWindowProps) => {
  const { t } = useTranslation();
  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: cancelReturn,
    onSuccess: () => {
      props.onSuccess();
    },
  });
  const submit = () => {
    mutate(props.parcelId);
  };

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={10} pt={4}>
              <Text type={TextType.H2} color={TextColor.PRIMARY}>
                {t("disposalQueueModalWindow.title")}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={10} pt={4}>
              <Text
                  type={TextType.SPAN}
                  size={TextSize.L}
                  color={TextColor.PRIMARY}
              >
                {t(`disposalQueueModalWindow.description.${props.tabType}`)}
              </Text>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box beforeSpace={true}>
              <Button
                  onClick={submit}
                  pending={isLoading}
                  title={t("disposalQueueModalWindow.recycle")}
                  type={ButtonType.SKELETON}
              />
              <div style={{ marginRight: 10 }}></div>
              <Button
                  onClick={props.onClose}
                  title={t("disposalQueueModalWindow.cancel")}
                  type={ButtonType.TERTIARY}
              />
            </Box>
          </Column>
        </Row>

        {isError ? (
          <FormErrorMessage error={error} />
        ) : null}
      </Grid>
  );
};

export default DisposalQueueModalWindow;
