import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import {
  getParcelImages,
  getParcelResaleLink,
  Return,
  setResale,
  getErrorCode,
} from "@api/apiClient";
import { Loader } from "@components/Loader";
import Avatar from "@components/Avatar";
import Box from "@components/Box/Box";
import { Text, TextColor, TextSize, TextType, TextWeight } from "@components/Text";
import { Grid, Column, Row } from "@components/Grid";
import { Button, ButtonType } from "@components/Button";
import { ParcelListSelector } from "@components/ParcelListSelector";
import styles from "./ResaleModalWindow.module.css";

interface ResaleModalWindowProps {
  onSuccess: () => void;
  resale: Return | null;
}

const ResaleModalWindow = ({ resale, onSuccess }: ResaleModalWindowProps) => {
  const { t } = useTranslation();
  const [selectedResale, setSelectedResale] = useState<string>("");

  const imagesQuery = useQuery({
    queryKey: ["parcelImages", resale?.id],
    queryFn: () => getParcelImages(resale?.id || ""),
    retry: 1,
  });

  const resaleQuery = useQuery({
    queryKey: ["getParcelResaleLink", resale?.id],
    queryFn: () => getParcelResaleLink([resale?.id || ""]),
    retry: 1,
  });

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: setResale,
    onSuccess: onSuccess,
  });

  if (!resale) {
    onSuccess();
    return null;
  }

  const submit = () => {
    mutate({
      resaleId: selectedResale,
      returnId: resale.id,
    });
  };

  const firstItem = resale.items?.[0] || { name: "", url: "" };

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <Text type={TextType.H1}>
              {t("resaleModalWindow.title")}
            </Text>
          </Box>
        </Column>
      </Row>

      <Row>
        {imagesQuery?.data?.imageLinks?.length ? (
          <div className={styles.img}>
            <img src={imagesQuery?.data?.imageLinks[0]} />
          </div>
        ) : null}
        <Column>
          <Box pb={2}>
            <Row gap={2}>
              <Text size={TextSize.S} weight={TextWeight.BOLD}>
                {resale.trackNumber}
              </Text>
              <Text size={TextSize.XS} color={TextColor.DARK_MUTED}>
                {resale.externalId}
              </Text>
            </Row>
          </Box>
          <Box pb={3}>
            <Row gap={2}>
              {firstItem.url ? (
                <a
                  href={firstItem.url}
                  target="_blank"
                  className={styles.link} rel="noreferrer"
                >
                  {firstItem.name}
                </a>
              ) : (
                firstItem.name
              )}
            </Row>
          </Box>
          <Box>
            <Row gap={2} align="center">
              <Avatar
                id={resale.sellerId}
                name={resale.sellerName}
                className={styles.avatar}
              />
              <Text size={TextSize.XS}>
                {resale.sellerName}
              </Text>
            </Row>
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={5} pt={5}>
            <Text size={TextSize.M} weight={TextWeight.BOLD}>
              {t("resaleModalWindow.description")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={8}>
            {resaleQuery.isLoading ? (
              <Loader size="s" />
            ) : (
              <ParcelListSelector
                onClick={(id) => {
                  setSelectedResale(id);
                }}
                parcels={
                  (resaleQuery.data?.items?.[0]?.parcelResaleLinks || [])
                  .map((link) => ({
                    id: link.resale.id.toString(),
                    trackingNumber: link.resale.trackNumber,
                    createdAt: link.resale.createdAt,
                    externalId: link.resale.externalId,
                    sellerName: link.resale.sellerName,
                    sellerId: link.resale.sellerId,
                    resaleItems: link.resale?.resaleItems || [],
                    excessItems: link.excessItems,
                  })) ?? []
                }
              />
            )}
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box>
            <Button
              type={ButtonType.BLOCK}
              onClick={submit}
              pending={isLoading}
              title={t("resaleModalWindow.button")}
              disabled={!selectedResale}
            />
          </Box>
        </Column>
      </Row>
      {isError ? (
        <Row>
          <Box pt={2}>
            <Text color={TextColor.ERROR}>
              {getErrorCode(error, "Unknown error")}
            </Text>
          </Box>
        </Row>
      ) : null}
    </Grid>
  );
};

export default ResaleModalWindow;
