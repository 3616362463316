import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import checkmark from "./checkmark.svg";
import cn from "classnames";
import styles from "./Radio.module.css";

interface RadioProps {
  id: string;
  checked: boolean;
  onChange?: (checked: boolean, value?: string) => void;
  className?: string;
  value?: string;
  markOnly?: boolean;
  label?: React.ReactNode,
}

const Label = ({ id, checked, markOnly, children, label }: {
  id: string,
  checked: boolean,
  markOnly?: boolean,
  children?: React.ReactNode,
  label?: React.ReactNode,
}) => {
  if (markOnly) return (<>{children}</>)

  return (
    <label
      htmlFor={id}
      className={cn(
        styles.label,
        checked && styles.labelChecked,
      )}
    >
      {children}
      {label}
    </label>
  );
}

const Radio = ({
  id,
  checked,
  onChange,
  className,
  value,
  markOnly,
  label,
}: RadioProps) => {
  const radioRef = useRef(null);

  return (
    <div className={cn(styles.root, className)}>
      <input
        type="radio"
        id={id}
        checked={checked}
        onChange={() => onChange && onChange(!checked, value)}
      />
      <Label
        id={id}
        checked={checked}
        markOnly={markOnly}
        label={label}
      >
        <span  className={styles.mark}>
          <CSSTransition
            nodeRef={radioRef}
            in={checked}
            out="true"
            timeout={500}
            classNames={{
              enter: styles.markEnter,
              enterActive: styles.markEnterActive,
              exit: styles.markExit,
              exitActive: styles.markExitActive,
              exitDone: styles.markExitDone,
            }}
            mountOnEnter
          >
            <img ref={radioRef} src={checkmark} />
          </CSSTransition>
        </span>
      </Label>
    </div>
  );
};

export default Radio;
