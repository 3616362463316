import { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { Filter, FilterTypes } from "@containers/Filter";
import { useFiltersSearchParams, FilterModel } from "@hooks/useFiltersSearchParams";
import CrossIcon from "@assets/icons/cross-grey-16.svg";
import { ButtonIcon } from "@components/ButtonIcon";
import styles from "./Filters.module.css";

// interface FilterValue {
//   name: string,
//   value: string[] | string,
//   fieldNames?: string[],
// }

interface FiltersDescription {
  name: string,
  type: FilterTypes,
  placeholder?: string,
  fieldNames?: string[],
}

// interface FiltersItem {
//   name: string,
//   value: string[] | string,
// }

const Filters = ({ items, onChange, values, asPanel }: {
  items: FiltersDescription[],
  onChange?: (v: FilterModel[]) => void,
  values?: FilterModel[],
  asPanel?: boolean,
}) => {
  const [initialValues, setInitialValues] = useState<Record<string, string[]>>({});
  const { filters, updateFilter, setFilters } = useFiltersSearchParams();
  const key = useMemo(() => JSON.stringify(new Date), [initialValues]);

  useEffect(() => {
    const values = filters.reduce<Record<string, string[]>>((
      acc: Record<string, string[]>,
      v: FilterModel,
    ) => {
      const res = { ...acc };
      if (items.some(f => f.name === v.name)) {
        res[v.name] = [
          ...(acc[v.name] || []),
          ...(Array.isArray(v.value) ? v.value : [v.value]),
        ];
      }
      return res;
    }, {});
    setInitialValues(values);
    onChange && onChange((Object.keys(values) || []).map((k) => ({
      name: k,
      value: values[k],
    })));
  }, [])

  return (
    <div key={key} className={cn(styles.filters, asPanel && styles.filtersPanel)}>
      {items.map(f => (
        <Filter
          key={`${key}-${f.name}`}
          initialValue={initialValues[f.name]}
          placeholder={f.placeholder}
          type={f.type}
          onChange={(v: string[], rv?: string[]) => {
            updateFilter({
              name: f.name,
              value: v,
            });
            onChange && onChange([
              ...(values || []).filter(item => (item.name !== f.name)),
              {
                name: f.name,
                fieldNames: f.fieldNames,
                value: rv?.length ? rv : v,
              },
            ]);
          }}
          asPanel={asPanel}
        />
      ))}
      {asPanel ? (
        <ButtonIcon
          className={styles.closeBtn}
          icon={CrossIcon}
          onClick={() => {
            setFilters(items.map((f) => ({
              name: f.name,
              value: "",
            })));
            onChange && onChange([
              ...(values || []).map(item => {
                const filter = items.find(f => item.name === f.name);
                if (!filter) return item;
                return { ...item, value: [] };
              }),
            ]);
            setInitialValues(items.reduce((acc, v) => ({ ...acc, [v.name]: "" }), {}))
          }}
        />
      ) : null}
    </div>
  );
}

export { Filters, FilterTypes };
