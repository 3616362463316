import "./ProgressBar.css";
import cn from "classnames";

interface ProgressBarProps {
  progress: number;
  className?: string;
  cells?: boolean;
  rtl?: boolean;
  label?: React.ReactNode;
  reverseColors?: boolean;
  column?: boolean;
  rounded?: boolean;
  colors?: Color[];
}

export interface Color {
  value: number;
  main: string;
  label?: string;
  bg?: string;
}

const getColors = (value: number, colors: Color[]): Color => {
  return colors.reduce((acc: Color, v: Color) => {
    if (!acc) return v;
    if ((acc.value <= v.value) && (value >= v.value)) return v;
    return acc;
  }, { value: 0, main: "" });
}

const ProgressBar = (props: ProgressBarProps) => {
  const defaultColors = (props.cells || props.rtl || props.reverseColors) ? [
    { value: 70, main: "#5ec983", bg: "#ceefda", label: "#5ec983" },
    { value: 40, main: "#f2af4c", bg: "#fcefdb", label: "#f2af4c" },
    { value: 0, main: "#ee6373", bg: "#fce0e3", label: "#ee6373" },
  ] : [
    { value: 0, main: "#5ec983", bg: "#ceefda", label: "#5ec983" },
    { value: 40, main: "#f2af4c", bg: "#fcefdb", label: "#f2af4c" },
    { value: 70, main: "#ee6373", bg: "#fce0e3", label: "#ee6373" },
  ];
  const color = getColors(props.progress, props.colors || defaultColors);

  return (
    <div className={cn("progress-bar-root", props.column && "progress-bar-root--column")}>
      <div
        className={cn(
          props.className,
          "progress-bar__bg",
          props.cells && "progress-bar__bg--cells",
          props.rounded && "progress-bar__bg--round",
        )}
        style={{
          backgroundColor: color.bg || "#FFFFFF",
        }}
      >
        <div
          className={cn(
            "progress-bar__fill",
            props.rtl && "progress-bar__fill--rtl",
          )}
          style={{
            width: `${props.progress}%`,
            backgroundColor: color.main,
          }}
        />
        {props.cells ? (
          <div className="progress-bar__cells">
            <div className="progress-bar__cell" />
            <div className="progress-bar__cell" />
            <div className="progress-bar__cell" />
            <div className="progress-bar__cell" />
            <div className="progress-bar__cell" />
            <div className="progress-bar__cell" />
            <div className="progress-bar__cell" />
            <div className="progress-bar__cell" />
            <div className="progress-bar__cell" />
          </div>
        ) : null}
      </div>
      {props.label ? (
        <div
          className={`progress-bar__label`}
          style={{ color: color.label }}
        >
          {props.label}
        </div>
      ) : null}
    </div>
  );
};

export default ProgressBar;
