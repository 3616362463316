import { sellerApiClient, getParams } from "./sellerApiClient";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { TWechatWallet } from '../../pages/ProfilePage/types';

export const getFinancePage = async ({
  page,
  filters,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<Response<GetWalletOperationsItem>> => {
    const response = await sellerApiClient.get<Response<GetWalletOperationsItem>>(
      "/v1/wallet/operations/page",
      {
        params: getParams(page, filters),
        paramsSerializer: { indexes: null },
      },
    );

    return response.data;
};

interface Response<T> {
  total: number,
  items: T[],
}

export interface GetWalletOperationsItem {
  id: string;
  date: string;
  amount: number;
  type: string; // RecyclingCompensation;
  status: string; // Waiting, Approved, Done, Cancelled
  details: GetWalletOperationsDetails;
}

interface GetWalletOperationsDetails {
  parcelId: string;
  trackNumber: string;
  parcelComplaintId: string;
  sellerId: string;
}

type TGetWalletsResponse = {
  balance: number;
  hasActiveWithdrawal: boolean;
  weChatWithdrawalLimit: number;
};

export const getWallet = async (): Promise<TGetWalletsResponse> => {
  const response = await sellerApiClient.get('/v1/wallet');
  return response.data;
};

export const removeWallet = async (id: string): Promise<void> => {
  const response = await sellerApiClient.delete<void>(
    `/v1/wallet/wechat/wallets/${id}`,
  );

  return response.data;
};

export interface WalletAuthRequest {
  requestId: string;
  qrCode: string;
}

export const authorizeWallet = async (): Promise<WalletAuthRequest> => {
  const response = await sellerApiClient.post<WalletAuthRequest>(
    "/v1/wallet/wechat",
  );

  return response.data;
};

export const getWalletRequestStatus = async (requestId: string): Promise<WalletRequestStatus> => {
  const response = await sellerApiClient.get<WalletRequestStatus>(
    `/v1/wallet/wechat/link/${requestId}`
  );

  return response.data;
};

export interface WalletRequestStatus {
  requestId: string;
  isProcessed: boolean;
  isSuccess: boolean;
  errorCode: string;
}

type TGetWechatWalletsResponse = {
  items: TWechatWallet[];
};

export const getWechatWallets = async (): Promise<TGetWechatWalletsResponse> => {
  const response = await sellerApiClient.get('/v1/wallet/wechat/wallets');
  return response.data;
};

export type sendWithdrawFundsRequest = {
  amount: number;
  weChatWalletId: string;
};

export const sendWithdrawFunds = async (data: sendWithdrawFundsRequest): Promise<void> => {
  await sellerApiClient.post('/v1/wallet/wechat/withdraw', data);
};

export type sendTopUpBalanceRequest = {
  amount: number;
};

export type sendTopUpBalanceResponse = {
  outTradeNo: string;
  paymentUrl?: string;
  qrCode: string;
};

export const sendTopUpBalance = async (data: sendTopUpBalanceRequest): Promise<sendTopUpBalanceResponse> => {
  const response = await sellerApiClient.post('/v1/wechat/topup-by-wechat', data);
  return response.data;
};

export type checkBalanceReplenishmentResponse = {
  amount: number;
  errorCode: string;
  isProcess: boolean;
  isSuccess: boolean;
};

export const checkBalanceReplenishment = async (outTradeNo: string): Promise<checkBalanceReplenishmentResponse> => {
  const response = await sellerApiClient.get(`/v1/wechat/payment/status/${outTradeNo}`);
  return response.data;
};