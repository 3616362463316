import AddSeller from "./AddSeller";
import styles from './AddSeller.module.css';

const AddSellerPanel = ({ isOzonSeller, onSuccess }: {
  isOzonSeller?: boolean,
  onSuccess?: () => void,
}) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <AddSeller
        isOzonSeller={isOzonSeller}
        onSuccess={onSuccess}
      />
    </div>
  </div>
)

export default AddSellerPanel;
