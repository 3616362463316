import { useState, useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import cn from "classnames";
// import { useQuery } from "react-query";
import { useProfile } from "@contexts/ProfileContext";
import { useNavigate } from 'react-router-dom';
import Page from "@components/Page";
import Avatar from "@components/Avatar";
import { Row } from "@components/Grid";
import { getComplaintRequests, ComplaintRequest } from "@api/apiClient/complaints";
import CreateRequestModal from "./components/CreateRequestModal";
import { TableColumn, TableColumnType } from "@components/Table/TableTypes";
import DataTable, { DataViewHandle } from "@containers/DataTable";
import { Button } from "@components/Button";
import { formatDate } from "@utils/formatValues";
import IconUni from "@assets/icons/uni.svg";
import styles from "./ComplaintList.module.css";

const ComplaintList = () => {
  const [isOpenCreatModal, setIsOpenCreatModal] = useState(false);
  const dataRef = useRef<DataViewHandle>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const handleClick = useCallback((row: ComplaintRequest) => {
    navigate(`/complaints/${row.id}`);
  }, []);

  const profile = useProfile();
  const profileName = profile?.account?.name ?? "";

  const columns: TableColumn<ComplaintRequest>[] = useMemo(() => [
    {
      header: t("complaintPage.number"),
      accessor: (row) => (row.data.id),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "80px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("complaintPage.status"),
      accessor: (row) => (
        <div className={cn(
          styles.status,
          styles[`status${row.data.status}`],
        )}>
          {t(`complaintPage.statuses.${row.data.status}`)}
        </div>
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "100px",
      fixWidth: true,
    },
    {
      header: t("complaintPage.type"),
      accessor: (row) => (
        t(`complaintPage.types.${row.data.reason}`)
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "120px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("complaintPage.lastMessage"),
      accessor: (row) => {
        if (!row.data.lastComment?.text && !row.data.lastComment?.attachments?.length) {
          return (
            <div className={styles.lastMessageText}>
              {row.data?.trackingNumbers.join(", ")}
            </div>
          );
        }

        const { lastComment, unreadCommentsCount: unreadCount } = row.data;
        return (
          <Row noWrap align="center">
            {lastComment?.managerLogin ? (
              <img className={styles.avatar} src={IconUni} alt="" />
            ) : (
              <Avatar
                className={styles.avatar}
                name={profileName}
                id={profileName}
              />
            )}
            <span className={cn(
              styles.lastMessageText,
              unreadCount && styles.lastMessageTextActive,
            )}>
              {(
                !lastComment?.text
                && lastComment?.attachments?.length
              ) ? t(lastComment?.managerLogin
                ? "complaintPage.attachmentsReceived"
                : "complaintPage.attachmentsSent"
              ) : lastComment?.text}
            </span>

            {unreadCount ? (
              <div className={styles.messagesCounter}>
                {unreadCount}
              </div>
            ) : null}
          </Row>
        )
      },
      type: TableColumnType.SECONDARY,
      alignment: "left",
      fixWidth: true,
      width: "500px",
    },
    {
      header: t("complaintPage.updateDate"),
      accessor: (row) => (
        row.data.lastUpdated
          ? formatDate(row.data.lastUpdated, "dd.MMM HH:mm")
          : ""
      ),
      type: TableColumnType.TERTIARY,
      alignment: "right",
      width: "120px",
      // fixWidth: true,
    },
  ], [currentLanguage, profileName]);

  const onCloseModal = () => {
    if (dataRef.current?.refresh) {
      dataRef.current.refresh();
    }
    setIsOpenCreatModal(false);
  }

  return (
    <Page
      title={t("complaintPage.title")}
      actions={(
        <Button
          onClick={() => setIsOpenCreatModal(true)}
          title={`+ ${t("complaintPage.createRequest")}`}
        />
      )}
    >
      <DataTable<ComplaintRequest>
        queryKey="getComplaintRequests"
        getData={getComplaintRequests}
        columns={columns}
        notFound={t("complaintPage.noRequest")}
        filters={[]}
        defaultPageSize={10}
        dataRef={dataRef}
        rowOnClick={handleClick}
      />

      {isOpenCreatModal ? (
        <CreateRequestModal onClose={onCloseModal} />
      ) : null}
    </Page>
  )
};

export default ComplaintList;
