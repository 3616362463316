import { useState, useEffect } from "react";
import cn from "classnames";
import CrossIcon from "@assets/icons/cross-grey-12.svg";
import { ButtonIcon } from "@components/ButtonIcon";
import SearchIcon from "@assets/icons/search.svg?react";
import styles from "./SearchFilter.module.css";

const SearchFilter = ({ onChange, initialValue, multi, multiOrName, placeholder, asPanel }: {
  onChange: (value: string[], rv?: string[]) => void,
  initialValue: string,
  placeholder?: string,
  multi?: boolean,
  multiOrName?: boolean,
  asPanel?: boolean,
}) => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (initialValue) {
      handleSearch(initialValue);
    }
  }, [initialValue])

  const handleSearch = (rawValue: string) => {
    let value = rawValue.trim();
    let requestValue: string | string[] = "";
    if (multiOrName) {
      if (value.search(/[^0-9\s,]/) === -1) {
        value = value.replace(/\s+/g, " ");
        requestValue = value.replace(/,?\s+/g, ",").split(",");
      } else {
        requestValue = [value];
      }
    }
    if (multi) {
      value = value.replace(/\s+/g, " ");
      requestValue = value.replace(/,?\s+/g, ",");
    }
    setText(value);
    const rValue = Array.isArray(requestValue) ? requestValue : (
      requestValue ? [requestValue] : undefined
    )
    onChange([value], rValue);
  };

  const onSearch = () => handleSearch(text)

  return (
    <div className={styles.root}>
      <div className={cn(styles.inputWrapper, asPanel && styles.inputWrapperPanel)}>
        <SearchIcon
          className={styles.inputIcon}
          onClick={onSearch}
        />
        <input
          className={cn(styles.input, asPanel && styles.inputPanel)}
          value={text}
          placeholder={placeholder}
          onChange={(e) => setText(e.target.value)}
          onBlur={onSearch}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onSearch();
            }
          }}
        />
        <ButtonIcon
          icon={CrossIcon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setText("");
            handleSearch("");
          }}
        />
      </div>
    </div>
  );
}

export default SearchFilter;
