import ReturnsCounterContext from './index.ts';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../AuthenticationContext';
import { useQuery } from 'react-query';
import { getAwaiting } from '../../api/apiClient/returns';

interface IReturnsCounterProviderProps {
  children: ReactNode;
}

const ReturnsCounterProvider = (props: IReturnsCounterProviderProps) => {
  const [value, setValue] = useState(0);
  const auth = useAuth();

  const query = useQuery({
    queryKey: ['returnsCounterProvider'],
    queryFn: () => getAwaiting({
      page: { page: 1, pageSize: 1 }
    }),
    retry: 1,
    enabled: auth.isAuthenticated
  });

  useEffect(() => {
    setValue(query.data?.total ?? 0);
  }, [query.data?.total]);

  const update = () => {
    query.refetch();
  };

  return (
      <ReturnsCounterContext.Provider value={{ value, update }}>
        <>{props.children}</>
      </ReturnsCounterContext.Provider>
  );
};

export default ReturnsCounterProvider;
