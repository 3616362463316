import moment from 'moment';
import Box from '@components/Box';
import { Column, Grid, Row } from '@components/Grid';
import getFullAddress from '@utils/getFullAddress';
import PickUpForm from '../components/PickUpForm/PickUpForm';
import FormErrorMessage from "@components/FormErrorMessage";
import { addAddresses, getAddresses } from '@api/apiClient';
import { IPickUpFormModalWindowProps, TAddressApplication } from '../types';
import { Loader } from '@components/Loader';
import { sendOneTimePickUp } from '@api/apiClient/pickUp';
import { getShipmentDates } from '@utils/getChineseDate';
import { Text, TextSize, TextType } from '@components/Text';
import { useProfile } from "../../../contexts/ProfileContext";
import { getAllowedCountry } from "@components/CountrySelect/CountrySelect";
import { getCurrentLanguage } from "@utils/lang";
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const emptyAddress = {
  address: {
    area: '',
    city: '',
    cityDistrict: '',
    country: '',
    countryCode: '',
    fias: {
      areaId: '',
      cityDistrictId: '',
      cityId: '',
      flatId: '',
      houseId: '',
      regionId: '',
      settlementId: '',
      streetId: ''
    },
    flat: '',
    house: '',
    latitude: 0,
    longitude: 0,
    rawLine: '',
    region: '',
    settlement: '',
    street: '',
    zipCode: ''
  },
  id: ''
};

const PickUpFormModalWindow: React.FC<IPickUpFormModalWindowProps> = (props: IPickUpFormModalWindowProps): React.ReactElement => {
  const { handlerRefreshParcels, onClose, isNew, parcelIds } = props;
  const [rememberAddress, setRememberAddress] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<number>(0);
  const { account } = useProfile();
  const { t } = useTranslation();
  const shipmentDates = useMemo(() => getShipmentDates(isNew), []);

  const query = useQuery({
    queryKey: ['addresses'],
    queryFn: () => getAddresses(),
    retry: 1
  });

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: sendOneTimePickUp,
    onSuccess: () => {
      onClose();
      handlerRefreshParcels();
    },
  });

  const currentLanguage = getCurrentLanguage();

  const emptyAddressWithCountry = useMemo(() => ({
    ...emptyAddress,
    address: {
      ...emptyAddress.address,
      countryCode: getAllowedCountry(account?.region),
    },
  }), [currentLanguage])

  const {
    control,
    handleSubmit,
    clearErrors,
    setValue,
    watch
  } = useForm<TAddressApplication>({
    defaultValues: {
      ...emptyAddressWithCountry,
      comment: '',
      name: '',
      phone: ''
    },
    mode: 'onChange'
  });

  const submit = (form: TAddressApplication): void => {
    const country = t(
      `countries.${form.address.countryCode}`,
      { defaultValue: form.address.countryCode || "" },
    );
    const address = {
      area: form.address.area!,
      city: form.address.city!,
      cityDistrict: form.address.cityDistrict!,
      country: form.address.country!,
      countryCode: form.address.countryCode!,
      flat: form.address.flat!,
      house: form.address.house!,
      rawLine: getFullAddress({ ...form.address, country }),
      region: form.address.region!,
      settlement: form.address.settlement!,
      street: form.address.street!,
      zipCode: form.address.zipCode!
    };
    mutate({
      address: address,
      comment: form.comment,
      contactName: form.name!,
      contactPhone: form.phone!,
      parcelIds: parcelIds!,
      shipmentDate: moment(shipmentDates[selectedDate]).format('YYYY-MM-DD')
    })

    if (rememberAddress) addAddresses(address);
  };

  const formProps = {
    addressTemplates: query.data?.addressTemplates || [],
    clearErrors,
    control,
    defaultAddress: emptyAddressWithCountry,
    handleSubmit,
    rememberAddress,
    selectedDate,
    setRememberAddress,
    setSelectedDate,
    setValue,
    shipmentDates,
    submit,
    watch
  };

  return (
      <Grid>
        {query.isFetching ? (
            <Row>
              <Column phone={12}>
                <Box>
                  <Loader size={'s'}/>
                </Box>
              </Column>
            </Row>
        ) : (
            <>
              <Row>
                <Column phone={12}>
                  <Box mb={5}>
                    <Text type={TextType.H1}>
                      {t('pickUpFormModalWindow.title')}
                    </Text>
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <Box mb={7}>
                    <Text size={TextSize.M}>
                      {t('pickUpFormModalWindow.description')}
                    </Text>
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <PickUpForm {...formProps} isLoading={isLoading} />
                  {isError ? (
                    <FormErrorMessage
                      error={error}
                      errorPrefix="pickUpErrors"
                    />
                  ) : null}
                </Column>
              </Row>
            </>
        )}
      </Grid>
  );
};

export default PickUpFormModalWindow;
