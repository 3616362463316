import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getPickUpServices } from "@api/apiClient/pickUp";
import MultiSelectFilter from "../Filter/MultiSelectFilter";

const ServiceFilter = ({ onChange, initialValue, asPanel }: {
  onChange: (sellers: string[]) => void,
  initialValue: string[],
  asPanel?: boolean,
}) => {
  const { t } = useTranslation();

  const { isFetching, data } = useQuery({
    queryKey: ["services"],
    queryFn: () => getPickUpServices(),
    retry: 1,
    select: (items) => items.map(item => ({ id: item.id, value: item.name })),
  });

  return (
    <MultiSelectFilter
      onChange={onChange}
      initialValue={initialValue}
      isFetching={isFetching}
      data={data || []}
      placeholder={t("filters.serviceFilter")}
      asPanel={asPanel}
    />
  );
}

export default ServiceFilter;
