import "./Button.scss";
import cn from "classnames";
import { Loader } from "../Loader";
import { ButtonSize, ButtonType } from "./types.ts";

interface ButtonProps {
  title?: React.ReactNode;
  onClick?: () => void;
  type?: ButtonType;
  icon?: string | React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  iconRight?: string | React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  size?: ButtonSize;
  pending?: boolean;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconRightClassName?: string;
  isNarrow?: boolean;
  onClickIconRight?: any;
}

export const Button = ({
  type: btnType,
  size,
  disabled,
  className,
  iconClassName,
  iconRightClassName,
  icon,
  iconRight,
  pending,
  title,
  onClick,
  isNarrow,
  onClickIconRight
}: ButtonProps) => {
  return (
    <div
      onClick={(disabled ?? pending) ? (() => {}) : onClick}
      className={cn(
        "button",
        className,
        {
          "button--primary": btnType === ButtonType.PRIMARY || !btnType,
          "button--danger": btnType === ButtonType.DANGER,
          "button--block": btnType === ButtonType.BLOCK,
          "button--skeleton": btnType === ButtonType.SKELETON || btnType === ButtonType.ROUNDED_SKELETON,
          "button--skeleton-red": btnType === ButtonType.RED_SKELETON,
          "button--skeleton-green": btnType === ButtonType.GREEN_SKELETON,
          "button--rounded": btnType === ButtonType.ROUNDED || btnType === ButtonType.ROUNDED_SKELETON,
          "button--link": btnType === ButtonType.LINK,
          "button--black": btnType === ButtonType.BLACK,
          "button--link-disabled": disabled && btnType === ButtonType.LINK,
          "button--small": size === ButtonSize.S,
          "button--xsmall": size === ButtonSize.XS,
          "button--narrow": isNarrow,
          "button--disabled": disabled,
          "button--cancel": btnType === ButtonType.CANCEL,
          "button--light": btnType === ButtonType.LIGHT,
          "button--light button--light-rounded": btnType === ButtonType.LIGHT_ROUNDED,
          "button--dashed": btnType === ButtonType.DASHED,
          "button--grey": btnType === ButtonType.GREY
        }
      )}
    >
      <div className={"button__body"}>
        {!pending && icon && (
          <div className={cn(
            "button__icon",
            iconClassName,
            title && "button__icon-with-margin"
          )}>
            {typeof icon === "string" && (
              <img alt={"icon"} src={icon} />
            )}
            {typeof icon === "function" && icon({})}
          </div>
        )}

        {!pending && Boolean(title) && (
          <div className={"button__text"}>{title}</div>
        )}

        {!pending && iconRight && (
            <div
                className={cn(
                "button__icon-right",
                iconRightClassName,
                title && "button__icon-right-with-margin"
            )}
                onClick={onClickIconRight}
            >
              {typeof iconRight === "string" && (
                  <img alt={"icon"} src={iconRight} />
              )}
              {typeof iconRight === "function" && iconRight({})}
            </div>
        )}

        {pending && (
          <div className={"button__loader"}>
            <Loader size={"xs"} />
          </div>
        )}
      </div>
    </div>
  );
};
