import React, { useEffect } from "react";
import Table, { TableProps } from "@components/Table/Table";
import DataView, { DataViewProps } from "./DataView";

export type { BeforeProps } from "./DataView";
import type { DataViewHandle as DataViewHandle_ } from "./DataView";
export type DataViewHandle = DataViewHandle_;

type TableExtraProps<T> = Omit<TableProps<T>, "data">
type DataViewExtraProps<T, BP, AP> = Omit<DataViewProps<T, TableProps<T>, BP, AP>, "View">
// type DataTableProps<T> = DataViewExtraProps<T> & TableExtraProps<T>
type DataTableProps<T, BP, AP> = DataViewExtraProps<T, BP, AP> & TableExtraProps<T> & {
  dataRef?: React.MutableRefObject<DataViewHandle | null>;
}

function View<T>(props: TableProps<T>) {
  return (
    <Table<T>
      {...props}
    />
  );
};

function areViewPropsEqual<T>(oldProps: TableProps<T>, newProps: TableProps<T>) {
  if (
    oldProps.onSelectionChange !== newProps.onSelectionChange
    || oldProps.columns !== newProps.columns
    || oldProps.selectable !== newProps.selectable
    || oldProps.className !== newProps.className
    || oldProps.wrapperClassName !== newProps.wrapperClassName
    || oldProps.isHeadless !== newProps.isHeadless
    || oldProps.isSmall !== newProps.isSmall
    || oldProps.isStriped !== newProps.isStriped
    || oldProps.noBorder !== newProps.noBorder
  ) return false
  return JSON.stringify(oldProps.selectedIds) === JSON.stringify(newProps.selectedIds)
}

const ViewMemo = React.memo(View, areViewPropsEqual) as typeof View

function DataTable<T, BP=void, AP=void>({
  pending,
  queryKey,
  getData,
  defaultPageSize,
  notFound,
  filters,
  Before,
  After,
  beforeProps,
  afterProps,
  dataRef,
  withPagination,
  ...restProps
}: DataTableProps<T, BP, AP>) {
  useEffect(() => {
    if (restProps.onSelectionChange) {
      restProps.onSelectionChange([], [])
    }
  }, [JSON.stringify(filters)]);

  return (
    <DataView<T, TableExtraProps<T>, BP, AP>
      pending={pending}
      queryKey={queryKey}
      getData={getData}
      defaultPageSize={defaultPageSize}
      notFound={notFound}
      filters={filters}
      Before={Before}
      After={After}
      beforeProps={beforeProps}
      afterProps={afterProps}
      View={ViewMemo<T>}
      viewProps={restProps}
      dataRef={dataRef}
      withPagination={withPagination}
    />
  );
}

export default DataTable;
