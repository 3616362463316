import { useState } from "react";
import cn from "classnames";
import { Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Text, TextSize, TextWeight } from "@components/Text";
import chevronBlue from "@assets/icons/chevron-blue.svg";
import styles from "./CollapsibleList.module.scss";

function getVisibleItems<T>(items: T[], limit: number, isOpen: boolean): T[] {
  if (isOpen || items.length <= limit) return items;
  return items.slice(0, limit - 1);
}

function CollapsibleList<T>({ title, items, limit, ItemComponent, columns }: {
  title: string,
  items: T[],
  limit: number,
  ItemComponent: (props: { data: T }) => React.ReactNode,
  columns?: boolean,
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const visibleItems = getVisibleItems<T>(items, limit, isOpen);

  return (
    <>
      <Row>
        <Column phone={12}>
          <Box pt={8}>
            <div
              className={styles.collapseBtn}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Text size={TextSize.M} weight={TextWeight.BOLD}>
                {title}
              </Text>
              {(limit < items.length) ? (
                <img
                  src={chevronBlue}
                  className={cn(styles.chevron, isOpen && styles.chevronUp)}
                  alt=""
                />
              ) : null}
            </div>
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pt={3}>
            <div className={cn(styles.items, columns && styles.itemsInColumns)}>
              {visibleItems.map((item, idx) => (
                <ItemComponent key={idx} data={item} />
              ))}
              {(!isOpen && visibleItems.length < items.length) ? (
                <div
                  onClick={() => setIsOpen(true)}
                  className={styles.moreBtn}
                >...</div>
              ) : null}
            </div>
          </Box>
        </Column>
      </Row>
    </>
  );
};

export default CollapsibleList;
