import { useState, useEffect, useImperativeHandle } from "react";
import { useQuery } from "react-query";
import Pagination from "@components/Pagination";
import { Grid, Row, Column } from "@components/Grid";
import { Loader } from "@components/Loader";
import Box from "@components/Box";
import { usePagingSearchParams, PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { SearchInfo } from "./components/SearchInfo";
import NoItems from "@components/NoItems";

export interface BeforeProps<T, Params=void> {
  items: T[];
  isLoading: boolean;
  total: number;
  filters?: FilterModel[];
  params?: Params;
}

export type DataViewHandle = {
  refresh: () => void,
  total: number,
  data?: any
}

export type DataViewProps<T, VP, BP=void, AP=void> = {
  pending?: boolean;
  queryKey?: string;
  getData: (data: {
    page: PaginationModel,
    filters?: FilterModel[],
  }) => Promise<({ items: T[], total: number })>;
  notFound?: string | React.FC;
  notFoundDescription?: string;
  filters?: FilterModel[];
  Before?: React.FC<BeforeProps<T, BP>>;
  After?: React.FC<BeforeProps<T, AP>>;
  View: React.FC<any>; // ViewExtendedProps<T, ViewProps>
  viewProps?: VP;
  beforeProps?: BP;
  afterProps?: AP;
  defaultPageSize?: number;
  dataRef?: React.RefObject<DataViewHandle>;
  withPagination?: boolean;
}

const MAX_PAGE_SIZE = 99999;

function DataView<T, ViewProps, BP = void, AP = void>({
  pending,
  getData,
  queryKey,
  notFound,
  notFoundDescription,
  filters,
  Before,
  beforeProps,
  After,
  afterProps,
  View,
  viewProps,
  defaultPageSize,
  dataRef,
  withPagination,
}: DataViewProps<T, ViewProps, BP, AP>) {
  const initPageSize = defaultPageSize || 10;
  const [currentPage, setCurrentPage] = usePagingSearchParams(1, initPageSize);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const showPagination = withPagination ?? true;

  const query = useQuery({
    queryKey: [
      queryKey,
      currentPage,
      filters,
    ],
    queryFn: () => getData({
      page: currentPage,
      filters,
    }),
    retry: 1,
    enabled: !pending,
  });
  const { items, total: totalItems } = query.data || { items: [], total: 0 };
  const isLoading = pending || query.isFetching;

  useImperativeHandle(dataRef, () => ({
    refresh: () => {
      query.refetch();
    },
    total: query.data?.total ?? 0,
    data: query.data?.items
  }));

  const updatePagination = (page: number, pageSize: number) => {
    setCurrentPage({ page, pageSize });
  }

  useEffect(() => {
    if (!pending) {
      setIsInitialized(true);
    }
  }, [pending]);

  useEffect(() => {
    if (isInitialized) {
      const trackNumberFilter = (filters || []).find(v => v.name === "trackOrExternalNumbers" || v.name === "trackNumbers");
      if (trackNumberFilter && trackNumberFilter.value.length && trackNumberFilter.value[0].split(",").length > 1) {
        setCurrentPage({ page: 1, pageSize: MAX_PAGE_SIZE });
      } else {
        setCurrentPage({
          page: 1,
          pageSize: currentPage.pageSize === MAX_PAGE_SIZE ? defaultPageSize ?? 10 : currentPage.pageSize
        });
      }
    }
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    if (
      query.data?.total
      && !query.data?.items?.length
      && currentPage.page > 1
    ) {
      setCurrentPage({ page: 1, pageSize: currentPage.pageSize });
    }
    if (window.location.search.includes('tab=awaiting')) {
      localStorage.setItem('awaitingParcels', JSON.stringify(items.map((item: any) => item.id)));
    }
    if (window.location.search.includes('tab=disposal')) {
      localStorage.setItem('disposalParcels', JSON.stringify(items.map((item: any) => item.id)));
    }
    if (window.location.search.includes('tab=resale')) {
      localStorage.setItem('resaleParcels', JSON.stringify(items.map((item: any) => item.id)));
    }
    if (window.location.search.includes('tab=returns')) {
      localStorage.setItem('returnsParcels', JSON.stringify(items.map((item: any) => item.id)));
    }
  }, [query.data?.total, query.data?.items?.length]);

  if (isLoading) {
    return (
      <>
        {Before && (
          <Before
            items={items ?? []}
            filters={filters}
            total={query.data?.total || 0}
            isLoading={isLoading}
            params={beforeProps}
          />
        )}
        <Grid>
          <Row>
            <Column phone={12}>
              <Loader alignment="center" size="s" />
            </Column>
          </Row>
        </Grid>
        {After && (
          <After
            items={items ?? []}
            filters={filters}
            total={query.data?.total || 0}
            isLoading={isLoading}
            params={afterProps}
          />
        )}
      </>
    )
  }

  if (items && !isLoading && !items.length) {
    const NotFound = notFound;
    return (
      <>
        {Before && (
          <Before
            items={items ?? []}
            filters={filters}
            total={query.data?.total || 0}
            isLoading={isLoading}
            params={beforeProps}
          />
        )}
        {(NotFound && typeof NotFound === "function") ? (
          <NotFound />
        ) : (
          <NoItems
            title={NotFound || ""}
            description={notFoundDescription || ""}
          />
        )}
        {After && (
          <After
            items={items ?? []}
            filters={filters}
            total={query.data?.total || 0}
            isLoading={isLoading}
            params={afterProps}
          />
        )}
      </>
    )
  }

  return (
    <>
      {Before && (
        <Before
          items={items ?? []}
          filters={filters}
          total={query.data?.total || 0}
          isLoading={isLoading}
          params={beforeProps}
        />
      )}
      <View {...viewProps} data={items ?? [] as T[]} filters={filters}/>
      <SearchInfo
        items={items ?? []}
        filters={filters}
        total={query.data?.total || 0}
        isLoading={isLoading}
      />
      {After && (
        <Box pt={4}>
          <After
            items={items ?? []}
            filters={filters}
            total={query.data?.total || 0}
            isLoading={isLoading}
            params={afterProps}
          />
        </Box>
      )}
      {(!showPagination || (currentPage.pageSize === MAX_PAGE_SIZE)) ? null : (
        <Box pt={4}>
          <Pagination
            currentPage={currentPage.page}
            totalItems={totalItems}
            pageSize={currentPage.pageSize}
            onChange={updatePagination}
            onSizeChange={updatePagination}
          />
        </Box>
      )}
    </>
  );
}

DataView.displayName = "DataView";
export default DataView;
