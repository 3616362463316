import { ButtonIcon } from "@components/ButtonIcon";
import PlusIcon from "@assets/icons/plus-btn.svg?react";
import MinusIcon from "@assets/icons/minus-btn.svg?react";
import styles from "./CountInput.module.scss";

const CountInput = ({ value, onChange, maxValue }: {
  value: number,
  onChange: (value: number) => void,
  maxValue?: number,
}) => {
  const changeCount = (v: number) => {
    let newValue = v;
    if (newValue < 1) return newValue = 1;
    if (maxValue && maxValue < newValue) return newValue = maxValue;
    onChange(newValue);
  }
  return (
    <div className={styles.countContainer}>
      <ButtonIcon
        icon={MinusIcon}
        onClick={() => changeCount(value - 1)}
        disabled={value <= 1}
      />
      <input
        type="number"
        min="1"
        step="1"
        value={value}
        onChange={(e) => changeCount(+e.target.value)}
      />
      <ButtonIcon
        icon={PlusIcon}
        onClick={() => changeCount(value + 1)}
        disabled={Boolean(maxValue && (maxValue <= value))}
      />
    </div>
  );
}

export default CountInput
