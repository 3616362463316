import { useEffect, useState } from "react";
import { Grid, Row, Column } from "@components/Grid";
import { addAddresses, rewriteAddress } from '@api/apiClient';
import Switcher from '@components/Switcher';
import TextInputContainer from "@containers/TextInputContainer";
import SelectContainer from "@containers/SelectContainer";
import Box from "@components/Box";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Address, AddressTemplate } from "@api/apiClient";
import SelectDropdown from "@components/SelectDropdown";
import { Text, TextColor, TextSize, TextType } from "@components/Text";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import {
  countries,
  getAllowedCountry,
  CountryType,
  CountrySelectValue,
  CountrySelectOption,
  countryKeyExtractor,
} from "@components/CountrySelect/CountrySelect";
import FormErrorMessage from "@components/FormErrorMessage";
import { getCurrentLanguage, Langs } from "@utils/lang";
import getFullAddress from '@utils/getFullAddress';

interface ReturnsFormType {
  address: Address;
  comment: string;
  contactPhone: string;
  contactPerson: string;
}

interface ReturnFormProps {
  onClose: () => void;
  defaultAddress: AddressTemplate | null;
  addressTemplates: AddressTemplate[];
  Sum: number;
  DeliverySum: number;
  parcelIds: string[];
  region?: string;
  selectedDepartureCount: number;
  mutation: any;
  isProblematicParcel?: boolean;
}

const ReturnForm = (props: ReturnFormProps) => {
  const {
    addressTemplates,
    defaultAddress,
    DeliverySum,
    parcelIds,
    region,
    selectedDepartureCount,
    Sum,
    mutation,
    isProblematicParcel,
  } = props;

  const { t } = useTranslation();

  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null);
  const [isSwitchVisible] = useState<boolean>(true);
  const [rememberAddress, setRememberAddress] = useState<boolean>(false);
  const [isReturnPage] = useState<boolean>(window.location.href.includes('returns?tab=awaiting'));
  const [isThereCityDistrict, setIsThereCityDistrict] = useState<boolean | undefined>(undefined);
  const currentLanguage = getCurrentLanguage();
  const count = parcelIds.length;

  useEffect(() => {
    if (defaultAddress) {
      setSelectedAddressId(defaultAddress.id);
    }
  }, []);

  useEffect(() => {
    if (selectedAddressId) {
      const selectedAddress = addressTemplates.find(
        (x: AddressTemplate) => x.id === selectedAddressId,
      );
      if (selectedAddress) {
        setValue("address", selectedAddress.address);
        if (isReturnPage) {
          setIsThereCityDistrict(!!selectedAddress.address.cityDistrict?.length);
          setRememberAddress(!selectedAddress.address.cityDistrict?.length);
        } else {
          setIsThereCityDistrict(true);
          setRememberAddress(false);
        }
      }
    } else {
      setIsThereCityDistrict(false);
      setRememberAddress(false);
      setValue("address", {
        rawLine: "",
        street: "",
        city: "",
        cityDistrict: "",
        settlement: "",
        country: "",
        zipCode: "",
        region: "",
        area: "",
        house: "",
        flat: "",
        longitude: 0,
        latitude: 0,
        countryCode: getAllowedCountry(region),
      });
    }
    clearErrors();
  }, [selectedAddressId]);

  const { handleSubmit, control, setValue, clearErrors } = useForm<ReturnsFormType>({
    mode: "onChange",
    defaultValues: {
      address: {
        rawLine: "",
        street: "",
        city: "",
        cityDistrict: "",
        settlement: "",
        country: "",
        zipCode: "",
        region: "",
        area: "",
        house: "",
        flat: "",
        longitude: 0,
        latitude: 0,
      },
      comment: "",
      contactPhone: "",
      contactPerson: "",
    },
  });

  const submit = (form: ReturnsFormType) => {
    const country = t(
      `countries.${form.address.countryCode}`,
      { defaultValue: form.address.countryCode || "" },
    );
    mutation.mutate({
      parcelIds,
      deliveryInfo: {
        zipCode: form.address.zipCode ?? "",
        recipientPhoneNumber: form.contactPhone,
        recipientName: form.contactPerson,
        region: form.address.region,
        city: form.address.city,
        cityDistrict: form.address.cityDistrict,
        countryCode: form.address.countryCode ?? "",
        address: getFullAddress({ ...form.address, country }),
      },
      comment: form.comment,
      selectedAddressId: selectedAddressId ?? "",
    });

    const address = {
      area: form.address.area!,
      city: form.address.city!,
      cityDistrict: form.address.cityDistrict!,
      country: form.address.country!,
      countryCode: form.address.countryCode!,
      flat: form.address.flat!,
      house: form.address.house!,
      rawLine: getFullAddress({ ...form.address, country }),
      region: form.address.region!,
      settlement: form.address.settlement!,
      street: form.address.street!,
      zipCode: form.address.zipCode!
    };

    if (selectedAddressId) {
      if (isReturnPage && !isThereCityDistrict && rememberAddress) rewriteAddress({
        address: address,
        id: selectedAddressId
      });
    } else {
      if (rememberAddress) addAddresses(address);
    }
  };

  const prepareAddress = (address: Address) => {
    const country = t(
      `countries.${address.countryCode}`,
      { defaultValue: address.countryCode || "" },
    )
    return { ...address, country };
  }

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={8}>
            <Text type={TextType.H1}>{t("returnsForm.title")}</Text>
          </Box>
        </Column>
      </Row>

      {isProblematicParcel ? (
        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Text color={TextColor.ERROR}>{t("returnsForm.comissionMsg")}</Text>
            </Box>
          </Column>
        </Row>
      ) : null}

      {defaultAddress && (
        <>
          <Row>
            <Column phone={12}>
              <Box pb={4}>
                <SelectDropdown
                  label={t("returnsForm.chooseAddress")}
                  value={selectedAddressId}
                  labelSelectedItem={(item) => item?.address
                    ? getFullAddress(prepareAddress(item?.address)) : ""
                  }
                  items={addressTemplates}
                  keyExtractor={(item) => item.id}
                  onItemSelect={(id) => {
                    setSelectedAddressId(id);
                  }}
                  renderItem={(props) => (
                    <div>
                      {getFullAddress(prepareAddress(props.item.address))}
                    </div>
                  )}
                />
              </Box>
            </Column>

            {!!selectedDepartureCount && (
                <Row>
                  <Column phone={12}>
                    <Box pb={4}>
                      <Text
                          size={TextSize.M}
                          type={TextType.BLOCK}
                          color={TextColor.ERROR_DARK}
                      >
                        {t('awaitingPage.await_departure3')}
                      </Text>
                    </Box>
                  </Column>
                </Row>
            )}

            <Column phone={12}>
              <Box pb={8}>
                <Button
                  size={ButtonSize.S}
                  title={t("returnsForm.addAddress")}
                  onClick={() => setSelectedAddressId(null)}
                />
              </Box>
            </Column>
          </Row>
        </>
      )}

      {/*Address*/}
      {!isThereCityDistrict && (
        <>
          <Row>
            <Column phone={12}>
              <Box pb={4}>
                <Text
                  type={TextType.H3}
                  size={TextSize.M}
                  color={TextColor.DISABLED}
                >
                  {t("returnsForm.addressTitle")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Box pb={4}>
              <Column phone={6}>
                <Box pr={4}>
                  <SelectContainer<CountryType, ReturnsFormType>
                    control={control}
                    name="address.countryCode"
                    title={t("returnsForm.country")}
                    renderSelectedItem={CountrySelectValue}
                    items={selectedDepartureCount ? countries.filter((item: CountryType) => item.id === region) : countries}
                    keyExtractor={countryKeyExtractor}
                    renderItem={CountrySelectOption}
                    rules={{
                      required: t("defaultFormErrors.required"),
                    }}
                  />
                </Box>
              </Column>
              <Column phone={6}>
                <Box>
                  <TextInputContainer
                    rules={{
                      required: t("defaultFormErrors.required"),
                    }}
                    control={control}
                    title={t("returnsForm.region")}
                    name={"address.region"}
                  />
                </Box>
              </Column>
            </Box>
          </Row>
          <Row>
            <Box pb={4}>
              <Column phone={6}>
                <Box pr={4}>
                  <TextInputContainer
                    rules={{
                      required: t("defaultFormErrors.required"),
                    }}
                    control={control}
                    title={t("returnsForm.city")}
                    name={"address.city"}
                  />{" "}
                </Box>
              </Column>
              <Column phone={6}>
                <Box>
                  {isReturnPage ? (
                      <TextInputContainer
                          rules={{
                            required: t('defaultFormErrors.required')
                          }}
                          control={control}
                          title={t('returnsForm.cityDistrict')}
                          name={'address.cityDistrict'}
                      />
                  ) : (
                      <TextInputContainer
                          control={control}
                          rules={{
                            required: t('defaultFormErrors.required')
                          }}
                          title={t('returnsForm.street')}
                          name={'address.street'}
                      />
                  )}{' '}
                </Box>
              </Column>
            </Box>
          </Row>
          {isReturnPage && (
              <Row>
                <Box pb={4}>
                  <Column phone={12}>
                    <Box>
                      <TextInputContainer
                          control={control}
                          rules={{
                            required: t("defaultFormErrors.required"),
                          }}
                          title={t("returnsForm.street")}
                          name={"address.street"}
                      />{" "}
                    </Box>
                  </Column>
                </Box>
              </Row>
          )}
          <Row>
            <Column phone={4}>
              <Box pr={4}>
                <TextInputContainer
                  rules={{
                    required: t("defaultFormErrors.required"),
                  }}
                  control={control}
                  title={t("returnsForm.house")}
                  name={"address.house"}
                />{" "}
              </Box>
            </Column>
            <Column phone={4}>
              <Box pr={4}>
                <TextInputContainer
                  control={control}
                  title={t("returnsForm.flat")}
                  name={"address.flat"}
                />{" "}
              </Box>
            </Column>
            <Column phone={4}>
              <Box>
                <TextInputContainer
                  control={control}
                  title={t("returnsForm.zipCode")}
                  name={"address.zipCode"}
                />{" "}
              </Box>
            </Column>
          </Row>
          {isSwitchVisible && (
            <Row>
              <Column phone={4}>
                <Box pt={8}>
                  <Switcher
                    isActive={rememberAddress}
                    onSwitch={() => setRememberAddress(!rememberAddress)}
                    text={t('returnsForm.rememberAddress')}
                  />
                </Box>
              </Column>
            </Row>
          )}
        </>
      )}
      {/*Address*/}

      {/*CONTACT*/}
      <Row>
        <Column phone={12}>
          <Box pt={8} pb={4}>
            <Text
              type={TextType.H3}
              size={TextSize.M}
              color={TextColor.DISABLED}
            >
              {t("returnsForm.contactTitle")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <TextInputContainer
              control={control}
              title={t("returnsForm.contactPerson")}
              name={"contactPerson"}
              rules={{
                required: t("defaultFormErrors.required"),
              }}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <TextInputContainer
              control={control}
              title={t("returnsForm.contactPhone")}
              name={"contactPhone"}
              rules={{
                required: t("defaultFormErrors.required"),
              }}
              type="phone"
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <TextInputContainer
              control={control}
              title={t("returnsForm.comment")}
              name={"comment"}
              maxLength={1000}
            />{" "}
          </Box>
        </Column>
      </Row>
      {/*CONTACT*/}

      <Row>
        <Box pb={8} pt={8}>
          <Column phone={4}>
            <Box p={1}>
              <Text
                type={TextType.SPAN}
                size={TextSize.S}
                color={TextColor.DISABLED}
              >
                {t("returnsForm.select")}
              </Text>
            </Box>
            <Box p={1}>
              {" "}
              <Text
                type={TextType.H3}
                size={TextSize.M}
                color={TextColor.DISABLED}
              >
                {count}
              </Text>
            </Box>
          </Column>
          <Column phone={4}>
            <Box p={1}>
              {" "}
              <Text
                type={TextType.SPAN}
                size={TextSize.S}
                color={TextColor.DISABLED}
              >
                {t("returnsForm.sum")}
              </Text>
            </Box>
            <Box p={1}>
              <Text
                type={TextType.H3}
                size={TextSize.M}
                color={TextColor.DISABLED}
              >
                {Intl.NumberFormat().format(Sum)} ₽
              </Text>
            </Box>
          </Column>
          {isProblematicParcel ? null : (
            <Column phone={4}>
              <Box p={1}>
                <Text
                  type={TextType.SPAN}
                  size={TextSize.S}
                  color={TextColor.DISABLED}
                >
                  {t("returnsForm.deliverySum")}
                </Text>
              </Box>
              <Box p={1}>
                <Text
                  type={TextType.H3}
                  size={TextSize.M}
                  color={TextColor.DISABLED}
                >
                  {currentLanguage === Langs.tr ? (
                    <div>{Intl.NumberFormat().format(count * 12)} $</div>
                  ) : (
                    <div>{Intl.NumberFormat().format(DeliverySum)} ¥</div>
                  )}
                </Text>
              </Box>
            </Column>
          )}
        </Box>
      </Row>
      <Row>
        <Column phone={6}>
          <Box pr={4}>
            <Button
              type={ButtonType.BLOCK}
              title={t("returnsForm.buttonTitle")}
              onClick={handleSubmit(submit)}
              pending={mutation.isLoading}
            />
          </Box>
          {mutation.isError ? (
            <FormErrorMessage
              error={mutation.error}
              errorPrefix="apiErrors"
            />
          ) : null}
        </Column>
        {isProblematicParcel ? null : (
          <Column phone={6}>
            <Box pr={4}>{t("returnsForm.descriptionPay")}</Box>
          </Column>
        )}
      </Row>
    </Grid>
  );
};

export default ReturnForm;
