import { ReactElement, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { TabsList, TabsContent } from "@components/Tabs/Tabs.tsx";
import Page from "@components/Page/Page.tsx";
import AwaitingTab from "./tabs/AwaitingTab.tsx";
import ReturnsTab from "./tabs/ReturnsTab.tsx";
import ResaleTab from "./tabs/ResaleTab.tsx";
import DisposalTab from "./tabs/DisposalTab.tsx";
import { useCounter } from "../../contexts/CounterContext";
import { useTranslation } from "react-i18next";
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import useTab from "@hooks/useTab";
import FilterIcon from "@assets/icons/filter.svg";
import styles from "./UnclaimedPage.module.css";
import ExcelIcon from "@assets/icons/xlx-white-19.svg";
import {
  getReturnsXLSXFile,
  getReturnsXLSXFileRequest,
  useResaleCounter,
  useReturnsCounter,
  useDisposalCounter,
  useSellersFound,
  useHasReturns,
} from '@api/apiClient';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { Tooltip } from '../../components/Tooltip';
import { VITE_APP_DEV } from '@utils/config';

const UnclaimedPage = () => {
  const { returns } = useCounter();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [savedAwaitingSelection, setSavedAwaitingSelection] = useState<string[]>([]);
  const [savedDisposalSelection, setSavedDisposalSelection] = useState<string[]>([]);
  const [savedResaleSelection, setSavedResaleSelection] = useState<string[]>([]);
  const [savedReturnsSelection, setSavedReturnsSelection] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { sellersNotFound } = useSellersFound();
  const { hasNoReturns: noData } = useHasReturns();

  const resaleCounter = useResaleCounter();
  const returnsCounter = useReturnsCounter();
  const disposalCounter = useDisposalCounter();

  const refreshData = () => {
    queryClient.invalidateQueries(
      { queryKey: ["returns-page"] }
    );
    queryClient.invalidateQueries(
      { queryKey: ["resales-page"] }
    );
  }

  useEffect(() => {
    if (filters[0]?.value.length) {
      setSavedAwaitingSelection([]);
      setSavedDisposalSelection([]);
      setSavedResaleSelection([]);
      setSavedReturnsSelection([]);
    }
  }, [filters]);

  const downloadingExcelFile = (type: string): void => {
    let parcelsIds: string[] = [];
    let viewType: string = '';

    if (activeTab === 'awaiting') viewType = 'Awaiting';
    if (activeTab === 'disposal') viewType = 'Disposal';
    if (activeTab === 'resale') viewType = 'Resale';
    if (activeTab === 'returns') viewType = 'Return';

    if (type === 'selectively') {
      if (activeTab === 'awaiting') parcelsIds = savedAwaitingSelection;
      if (activeTab === 'disposal') parcelsIds = savedDisposalSelection;
      if (activeTab === 'resale') parcelsIds = savedResaleSelection;
      if (activeTab === 'returns') parcelsIds = savedReturnsSelection;
    }

    const data: getReturnsXLSXFileRequest = {
      FileType: 'Excel',
      LocalTimeUtcOffsetMinute: moment().utcOffset(),
      ParcelIds: parcelsIds,
      ReturnParcelViewType: viewType
    };

    getReturnsXLSXFile(data)
        .then((response: AxiosResponse) => {
          const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const fileURL = URL.createObjectURL(file);
          const element = document.createElement('a');
          element.setAttribute('href', fileURL);
          element.setAttribute('download', `${viewType} ${moment().format('DD/MM/YYYY HH:mm:ss')}`);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
  };

  const downloadingTopBtn: ReactElement =
      <Button
          icon={ExcelIcon}
          onClick={() => downloadingExcelFile('all')}
          size={ButtonSize.S}
          type={ButtonType.ROUNDED}
          title={t('unclaimedPage.titleActionTop')}
      />;

  const downloadingBottomBtn: ReactElement =
      <Button
          icon={ExcelIcon}
          onClick={() => downloadingExcelFile('selectively')}
          size={ButtonSize.S}
          title={t('unclaimedPage.titleActionBottom')}
      />;

  const tabs = [
    {
      id: "awaiting",
      title: t("unclaimedPage.tabAwaiting"),
      content: <AwaitingTab
          filters={filters}
          pending={!isInitialized}
          downloadingBtn={downloadingBottomBtn}
          handlerSetSavedAwaitingSelection={setSavedAwaitingSelection}
          savedAwaitingSelection={savedAwaitingSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
          noData={noData}
      />,
      count: returns ?? 0,
    },
    {
      id: "returns",
      title: t("unclaimedPage.tabReturns"),
      content: <ReturnsTab
          filters={filters}
          pending={!isInitialized}
          downloadingBtn={downloadingBottomBtn}
          handlerSetSavedReturnsSelection={setSavedReturnsSelection}
          savedReturnsSelection={savedReturnsSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
          noData={noData}
      />,
      count: returnsCounter.data ?? 0,
      disable: noData || sellersNotFound,
    },
    {
      id: "resale",
      title: t("unclaimedPage.tabResales"),
      content: <ResaleTab
          filters={filters}
          pending={!isInitialized}
          downloadingBtn={downloadingBottomBtn}
          handlerSetSavedResaleSelection={setSavedResaleSelection}
          savedResaleSelection={savedResaleSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
          noData={noData}
      />,
      count: resaleCounter.data ?? 0,
      disable: noData || sellersNotFound,
    },
    {
      id: "disposal",
      title: t("unclaimedPage.tabDisposal"),
      content: <DisposalTab
          filters={filters}
          pending={!isInitialized}
          downloadingBtn={downloadingBottomBtn}
          handlerSetSavedDisposalSelection={setSavedDisposalSelection}
          savedDisposalSelection={savedDisposalSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
          noData={noData}
      />,
      count: disposalCounter.data ?? 0,
      disable: noData || sellersNotFound,
    },
  ];
  const { activeTab, setActiveTab } = useTab(tabs);

  const actionWrapper: ReactElement =
      <Tooltip
          action={downloadingTopBtn}
          right={55}
          text={t("unclaimedPage.actionTooltip")}
          top={51}
      />

  return (
    <Page
        actions={actionWrapper}
        title={t(VITE_APP_DEV ? "unclaimedPage.title" : 'returnsPage.title')}
    >
      <div className={styles.tabsRow}>
        <TabsList
          tabs={tabs}
          onChange={setActiveTab}
          value={activeTab || tabs[0].id}
        />

        <div className={styles.filters}>
          {sellersNotFound ? null : (
            <Filters
              key={activeTab}
              items={[
                {
                  name: "sellerIds",
                  type: FilterTypes.SELLER,
                },
              ]}
              onChange={(v) => {
                (v || []).forEach((f) => {
                  if (f.name === "sellerIds") {
                    setIsInitialized(true);
                  }
                  setFilters(v);
                })
              }}
              values={filters}
            />
          )}

          {false && <Button
            icon={FilterIcon}
            onClick={() => {}}
            size={ButtonSize.S}
            type={ButtonType.LINK}
            title={t("parcelsPage.filters")}
          />}
        </div>
      </div>

      <TabsContent
        tab={tabs.find((tab) => (tab.id === activeTab))}
      />
    </Page>
  );
};

export default UnclaimedPage;
