import i18n from "i18next";

export enum Langs {
  en="en",
  cn="cn",
  tr="tr",
  ru="ru",
}

export const getLanguageId = (value: string): Langs => {
  if (["en", "en-EN", "en-US", "en-GB"].includes(value)) {
    return Langs.en;
  }
  if (["ru", "ru-RU"].includes(value)) {
    return Langs.ru;
  }
  if (["tr", "tr-TR"].includes(value)) {
    return Langs.tr;
  }
  if (["cn", "cn-CN", "zh-CN", "zh", "zh-TW", "zh-Hans"].includes(value)) {
    return Langs.cn;
  }

  return Langs.en;
};

export const getCurrentLanguage = (): Langs => getLanguageId(i18n.language)
