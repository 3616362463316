import styles from './BeforeReadyTabTable.module.scss';
import ArrowIcon from '@assets/icons/arrow-down-blue-14.svg';
import cn from 'classnames';
import CopyTextToClipboard from '@components/CopyTextToClipboard';
import { IBeforeReadyTabTableProps } from '../../types';
import { Text, TextSize, TextType, TextWeight } from '@components/Text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const BeforeReadyTabTable = (props: IBeforeReadyTabTableProps): React.ReactElement => {
  const { trackNumbers: { notFound, search } } = props;
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const defaultVisibleParcels: number = 14;

  return (
      <div className={styles.before}>
        <div className={styles.heading}>
          <Text
              size={TextSize.M}
              type={TextType.SPAN}
              weight={TextWeight.BOLD}
          >
            {t('pickUp.tabReady.noParcelsFound')}: {notFound.length} {t('pickUp.tabReady.of')} {search.length}
          </Text>
          <CopyTextToClipboard text={notFound.join(',')}>
            <Text
                size={TextSize.S}
                type={TextType.SPAN}
            >
              {t('pickUp.tabReady.copy')}
            </Text>
          </CopyTextToClipboard>
        </div>
        <div className={styles.values}>
          {notFound.map((item: string, index: number) => (
              <Text
                  className={cn(styles.value, {
                    [styles.hidden]: !isListOpen && ((notFound.length > defaultVisibleParcels ? index + 1 : index) >= defaultVisibleParcels)
                  })}
                  key={index}
                  size={TextSize.M}
                  type={TextType.SPAN}
              >
                {item}
              </Text>
          ))}
          {notFound.length > defaultVisibleParcels && (
              <span>
                <button
                    className={styles.toggle}
                    onClick={() => setIsListOpen(!isListOpen)}
                >
                  {t(isListOpen ? 'pickUp.tabReady.hide' : 'pickUp.tabReady.more')}
                  <span className={cn(styles.icon, isListOpen && styles.open)}>
                    <img
                        src={ArrowIcon}
                        alt=""
                    />
                  </span>
                </button>
              </span>
          )}
        </div>
      </div>
  );
};

export default BeforeReadyTabTable;