import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import cn from "classnames";
import Table from "@components/Table/Table.tsx";
import { TableColumn, TableColumnType, TableRowType } from "@components/Table/TableTypes.ts";
import { formatDate } from "@utils/formatValues";
import DoneIcon from "@assets/icons/check.svg";
import UpIcon from "@assets/icons/arrow-up-white.svg";
import "./ParcelModalTimeline.css";

export enum EventStatus { WAIT, ACTIVE, DONE }

export interface Event {
  type: number;
  message: string;
  ownerName: string;
  moment?: Date;
}

export interface TimelineItemProps {
  id: string;
  name: string;
  moment?: Date;
  status: EventStatus,
  statuses: Event[],
}

export const TimelineItem = ({
  name,
  moment: date,
  status,
  statuses,
}: TimelineItemProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const isClickable = statuses?.length;
  const detailsRef = useRef<HTMLDivElement>(null);

  const statusColumns: TableColumn<Event>[] = [
    {
      header: t("parcelModalWindowStatusTable.code"),
      accessor: (row: TableRowType<Event>) => row.data.type,
      alignment: "left",
      width: "40px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("parcelModalWindowStatusTable.status"),
      accessor: (row: TableRowType<Event>) => row.data.message,
      alignment: "left",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("parcelModalWindowStatusTable.partner"),
      accessor: (row: TableRowType<Event>) => row.data.ownerName,
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("parcelModalWindowStatusTable.date"),
      accessor: (row: TableRowType<Event>) => formatDate(row.data.moment, "dd.MM.yy HH:mm"),
      alignment: "right",
      width: "83px",
      fixWidth: true,
      noOverflow: true,
      type: TableColumnType.TERTIARY,
    },
  ];

  const handleOnClick = () => {
    if (!isClickable) return
    if (isOpen) {
      setIsClosing(true)
    } else {
      setIsOpen(true)
    }
  }

  return (
    <div className="parcel-modal-pickup-item">
      <div
        className={cn("parcel-modal-pickup-item__head", isClickable && "parcel-modal-pickup-item__head--clickable")}
        onClick={handleOnClick}
      >
        {status === EventStatus.WAIT ? (
          <div className="parcel-modal-pickup-item__title-icon parcel-modal-pickup-item__title-icon--wait" />
        ) : (
          <img
            className={cn({
              "parcel-modal-pickup-item__title-icon": true,
              "parcel-modal-pickup-item__title-icon--done": status === EventStatus.DONE,
              "parcel-modal-pickup-item__title-icon--active": status === EventStatus.ACTIVE,
              "parcel-modal-pickup-item__title-icon--open": isOpen,
            })}
            alt=""
            src={isOpen ? UpIcon : DoneIcon}
          />
        )}
        <div className={cn({
          "parcel-modal-pickup-item__title": true,
          "parcel-modal-pickup-item__title--done": status === EventStatus.DONE,
          "parcel-modal-pickup-item__title--active": status === EventStatus.ACTIVE,
          "parcel-modal-pickup-item__title--wait": status === EventStatus.WAIT,
        })}>
          {name}
        </div>
        {status !== EventStatus.WAIT && (
          <div className="parcel-modal-pickup-item__date">
            {date ? formatDate(date, "dd.MM.yy HH:mm") : ""}
          </div>
        )}
      </div>
      {isClickable ? (
        <CSSTransition
          in={isOpen && !isClosing}
          timeout={300}
          classNames="parcel-modal-pickup-item__details"
          mountOnEnter={false}
          unmountOnExit={false}
          onExited={() => {
            setIsOpen(false)
            setIsClosing(false)
          }}
          nodeRef={detailsRef}
        >
          <div className="parcel-modal-pickup-item__details-wrapper">
            <div ref={detailsRef}>
              {(isOpen) ? (
                <Table
                  columns={statusColumns}
                  data={statuses}
                  isHeadless
                  isSmall
                  isStriped
                  noBorder
                />
              ) : null}
            </div>
          </div>
        </CSSTransition>
      ) : null}
    </div>
  )
}
