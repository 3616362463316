import styles from './Tooltip.module.scss';
import parse from 'html-react-parser';

export interface ITooltipProps {
  action: React.ReactElement;
  bottom?: number;
  left?: number;
  right?: number;
  text: React.ReactNode;
  top?: number;
  whiteSpace?: string;
}

const Tooltip: React.FC<ITooltipProps> = (props: ITooltipProps): React.ReactElement => {
  const { action, bottom, left, right, text, top, whiteSpace } = props;

  return (
      <div className={styles.action}>
        {action}
        {text && (
            <div
                className={styles.tooltip}
                style={{
                  top: `${top}px`,
                  right: `${right}px`,
                  bottom: `${bottom}px`,
                  left: `${left}px`,
                  whiteSpace: whiteSpace
                }}
            >
              {typeof text === "string" ? parse(text) : text}
            </div>
        )}
      </div>
  );
};

export default Tooltip;
