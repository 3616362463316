import { Text, TextColor, TextWeight, TextSize } from "@components/Text";
import { SearchableString } from "@components/SearchInput";
import { FulfillmentProduct } from "@api/apiClient/fulfillment";

const SearchItem = ({ item, search }: { item: FulfillmentProduct, search?: string }) => {
  return (
    <>
      <Text weight={TextWeight.NORMAL} size={TextSize.S} color={TextColor.DARK_MUTED}>
        <SearchableString value={String(item.ozonSku)} search={search} />
      </Text>
      <Text weight={TextWeight.NORMAL} size={TextSize.S} ellipsis>
        <SearchableString value={item.name} search={search} />
      </Text>
    </>
  )
}

export default SearchItem;
