import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { updateUserInfo } from "@api/apiClient/signUp";
import { getAccountById } from "@api/apiClient";
import { Grid, Column, Row } from "@components/Grid";
import TextInputContainer from "@containers/TextInputContainer";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import FormErrorMessage from "@components/FormErrorMessage";
import { Text, TextType, TextSize } from "@components/Text";
import Box from "@components/Box";
import ConfirmPhone from "./ConfirmPhone";
import styles from "./UserRegistrationPage.module.scss";

export interface UserRegistrationFormType {
  name: string;
  phone: string;
}

const UserRegistrationPage = () => {
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const accQuery = useQuery({
    queryKey: ["account"],
    queryFn: () => getAccountById(),
    retry: 1,
  });

  const { control, handleSubmit, watch, setValue } = useForm<UserRegistrationFormType>({
    defaultValues: {
      name: "",
      phone: "",
    },
  });

  useEffect(() => {
    if (accQuery.data?.name || accQuery.data?.phone) {
      setValue("name", accQuery.data?.name);
      setValue("phone", accQuery.data?.phone);
    }
  }, [accQuery.data?.name, accQuery.data?.phone])

  const phone = watch("phone");

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: updateUserInfo,
    onSuccess: () => {
      setConfirmVisible(true);
    },
  });

  const onSendUserInfo = async (form: UserRegistrationFormType) => {
    mutate({ phone: form.phone, name: form.name });
  };

  const { t } = useTranslation();

  if (confirmVisible) {
    return (
      <div className={styles.panel}>
        <ConfirmPhone
          onSuccess={() => {
            accQuery.refetch();
          }}
          goBack={() => setConfirmVisible(false)}
          phone={phone}
          isLoading={isLoading}
          resend={handleSubmit(onSendUserInfo)}
        />
      </div>
    )
  }

  return (
    <>
      <div className={styles.panel}>
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={4}>
                <Text type={TextType.H1}>
                  {t("signUp.info.title2")}
                </Text>
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Box pb={5}>
                <Text size={TextSize.M}>
                  {t("signUp.info.description")}
                </Text>
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Box pb={5}>
                <TextInputContainer
                  control={control}
                  name="name"
                  title={t("signUp.info.name")}
                  rules={{ required: t('defaultFormErrors.required') }}
                />
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Box pb={7}>
                <TextInputContainer
                  control={control}
                  type="phone"
                  name="phone"
                  title={t("signUp.info.phone")}
                  rules={{ required: t('defaultFormErrors.required') }}
                />
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Button
                title={t("signUp.info.btn")}
                type={ButtonType.BLOCK}
                size={ButtonSize.S}
                onClick={handleSubmit(onSendUserInfo)}
                pending={isLoading}
              />
            </Column>
          </Row>
          {isError ? (
            <Column phone={12}>
              <Box pb={8}>
                <FormErrorMessage error={error} />
              </Box>
            </Column>
          ) : null}
        </Grid>
      </div>
    </>
  );
};

export default UserRegistrationPage;
