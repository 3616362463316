import { createSearchParams, useSearchParams, URLSearchParamsInit } from "react-router-dom";
import { State } from "history";

function appendSearchParams(obj: object, searchParams: URLSearchParamsInit | undefined) {
  const sp = createSearchParams(searchParams);
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      sp.delete(key);
      value.forEach((v) => sp.append(key, v));
    } else if (value === undefined) {
      sp.delete(key);
    } else {
      sp.set(key, value as string);
    }
  });
  return sp;
}

export const useAppendSearchParams = (init?: URLSearchParamsInit): [
  URLSearchParams,
  (
    obj: object,
    navigateOptions?: {
      replace?: boolean | undefined;
      state?: State | undefined;
    } | undefined
  ) => void] => {
  const [searchParams, setSearchParams] = useSearchParams(init);

  const setLocalSearchParams = (obj: object) => {
    setSearchParams(appendSearchParams(obj, searchParams));
  };

  return [searchParams, setLocalSearchParams];
}
