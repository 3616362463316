import { useTranslation } from "react-i18next";
import cn from "classnames";
import Box from "@components/Box";
import { Row } from "@components/Grid";
import { Button } from "@components/Button";
import { Text, TextType, TextWeight } from "@components/Text";
import styles from "./EmptyWarehouse.module.scss";

const EmptyWarehouse = ({ onCreateOrder }: {
  onCreateOrder: () => void,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={cn(styles.main, styles.mainCenter)}>
        <Row justify="center">
          <Box pb={3}>
            <Text type={TextType.H1}>
              {t(`fulfillmentPage.emptyWarehouse.title`)}
            </Text>
          </Box>
        </Row>
        <Row justify="center">
          <Box pb={10}>
            <Text weight={TextWeight.MEDIUM}>
              {t(`fulfillmentPage.emptyWarehouse.description`)}
            </Text>
          </Box>
        </Row>
        <Row justify="center">
          <Button
            onClick={onCreateOrder}
            title={`+ ${t("fulfillmentPage.order.createOrder")}`}
          />
        </Row>
      </div>
    </div>
  );
};

export default EmptyWarehouse;
