import { Ref, useState } from "react";
import classNames from "classnames";
import "./Textarea.css";

interface TextInputProps {
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  hasError?: boolean;
  errorMessage?: string;
  name?: string;
  ref?: Ref<HTMLTextAreaElement>;
  title: string;
  disabled?: boolean;
  rows?: number;
  maxLength?: number;
}

const Textarea = (props: TextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const isFilled = props.value && props.value.length > 0;

  const handleFocus = () => {
    setIsFocused(true);
    if (props.onFocus) {
      props.onFocus();
    }
  };
  const handleBlur = () => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <div className={"textarea-container"}>
      <div
        className={classNames("textarea", {
          "textarea--active": isFocused || isFilled,
          "textarea--error": props.hasError,
          "textarea--focus": isFocused,
          "textarea--disabled": props.disabled,
        })}
      >
        <label className={"textarea__label"}>
          <span className={"textarea__title"}>
            {props.title}
          </span>
          <div className={"textarea__wrapper"}>
            <textarea
              ref={props.ref}
              value={props.value}
              className={"textarea__input"}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name={props.name}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e.target.value);
                }
              }}
              disabled={props.disabled}
              rows={props.rows}
              maxLength={props.maxLength}
            />
          </div>
        </label>
      </div>
      {props.hasError && (
        <div className={"textarea-error"}>{props.errorMessage}</div>
      )}
    </div>
  );
};

export default Textarea;
