import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useQuery } from "react-query";
import {
  getParcelImages,
  getParcel,
  Parcel,
  ParcelItem,
} from "@api/apiClient";
import { ErrorResponse } from "@api/Responses/ErrorResponse";
import Table from "@components/Table/Table.tsx";
import ToggleButtons from "@components/ToggleButtons";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import { Loader } from "@components/Loader";
import Row from "@components/Grid/Row.tsx";
import Column from "@components/Grid/Column.tsx";
import { Text, TextColor, TextSize, TextType } from "@components/Text";
import ParcelModalTimeline from "./ParcelModalTimeline";
import RocketIcon from "@assets/icons/rocket.svg";
import ReturnIcon from "@assets/icons/return.svg";
import "./ParcelModalWindow.css";
import LengthIcon from "@assets/icons/double-arrow-horizontal-grey-20.svg";
import WidthIcon from "@assets/icons/double-arrow-oblique-grey-22.svg";
import HeightIcon from "@assets/icons/double-arrow-vertical-grey-10.svg";
import WeightIcon from "@assets/icons/weight-grey-17.svg";
import PriceIcon from "@assets/icons/diamond-grey-18.svg";
import CopyTextToClipboard from '@components/CopyTextToClipboard';

interface ParcelModalWindowProps {
  parcelId: string;
}

const ParcelModalWindow = ({ parcelId }: ParcelModalWindowProps) => {
  const { t } = useTranslation();
  const query = useQuery<Parcel, ErrorResponse>({
    queryKey: ["parcel", parcelId],
    queryFn: () => getParcel(parcelId),
    retry: 1,
  });
  const isReturn = Boolean(
    query.data?.trackEvents
    && query.data.trackEvents.find((e) => e.type > 20000)
  );
  const imagesQuery = useQuery({
    queryKey: ["parcelImages", parcelId],
    queryFn: () => getParcelImages(parcelId),
    retry: 1,
    enabled: isReturn,
  });

  const [statusView, setStatusView] = useState<string>(
    isReturn ? "return" : "send",
  );

  useEffect(() => {
    setStatusView(isReturn ? "return" : "send")
  }, [isReturn])

  const columns: TableColumn<ParcelItem>[] = [
    {
      header: t("parcelModalWindowAttachmentsTable.article"),
      accessor: (row: TableRowType<ParcelItem>) => row.data.vendorCode,
      alignment: "left",
      width: "85px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("parcelModalWindowAttachmentsTable.name"),
      accessor: (row: TableRowType<ParcelItem>) => (
        <a
          href={row.data.url}
          target="_blank"
          className="parcel-modal-ellipsis" rel="noreferrer"
        >
          {row.data.name}
        </a>
      ),
      width: "185px",
      alignment: "left",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("parcelModalWindowAttachmentsTable.hscode"),
      accessor: ({ data }) => data.hsCodeRu || data.hsCodeUe || data.hsCodeIs,
      alignment: "left",
      width: "110px",
      fixWidth: true,
      noOverflow: true,
      type: TableColumnType.TERTIARY,
    },
    {
      header: t("parcelModalWindowAttachmentsTable.quantity"),
      accessor: (row: TableRowType<ParcelItem>) => row.data.quantity,
      alignment: "right",
      width: "65px",
      fixWidth: true,
      noOverflow: true,
      type: TableColumnType.TERTIARY,
    },
    {
      header: t("parcelModalWindowAttachmentsTable.cost"),
      accessor: (row: TableRowType<ParcelItem>) =>
        (new Intl.NumberFormat().format(row.data.declaredValue?.amount) ?? "") +
        " ₽",
      alignment: "right",
      width: "90px",
      fixWidth: true,
      noOverflow: true,
    },
  ];

  if (query.isFetching) {
    return (
      <>
        <div
          className={cn(
            "parcel-modal-title",
            isReturn && "parcel-modal-title--return",
          )}
        />
        <div className="parcel-modal__body">
          <div className="parcel-modal__body-inner">
            <Row>
              <Column phone={12}>
                <div className="parcel-modal__panel parcel-modal__panel--no-border">
                  <Loader size={"s"} />
                </div>
              </Column>
            </Row>
          </div>
        </div>
      </>
    );
  }

  if (query.isError) {
    return (
      <>
        <div
          className={cn(
            "parcel-modal-title",
            isReturn && "parcel-modal-title--return",
          )}
        />
        <div className="parcel-modal__body">
          <div className="parcel-modal__body-inner">
            <Row>
              <Column phone={12}>
                <div className="parcel-modal__panel parcel-modal__panel--no-border">
                  <Text
                    type={TextType.SPAN}
                    size={TextSize.M}
                    color={TextColor.ERROR}
                  >
                    {query.error.message}
                  </Text>
                </div>
              </Column>
            </Row>
          </div>
        </div>
      </>
    );
  }

  const deliveryTrackNumber = query?.data?.returnDeliveryInfo?.deliveryTrackNumber ?? "";
  const deliveryServiceName = query?.data?.returnDeliveryInfo?.deliveryServiceName ?? "";

  return (
    <>
      <div
        className={cn(
          "parcel-modal-title",
          isReturn && "parcel-modal-title--return",
        )}
      >
        <div>
          <Text type={TextType.H1} color={TextColor.WHITE}>
            {query?.data?.trackNumber}
          </Text>

          <Text type={TextType.SPAN} size={TextSize.M} color={TextColor.WHITE}>
            {query?.data?.externalId}
          </Text>
        </div>

        <div className="parcel-modal-title__status">
          {isReturn ? (
            <>
              <img src={ReturnIcon} alt="icon" />
              {t("parcelModalWindow.statusReturn")}
            </>
          ) : (
            <>
              <img src={RocketIcon} alt="icon" />
              {t("parcelModalWindow.statusSend")}
            </>
          )}
        </div>
      </div>
      <div className="parcel-modal__body">
        <div className="parcel-modal__body-inner">
          <Row>
            <Column phone={12}>
              <div className="parcel-modal__panel parcel-modal__panel-info">
                <Text
                    type={TextType.H3}
                    color={TextColor.PRIMARY}
                    className="parcel-modal-pickup__title"
                >
                  {t("parcelModalWindow.parcel")}
                </Text>
                <div className="parcel-modal__panel-info-inner">
                  <div className="parcel-modal__panel-info-item">
                    <span className="parcel-modal__panel-info-item-icon">
                      <img src={LengthIcon} alt="Length"/>
                    </span>
                    <span className="parcel-modal__panel-info-item-text">
                      <span className="parcel-modal__panel-info-item-title">
                        {t("parcelModalWindow.length")}
                      </span>
                      <span className="parcel-modal__panel-info-item-value">
                        {new Intl.NumberFormat().format(query?.data!.dimensions.length.value) ?? ''} {query?.data?.dimensions.length.unit}
                      </span>
                    </span>
                  </div>
                  <div className="parcel-modal__panel-info-item">
                    <span className="parcel-modal__panel-info-item-icon">
                      <img src={WidthIcon} alt="Width"/>
                    </span>
                    <span className="parcel-modal__panel-info-item-text">
                      <span className="parcel-modal__panel-info-item-title">
                        {t("parcelModalWindow.width")}
                      </span>
                      <span className="parcel-modal__panel-info-item-value">
                        {new Intl.NumberFormat().format(query?.data!.dimensions.width.value) ?? ''} {query?.data?.dimensions.width.unit}
                      </span>
                    </span>
                  </div>
                  <div className="parcel-modal__panel-info-item">
                    <span className="parcel-modal__panel-info-item-icon">
                      <img src={HeightIcon} alt="Height"/>
                    </span>
                    <span className="parcel-modal__panel-info-item-text">
                      <span className="parcel-modal__panel-info-item-title">
                        {t("parcelModalWindow.height")}
                      </span>
                      <span className="parcel-modal__panel-info-item-value">
                        {new Intl.NumberFormat().format(query?.data!.dimensions.height.value) ?? ''} {query?.data?.dimensions.height.unit}
                      </span>
                    </span>
                  </div>
                  <div className="parcel-modal__panel-info-item">
                    <span className="parcel-modal__panel-info-item-icon">
                      <img src={WeightIcon} alt="Weight"/>
                    </span>
                    <span className="parcel-modal__panel-info-item-text">
                      <span className="parcel-modal__panel-info-item-title">
                        {t("parcelModalWindow.weight")}
                      </span>
                      <span className="parcel-modal__panel-info-item-value">
                        {new Intl.NumberFormat().format(query?.data!.weight.value) ?? ''} {query?.data?.weight.unit}
                      </span>
                    </span>
                  </div>
                  <div className="parcel-modal__panel-info-item"/>
                  <div className="parcel-modal__panel-info-item">
                    <span className="parcel-modal__panel-info-item-icon">
                      <img src={PriceIcon} alt="Price"/>
                    </span>
                    <span className="parcel-modal__panel-info-item-text">
                      <span className="parcel-modal__panel-info-item-title">
                        {t("parcelModalWindow.postageCost")}
                      </span>
                      <span className="parcel-modal__panel-info-item-value">
                        {(new Intl.NumberFormat().format(query?.data!.declaredValue.amount) ?? '') + ' ₽'}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <div className="parcel-modal__panel parcel-modal__panel--autohight">
                <Text
                  type={TextType.SPAN}
                  size={TextSize.L}
                  color={TextColor.PRIMARY}
                  className="parcel-modal-pickup__title"
                >
                  {query.data?.serviceName || ""}
                </Text>

                {isReturn ? (
                  <ToggleButtons
                    value={statusView}
                    onChange={setStatusView}
                    items={[
                      { id: "send", title: t("parcelModalWindow.statuses") },
                      { id: "return", title: t("parcelModalWindow.statusReturn") },
                    ]}
                  />
                ) : null}
                {(deliveryTrackNumber && deliveryServiceName) ? (
                  <div className="parcel-modal__service">
                    <div className="parcel-modal__service-item">
                      <span className="parcel-modal__service-title">
                        {t("parcelModalWindow.trackNumber")}
                      </span>
                      <span className="parcel-modal__service-value">
                        {(deliveryServiceName === "STO Express") ? (
                          <CopyTextToClipboard text={deliveryTrackNumber}>
                            <a href={`https://www.sto.cn/pc/service-page/iframe_2_21_${deliveryTrackNumber}`} target="_blank">
                              {deliveryTrackNumber}
                            </a>
                          </CopyTextToClipboard>
                        ) : (
                          <CopyTextToClipboard text={deliveryTrackNumber}>
                            {deliveryTrackNumber}
                          </CopyTextToClipboard>
                        )}
                      </span>
                    </div>
                    <div className="parcel-modal__service-item">
                      <span className="parcel-modal__service-title">
                        {t("parcelModalWindow.courierService")}
                      </span>
                      <span className="parcel-modal__service-value">
                        <CopyTextToClipboard text={deliveryServiceName}>
                          {deliveryServiceName}
                        </CopyTextToClipboard>
                      </span>
                    </div>
                  </div>
                ) : null}

                {query?.data?.trackEvents ? (
                  <ParcelModalTimeline
                    items={query?.data?.trackEvents}
                    countryCode={query?.data?.returnDeliveryInfo?.countryCode}
                    isReturn={statusView === "return"}
                  />
                ) : null}
              </div>
            </Column>
          </Row>

          {isReturn && imagesQuery?.data?.imageLinks?.length ? (
            <Row>
              <Column phone={12}>
                <div className="parcel-modal__panel">
                  <Text
                    type={TextType.H3}
                    color={TextColor.PRIMARY}
                    className="parcel-modal-pickup__title"
                  >
                    {t("parcelModalWindow.photo")}
                  </Text>

                  <div className="parcel-modal__photos">
                    {imagesQuery?.data?.imageLinks.map((img) => (
                      <a
                        key={img}
                        href={img}
                        className="parcel-modal__photo"
                        target="_blank" rel="noreferrer"
                      >
                        <img src={img} />
                      </a>
                    ))}
                  </div>
                </div>
              </Column>
            </Row>
          ) : null}
          <Row>
            <Column phone={12}>
              <div className="parcel-modal__panel parcel-modal__panel--no-border">
                <Text
                  type={TextType.H3}
                  color={TextColor.PRIMARY}
                  className="parcel-modal-pickup__title"
                >
                  {t("parcelModalWindow.attachments")}
                </Text>

                <Table
                  selectable={false}
                  data={query?.data?.items || ([] as ParcelItem[])}
                  columns={columns}
                  className="parcel-modal-attachments-table"
                />
              </div>
            </Column>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ParcelModalWindow;
