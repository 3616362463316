import MultiSelectFilter from '../../Filter/MultiSelectFilter';
import { getStates } from '@api/apiClient/returns';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const StatusFilter = ({ asPanel, initialValue, onChange }: {
  asPanel?: boolean,
  initialValue: string[],
  onChange: (sellers: string[]) => void,
}) => {
  const { t } = useTranslation();

  const { isFetching, data } = useQuery({
    queryKey: ['states'],
    queryFn: () => getStates(),
    retry: 1,
    select: (items) => items.map(item => ({
      id: item.id,
      value: t(`returnsPage.awaitingTab.states.${item.name}`)
    }))
  });

  return (
      <MultiSelectFilter
          asPanel={asPanel}
          data={data || []}
          initialValue={initialValue}
          isFetching={isFetching}
          onChange={onChange}
          placeholder={t('returnsPage.awaitingTab.state')}
      />
  );
};

export default StatusFilter;
