import { Link } from "react-router-dom";
import walletIcon from "@assets/icons/wallet.svg";
import { useProfile } from "@contexts/ProfileContext";
import "./WalletWidget.css";

const WalletWidget = () => {
  const profile = useProfile();

  return (
    <Link
      className="wallet-widget"
      to={"/profile?tab=finTab"}
    >
      <div className={"wallet-widget__icon"}>
        <img src={walletIcon} alt={"wallet"} />
      </div>
      <div className={"wallet-widget__value"}>
        {profile?.account?.balance ?? 0}
      </div>
    </Link>
  );
};

export default WalletWidget;
