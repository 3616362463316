import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Grid, Column, Row } from "@components/Grid";
import { Text, TextType } from "@components/Text";
import TextInputContainer from "@containers/TextInputContainer";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import Box from "@components/Box";

export interface NameFormType {
  name: string;
}

interface NameFormProps {
  onSuccess: (name: string) => void;
}

const NameForm = ({
  onSuccess,
}: NameFormProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<NameFormType>({
    defaultValues: {
      name: "",
    },
  });

  const onNameSubmit = (form: NameFormType) => {
    onSuccess(form.name);
  };

  return (
    <>
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <Text type={TextType.H1}>
                {t(`addOzonShop.nameTitle`)}
              </Text>
            </Box>
          </Column>
        </Row>

        <form onSubmit={handleSubmit(onNameSubmit)}>
          <Row>
            <Column phone={12}>
              <Box pb={8}>
                <TextInputContainer
                  control={control}
                  name="name"
                  title={t("addOzonShop.name")}
                  rules={{ required: t("defaultFormErrors.required") }}
                />
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Box pb={5}>
                <Button
                  title={t("addOzonShop.addBtn")}
                  type={ButtonType.BLOCK}
                  size={ButtonSize.S}
                  onClick={handleSubmit(onNameSubmit)}
                />
              </Box>
            </Column>
          </Row>
        </form>
      </Grid>
    </>
  );
};

export default NameForm;
