import { useTranslation } from "react-i18next";
import { Text, TextSize } from "@components/Text";
import ForbiddenIcon from "@assets/icons/forbidden.png";
import WeightIcon from "@assets/icons/excessWeight.png";
import DimensionsIcon from "@assets/icons/excessDimensions.png";
import styles from "./Reason.module.css";

const reasons: Record<string, { icon: string, name: string }> = {
  OverWeight: { name: "weight", icon: WeightIcon },
  Oversized: { name: "oversized", icon: DimensionsIcon },
  ContainsProhibitedItem: { name: "prohibitedItems", icon: ForbiddenIcon },
}

const Reason = ({ value }: {
  value: string
}) => {
  const { t } = useTranslation();

  const reason = reasons[value] || {
    name: value,
    icon: null,
  };

  return (
    <div className={styles.root}>
      <img className={styles.icon} src={reason.icon} alt="" />
      <Text size={TextSize.S}>
        {t(
          `problematicParcelsPage.reasons.${reason.name}`,
          { defaultValue: "" },
        )}
      </Text>
    </div>
  );
};

export default Reason;
