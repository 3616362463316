import Grid from "../../components/Grid/Grid.tsx";
import Column from "../../components/Grid/Column.tsx";
import Row from "../../components/Grid/Row.tsx";
import { useForm } from "react-hook-form";
import TextInputContainer from "../../containers/TextInputContainer.tsx";
import { Button, ButtonSize, ButtonType } from "../../components/Button";
import Title from "../../components/Title/Title.tsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Box from "../../components/Box/Box.tsx";
import { LoginFormProps, LoginFormType, TFormField } from './types';

const LoginForm = (props: LoginFormProps) => {
  const { onSubmit, isAdmin, pending} = props;

  const { control, handleSubmit } = useForm<LoginFormType>({
    defaultValues: {
      email: "",
      emailAdmin: "",
      emailSeller: "",
      password: "",
    },
  });

  const { t } = useTranslation();

  const emailField: TFormField = {
    control,
    name: 'email',
    rules: { required: t("loginForm.emailErrorText") },
    title: t("loginForm.email"),
    type: 'email'
  };
  const emailAdminField: TFormField = {
    control,
    name: 'emailAdmin',
    rules: { required: t("loginForm.emailErrorText") },
    title: t("loginForm.emailAdmin"),
    type: 'email'
  };
  const emailSellerField: TFormField = {
    control,
    name: 'emailSeller',
    rules: { required: t("loginForm.emailSellerErrorText") },
    title: t("loginForm.emailSeller"),
    type: 'email'
  };
  const passwordField: TFormField = {
    control,
    name: 'password',
    rules: { required: t("loginForm.passwordErrorText") },
    title: t("loginForm.password"),
    type: 'password'
  };

  const formFields: TFormField[] = isAdmin ? [emailAdminField, passwordField, emailSellerField] : [emailField, passwordField];

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={8}>
            <Title title={t("loginForm.title")} />
          </Box>
        </Column>
      </Row>
      {formFields.map((item: TFormField, index: number) => (
          <Row key={index}>
            <Column phone={12}>
              <Box pb={4}>
                <TextInputContainer {...item}/>
              </Box>
            </Column>
          </Row>
      ))}
      {!isAdmin && (
          <Row>
            <Column phone={12}>
              <Box pb={4} textAlign={"right"}>
                <Link to={"/send-email-to-reset-password"}>
                  {t("loginForm.resetPasswordLink")}
                </Link>
              </Box>
            </Column>
          </Row>
      )}
      <Row>
        <Column phone={12}>
          <Button
            title={t("loginForm.button")}
            type={ButtonType.BLOCK}
            size={ButtonSize.S}
            onClick={handleSubmit(onSubmit)}
            pending={pending}
          />
        </Column>
      </Row>
    </Grid>
  );
};

export default LoginForm;
