import {
  RegisterOptions,
  useController,
  Control,
  FieldValues,
  Path,
} from "react-hook-form";
import Textarea from "@components/Textarea";

interface TextAreaContainerProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  rules?: Omit<
    RegisterOptions<TFieldValues>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  title: string;
  rows?: number;
  disabled?: boolean;
  maxLength?: number;
}

const TextAreaContainer = <TFieldValues extends FieldValues = FieldValues>(
  props: TextAreaContainerProps<TFieldValues>,
) => {
  const { field, fieldState } = useController<TFieldValues>({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <Textarea
      value={field.value}
      onChange={field.onChange}
      name={field.name}
      title={props.title}
      errorMessage={fieldState.error?.message}
      hasError={!!fieldState.error}
      rows={props.rows}
      disabled={props.disabled}
      maxLength={props.maxLength ?? 1000}
    />
  );
};

export default TextAreaContainer;
