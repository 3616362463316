import { useState, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
// import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Modal } from "@components/Modal";
import TextAreaContainer from "@containers/TextAreaContainer";
import { Button, ButtonType, ButtonSize } from "@components/Button";
import { Grid, Column, Row } from "@components/Grid";
import FormErrorMessage from "@components/FormErrorMessage";
import {
  createComplaintRequest,
  validateTrackingNumbers,
} from "@api/apiClient/complaints";
import { Text, TextColor, TextType, TextWeight, TextSize } from "@components/Text";
import Box from "@components/Box";
import FileItem from "../FileItem";
import { ToggleButtons, ToggleButtonTypes } from "@components/ToggleButtons";
import IconImportant from "@assets/icons/important.png";
import IconPaperclip from "@assets/icons/paperclip.svg";
import styles from "./CreateRequestModal.module.scss";

interface FormType {
  numbers: string,
  comment: string,
}

const CreateRequestModal = ({ onClose }: {
  onClose: () => void,
}) => {
  const { t } = useTranslation();
  const [requestType, setRequestType] = useState<string>("Damage");
  const [files, setFiles] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [trackingNumberErrors, setTrackingNumberErrors] = useState<Record<string, string[]>>({});

  const { control, handleSubmit, ...rest } = useForm<FormType>({
    defaultValues: {
      numbers: "",
      comment: "",
    },
  });

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: createComplaintRequest,
    onSuccess: () => {
      onClose();
    },
  });

  const validateNumbersQuery = useQuery({
    queryKey: ["validateTrackingNumbers"],
    queryFn: () => validateTrackingNumbers(rest.getValues().numbers.split(/,?\s+/g)),
    retry: 1,
    enabled: false,
    onSuccess: ({ trackingNumberValidationResults }) => {
      const errNumbers: Record<string, string[]> = trackingNumberValidationResults
        .reduce((
          acc: Record<string, string[]>,
          item: { validationErrorTypes: string[], trackingNumber: string },
        ) => {
          item.validationErrorTypes.forEach((errType) => {
            acc[errType] = [...(acc[errType] || []), item.trackingNumber];
          });
          return acc;
        }, {});
      setTrackingNumberErrors(errNumbers);

      if (!Object.keys(errNumbers).length) {
        const formValues = rest.getValues();
        mutate({
          reasonType: requestType,
          trackingNumbers: formValues.numbers.split(/,?\s+/g),
          comment: formValues.comment,
          attachments: files,
        });
      }
    },
  });

  const submit = () => {
    validateNumbersQuery.refetch();
  };

  const handleNewFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = (
      event.target.files ? Array.from(event.target.files) : []
    ).filter((f) => (f.size <= 10*1024*1024));
    setFiles([...files, ...newFiles]);
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      onClickOverlay={onClose}
      width={567}
    >
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <Text type={TextType.H2} color={TextColor.PRIMARY}>
                {t("complaintPage.newRequestModal.title")}
              </Text>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box>
            <Text size={TextSize.S} weight={TextWeight.BOLD}>
              {t('complaintPage.newRequestModal.type')}
            </Text>
            </Box>
          </Column>
        </Row>

        <Row>
          <Box pt={5}>
            <Column phone={12}>
              <ToggleButtons
                value={requestType}
                onChange={setRequestType}
                className={styles.typeBtns}
                items={[
                  { id: "Damage", title: t("complaintPage.types.Damage") },
                  { id: "Loss", title: t("complaintPage.types.Loss") },
                  { id: "Delay", title: t("complaintPage.types.Delay") },
                  { id: "Substitution", title: t("complaintPage.types.Substitution") },
                ]}
                type={ToggleButtonTypes.WITH_MARK}
              />
            </Column>
          </Box>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={8}>
              <TextAreaContainer
                control={control}
                name="numbers"
                rows={5}
                title={t('complaintPage.newRequestModal.numbers')}
                rules={{
                  required: t("defaultFormErrors.required"),
                }}
              />
            </Box>
          </Column>
        </Row>

        {Object.keys(trackingNumberErrors).length ? (
          <Row>
            <Column phone={12}>
              <Box pt={4}>
                <div className={styles.numberErrors}>
                  {Object.keys(trackingNumberErrors).map(errType => (
                    <div key={errType}>
                      <img src={IconImportant} alt="" />
                      <Text size={TextSize.S} weight={TextWeight.BOLD}>
                        {t(
                          `complaintPage.newRequestModal.validationRrrors.${errType}`,
                          { defaultValue: errType },
                        )}
                      </Text>
                      <ul>
                        {trackingNumberErrors[errType].map(item => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </Box>
            </Column>
          </Row>
        ) : null}

        <Row>
          <Column phone={12}>
            <Box pt={5}>
              <TextAreaContainer
                control={control}
                name="comment"
                maxLength={4000}
                rows={2}
                title={t('complaintPage.newRequestModal.comment')}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={1} flex="column">
              <input
                ref={fileInputRef}
                className={styles.fileInput}
                type="file"
                multiple
                onChange={handleNewFiles}
              />

              {files.map((item, idx) => (
                <FileItem
                  name={item.name}
                  onRemove={() => setFiles(files.filter((_, i) => i !== idx))}
                />
              ))}
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={4}>
              <Button
                type={ButtonType.LIGHT_ROUNDED}
                size={ButtonSize.XS}
                onClick={() => {
                  fileInputRef?.current?.click?.();
                }}
                icon={IconPaperclip}
                title={t("complaintPage.newRequestModal.attachFiles")}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={8}>
              <Button
                pending={isLoading || validateNumbersQuery.isFetching}
                type={ButtonType.PRIMARY}
                onClick={handleSubmit(submit)}
                title={t("complaintPage.newRequestModal.sendBtn")}
              />
            </Box>
          </Column>
        </Row>

        {isError && (
          <FormErrorMessage error={error} />
        )}
      </Grid>
    </Modal>
  );
}

export default CreateRequestModal;
