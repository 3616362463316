import { useMemo } from "react";
import { useAppendSearchParams } from "./useAppendSearchParams";

export interface FilterModel {
  name: string;
  value: string | string[];
  fieldNames?: string[],
}

function upperCaseFirstChar(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function lowerCaseFirstChar(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

const prefix = "filter";

export const useFiltersSearchParams = (): {
  filters: FilterModel[],
  setFilters: (filters: FilterModel[]) => void,
  updateFilter: (filter: FilterModel) => void,
} => {
  const [searchParams, setSearchParams] = useAppendSearchParams();

  const setFilters = (filters: FilterModel[]) => {
    const obj: Record<string, string | string[] | undefined> = {};
    filters.forEach((f) => {
      obj[prefix + upperCaseFirstChar(f.name)] = f.value || undefined;
    });

    setSearchParams(obj);
  };

  const updateFilter = (filter: FilterModel) => {
    setSearchParams({
      ...searchParams,
      [prefix + upperCaseFirstChar(filter.name)]: filter.value || undefined,
    });
  };

  const filters = useMemo(() => {
    const result: FilterModel[] = [];
    for (const [key, value] of searchParams) {
      if (key.startsWith(prefix)) {
        result.push({
          name: lowerCaseFirstChar(key.replace(prefix, "")),
          value,
        });
      }
    }
    return result;
  }, [searchParams]);

  return { filters, setFilters, updateFilter };
};
