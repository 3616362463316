import { createContext, useContext } from "react";

interface CounterContextType {
  returns: number;
  update: () => void;
}

const CounterContext = createContext<CounterContextType | null>(null);

export const useCounter = (): CounterContextType => {
  const context = useContext(CounterContext);
  if (!context) {
    throw new Error("useCounter must be used within an ProfileProvider");
  }
  return context;
};

export default CounterContext;
export { default as CounterProvider } from "././CounterProvider.tsx";
