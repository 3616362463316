import { useMemo } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import Avatar from "@components/Avatar";
import Dropdown, { DropdownOptionProps, DropdownValueProps } from "@components/Dropdown";
import { getSellers, Seller } from "@api/apiClient";
import styles from "./SellerFilterSingle.module.css";
import OzonIcon from "@assets/img/ozon.svg";

interface SellerOption {
  id: string;
  value: Seller;
}

const OptionComponent = ({ value: seller }: DropdownOptionProps<string, Seller>) => (
    <div className={styles.option}>
      <Avatar
          className={styles.avatar}
          id={seller?.id}
          name={seller?.name || ''}
      />
      <img
          alt="Ozon"
          className={cn(styles.ozon, {
            [styles.ozonHidden]: !seller?.ozonApiExists
          })}
          src={OzonIcon}
      />
      <span className={styles.name}>{seller?.name}</span>
    </div>
);

const ValueComponentCreator = ({ placeholder }: { placeholder?: string }) => ({ value, disabled }: DropdownValueProps<string, Seller>) => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.root, disabled && styles.disabled)}>
      {value?.value?.id ? (
        <>
          <Avatar
            className={styles.avatar}
            id={value?.value?.id}
            name={value?.value?.name || ""}
          />
          <span className={styles.name}>{value?.value?.name}</span>
        </>
      ) : (
        <div className={styles.name}>
          {placeholder ?? t("fulfillmentPage.selectSeller")}
        </div>
      )}
    </div>
  )
}

const SellerFilterSingle = ({
  onChange,
  initialValue,
  disabled,
  needSortBy,
  placeholder,
}: {
  onChange: (seller?: Seller) => void,
  initialValue?: Seller,
  disabled?: boolean,
  needSortBy?: string,
  placeholder?: string,
}) => {
  const { data } = useQuery({
    queryKey: ["sellers"],
    queryFn: () => getSellers(),
    retry: 1,
    select: ({ items }) => items.map(item => ({ id: item.id, value: item })),
  });

  const defaultOption = useMemo(() => {
    const value = initialValue ?? { id: "", name: "" };
    return { id: value.id, value };
  }, [initialValue?.id]);

  const ValueComponent = useMemo(() => {
    return ValueComponentCreator({ placeholder });
  }, [placeholder])

  let filteredData: SellerOption[] = [];

  if (data) {
    if (needSortBy) {
      filteredData = data.sort((a: SellerOption, b: SellerOption) => b.value[needSortBy] - a.value[needSortBy]);
    } else {
      filteredData = data;
    }
  }

  return (
    <Dropdown<string, Seller>
      key={defaultOption?.id}
      onChange={(_, seller) => onChange(seller)}
      OptionComponent={OptionComponent}
      ValueComponent={ValueComponent}
      defaultOption={defaultOption}
      options={filteredData}
      menuPosition="bottom"
      disabled={disabled}
    />
  );
}

export default SellerFilterSingle;
