import { useQuery } from "react-query";
import { AddressTemplate, getAddresses } from '@api/apiClient';
import { Grid, Column, Row } from "@components/Grid";
import Box from "@components/Box/Box";
import { Loader } from "@components/Loader";
import ReturnForm from "./ReturnForm";
import { useProfile } from "@contexts/ProfileContext";

interface SentReturnsFormProps {
  Sum: number;
  DeliverySum: number;
  parcelIds: string[];
  onClose: () => void;
  selectedDepartureCount: number;
  mutation: any;
  countryCode?: string;
  isProblematicParcel?: boolean;
}

const ReturnModalWindow = (props: SentReturnsFormProps) => {
  const {
    DeliverySum,
    onClose,
    parcelIds,
    selectedDepartureCount,
    Sum,
    mutation,
    countryCode,
    isProblematicParcel,
  } = props;

  const { account } = useProfile();

  const query = useQuery({
    queryKey: ["addresses"],
    queryFn: () => getAddresses(),
    retry: 1,
  });

  let filteredAddresses: AddressTemplate[] = [];

  if (query.data?.addressTemplates) {
    if (selectedDepartureCount) {
      filteredAddresses = query.data?.addressTemplates
        .filter((item: AddressTemplate) => (
          item.address.countryCode === (countryCode || account?.region)
        ));
    } else {
      filteredAddresses = query.data?.addressTemplates;
    }
  }

  return (
    <Grid>
      {query.isFetching && (
        <Row>
          <Column phone={12}>
            <Box p={10} textAlign={"center"}>
              <Loader alignment={"center"} size={"s"} />
            </Box>
          </Column>
        </Row>
      )}

      {!query.isFetching && query.data && (
        <Row>
          <Column phone={12}>
            <ReturnForm
              onClose={onClose}
              defaultAddress={filteredAddresses[0]}
              addressTemplates={filteredAddresses}
              DeliverySum={DeliverySum}
              Sum={Sum}
              parcelIds={parcelIds}
              selectedDepartureCount={selectedDepartureCount}
              region={countryCode || account?.region}
              mutation={mutation}
              isProblematicParcel={isProblematicParcel}
            />
          </Column>
        </Row>
      )}
    </Grid>
  );
};

export default ReturnModalWindow;
