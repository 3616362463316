import { useLayoutEffect, useRef } from 'react';
import {ZendeskUtils} from "../../utils/zendeskUtils.ts";

const ZendeskWidget = () => {
    const scriptRef = useRef<HTMLScriptElement>();

    useLayoutEffect(() => {
      if (!scriptRef.current) {
        scriptRef.current = document.createElement('script');
        scriptRef.current.src = 'https://static.zdassets.com/ekr/snippet.js?key=c020c36f-f7ae-476b-b05b-300d491baddd';
        scriptRef.current.id = 'ze-snippet';
        scriptRef.current.async = true;
        scriptRef.current.onload = () => {
          if (typeof window.zE === 'function') {
            ZendeskUtils.login();
          } else {
            if (scriptRef.current instanceof HTMLScriptElement) {
              scriptRef.current.remove();
            }
          }
        };
        scriptRef.current.onerror = () => {
          if (scriptRef.current instanceof HTMLScriptElement) {
            scriptRef.current.remove();
          }
        };
        document.body.appendChild(scriptRef.current);
      }
    }, []);

    return null;
};

export default ZendeskWidget;
