import { useTranslation } from "react-i18next";
import { Grid, Column, Row } from "@components/Grid";
import { Text, TextSize, TextType } from "@components/Text";
import { Button } from "@components/Button";
import Box from "@components/Box/Box";
import styles from "./FpbInstruction.module.css";

const FpbInstruction = ({ onClose }: {
  onClose: () => void,
}) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={3}>
            <Text type={TextType.H1}>
              {t("fbpInstruction.title")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={3}>
            <Text type={TextType.P} size={TextSize.M}>
              {t("fbpInstruction.text")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <ol className={styles.list}>
              <li>
                <Text size={TextSize.M}>
                  {t("fbpInstruction.text1")}
                </Text>
              </li>
              <li>
                <Text size={TextSize.M}>
                  {t("fbpInstruction.text2")}
                </Text>
              </li>
            </ol>
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Button
            onClick={onClose}
            title={t("fbpInstruction.closeBtn")}
          />
        </Column>
      </Row>
    </Grid>
  );
};

export default FpbInstruction;
