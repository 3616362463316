import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "../../components/Grid/Grid.tsx";
import Row from "../../components/Grid/Row.tsx";
import Column from "../../components/Grid/Column.tsx";
import Box from "../../components/Box/Box.tsx";
import Title from "../../components/Title/Title.tsx";
import TextInputContainer from "../../containers/TextInputContainer.tsx";
import { Button, ButtonSize, ButtonType } from "../../components/Button";
import { Link } from "react-router-dom";
import { getPasswordStrength } from "../../utils/passwordStrength.ts";
import StrengthPasswordContainer from "../../containers/StrengthPasswordContainer.tsx";

export interface SetPasswordFormType {
  password: string;
  passwordConfirmation: string;
}

interface SetPasswordFormProps {
  onSubmit: (data: SetPasswordFormType) => void;
  pending: boolean;
}

const ResetPasswordForm = (props: SetPasswordFormProps) => {
  const { control, handleSubmit, watch } = useForm<SetPasswordFormType>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });
  const password = watch("password");
  const { t } = useTranslation();

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Title title={t("setPasswordForm.title")} />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>{t("setPasswordForm.description")}</Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={0}>
            <TextInputContainer
              control={control}
              name={"password"}
              title={t("setPasswordForm.password")}
              rules={{
                required: t("defaultFormErrors.required"),
                validate: (value) =>
                  getPasswordStrength(value) > 8 && value !== ""
                    ? true
                    : t("setPasswordForm.lowPasswordStrength"),
              }}
              type={"password"}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={6}>
            <StrengthPasswordContainer name={"password"} control={control} />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <TextInputContainer
              control={control}
              name={"passwordConfirmation"}
              title={t("setPasswordForm.passwordConfirmation")}
              rules={{
                required: t("setPasswordForm.passwordConfirmationErrorText"),
                validate: (value) =>
                  value === password ||
                  t("setPasswordForm.passwordsDoNotMatch"),
              }}
              type={"password"}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Button
              title={t("setPasswordForm.button")}
              type={ButtonType.BLOCK}
              size={ButtonSize.S}
              onClick={handleSubmit(props.onSubmit)}
              pending={props.pending}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={1} textAlign={"center"}>
            <Link to={"/login"}>{t("setPasswordForm.back")}</Link>
          </Box>
        </Column>
      </Row>
    </Grid>
  );
};

export default ResetPasswordForm;
