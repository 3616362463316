import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyToBuffer from "../../assets/icons/copyToBuffer.svg";
import { ReactNode, useState } from "react";
import cn from "classnames";
import "./CopyTextToClipboard.css";
import { useTranslation } from "react-i18next";

interface CopyTextToClipboardProps {
  text: string;
  children: ReactNode;
  visibleOnHover?: boolean;
}

const CopyTextToClipboard = (props: CopyTextToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false); // Скрывать надпись через 2 секунды
    }, 2000);
  };

  return (
    <CopyToClipboard text={props.text} onCopy={handleCopy}>
      <div className={"copy-text-to-clipboard"}>
        <span className={"copy-text-to-clipboard__child"}>
          {props.children}
        </span>
        <span>
          <img
            alt={""}
            className={cn("copy-text-to-clipboard__icon", props.visibleOnHover && "copy-text-to-clipboard__icon--onHover")}
            src={CopyToBuffer}
          />
        </span>
        {isCopied && (
          <span className={"copy-text-to-clipboard__copied"}>
            {t("copyTextToClipboard.copied")}
          </span>
        )}
      </div>
    </CopyToClipboard>
  );
};

export default CopyTextToClipboard;
