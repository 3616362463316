import moment from 'moment/moment';

export const getChineseDateNow = () => moment.utc().add(8, 'hours').format();

// export const getAreWorkingHoursInChina = () => moment(getChineseDateNow()).utc().hour() < 20;

export const getShipmentDates = (isNew: boolean) => {
  const chineseDate = getChineseDateNow();
  const dates: number[] = isNew ? [2, 3] : [0, 1, 2];

  return dates.map((d: number) => moment(chineseDate).utc().add(d, 'days'));
};
