import styles from './Tab.module.scss';
import Avatar from '@components/Avatar';
import Box from '@components/Box';
import CardParcelModalWindow from '../modals/CardParcelModalWindow/CardParcelModalWindow';
import cn from 'classnames';
import CopyTextToClipboard from '@components/CopyTextToClipboard';
import DataTable, { DataViewHandle } from '@containers/DataTable';
import TruckIcon from '@assets/icons/truck-grey-12.svg';
import UIcon from '@assets/icons/u-blue-10.svg';
import { FilterModel } from '@hooks/useFiltersSearchParams';
import { Filters, FilterTypes } from '@containers/Filters';
import { formatDate, formatSumWithCurrency } from '@utils/formatValues';
import { getCurrentLanguage } from '@utils/lang';
import { getProcessing, TProcessingParcel } from '@api/apiClient/returns';
import { IProcessingTabProps } from '../types';
import { Modal } from '@components/Modal';
import { Row, Column } from '@components/Grid';
import { TableColumn, TableColumnType, TableRowType } from '@components/Table/TableTypes.ts';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ProcessingTab: React.FC<IProcessingTabProps> = (props: IProcessingTabProps): React.ReactElement => {
  const {
    isFilterOpen
    // onUpdate
  } = props;

  const [columnsSort, setColumnsSort] = useState<string[]>([]);
  const [innerFilters, setInnerFilters] = useState<FilterModel[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);
  const [parcel, setParcel] = useState<TProcessingParcel | undefined>(undefined);

  const dataRef = useRef<DataViewHandle>(null);

  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // const refreshData = () => {
  //   if (dataRef.current?.refresh) {
  //     dataRef.current.refresh();
  //   }
  //   onUpdate();
  // };

  const onCloseParcel = () => {
    setIsOpenParcelWindow(false);
    setTimeout(() => setParcel(undefined), 500);
  };

  const sortByPrice = (position: number, value: string, filters: FilterModel[]): void => {
    const newColumnsSort: string[] = columnsSort.map((item: string, index: number) => {
      if (position === index) {
        return item === 'desc' ? 'asc' : 'desc';
      } else {
        return item.length ? 'desc' : '';
      }
    });
    setColumnsSort(newColumnsSort);
    const newInnerFilters: FilterModel[] = filters.filter((item: FilterModel) =>
        item.value[0] !== 'Price' && item.value[0] !== 'SellerName');
    const filtersByPrice: FilterModel = {
      fieldNames: ['field', 'direction'],
      name: 'Sort',
      value: ['Price', value.charAt(0).toUpperCase() + value.slice(1)]
    };
    newInnerFilters.push(filtersByPrice);
    setInnerFilters(newInnerFilters);
  };

  const sortByStores = (position: number, value: string, filters: FilterModel[]): void => {
    const newColumnsSort: string[] = columnsSort.map((item: string, index: number) => {
      if (position === index) {
        return item === 'desc' ? 'asc' : 'desc';
      } else {
        return item.length ? 'desc' : '';
      }
    });
    setColumnsSort(newColumnsSort);
    const newInnerFilters: FilterModel[] = filters.filter((item: FilterModel) =>
        item.value[0] !== 'Price' && item.value[0] !== 'SellerName');
    const filtersByPrice: FilterModel = {
      fieldNames: ['field', 'direction'],
      name: 'Sort',
      value: ['SellerName', value.charAt(0).toUpperCase() + value.slice(1)]
    };
    newInnerFilters.push(filtersByPrice);
    setInnerFilters(newInnerFilters);
  };

  const columns: TableColumn<TProcessingParcel>[] = useMemo(() => [
    {
      header: t('returnsPage.processingTab.trackNumber'),
      accessor: (row: TableRowType<TProcessingParcel>) => (
          <>
            <CopyTextToClipboard
                text={row.data?.returnPostingNumber || ''}
                visibleOnHover
            >
              <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenParcelWindow(true);
                    setParcel(row.data);
                  }}
              >
                <Text
                    color={TextColor.PRIMARY}
                    onHoverColor={TextColor.BLUE}
                    size={TextSize.S}
                    weight={TextWeight.BOLD}
                >
                  {row?.data?.returnPostingNumber}
                </Text>
              </span>
            </CopyTextToClipboard>
            <CopyTextToClipboard
                text={row.data?.trackingNumber || ''}
                visibleOnHover
            >
              <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenParcelWindow(true);
                    setParcel(row.data);
                  }}
              >
                <Text
                    color={TextColor.DARK_MUTED}
                    size={TextSize.XS}
                >
                  {row?.data?.trackingNumber}
                </Text>
              </span>
            </CopyTextToClipboard>
          </>
      ),
      alignment: 'left',
      width: '205px'
    },
    {
      header: t('returnsPage.processingTab.name'),
      accessor: (row: TableRowType<TProcessingParcel>) => (
          <a
              className={styles.link}
              href={row?.data?.url}
              rel="noreferrer"
              target="_blank"
          >
            {row?.data?.title}
          </a>
      ),
      alignment: 'left',
      width: '400px'
    },
    {
      header: t('returnsPage.processingTab.priceOfProduct'),
      accessor: (row: TableRowType<TProcessingParcel>) => (
          <div className={styles.price}>
            <Text
                color={TextColor.PRIMARY}
                size={TextSize.S}
            >
              {formatSumWithCurrency(row.data.priceRub, '₽')}
            </Text>
            <Text
                color={TextColor.DARK_MUTED}
                size={TextSize.XS}
            >
              {formatSumWithCurrency(row.data.priceCny, '¥')}
            </Text>
          </div>
      ),
      alignment: 'right',
      noOverflow: true,
      sortable: true,
      sortableFn: sortByPrice,
      width: '100px'
    },
    {
      header: t('returnsPage.processingTab.returnPrice'),
      accessor: (row: TableRowType<TProcessingParcel>) => (
          <div className={styles.returnPrice}>
            <img
                src={row.data.directStreamProvider === 'Unitrade' ? UIcon : TruckIcon}
                alt="Icon"
            />
            {formatSumWithCurrency(row.data.reexportPrice.amount, '¥')}
          </div>
      ),
      alignment: 'left',
      type: TableColumnType.PRIMARY,
      width: '100px'
    },
    {
      header: t('returnsPage.processingTab.store'),
      accessor: (row: TableRowType<TProcessingParcel>) => (
          <div className={styles.store}>
            <Tooltip
                action={
                  <Avatar
                      className={styles.avatar}
                      name={row.data.seller.name}
                      id={row.data.seller.id}
                  />
                }
                left={-227}
                text={row.data.seller.name}
                top={-9}
            />
          </div>
      ),
      alignment: 'center',
      noOverflow: true,
      sortable: true,
      sortableFn: sortByStores
    },
    {
      header: t('returnsPage.processingTab.status'),
      accessor: (row: TableRowType<TProcessingParcel>) => (
          <Text
              color={TextColor.PRIMARY}
              size={TextSize.S}
          >
            {t(`returnsPage.processingTab.statuses.${row.data.status}`)}
          </Text>
      ),
      alignment: 'left',
      width: '150px'
    },
    {
      header: t('returnsPage.processingTab.date'),
      accessor: (row: TableRowType<TProcessingParcel>) => (formatDate(row.data.createdAt, 'dd.MM.yy')),
      alignment: 'left',
      type: TableColumnType.TERTIARY,
      width: '100px'
    }
  ], [currentLanguage, columnsSort]);

  useEffect(() => {
    const columnsSort: string[] = columns.map((item: TableColumn<TProcessingParcel>) => item.sortable ? 'asc' : '');
    setColumnsSort(columnsSort);
  }, []);

  return (
      <div>
        <Box
            pb={5}
            className={cn(!isFilterOpen && styles.hidden)}
        >
          <Column phone={12}>
            <Row
                justify="right"
                gap={5}
            >
              <Filters
                  items={[
                    {
                      name: 'trackNumbers',
                      type: FilterTypes.MULTI_SEARCH,
                      placeholder: t('returnsPage.processingTab.searchByTrackNumber')
                    },
                    {
                      name: 'status',
                      type: FilterTypes.STATUS,
                      placeholder: t('returnsPage.processingTab.status')
                    },
                    {
                      name: 'sellerIds',
                      type: FilterTypes.SELLER
                    },
                    {
                      name: 'dateRange',
                      type: FilterTypes.DATE_RANGE,
                      placeholder: t('returnsPage.processingTab.date'),
                      fieldNames: ['startDate', 'endDate']
                    }
                  ]}
                  onChange={(v) => {
                    if (v.find(f => (f.name === 'sellerIds'))) {
                      setIsInitialized(true);
                    }
                    setInnerFilters(v);
                  }}
                  values={innerFilters}
                  asPanel
              />
            </Row>
          </Column>
        </Box>
        <DataTable<TProcessingParcel>
            columns={columns}
            columnsSort={columnsSort}
            dataRef={dataRef}
            defaultPageSize={10}
            filters={innerFilters}
            getData={getProcessing}
            notFound={t('returnsPage.processingTab.noParcels')}
            pending={!isInitialized}
            queryKey="processingParcels"
        />
        <Modal
            bodyWithoutIndentations
            isOpen={isOpenParcelWindow}
            onClickOverlay={onCloseParcel}
            onClose={onCloseParcel}
            width={900}
        >
          <CardParcelModalWindow
              callFrom="processingTab"
              handlerSetIsOpenParcelWindow={setIsOpenParcelWindow}
              parcel={parcel!}
          />
        </Modal>
      </div>
  );
};

export default ProcessingTab;