import styles from './TopUpBalanceCompletedModalWindow.module.scss';
import Box from '../../../../components/Box';
import CheckIcon from '@assets/icons/check_62.svg';
import { Button } from '../../../../components/Button';
import { Column, Row } from '../../../../components/Grid';
import { ITopUpBalanceCompletedModalWindowProps } from '../../types';
import { Text, TextColor, TextSize } from '../../../../components/Text';
import { TextWeight } from '@components/Text';
import { useTranslation } from 'react-i18next';

const TopUpBalanceCompletedModalWindow: React.FC<ITopUpBalanceCompletedModalWindowProps> = (props: ITopUpBalanceCompletedModalWindowProps): React.ReactElement => {
  const { handlerSetIsOpenTopUpBalanceCompletedWindow, replenishmentAmount } = props;

  const { t } = useTranslation();

  return (
      <div className={styles.grid}>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <img
                  src={CheckIcon}
                  alt="check"
              />
            </Box>
          </Column>
        </Row>
        <Row justify={'center'}>
          <Column phone={9}>
            <Box pb={8}>
              <Text
                  className={styles.info}
                  color={TextColor.PRIMARY}
                  size={TextSize.XXL}
                  weight={TextWeight.SEMIBOLD}
              >
                {t('TopUpBalanceCompletedModalWindow.info').replace('{{amount}}', replenishmentAmount.toString())}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row noWrap>
          <Box>
            <Button
                onClick={() => handlerSetIsOpenTopUpBalanceCompletedWindow(false)}
                title={t('TopUpBalanceCompletedModalWindow.buttonFinish')}
            />
          </Box>
        </Row>
      </div>
  );
};

export default TopUpBalanceCompletedModalWindow;