import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Avatar from '@components/Avatar';
import PickUpOrderActions from '../PickUpOrderActions/PickUpOrderActions';
import Table from '@components/Table/Table';
import TextInputContainer from '@containers/TextInputContainer';
import { Button, ButtonType } from '@components/Button';
import { changeOneTimePickUpParcels, getOneTimePickUp, getPickUpParcels, PickUpOrder, PickUpParcel } from '@api/apiClient/pickUp';
import { ErrorResponse } from '@api/Responses/ErrorResponse';
import { IPickUpEditingParcelsProps, TPickUpEditingParcelsForm } from '../../types';
import { TableColumn, TableRowType } from '@components/Table/TableTypes';
import FormErrorMessage from "@components/FormErrorMessage";
import styles from './PickUpEditingParcels.module.scss';

const PickUpEditingParcels: React.FC<IPickUpEditingParcelsProps> = (props: IPickUpEditingParcelsProps): React.ReactElement => {
  const {
    currentTypeModalWindow,
    handlerSetCurrentTypeModalWindow,
    handlerSetIsOpenPickUpCancelWindow,
    handlerSetIsOpenPickUpOrderWindow,
    pickUpOrder: {
      id
    }
  } = props;

  const [currentParcels, setCurrentParcels] = useState<PickUpParcel[]>([]);
  const [futureParcels, setFutureParcels] = useState<PickUpParcel[]>([]);

  const { t } = useTranslation();

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: changeOneTimePickUpParcels,
    onSuccess: () => {
      handlerSetCurrentTypeModalWindow('order');
    },
  });

  const parcelsInPickUp = useQuery<PickUpOrder, ErrorResponse>({
    queryKey: ['parcelsInPickUp', id],
    queryFn: () => getOneTimePickUp(id),
    retry: 1
  });

  const remainingParcels = useQuery({
    queryKey: ['remainingParcels', { page: 1, pageSize: 99999 }],
    queryFn: () => getPickUpParcels({
      page: { page: 1, pageSize: 99999 }
    }),
    retry: 1,
    select: ({ items }) => items
  });

  useEffect(() => {
    if (parcelsInPickUp.data) {
      setCurrentParcels(parcelsInPickUp.data.parcels);
      setFutureParcels(parcelsInPickUp.data.parcels);
    }
  }, [parcelsInPickUp.data]);

  const removeParcel = (id: string): void => {
    const filteredParcels: PickUpParcel[] = futureParcels.filter((item: PickUpParcel) => item.id !== id);
    setFutureParcels(filteredParcels);
  };

  const creationDate: string = t('pickUpCompleted.creationDate');
  const externalNumber: string = t('pickUpCompleted.externalNumber');
  const shop: string = t('pickUpCompleted.shop');
  const trackNumber: string = t('pickUpCompleted.trackNumber');

  const createTdTextClass = (id: string): string =>
      currentParcels.find((item: PickUpParcel) => item.id === id) ? styles.tableTdText : styles.tableTdTextAccented;

  const columns: TableColumn<PickUpParcel>[] = [
    {
      header: trackNumber,
      accessor: (row: TableRowType<PickUpParcel>) => (
          <span className={styles.tableTdTextAccented}>
            {row.data.trackNumber}
          </span>
      ),
      width: '20%',
      fixWidth: true,
      noOverflow: true
    },
    {
      header: externalNumber,
      accessor: (row: TableRowType<PickUpParcel>) => (
          <span className={createTdTextClass(row.data.id)}>
            {row.data.externalNumber}
          </span>
      ),
      width: '20%',
      fixWidth: true,
      noOverflow: true
    },
    {
      header: shop,
      accessor: (row: TableRowType<PickUpParcel>) => (
          <div className={styles.tableTd}>
            <Avatar
                className={styles.tableTdIcon}
                id={row.data.sellerId}
                name={row.data.sellerName}
            />
            <span className={createTdTextClass(row.data.id)}>
              {row.data.sellerName}
            </span>
          </div>
      ),
      width: '42%',
      fixWidth: true,
      noOverflow: true
    },
    {
      header: creationDate,
      accessor: (row: TableRowType<PickUpParcel>) => (
          <div className={styles.tableTd}>
            <span className={createTdTextClass(row.data.id)}>
              {moment(row.data.createdAt).format('DD.MM, HH:mm')}
            </span>
            <button
                className={styles.tableTdAction}
                onClick={() => removeParcel(row.data.id)}
            />
          </div>
      ),
      width: '18%',
      fixWidth: true,
      noOverflow: true
    }
  ];

  const {
    control,
    handleSubmit,
    reset,
    setError
  } = useForm<TPickUpEditingParcelsForm>({
    defaultValues: {
      track: ''
    },
    mode: 'onChange'
  });

  const submit = (form: { track: string }): void => {
    const croppedTrack: string = form.track.trim();
    const hasParcelAlreadyBeenAdded: PickUpParcel | undefined = futureParcels.find((item: PickUpParcel) =>
        item.trackNumber === form.track || item.externalNumber === form.track);
    const requiredParcel: PickUpParcel | undefined = remainingParcels.data!.find((item: PickUpParcel) =>
        item.trackNumber === form.track || item.externalNumber === form.track);
    const hasParcelAlreadyBeenAddedError: string = t('pickUpEditingParcels.parcelHasAlreadyBeenAdded');
    const requiredParcelError: string = t('pickUpEditingParcels.parcelNotFound');
    const trackParcelError: string = t('pickUpEditingParcels.youCanAddOnly1TrackNumber');

    if (croppedTrack.includes(' ') || croppedTrack.includes(',')) {
      setError('track', {
        type: 'manual',
        message: trackParcelError
      });
    } else {
      if (hasParcelAlreadyBeenAdded) {
        setError('track', {
          type: 'manual',
          message: hasParcelAlreadyBeenAddedError
        });
      } else {
        if (requiredParcel) {
          setFutureParcels([requiredParcel].concat(futureParcels));
        } else {
          setError('track', {
            type: 'manual',
            message: requiredParcelError
          });
        }
      }
    }
  };

  const handlerChangeOneTimePickUpParcels = (): void => {
    const data = {
      id,
      parcelIds: futureParcels.map((item: PickUpParcel) => item.id)
    };

    mutate(data);
  };

  const actionsProps = {
    currentTypeModalWindow,
    isSavingPickUpImpossible: futureParcels.length < 20,
    handlerSetCurrentTypeModalWindow,
    handlerSetIsOpenPickUpCancelWindow,
    handlerSetIsOpenPickUpOrderWindow,
    handleSubmit: handlerChangeOneTimePickUpParcels,
    isLoading,
    reset
  };

  return (
      <>
        <div className={styles.row}>
          <span className={styles.title}>
            {t('pickUpEditingParcels.parcels')}
          </span>
        </div>
        <div className={styles.row}>
          <div className={styles.track}>
            <TextInputContainer
                control={control}
                name="track"
                rules={{ required: t('defaultFormErrors.required') }}
                title={t('pickUpEditingParcels.trackFieldText')}
            />
          </div>
          <Button
              onClick={handleSubmit(submit)}
              title={t('pickUpEditingParcels.add')}
              type={ButtonType.LIGHT_ROUNDED}
          />
        </div>
        <div className={styles.row}>
          <Table<PickUpParcel>
              className={styles.table}
              columns={columns}
              data={futureParcels}
              rowClassName={styles.tableRow}
          />
        </div>
        <div className={styles.row}>
          <PickUpOrderActions {...actionsProps}/>
          {!isError ? (
            <FormErrorMessage
              error={error}
              errorPrefix="pickUpErrors"
            />
          ) : null}
        </div>
      </>
  );
};

export default PickUpEditingParcels;
