import { useState, useMemo, useRef } from 'react';
import DataTable from "@containers/DataTable";
import { ProblematicParcel, getProblematicParcels } from "@api/apiClient/problematicParcels";
import { useTranslation } from "react-i18next";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import { DataViewHandle } from "@containers/DataTable";
import Avatar from "@components/Avatar";
import { Row } from "@components/Grid";
import Box from "@components/Box";
import { Button, ButtonSize } from "@components/Button";
import { Text, TextColor, TextSize, TextWeight } from "@components/Text";
import Popup from "@components/Popup";
import { Modal } from "@components/Modal";
import ParcelModalWindow from "@containers/ParcelModalWindow";
import CopyTextToClipboard from "@components/CopyTextToClipboard";
import CancelModalWindow from "../modals/CancelModalWindow";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { getCurrentLanguage } from "@utils/lang";
import Reason from "../components/Reason.tsx";
import { formatSumFromObject } from "@utils/formatValues";
import { formatDate } from "@utils/formatValues";
import styles from "./returnsPageTabs.module.css";

const ReturnsTab = ({
  filters,
  pending,
  status,
  refreshData,
}: {
  filters: FilterModel[],
  pending: boolean,
  status: string,
  refreshData: () => void,
}) => {
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);
  const [isOpenCancelWindow, setIsOpenCancelWindow] = useState(false);
  const [cancelId, setIsCancelId] = useState("");
  const [detailId, setDetailId] = useState("");
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const dataRef = useRef<DataViewHandle>(null);

  const closeParcelWindow = () => {
    setIsOpenParcelWindow(false);
  }

  const columns: TableColumn<ProblematicParcel>[] = useMemo(() => [
    {
      header: t("problematicParcelsPage.trackNumber"),
      accessor: (row: TableRowType<ProblematicParcel>) => (
        <>
          <CopyTextToClipboard visibleOnHover text={row.data?.trackNumber}>
            <div onClick={(e) => {
              e.stopPropagation()
              setIsOpenParcelWindow(true)
              setDetailId(row.data?.id)
            }}>
              <Text
                size={TextSize.S}
                color={TextColor.PRIMARY}
                onHoverColor={TextColor.BLUE}
                weight={TextWeight.BOLD}
              >
                {row?.data?.trackNumber}
              </Text>
            </div>
          </CopyTextToClipboard>
          <CopyTextToClipboard visibleOnHover text={row.data?.externalId ?? ""}>
            <div onClick={(e) => {
              e.stopPropagation()
              setIsOpenParcelWindow(true)
              setDetailId(row.data?.id)
            }}>
              <Text
                size={TextSize.XS}
                color={TextColor.SECONDARY}
              >
                {row?.data?.externalId}
              </Text>
            </div>
          </CopyTextToClipboard>
        </>
      ),
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("problematicParcelsPage.reason"),
      accessor: (row: TableRowType<ProblematicParcel>) => <Reason value={row.data.reason} />,
      alignment: "left",
      width: "200px",
      fixWidth: true,
    },
    {
      header: t("problematicParcelsPage.service"),
      accessor: (row: TableRowType<ProblematicParcel>) => row.data.serviceName,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "200px",
      fixWidth: true,
    },
    {
      header: t("problematicParcelsPage.sum"),
      accessor: (row: TableRowType<ProblematicParcel>) => (
        formatSumFromObject(row.data.declaredValue)
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "90px",
      fixedWidth: true,
    },
    {
      header: t("problematicParcelsPage.seller"),
      accessor: (row: TableRowType<ProblematicParcel>) => (
        <Row noWrap align="center">
          <Avatar
            className={styles.avatar}
            name={row.data.sellerName}
            id={row.data.sellerId}
          />
          <span className={styles.sellerText}>
            {row.data.sellerName}
          </span>
        </Row>
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "200px",
      fixedWidth: true,
    },
    {
      header: t("problematicParcelsPage.decisionDate"),
      accessor: (row: TableRowType<ProblematicParcel>) => (
        formatDate(row.data.createAt, "dd.MM.yy")
      ),
      alignment: "left",
      fixWidth: true,
      width: "100px",
    },
    {
      header: "",
      accessor: (row: TableRowType<ProblematicParcel>) => (
        <Box beforeSpace pr={10}>
          {row.data.isCancelable ? (
            <Button
              onClick={() => {
                setIsCancelId(row.data.id);
                setIsOpenCancelWindow(true);
              }}
              size={ButtonSize.XS}
              title={t("disposalTable.resetButton")}
            />
          ) : (
            <Popup text={t("disposalTable.resetButtonNoty")}>
              <Button
                disabled
                onClick={() => {}}
                size={ButtonSize.XS}
                title={t("disposalTable.resetButton")}
              />
            </Popup>
          )}
        </Box>
      ),
      alignment: "left",
      noOverflow: true,
      width: "200px",
    },
  ], [currentLanguage]);

  return (
    <>
      <DataTable<ProblematicParcel>
        queryKey="getProblematicParcels"
        pending={pending}
        getData={getProblematicParcels}
        columns={columns}
        notFound={t("problematicParcelsPage.noParcels")}
        filters={[
          ...filters,
          { name: "Statuses", value: [status] },
        ]}
        dataRef={dataRef}
      />

      {isOpenParcelWindow ? (
        <Modal
          isOpen={isOpenParcelWindow}
          onClose={closeParcelWindow}
          onClickOverlay={closeParcelWindow}
          width={900}
          bodyWithoutIndentations
        >
          <ParcelModalWindow parcelId={detailId} />
        </Modal>
      ) : null}

      <Modal
        isOpen={isOpenCancelWindow}
        onClose={() => {
          setIsOpenCancelWindow(false);
        }}
        onClickOverlay={() => {
          setIsOpenCancelWindow(false);
        }}
      >
        <CancelModalWindow
          parcelId={cancelId}
          onSuccess={() => {
            if (typeof dataRef.current?.refresh === "function") {
              dataRef.current?.refresh();
            }
            setIsCancelId("");
            setIsOpenCancelWindow(false);
            refreshData();
          }}
          onClose={() => {
            setIsOpenCancelWindow(false);
            setIsCancelId("");
          }}
        />
      </Modal>
    </>
  );
};

export default ReturnsTab;
