import Box from '@components/Box';
import { Column, Grid, Row } from '@components/Grid';
import { Modal } from "@components/Modal";
import { useMutation } from 'react-query';
import FormErrorMessage from "@components/FormErrorMessage";
import { Button, ButtonType } from '@components/Button';
import { Text, TextType } from '@components/Text';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
  title: string,
  yesBtnTitle?: string,
  noBtnTitle?: string,
  onClose: () => void,
  onSuccess: () => void,
  requestFn: () => any,
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  yesBtnTitle,
  noBtnTitle,
  onClose,
  onSuccess,
  requestFn,
}: ConfirmModalProps): React.ReactElement => {
  const { t } = useTranslation();

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: () => requestFn(),
    onSuccess: () => {
      onSuccess();
      onClose();
    },
  });

  return (
    <Modal
        isOpen={true}
        onClickOverlay={() => onClose()}
        onClose={() => onClose()}
    >
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text type={TextType.H1}>
                {title}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row gap={5}>
          <Column phone={2}>
            <Box>
              <Button
                  onClick={() => onClose()}
                  title={noBtnTitle ?? t('confirmModal.no')}
              />
            </Box>
          </Column>
          <Column phone={2}>
            <Box>
              <Button
                  onClick={() => mutate()}
                  pending={isLoading}
                  title={yesBtnTitle ?? t('confirmModal.yes')}
                  type={ButtonType.LIGHT}
              />
            </Box>
          </Column>
        </Row>
        {isError ? (
          <FormErrorMessage error={error} />
        ) : null}
      </Grid>
    </Modal>
  );
};

export default ConfirmModal;
