import { useTranslation } from "react-i18next";
import { useMutation } from 'react-query';
import Box from "@components/Box";
import { Grid, Row, Column } from "@components/Grid";
import { Text, TextColor, TextSize, TextType } from "@components/Text";
import { Button, ButtonType } from "@components/Button";
import { cancelReturn } from "@api/apiClient/problematicParcels";
import FormErrorMessage from "@components/FormErrorMessage";

interface CancelModalWindowProps {
  onClose: () => void;
  parcelId: string;
  onSuccess: () => void;
}

const CancelModalWindow = (props: CancelModalWindowProps) => {
  const { t } = useTranslation();
  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: cancelReturn,
    onSuccess: () => {
      props.onSuccess()
    },
  });

  const submit = () => {
    mutate(props.parcelId);
  };

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={10} pt={4}>
            <Text type={TextType.H2} color={TextColor.PRIMARY}>
              {t("cancelModalWindow.description")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={10} pt={4}>
            <Text
              type={TextType.SPAN}
              size={TextSize.L}
              color={TextColor.PRIMARY}
            >
              {t("cancelModalWindow.text")}
            </Text>
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box beforeSpace={true}>
            <Button
              type={ButtonType.SKELETON}
              onClick={props.onClose}
              title={t("disposalModalWindow.no")}
            />
            <div style={{ marginRight: 10 }}></div>
            <Button
              pending={isLoading}
              type={ButtonType.TERTIARY}
              onClick={submit}
              title={t("disposalModalWindow.yes")}
            />
          </Box>
        </Column>
      </Row>

      {isError ? (
        <FormErrorMessage error={error} />
      ) : null}
    </Grid>
  );
};

export default CancelModalWindow;
