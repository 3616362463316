import React from 'react';
import { FilterModel } from '@hooks/useFiltersSearchParams';

export interface TableRowType<T> {
  index: number,
  data: T,
  isSelected?: boolean,
}

export interface TableColumn<T> {
  header: string | JSX.Element;
  accessor: (row: TableRowType<T>) => React.ReactNode;
  sortable?: boolean;
  sortableFn?: (position: number, value: string, filters: FilterModel[]) => void;
  alignment?: "left" | "center" | "right";
  width?: string;
  noOverflow?: boolean;
  fixWidth?: boolean;
  type?: TableColumnType;
}

export enum TableColumnType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}
