import {
  RegisterOptions,
  useController,
  Control,
  FieldValues,
  Path,
} from "react-hook-form";
import SelectDropdown from "../components/SelectDropdown";

interface SelectContainerProps<ItemType, TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  rules?: Omit<
    RegisterOptions<TFieldValues>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  title: string;
  disabled?: boolean;
  items: ItemType[];
  keyExtractor: (item: ItemType) => string;
  labelOptionItem?: (item: ItemType | null) => string;
  renderSelectedItem?: (props: { item: ItemType | null }) => React.ReactNode;
  labelSelectedItem?: (item: ItemType | null) => string;
  renderItem?: (props: { item: ItemType, search?: string }) => React.ReactNode;
  searchable?: boolean;
  isLoading?: boolean;
  onOpen?: () => void;
}

const SelectContainer = <ItemType extends object = { id: string, value: string }, TFieldValues extends FieldValues = FieldValues>(
  props: SelectContainerProps<ItemType, TFieldValues>,
) => {

  const { field, fieldState } = useController<TFieldValues>({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <SelectDropdown<ItemType>
      label={props.title}
      errorMessage={fieldState.error?.message}
      hasError={!!fieldState.error}
      disabled={props.disabled}
      defaultSelectedItem={field.value}
      labelSelectedItem={props.labelSelectedItem}
      renderSelectedItem={props.renderSelectedItem}
      renderItem={props.renderItem}
      items={props.items}
      keyExtractor={props.keyExtractor}
      onItemSelect={field.onChange}
      value={field.value}
      searchable={props.searchable}
      labelOptionItem={props.labelOptionItem}
      isLoading={props.isLoading}
      onOpen={props.onOpen}
    />
  );
};

export default SelectContainer;
