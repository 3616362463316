// import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useAuth } from "@contexts/AuthenticationContext";
import LanguageSwitcherContainer from "../../containers/LanguageSwitcherContainer/LanguageSwitcherContainer.tsx";
import { Logo } from "@components/Logo";
import exitIcon from "@assets/icons/arrow-right-to-bracket-svgrepo-com.svg";
import styles from "./AuthenticatedEmptyLayout.module.css";

const AuthenticatedEmptyLayout = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={cn(styles.headerItem, styles.headerItemLogo)}>
          <Logo />
        </div>
        <div className={styles.headSpacer}></div>
        <div style={{ marginRight: "20px" }}>
          <LanguageSwitcherContainer />
        </div>
        <div className={"user-widget__item"}>
          <div
            className={"user-widget-item"}
            onClick={auth.logout}
          >
            <div className="user-widget-item__text">
              {t("userWidget.logout")}
            </div>
            <div className={"user-widget-item__space"}></div>
            <div className={"user-widget-item__icon"}>
              <img src={exitIcon} alt={""} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedEmptyLayout;
