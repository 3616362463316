import { Row } from "@components/Grid";
import { Text, TextSize, TextType } from "@components/Text";
import Box from "@components/Box";
import NoItemsIcon from "@assets/icons/no-items.svg";
import styles from './NoItems.module.css';

const NoItems = ({ title, description }: {
  title: string,
  description: string,
}) => {
  return (
    <div className={styles.root}>
      <img alt="Icon" src={NoItemsIcon} width="110px" />

      <div>
        <Row>
          <Box>
            <Text type={TextType.H1}>
              {title}
            </Text>
          </Box>
        </Row>
        <Row>
          <Box pt={2}>
            <Text
              size={TextSize.M}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Box>
        </Row>
      </div>
    </div>
  );
};

export default NoItems;
