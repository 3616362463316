import { sellerApiClient } from "./sellerApiClient";
import {KeycloakTokenResponse} from "@api/Responses/KeycloakTokenResponse.ts";

export const impersonate = async (
  userName: string,
  password: string,
  sellerEmail: string,
): Promise<KeycloakTokenResponse> => {
  const response = await sellerApiClient.post<KeycloakTokenResponse>(
    "/v1/auth/impersonate",
    {
      userName: userName,
      password: password,
      clientId: "sellers-dashboard-webui",
      sellerEmail: sellerEmail
    }
  );

  return response.data;
};