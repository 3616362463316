import { sellerApiClient, getParams } from "./sellerApiClient";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";

export interface UniWarehouse {
  id: string,
  name: string,
  address: string,
  longitude: number,
  latitude: number,
  phone: string,
  contactName: string,
}

export interface OzonWarehouse {
  ozonWarehouseId: number,
  name: string,
}

export interface LinkedWarehouse {
  id: string,
  name: string,
  hasEntrustedAcceptance: boolean,
  isRfbs: boolean,
  ozonWarehouseId: number,
  status: string, // New
  stocks: number,
  uniWarehouse: UniWarehouse,
  discrepanciesCount: number,
}

export const getLinkedWarhauses = async (sellerId: string): Promise<Response<LinkedWarehouse>> => {
    const response = await sellerApiClient.get<Response<LinkedWarehouse>>(
      "/v1/warehouses/linked",
      { params: { sellerId } },
    );

    return response.data;
};

export const getOzonWarhauses = async (sellerId: string): Promise<Response<OzonWarehouse>> => {
    const response = await sellerApiClient.get<Response<OzonWarehouse>>(
      "/v1/warehouses/ozon/allowed",
      { params: { sellerId } },
    );

    return response.data;
};

export const getUniWarhauses = async (): Promise<Response<UniWarehouse>> => {
    const response = await sellerApiClient.get<Response<UniWarehouse>>(
      "/v1/warehouses/uni",
    );

    return response.data;
};

export const linkWarehouses = async ({
  sellerId, uniWarehouseId, ozonWarehouseId,
}: WarehouseLinkData): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    "/v1/warehouses/link",
    {
      sellerId,
      uniWarehouseId,
      ozonWarehouseId,
    },
  );

  return response.data;
};

interface WarehouseLinkData {
  sellerId: string;
  uniWarehouseId: string;
  ozonWarehouseId: number;
}

export const getWarehause = async ({ warehouseId }: {
  // page: PaginationModel;
  // filters?: FilterModel[];
  warehouseId: string;
}): Promise<ResponsePages<WarehouseStockItem>> => {
  const response = await sellerApiClient.get<Response<WarehouseStockItem>>(
    `/v1/warehouses/${warehouseId}/stocks`,
  );

  return {
    total: response.data.items.length,
    items: response.data.items,
  };
};

export interface WarehouseProduct extends FulfillmentProduct {
  count: number,
  // productId?: string,
}

export interface WarehouseStockItem {
  productId: string;
  offerId: string;
  primaryImage: string;
  ozonSku: number;
  ozonUrl: string;
  name: string;
  weight: {
    unit: string;
    value: number;
  };
  price: {
    amount: number;
    currency: string;
  };
  createdAt: string;
  modifiedAt: string;
  present: number;
  reserved: number;
}

export interface FulfillmentProduct {
  id: string,
  ozonOfferId: string,
  ozonProductId: number,
  ozonSku: number,
  ozonUrl: string,
  primaryImage: string,
  price: {
    amount: number,
    currency: string, // "USD"
  },
  name: string,
  dimensions: {
    length: {
      value: number,
      unit: string, // "mm"
    },
    width: {
      value: number,
      unit: string, // "mm"
    },
    height: {
      value: number,
      unit: string, // "mm"
    },
  },
  weight: {
    unit: string, // "g"
    value: number,
  },
}

export interface RefillRequest {
  id: string,
  createdAt: string,
  number: string,
  productsCount: number,
  status: string; // Created, WarehouseAwaiting, WarehouseValidation, WarehouseRejected, PartiallyApproved, FullyApproved, Cancelled
  totalWeight: {
    unit: string, // "g"
    value: number,
  },
}

export interface RefillRequestDetails {
  id: string;
  number: string;
  warehouseId: string;
  boxCount: number;
  products: WarehouseProduct[];
  status: string; // Created, WarehouseAwaiting, WarehouseValidation, WarehouseRejected, PartiallyApproved, FullyApproved, Cancelled
}

export interface RefillRequestProduct {
  productId: string;
  count: number;
}

export interface RefillRequestData {
  boxCount: number,
  products: RefillRequestProduct[],
}

export const getFulfillmentProducts = async (sellerId: string, search: string): Promise<FulfillmentProduct[]> => {
  const response = await sellerApiClient.get<Response<FulfillmentProduct>>(
    "/v1/products/search",
    { params: { sellerId, search } },
  );

  return response.data?.items;
};

export const getWarehouseRefillRequest = async (warehouseId: string, id: string): Promise<RefillRequestDetails> => {
  const response = await sellerApiClient.get<RefillRequestDetails>(
    `/v1/fulfillment/warehouses/${warehouseId}/restock-requests/${id}`,
  );

  return response.data;
};

export const createWarehouseRefillRequest = async ({ warehouseId, data }: {
  warehouseId: string,
  data: RefillRequestData,
}): Promise<{ id: string }> => {
  const response = await sellerApiClient.post<{ id: string }>(
    `/v1/fulfillment/warehouses/${warehouseId}/restock-requests`,
    data,
  );

  return response.data;
};

export const updateWarehouseRefillRequest = async ({ requestId, warehouseId, data }: {
  requestId: string,
  warehouseId: string,
  data: RefillRequestData,
}): Promise<{ id: string }> => {
  const response = await sellerApiClient.put<{ id: string }>(
    `/v1/fulfillment/warehouses/${warehouseId}/restock-requests/${requestId}`,
    data,
  );

  return response.data;
};

export const finishWarehouseRefillRequest = async ({ requestId, warehouseId }: {
  requestId: string,
  warehouseId: string,
}): Promise<{ id: string }> => {
  const response = await sellerApiClient.put<{ id: string }>(
    `/v1/fulfillment/warehouses/${warehouseId}/restock-requests/${requestId}/finish-creating`,
  );

  return response.data;
};

export const getWarehouseRefillRequests = async ({
  page,
  filters,
  warehouseId,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
  warehouseId: string,
}): Promise<ResponsePages<RefillRequest>> => {
  const response = await sellerApiClient.get<ResponsePages<RefillRequest>>(
    `/v1/fulfillment/warehouses/${warehouseId}/restock-requests/page`,
    {
      params: getParams(page, filters),
      paramsSerializer: { indexes: null },
    },
  );

  return response.data;
};

export interface Discrepancies {
  id: string;
  restockRequestId: number;
  restockRequestNumber: string;
  acceptedDate: string;
  discrepanciesCount: number;
  defectDiscrepanciesCount: number;
  quantityDiscrepanciesCount: number;
  unknownDiscrepanciesCount: number;
}

export interface DefectDiscrepancies {
  id: string;
  productId: string;
  name: string;
  ozonSku: number;
  ozonUrl: string;
  photo: string;
  description: string;
}

export interface QuantityDiscrepancies {
  productId: string;
  primaryImage: string;
  name: string;
  ozonSku: number;
  ozonUrl: string;
  expectedQuantity: number;
  actualQuantity: number;
}

export interface UnknownProductDiscrepancies {
  id: string;
  description: string;
  photo: string;
  quantity: number;
}

export interface DiscrepnciesDetails {
  id: string;
  restockRequestId: number;
  restockRequestNumber: string;
  acceptedDate: string;
  defectDiscrepancies: DefectDiscrepancies[];
  quantityDiscrepancies: QuantityDiscrepancies[];
  unknownProductDiscrepancies: UnknownProductDiscrepancies[];
  isViewed: boolean;
}

export const getDiscrepancies = async ({
  page,
  filters,
  warehouseId,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
  warehouseId: string,
}): Promise<ResponsePages<Discrepancies>> => {
  const response = await sellerApiClient.get<ResponsePages<Discrepancies>>(
    `/v1/warehouses/${warehouseId}/discrepancies/page`,
    {
      params: getParams(page, filters),
      paramsSerializer: { indexes: null },
    },
  );

  return response.data;
};

export const getDiscrepanciesById = async (id: string, warehouseId: string): Promise<DiscrepnciesDetails> => {
  const response = await sellerApiClient.get<DiscrepnciesDetails>(
    `/v1/warehouses/${warehouseId}/discrepancies/${id}`,
  );

  return response.data;
};

export const markDiscrepancyAsViewed = async (id: string, warehouseId: string): Promise<DiscrepnciesDetails> => {
  const response = await sellerApiClient.put<DiscrepnciesDetails>(
    `/v1/warehouses/${warehouseId}/discrepancies/${id}/mark-as-viewed`,
  );

  return response.data;
};

interface Response<T> {
  items: T[];
}

export interface ResponsePages<T> {
  total: number;
  items: T[];
}

type getPictureForPrintingRequest = {
  id: string;
  pageCount: number;
  warehouseId: string;
};

export const getPictureForPrinting = async (data: getPictureForPrintingRequest): Promise<any> => {
  const { id, pageCount, warehouseId } = data;
  const response = await sellerApiClient.get(
      `/v1/fulfillment/warehouses/${warehouseId}/restock-requests/${id}/label?pageCount=${pageCount}`,
      { responseType: 'blob' }
  );
  return response.data;
};
