import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import Box from '@components/Box';
import { Modal } from "@components/Modal";
import { Column, Grid, Row } from '@components/Grid';
import FormErrorMessage from "@components/FormErrorMessage";
import { Button, ButtonType } from '@components/Button';
import { Text, TextType } from '@components/Text';
import { Loader } from "@components/Loader";
import { authorizeWallet } from "@api/apiClient/finance";

interface QrModalProps {
  onClose: () => void,
  onSuccess: (id: string) => void,
}

const QrModal: React.FC<QrModalProps> = ({
  onClose,
  onSuccess,
}: QrModalProps): React.ReactElement => {
  const { t } = useTranslation();

  const {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    mutate,
  } = useMutation({
    mutationFn: authorizeWallet,
  });

  useEffect(() => {
    mutate();
  }, []);

  return (
    <Modal
      isOpen={true}
      onClickOverlay={() => onClose()}
      onClose={() => onClose()}
    >
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text type={TextType.H1}>
                {t('profileFinance.qrModalTitle')}
              </Text>
            </Box>
          </Column>
        </Row>
        {isLoading ? (
          <Row>
            <Column phone={12}>
              <Loader alignment="center" />
            </Column>
          </Row>
        ) : (
          <img
            src={`data:image/svg+xml;base64,${data?.qrCode}`}
            alt=""
            style={{ width: "140px", height: "140px" }}
          />
        )}
        <Row gap={5}>
          <Column phone={12}>
            <Row gap={3}>
              <Button
                onClick={() => onClose()}
                title={t('profileFinance.cancel')}
              />

              <Button
                onClick={() => onSuccess(data?.requestId ?? "")}
                disabled={!isSuccess}
                title={t('profileFinance.ok')}
                type={ButtonType.LIGHT}
              />
            </Row>
          </Column>
        </Row>
        {isError ? (
          <FormErrorMessage error={error} />
        ) : null}
      </Grid>
    </Modal>
  );
};

export default QrModal;
