import styles from './PickUpForm.module.scss';
import Box from '@components/Box';
import getFullAddress from '@utils/getFullAddress';
import moment, { Moment } from 'moment';
import cn from "classnames";
import SelectDropdown from '@components/SelectDropdown';
import Switcher from '@components/Switcher';
import TextAreaContainer from '@containers/TextAreaContainer';
import TextInputContainer from '@containers/TextInputContainer';
import { AddressTemplate, TLabelSelectedItem } from '@api/apiClient';
import { Button, ButtonType } from '@components/Button';
import { Grid, Row, Column } from '@components/Grid';
import { IPickUpFormProps, TFullListAddresses } from '../../types';
import { Text, TextType } from '@components/Text';
import SelectContainer from "@containers/SelectContainer";
import {
  countries,
  CountryType,
  CountrySelectValue,
  CountrySelectOption,
  countryKeyExtractor,
} from "@components/CountrySelect/CountrySelect";
import { TAddressApplication } from '../../types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PickUpForm: React.FC<IPickUpFormProps> = (props: IPickUpFormProps): React.ReactElement => {
  const {
    addressTemplates,
    clearErrors,
    control,
    defaultAddress,
    handleSubmit,
    isThisEditing,
    rememberAddress,
    selectedDate,
    setRememberAddress,
    setSelectedDate,
    setValue,
    shipmentDates,
    submit,
    watch,
    isLoading,
  } = props;

  const [currentAddress, setCurrentAddress] = useState<AddressTemplate>(defaultAddress);
  const [fullListAddresses, setFullListAddresses] = useState<TFullListAddresses>([]);
  const [isSwitchVisible, setIsSwitchVisible] = useState<boolean>(true);

  const { t } = useTranslation();

  useEffect(() => {
    const filteredAddressTemplates: AddressTemplate[] = addressTemplates.filter((item: AddressTemplate) =>
        item.address.rawLine !== defaultAddress.address.rawLine);
    const isAddressAlreadySaved: AddressTemplate | undefined = addressTemplates.find((item: AddressTemplate) =>
        item.address.rawLine === defaultAddress.address.rawLine);

    if (isAddressAlreadySaved) setIsSwitchVisible(false);
    setFullListAddresses([defaultAddress, ...filteredAddressTemplates]);
  }, [defaultAddress]);

  const labelSelectedItem: TLabelSelectedItem = {
    address: control._formValues.address,
    id: control._formValues.id
  };

  watch(['address.city', 'address.flat', 'address.street', 'address.zipCode']);

  const handlerGetFullAddress = (item: TLabelSelectedItem, from?: string): string => {
    let fullAddress: string = '';
    const country = t(
      `countries.${item.address.countryCode}`,
      { defaultValue: item.address.countryCode || "" },
    );
    const address = { ...item.address, country };
    if (item.id === '') {
      if (from === 'renderItem') {
        if (isThisEditing) {
          fullAddress = getFullAddress(address);
        } else {
          fullAddress = `+ ${t('pickUpForm.newAddress')}`;
        }
      } else {
        if (address.city === '' && address.flat === '' && address.street === '' && address.zipCode === '') {
          fullAddress = `+ ${t('pickUpForm.newAddress')}`;
        } else {
          fullAddress = getFullAddress(address);
        }
      }
    } else {
      fullAddress = getFullAddress(address);
    }
    return fullAddress;
  };

  const handlerSetCurrentAddress = (id: string): void => {
    const addressApplication: AddressTemplate = fullListAddresses.find((address: AddressTemplate) => address.id === id)!;
    setCurrentAddress(addressApplication);
    setValue('address', addressApplication.address);
    setValue('id', addressApplication.id);
    clearErrors();
    setRememberAddress(id === '');
  };

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <SelectDropdown
                  defaultSelectedItem={defaultAddress}
                  items={fullListAddresses}
                  keyExtractor={(item: TLabelSelectedItem) => item.id}
                  label={t('pickUpForm.addressTitle')}
                  labelSelectedItem={() => handlerGetFullAddress(labelSelectedItem)}
                  onItemSelect={( id: string ) => handlerSetCurrentAddress(id)}
                  renderItem={(props: { item: TLabelSelectedItem }) =>
                      <div>{handlerGetFullAddress(props.item, 'renderItem')}</div>}
              />
            </Box>
          </Column>
        </Row>
        {currentAddress.id === '' && (
            <>
              <Row>
                <Column phone={12}>
                  <Box mb={5}>
                    <Text type={TextType.H3}>
                      {t('pickUpForm.newAddress')}
                    </Text>
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={6}>
                  <Box mb={5} pr={4}>
                    <SelectContainer<CountryType, TAddressApplication>
                      control={control}
                      name={"address.countryCode"}
                      title={t("returnsForm.country")}
                      renderSelectedItem={CountrySelectValue}
                      items={countries}
                      keyExtractor={countryKeyExtractor}
                      renderItem={CountrySelectOption}
                      rules={{
                        required: t("defaultFormErrors.required"),
                      }}
                    />
                  </Box>
                </Column>
                <Column phone={6}>
                  <Box mb={5}>
                    <TextInputContainer
                        control={control}
                        name="address.city"
                        rules={{ required: t('defaultFormErrors.required') }}
                        title={t('pickUpForm.city')}
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={9}>
                  <Box
                      mb={5}
                      pr={4}
                  >
                    <TextInputContainer
                        control={control}
                        name="address.street"
                        rules={{ required: t('defaultFormErrors.required') }}
                        title={t('pickUpForm.streetHouse')}
                    />
                  </Box>
                </Column>
                <Column phone={3}>
                  <Box mb={5}>
                    <TextInputContainer
                        control={control}
                        name="address.zipCode"
                        rules={{ required: t('defaultFormErrors.required') }}
                        title={t('pickUpForm.zipCode')}
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={4}>
                  <Box
                      mb={5}
                      pr={4}
                  >
                    <TextInputContainer
                        control={control}
                        name="address.flat"
                        rules={{ required: t('defaultFormErrors.required') }}
                        title={t('pickUpForm.flatNumber')}
                    />
                  </Box>
                </Column>
              </Row>
              {isSwitchVisible && (
                  <Row>
                    <Column phone={4}>
                      <Box mb={8}>
                        <Switcher
                            isActive={rememberAddress}
                            onSwitch={() => setRememberAddress(!rememberAddress)}
                            text={t('pickUpForm.rememberAddress')}
                        />
                      </Box>
                    </Column>
                  </Row>
              )}
            </>
        )}
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text type={TextType.H3}>
                {t('pickUpForm.contactPerson')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <TextInputContainer
                  control={control}
                  name="name"
                  rules={{ required: t('defaultFormErrors.required') }}
                  title={t('pickUpForm.name')}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <TextInputContainer
                control={control}
                name="phone"
                rules={{ required: t('defaultFormErrors.required') }}
                title={t('pickUpForm.phone')}
                type="phone"
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text type={TextType.H3}>
                {t('pickUpForm.date')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          {shipmentDates.map((item: Moment, index: number) =>
              <Column
                  key={index}
                  phone={3}
              >
                <Box
                    mb={5}
                    pr={4}
                >
                  <button
                      className={cn(styles.dateItemBtn, (index === selectedDate) && styles.dateItemBtnSelected)}
                      onClick={() => setSelectedDate(index)}
                  >
                    {moment(item).format('DD.MM')}
                  </button>
                </Box>
              </Column>
          )}
        </Row>
        <Row>
          <Column phone={12}>
            <Box>
              <TextAreaContainer
                  control={control}
                  name="comment"
                  rows={4}
                  title={t('pickUpForm.comment')}
              />
            </Box>
          </Column>
        </Row>
        {!isThisEditing && (
          <Row>
            <Column phone={6}>
              <Box mt={7}>
                <Button
                  onClick={handleSubmit(submit)}
                  title={t('pickUpForm.orderPickUp')}
                  type={ButtonType.BLOCK}
                  pending={isLoading}
                />
              </Box>
            </Column>
          </Row>
        )}
      </Grid>
  );
};

export default PickUpForm;
