import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { Text, TextSize } from '@components/Text';

interface MenuItem {
  path: string;
  icon: string;
  text: string;
  count?: number;
  key: string;
}

interface NavbarProps {
  menu: MenuItem[];
}

const Navbar = (props: NavbarProps) => {
  return (
    <div className={"navbar"}>
      {props.menu.map((item) => (
        <NavLink key={item.key} to={item.path} className={"navbar__link"}>
          <div className={"navbar__icon"}>
            <img src={item.icon} alt={""} />
          </div>
          <div className={"navbar__text"}> {item.text}</div>

          {item.count != undefined && item.count > 0 && (
              <Text
                  className="navbar__count navbar__count--red"
                  size={item.count.toString().length > 5 ? TextSize.XXS : TextSize.S}
              >
                {item.count}
              </Text>
          )}
        </NavLink>
      ))}
    </div>
  );
};

export default Navbar;
