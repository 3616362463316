import { ReactNode, useRef } from "react";
import styles from "./TableSelectionPanel.module.css";
import { CSSTransition } from "react-transition-group";

interface PanelItem {
  label: ReactNode;
  value: ReactNode;
}

interface TableSelectionPanelProps {
  children: ReactNode;
  panelContent?: ReactNode;
  isShown?: boolean;
  items: PanelItem[];
  buttons: ReactNode[];
  footer?: ReactNode;
}

const TableSelectionPanel = (props: TableSelectionPanelProps) => {
  const nodeRef = useRef(null);
  return (
    <div className={styles.wrapper}>
      {props.children}
      <CSSTransition
        in={props.isShown}
        timeout={300}
        classNames={{
          enter: styles.contentEnter,
          enterActive: styles.contentEnterActive,
          exit: styles.contentExit,
          exitActive: styles.contentExitActive,
        }}
        mountOnEnter
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div ref={nodeRef} className={styles.content}>
          <div className={styles.panel}>
            <div className={styles.panelMain}>
              {props.panelContent ? (props.panelContent) : (
                <>
                  {(props.items || []).map((item: PanelItem, idx: number) => (
                    <div
                      key={typeof item.label === "string" ? item.label : idx} 
                      className={styles.item}
                    >
                      <div className={styles.itemTitle}>
                        {item.label}:
                      </div>
                      <div className={styles.itemValue}>
                        {item.value}
                      </div>
                    </div>
                  ))}

                  <div className={styles.actions}>
                    {...props.buttons}
                  </div>
                </>
              )}
            </div>
            {props.footer ? (
              <div className={styles.footer}>
                {props.footer}
              </div>
            ) : null}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default TableSelectionPanel;
