import { useState, useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";
// import cn from "classnames";
import { getDiscrepancies, Discrepancies } from "@api/apiClient/fulfillment";
import { formatDate } from "@utils/formatValues";
import { Text, TextColor, TextSize, TextWeight } from "@components/Text";
import DataList, { ListField, DataViewHandle } from "@containers/DataList";
import DiffModal from "../components/DiffModal";
import styles from "./DiffTab.module.css";

const DiffTab = ({ warehouseId, sellerId }: {
  sellerId: string,
  warehouseId: string,
}) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const dataRef = useRef<DataViewHandle>(null);
  const [requestId, setRequestId] = useState<string>("");

  const fields: ListField<Discrepancies>[] = useMemo(() => [
    {
      label: t("fulfillmentPage.tabDiff.number"),
      value: (data) => (
        <Text
          size={TextSize.L}
          color={TextColor.LIGHT_BLUE}
          weight={TextWeight.MEDIUM}
        >
          {data.restockRequestNumber}
        </Text>
      ),
    },
    {
      label: t("fulfillmentPage.tabDiff.count"),
      value: (data) => (data.discrepanciesCount),
    },
    {
      label: t("fulfillmentPage.tabDiff.date"),
      value: (data) => (formatDate(data.acceptedDate , "dd.MM.yy")),
    },
    {
      label: t("fulfillmentPage.tabDiff.diff"),
      value: (data) => {
        const discrepancies = [
          { name: "quantity", field: data.quantityDiscrepanciesCount },
          { name: "extra", field: data.unknownDiscrepanciesCount },
          { name: "defective", field: data.defectDiscrepanciesCount },
        ].filter(v => (v.field));

        return (
          <div className={styles.discrepancies}>
            {discrepancies.map(item => (
              <div className={styles.discrepancy}>
                {t(`fulfillmentPage.tabDiff.discrepancies.${item?.name}`)}
              </div>
            ))}
          </div>
        );
      },
      withBorder: false,
    },
  ], [currentLanguage]);

  const getDiscrepanciesRequest = useCallback(
    ({ page, filters }: {
      page: PaginationModel,
      filters?: FilterModel[],
    }) => getDiscrepancies({ page, filters, warehouseId }),
    [warehouseId],
  )

  return (
    <div>
      <DataList<Discrepancies>
        queryKey={`getDiscrepancies-${warehouseId}`}
        getData={getDiscrepanciesRequest}
        notFound={t("fulfillmentPage.tabOrders.noOrders")}
        filters={[{
          name: "SellerId",
          value: sellerId,
        }]}
        viewProps={{
          onClick: (data) => setRequestId(data.id),
          getItemKey: (data: Discrepancies) => data.id,
          fields,
        }}
        dataRef={dataRef}
      />

      {requestId ? (
        <DiffModal
          onClose={() => setRequestId("")}
          sellerId={sellerId}
          warehouseId={warehouseId}
          requestId={requestId}
        />
      ) : null}
    </div>
  );
};

export default DiffTab;
