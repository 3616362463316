import { useState } from "react";
import { Seller } from "../../../api/apiClient";
import { Modal } from "@components/Modal";
import AddShopModalStepEmail from "./AddShopModalStepEmail";
import AddShopModalStepCode from "./AddShopModalStepCode";
import AddShopModalStepSuccess from "./AddShopModalStepSuccess";

interface AddShopModalProps {
  onClose: () => void;
}

enum STEPS { EMAIL, CODE, SUCCESS }

const AddShopModal = ({ onClose }: AddShopModalProps) => {
  const [step, setStep] = useState<STEPS>(STEPS.EMAIL);
  const [shops, setShops] = useState<Seller[]>([]);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [ticketId, setTicketId] = useState<string>("");
  // query.refetch();

  if (step === STEPS.EMAIL) {
    return (
      <Modal
        isOpen={true}
        onClose={onClose}
        onClickOverlay={onClose}
      >
        <AddShopModalStepEmail
          onSuccess={(newEmail, newTicketId) => {
            setEmail(newEmail)
            setTicketId(newTicketId)
            setStep(STEPS.CODE)
          }}
          onError={(err: string, newEmail: string) => {
            setEmail(newEmail)
            setError(err)
            setStep(STEPS.SUCCESS)
          }}
        />
      </Modal>
    )
  }

  if (step === STEPS.CODE) {
    return (
      <Modal
        isOpen={true}
        onClose={onClose}
        onClickOverlay={onClose}
      >
        <AddShopModalStepCode
          email={email}
          setTicketId={setTicketId}
          ticketId={ticketId}
          onSuccess={(data) => {
            setStep(STEPS.SUCCESS)
            setShops(data)
          }}
        />
      </Modal>
    )
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      onClickOverlay={onClose}
    >
      <AddShopModalStepSuccess
        onClose={onClose}
        data={shops}
        email={email}
        errCode={error}
      />
    </Modal>
  )
}

export default AddShopModal;
