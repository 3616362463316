import styles from './ReturnProcessingModalWindow.module.scss';
import Box from '@components/Box';
import FormErrorMessage from '@components/FormErrorMessage';
import getFullAddress from '@utils/getFullAddress';
import ReturnForm from '../../components/ReturnForm';
import ReturnOrder from '../../components/ReturnOrder/ReturnOrder';
import { addAddresses } from '@api/apiClient';
import { AddressApplication, IReturnProcessingModalWindowProps } from '../../types';
import { Button, ButtonType } from '@components/Button';
import { Column, Grid, Row } from '@components/Grid';
import { FieldPath, useForm } from 'react-hook-form';
import { getCurrentLanguage } from '@utils/lang';
import {
  checkReexportOrderStatus,
  sendReturnOrder,
  TAwaitingParcel, TCheckReexportOrderStatusResponse,
  TReturnOrderResponse
} from '@api/apiClient/returns';
import { Text, TextType } from '@components/Text';
import { useMutation } from 'react-query';
import { useProfile } from '@contexts/ProfileContext';
import { useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@components/Loader';

const emptyAddress = {
  address: {
    area: '',
    city: '',
    cityDistrict: '',
    country: '',
    countryCode: '',
    entrance: '',
    fias: {
      areaId: '',
      cityDistrictId: '',
      cityId: '',
      flatId: '',
      houseId: '',
      regionId: '',
      settlementId: '',
      streetId: ''
    },
    flat: '',
    floor: '',
    house: '',
    intercom: '',
    latitude: 0,
    longitude: 0,
    rawLine: '',
    region: '',
    settlement: '',
    street: '',
    zipCode: ''
  },
  fullName: '',
  id: '',
  phone: '',
  rawLineEnglish: ''
};

const ReturnProcessingModalWindow: React.FC<IReturnProcessingModalWindowProps> = (props: IReturnProcessingModalWindowProps): React.ReactElement => {
  const {
    errorSendTopUpBalance,
    handlerOnCloseReturnProcessing,
    handlerRefreshData,
    handlerSetIsOpenPaymentReturnCompletedWindow,
    handlerSetIsOpenPaymentReturnWindow,
    handlerSetPaymentReturnInProcessed,
    handlerSetSubmitParams,
    isErrorSendTopUpBalance,
    isLoadingSendTopUpBalance,
    mutateSendTopUpBalance,
    parcel,
    queryAddresses,
    selectedDelivery,
    selectedParcels,
    selectedRowIds
  } = props;

  const [currentTypeModalWindow, setCurrentTypeModalWindow] = useState<string>('form');
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [reexportOrderInProcessed, setReexportOrderInProcessed] = useState<boolean>(false);
  const [rememberAddress, setRememberAddress] = useState<boolean>(false);

  const currentLanguage = getCurrentLanguage();
  const timeoutCheckStatus = useRef<number | null>(null);
  const { account, get } = useProfile();
  const { t } = useTranslation();

  const parcelsMap: Map<string, TAwaitingParcel> = new Map();
  parcel && parcelsMap.set(parcel!.id, parcel!);

  const delivery: number = parcel ? parcel.reexportPrice.amount : selectedDelivery;
  const parcels: Map<string, TAwaitingParcel> = parcel ? parcelsMap : selectedParcels;
  const returnIds: string[] = parcel ? [parcel.id] : selectedRowIds;

  const emptyAddressWithCountry = useMemo(() => ({
    ...emptyAddress,
    address: {
      ...emptyAddress.address,
      countryCode: account!.region
    }
  }), [currentLanguage]);

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm<AddressApplication>({
    defaultValues: {
      ...queryAddresses[0],
      comment: '',
      fullName: '',
      phone: '',
      rawLineEnglish: ''
    },
    mode: 'onChange'
  });

  const { mutate: mutateCheckReexportOrderStatus } = useMutation({
    mutationFn: checkReexportOrderStatus
  });

  const {
    error: errorSendReturnOrder,
    isError: isErrorSendReturnOrder,
    isLoading: isLoadingSendReturnOrder,
    mutate: mutateSendReturnOrder
  } = useMutation({
    mutationFn: sendReturnOrder,
    onSuccess: (result: TReturnOrderResponse) => {
      setReexportOrderInProcessed(true);
      waitingReexportOrderStatus(result.reexportOrderId);
    }
  });

  const waitingReexportOrderStatus = (reexportOrderId: string): void => {
    timeoutCheckStatus.current = window.setTimeout(() => {
      mutateCheckReexportOrderStatus(reexportOrderId, {
        onSuccess: (data: TCheckReexportOrderStatusResponse) => {
          if (data.status === 'Paid') {
            get();
            handlerOnCloseReturnProcessing();
            handlerRefreshData();
            handlerSetIsOpenPaymentReturnCompletedWindow(true);
            handlerSetIsOpenPaymentReturnWindow(false);
            handlerSetPaymentReturnInProcessed(false);
            setReexportOrderInProcessed(false);
          }
          if (data.status === 'Cancelled') {
            waitingReexportOrderStatus(reexportOrderId);
          }
        }
      });
    }, 3000);
  };

  const submit = (form: AddressApplication): void => {
    const address = {
      area: form.address.area,
      city: form.address.city,
      cityDistrict: form.address.cityDistrict,
      country: form.address.country,
      countryCode: form.address.countryCode,
      entrance: form.address.entrance,
      fias: form.address.fias,
      flat: form.address.flat,
      floor: form.address.floor,
      house: form.address.house,
      latitude: form.address.latitude,
      longitude: form.address.longitude,
      rawLine: getFullAddress({ ...form.address }),
      region: form.address.region,
      settlement: form.address.settlement,
      street: form.address.street,
      zipCode: form.address.zipCode
    };
    mutateSendReturnOrder({
      address: address,
      comment: form.comment,
      contactInfo: {
        name: form.fullName,
        phone: form.phone
      },
      customerReturnIds: returnIds,
      rawLineEnglish: form.rawLineEnglish
    });
    if (rememberAddress) addAddresses(address);
  };

  const formProps = {
    addressTemplates: [emptyAddressWithCountry, ...(queryAddresses || [])],
    clearErrors,
    control,
    defaultAddress: queryAddresses[0],
    openForm: openForm,
    rememberAddress,
    setRememberAddress,
    setValue,
    watch
  };

  const orderProps = {
    address: getFullAddress(getValues('address')),
    delivery: delivery,
    fullName: getValues('fullName'),
    handlerSetCurrentTypeModalWindow: setCurrentTypeModalWindow,
    parcels: parcels,
    phone: getValues('phone')
  };

  const handlerOnClickNext = (): void => {
    const formValues = [
      { key: 'address.city', value: getValues('address.city') },
      { key: 'address.entrance', value: getValues('address.entrance') },
      { key: 'address.flat', value: getValues('address.flat') },
      { key: 'address.floor', value: getValues('address.floor') },
      { key: 'address.street', value: getValues('address.street') },
      { key: 'address.zipCode', value: getValues('address.zipCode') },
      { key: 'fullName', value: getValues('fullName') },
      { key: 'phone', value: getValues('phone') },
      { key: 'rawLineEnglish', value: getValues('rawLineEnglish') }
    ];
    let areAllFieldsFilledIn: boolean = true;
    for (let i = 0; i < formValues.length; i++) {
      if (!formValues[i].value) areAllFieldsFilledIn = false;
    }
    if (areAllFieldsFilledIn) {
      setCurrentTypeModalWindow('order');
    } else {
      for (let i = 0; i < formValues.length; i++) {
        if (!formValues[i].value) {
          setError(formValues[i].key as FieldPath<AddressApplication>, {
            type: 'manual',
            message: t('defaultFormErrors.required')
          });
        }
      }
      setOpenForm(true);
      setRememberAddress(true);
    }
  };

  const handlerOnClickPay = (): void => {
    if (account!.balance >= delivery) {
      handleSubmit(submit)();
    } else {
      if (account!.region === 'TR') {
        handlerOnCloseReturnProcessing();
        handlerSetIsOpenPaymentReturnWindow(true);
        handlerSetSubmitParams({ params: { handleSubmit, submit } });
      } else {
        mutateSendTopUpBalance({
          amount: delivery - account!.balance
        });
      }
    }
  };

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text type={TextType.H1}>
                {currentTypeModalWindow === 'form' && t('returnForm.title')}
                {currentTypeModalWindow === 'order' && t('returnOrder.title')}
              </Text>
            </Box>
          </Column>
        </Row>
        {reexportOrderInProcessed ? (
            <Loader
                alignment="center"
                size="xl"
            />
        ) : (
            <>
              <Row>
                <Column phone={12}>
                  {currentTypeModalWindow === 'form' && (
                      <ReturnForm {...formProps}/>
                  )}
                  {currentTypeModalWindow === 'order' && (
                      <ReturnOrder {...orderProps}/>
                  )}
                </Column>
              </Row>
              <div className={styles.actions}>
                <div>
                  <Button
                      disabled={isLoadingSendReturnOrder || isLoadingSendTopUpBalance}
                      onClick={handlerOnCloseReturnProcessing}
                      title={t('returnProcessingModalWindow.cancel')}
                      type={ButtonType.SKELETON}
                  />
                </div>
                <div>
                  {currentTypeModalWindow === 'form' && (
                      <Button
                          onClick={handlerOnClickNext}
                          title={t('returnProcessingModalWindow.next')}
                          type={ButtonType.BLOCK}
                      />
                  )}
                  {currentTypeModalWindow === 'order' && (
                      <Button
                          onClick={handlerOnClickPay}
                          pending={isLoadingSendReturnOrder || isLoadingSendTopUpBalance}
                          title={t('returnProcessingModalWindow.payForReturn')}
                          type={ButtonType.BLOCK}
                      />
                  )}
                </div>
              </div>
            </>
        )}
        {isErrorSendReturnOrder && <FormErrorMessage error={errorSendReturnOrder}/>}
        {isErrorSendTopUpBalance && <FormErrorMessage error={errorSendTopUpBalance}/>}
      </Grid>
  );
};

export default ReturnProcessingModalWindow;
