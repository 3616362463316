import { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from 'react-query';
import i18n from "i18next";
import { Modal } from "@components/Modal";
import { Button, ButtonType } from "@components/Button";
import { Grid, Column, Row } from "@components/Grid";
import {
  createWarehouseRefillRequest,
  getFulfillmentProducts,
  FulfillmentProduct,
} from "@api/apiClient/fulfillment";
import { Text, TextColor, TextType } from "@components/Text";
import { TableColumn, TableColumnType } from "@components/Table/TableTypes";
import Table from "@components/Table/Table";
import Box from "@components/Box";
import SearchInput from "@components/SearchInput";
import CountInput from "@components/CountInput";
import { ButtonIcon } from "@components/ButtonIcon";
import FormErrorMessage from "@components/FormErrorMessage";
import SearchItem from "../SearchItem";
import DelIcon from "@assets/icons/del.svg?react";
import styles from "./RefillWarehouseModal.module.scss";

interface Product {
  id: string,
  count: number,
  data: FulfillmentProduct,
}

const RefillWarehouseModal = ({ onClose, sellerId, warehouseId }: {
  onClose: () => void,
  sellerId: string,
  warehouseId: string,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const currentLanguage = i18n.language;
  const [products, setProducts] = useState<Product[]>([]);

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: createWarehouseRefillRequest,
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries(
        { queryKey: [`getWarehouseRefillRequests-${warehouseId}`] }
      );
      navigate(`/fulfillment/${warehouseId}/request/${id}`);
    },
  });

  const submit = () => {
    mutate({
      warehouseId,
      data: {
        boxCount: 1,
        products: products.map((v: Product) => ({
          productId: v.id,
          count: v.count,
        })),
      },
    });
  };

  const changeCount = (product: Product, count: number) => {
    setProducts((products: Product[]) => {
      const idx = products.findIndex((item: Product) => (item.id === product.id))
      if (idx < 0) return products;
      products[idx].count = count;
      return [...products];
    })
  }

  const columns: TableColumn<Product>[] = useMemo(() => [
    {
      header: t("fulfillmentPage.refillModal.article"),
      accessor: (row) => row.data.data.ozonSku,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "60px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: "",
      accessor: (row) => (
        <img className={styles.producImage} src={row.data.data.primaryImage} alt="" />
      ),
      alignment: "right",
      width: "50px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.refillModal.name"),
      accessor: (row) => (
        <a
          href={String(row.data.data.ozonUrl)}
          target="_blank"
          rel="noreferrer"
          className={styles.productLink}
        >
          {row.data.data.name}
        </a>
      ),
      alignment: "left",
      width: "450px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.tabAvailable.count"),
      accessor: (row) => (
        <CountInput
          value={row.data.count}
          onChange={(v: number) => changeCount(row.data, v)}
        />
      ),
      alignment: "center",
      fixWidth: true,
      width: "120px",
    },
    {
      header: "",
      accessor: (row) => (
        <div>
          <ButtonIcon
            icon={DelIcon}
            onClick={() => setProducts(products.filter(p => row.data.id !== p.id))}
          />
        </div>
      ),
      alignment: "left",
      fixWidth: true,
      width: "20px",
    },
  ], [currentLanguage, products]);

  const getProducts = useCallback((search: string) => getFulfillmentProducts(sellerId, search), [sellerId])

  const addProduct = (data: FulfillmentProduct) => {
    setProducts((products: Product[]) => {
      if (products.find((item: Product) => (item.id === data.id))) {
        return products;
      }
      return [...products, { id: data.id, count: 1, data }];
    });
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      onClickOverlay={onClose}
      width={900}
    >
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <Text type={TextType.H2} color={TextColor.PRIMARY}>
                {t("fulfillmentPage.refillModal.title")}
              </Text>
            </Box>
          </Column>
        </Row>

        <Row>
          <Box pb={8}>
            <Column phone={12}>
              <SearchInput<FulfillmentProduct>
                getItems={getProducts}
                requestKey={`getFulfillmentProducts-${sellerId}`}
                keyExtractor={(item: FulfillmentProduct) => item.id}
                renderItem={SearchItem}
                onItemSelect={addProduct}
              />
            </Column>
          </Box>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <Table<Product>
                columns={columns}
                data={products}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Button
              pending={isLoading}
              type={ButtonType.PRIMARY}
              onClick={submit}
              title={t("fulfillmentPage.refillModal.createOrder")}
            />
          </Column>
        </Row>

        {isError && (
          <FormErrorMessage error={error} />
        )}
      </Grid>
    </Modal>
  );
}

export default RefillWarehouseModal;
