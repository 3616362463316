import styles from './ReturnCostModalWindow.module.scss';
import Box from '@components/Box';
import Title from '@components/Title';
import { Column, Grid, Row } from '@components/Grid';
import { ErrorResponse } from '@api/Responses/ErrorResponse';
import {
  getReturnCost,
  TCountryTariff,
  TReturnCostResponse
} from '@api/apiClient/returns';
import { Loader } from '@components/Loader';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { useEffect, useState } from 'react';
import { useProfile } from '@contexts/ProfileContext';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const ReturnCostModalWindow: React.FC = (): React.ReactElement => {
  const [countryTariffs, setCountryTariffs] = useState<TCountryTariff[] | []>([]);

  const { account } = useProfile();
  const { t } = useTranslation();

  const query = useQuery<TReturnCostResponse, ErrorResponse>({
    queryKey: ['returnCost'],
    queryFn: () => getReturnCost(),
    retry: 1
  });

  useEffect(() => {
    if (query.data) {
      const countryTariffs: TCountryTariff[] = query.data.countryTariffs.filter((item: TCountryTariff) => item.country === account?.region);
      setCountryTariffs(countryTariffs);
    }
  }, [query.data]);

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <Title title={t('returnCostModalWindow.title')}/>
            </Box>
          </Column>
        </Row>
        {!countryTariffs.length ? (
            <Loader size="s"/>
        ) : (
            <>
              <Row>
                <Box pb={8}>
                  <div className={styles.point}>
                    <Text
                        color={TextColor.PRIMARY}
                        size={TextSize.S}
                        weight={TextWeight.BOLD}
                    >
                      {t('returnCostModalWindow.point1')}
                    </Text>
                    <div className={styles.list}>
                      <div className={styles.item}>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                        >
                          {t('returnCostModalWindow.item1')}:
                        </Text>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                            weight={TextWeight.SEMIBOLD}
                        >
                          {countryTariffs[0].itemCost.amount} {countryTariffs[0].itemCost.currency}
                        </Text>
                      </div>
                      <div className={styles.item}>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                        >
                          {t('returnCostModalWindow.item2')}:
                        </Text>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                            weight={TextWeight.SEMIBOLD}
                        >
                          {countryTariffs[0].kilogramCost.amount} {countryTariffs[0].kilogramCost.currency}
                        </Text>
                      </div>
                    </div>
                  </div>
                </Box>
              </Row>
              <Row>
                <Box>
                  <div className={styles.point}>
                    <Text
                        color={TextColor.PRIMARY}
                        size={TextSize.S}
                        weight={TextWeight.BOLD}
                    >
                      {t('returnCostModalWindow.point2')}
                    </Text>
                    <div className={styles.list}>
                      <div className={styles.item}>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                        >
                          {t('returnCostModalWindow.item1')}:
                        </Text>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                            weight={TextWeight.SEMIBOLD}
                        >
                          {countryTariffs[1].itemCost.amount} {countryTariffs[1].itemCost.currency}
                        </Text>
                      </div>
                      <div className={styles.item}>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                        >
                          {t('returnCostModalWindow.item2')}:
                        </Text>
                        <Text
                            color={TextColor.PRIMARY}
                            size={TextSize.S}
                            weight={TextWeight.SEMIBOLD}
                        >
                          {countryTariffs[1].kilogramCost.amount} {countryTariffs[1].kilogramCost.currency}
                        </Text>
                      </div>
                    </div>
                  </div>
                </Box>
              </Row>
            </>
        )}
      </Grid>
  );
};

export default ReturnCostModalWindow;