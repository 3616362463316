import styles from './AuthForm.module.scss';
import Box from '@components/Box';
import OzonAuthorizeApiForm from '@components/OzonAuthorizeApiForm';
import { addOzonApiData, Seller } from '@api/apiClient';
import { Text, TextType } from '@components/Text';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const AuthForm = ({ seller, onAuth }: {
  seller: Seller,
  onAuth: () => void,
  onAddWarehouse?: () => void,
}) => {
  const { t } = useTranslation();

  const {
    error,
    isLoading,
    mutate
  } = useMutation({
    mutationFn: addOzonApiData,
    onSuccess: () => {
      onAuth();
    }
  });

  const onSubmit = useCallback((
      sellerId: string,
      apiKey: string,
      apiClientId: string
  ) => mutate({ sellerId, apiKey, apiClientId }), []);

  return (
      <>
        <div className={styles.root}>
          <div className={styles.main}>
            <Box pb={3}>
              <Text type={TextType.H1}>
                {t(`dropshipping.authorizeApiModal.title`)}
              </Text>
            </Box>
            <OzonAuthorizeApiForm
                key={seller.id}
                seller={seller}
                isLoading={isLoading}
                onSubmit={onSubmit}
                error={error}
            />
          </div>
        </div>
      </>
  );
};

export default AuthForm;
