import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
// import cn from "classnames";
import { getLinkedWarhauses, LinkedWarehouse } from "@api/apiClient/fulfillment";
// import DataGrid from "@containers/DataGrid";
import WarehouseIcon from "@assets/icons/fulfillment-warehouses.svg?react";
import { Seller } from "@api/apiClient";
import { Grid, Row, Column } from "@components/Grid";
import { Loader } from "@components/Loader";
import { Text, TextType, TextSize, TextColor, TextWeight } from "@components/Text";
import styles from "./Stores.module.scss";

// interface StoreViewProps {
//   onClick: (d: LinkedWarehouse) => void;
// }

const StoreView = ({
  data,
  // onClick
}: {
  data: LinkedWarehouse,
  // onClick: (data: LinkedWarehouse) => void
}): React.ReactNode => {
  const { t } = useTranslation();
  return (
    <Link to={`/fulfillment/${data.id}`}>
      <div className={styles.storeView}>
        <div className={styles.storeIcons}>
          <WarehouseIcon />
        </div>

        <div className={styles.storeNames} >
          <Text
            size={TextSize.XXL}
            color={TextColor.PRIMARY}
            weight={TextWeight.MEDIUM}
          >
            {data.uniWarehouse?.name ?? ""}
          </Text>
          <Text
            size={TextSize.S}
            color={TextColor.DARK_MUTED}
            weight={TextWeight.NORMAL}
          >
            {data.uniWarehouse?.address ?? ""}
          </Text>
        </div>

        {(data.discrepanciesCount) ? (
          <div className={styles.discrepanciesColumn} >
            <Text
              size={TextSize.XS}
              color={TextColor.DARK_MUTED}
              weight={TextWeight.NORMAL}
            >
              {t("fulfillmentPage.discrepancies")}
            </Text>
            <Text
              size={TextSize.XXL}
              color={TextColor.WHITE}
              weight={TextWeight.MEDIUM}
              className={styles.discrepancies}
            >
              {data.discrepanciesCount}
            </Text>
          </div>
        ) : null}

        <div className={styles.storeAmount} >
          <Text
            size={TextSize.XS}
            color={TextColor.DARK_MUTED}
            weight={TextWeight.NORMAL}
          >
            {t("fulfillmentPage.itemsCount")}
          </Text>
          <Text
            size={TextSize.XXL}
            color={TextColor.PRIMARY}
            weight={TextWeight.MEDIUM}
          >
            {data.stocks}
          </Text>
        </div>
      </div>
    </Link>
  );
}

const Stores = ({ seller }: { seller?: Seller }) => {
  const { t } = useTranslation();

  const { data, isFetching } = useQuery<{ items: LinkedWarehouse[] }>({
    queryKey: ["linkedWarehouses", seller?.id ?? ""],
    queryFn: () => getLinkedWarhauses(seller?.id ?? ""),
    retry: 1,
    enabled: Boolean(seller?.id),
    // select: ({ items }) => items,
  });

  if (isFetching) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Loader alignment="center" size="s" />
          </Column>
        </Row>
      </Grid>
    )
  }

  if (!data?.items?.length) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Text color={TextColor.DISABLED} type={TextType.H2}>
              {t("fulfillmentPage.notFound")}
            </Text>
          </Column>
        </Row>
      </Grid>
    );
  }

  return (
    <div className={styles.root}>
      {data.items.map((item: LinkedWarehouse) => (
        <StoreView key={item.id} data={item} />
      ))}
    </div>
  );

  // return (
  //   <DataGrid<LinkedWarehouse, StoreViewProps>
  //     queryKey="fulfillmentStores"
  //     // pending={!isInitialized}
  //     getData={getLinkedWarhauses}
  //     ItemComponent={StoreView}
  //     notFound={t("fulfillmentPage.noStores")}
  //     // filters={innerFilters}
  //     // dataRef={dataRef}
  //     viewProps={{ onClick: () => {} }}
  //   />
  // );
};

export default Stores;
