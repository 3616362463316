import {
  RegisterOptions,
  useController,
  Control,
  FieldValues,
  Path,
} from "react-hook-form";
import DigitInput from "../components/DigitInput";

interface DigitInputContainerProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  rules?: Omit<
    RegisterOptions<TFieldValues>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  length: number;
  disabled?: boolean;
}

const DigitInputContainer = <TFieldValues extends FieldValues = FieldValues>(
  props: DigitInputContainerProps<TFieldValues>,
) => {
  console.log(props.name + " input render ");

  const { field, fieldState } = useController<TFieldValues>({
    control: props.control,
    name: props.name,
    rules: props.rules,
  });

  return (
    <DigitInput
      value={field.value}
      onChange={field.onChange}
      name={field.name}
      errorMessage={fieldState.error?.message}
      hasError={!!fieldState.error}
      length={props.length}
      disabled={props.disabled}
    />
  );
};

export default DigitInputContainer;
