import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import { linkSendCode, getErrorCode } from "@api/apiClient";
import { Button, ButtonType, ButtonSize } from "@components/Button";
import Title from "@components/Title/Title.tsx";
import { Text, TextSize, TextColor } from "@components/Text";
import { Grid, Column, Row } from "@components/Grid";
import Box from "@components/Box";
import TextInputContainer from "@containers/TextInputContainer";

export interface SendEmailFormProps {
  onError: (err: string, email: string) => void;
  onSuccess: (email: string, ticketId: string) => void;
}

export interface SendEmailForm {
  email: string;
}

const AddShopModalStepEmail = ({ onSuccess, onError }: SendEmailFormProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<SendEmailForm>({
    defaultValues: {
      email: "",
    },
  });

  const {
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: linkSendCode,
  });

  const onEmailSubmit = (form: SendEmailForm) => {
    mutate({ email: form.email }, {
      onSuccess: ({ ticketId }) => {
        onSuccess(form.email, ticketId);
      },
      onError: (error) => {
        onError(getErrorCode(error, "unknown-error"), form.email);
      },
    });
  };

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={5} pt={1}>
            <Title title={t("profileShops.addShopTitle")} />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={10}>
            <Text color={TextColor.PRIMARY} size={TextSize.M}>
              {t("profileShops.addShopDescription")}
            </Text>
          </Box>
        </Column>
      </Row>

      <form onSubmit={handleSubmit(onEmailSubmit)}>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <TextInputContainer
                control={control}
                name="email"
                title={t("profileShops.addShopEmail")}
                rules={{ required: t("profileShops.emailErrorText") }}
                type="email"
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box>
              <Button
                title={t("profileShops.addShopSendEmail")}
                type={ButtonType.PRIMARY}
                size={ButtonSize.S}
                onClick={handleSubmit(onEmailSubmit)}
                pending={isLoading}
              />
            </Box>
          </Column>
        </Row>
      </form>
    </Grid>
  )
}

export default AddShopModalStepEmail;
