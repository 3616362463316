import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from 'react-query';
import cn from "classnames";
import { addOzonApiData, Seller } from "@api/apiClient";
import Box from "@components/Box";
import { Row, Column } from "@components/Grid";
import OzonAuthorizeApiForm from "@components/OzonAuthorizeApiForm";
import { Button } from "@components/Button";
import { Text, TextColor, TextSize, TextType, TextWeight } from "@components/Text";
import styles from "./AuthForm.module.scss";

const AuthForm = ({ seller, onAuth, onAddWarehouse }: {
  seller: Seller,
  onAuth: () => void,
  onAddWarehouse: () => void,
}) => {
  const { t } = useTranslation();

  const {
    error,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: addOzonApiData,
    onSuccess: () => {
      onAuth()
    },
  });

  const onSubmit = useCallback((
    sellerId: string,
    apiKey: string,
    apiClientId: string
  ) => mutate({ sellerId, apiKey, apiClientId }), []);

  if (seller?.ozonApiExists) {
    return (
      <>
        <Row>
          <Column phone={6}>
            <Text
              type={TextType.SPAN}
              size={TextSize.M}
              color={TextColor.PRIMARY}
              weight={TextWeight.NORMAL}
            >
              {t("fulfillmentPage.OzonAuth.fulfillmentStoresDescription")}
            </Text>
          </Column>
        </Row>
        <div className={styles.root}>
          <div className={cn(styles.main, styles.mainCenter)}>
            <Row justify="center">
              <Box pb={3}>
                <Text type={TextType.H1}>
                  {t(`fulfillmentPage.OzonAuth.successTitle`)}
                </Text>
              </Box>
            </Row>
            <Row justify="center">
              <Box pb={10}>
                <Text weight={TextWeight.MEDIUM}>
                  {t(`fulfillmentPage.OzonAuth.successDescription`)}
                </Text>
              </Box>
            </Row>
            <Row justify="center">
              <Button
                onClick={onAddWarehouse}
                title={`+ ${t("fulfillmentPage.addStoreBtn")}`}
              />
            </Row>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Row>
        <Column phone={6}>
          <Text
            type={TextType.SPAN}
            size={TextSize.M}
            color={TextColor.PRIMARY}
            weight={TextWeight.NORMAL}
          >
            {t("fulfillmentPage.OzonAuth.fulfillmentStoresDescription")}
          </Text>
        </Column>
      </Row>
      <div className={styles.root}>
        <div className={styles.main}>
          <Box pb={3}>
            <Text type={TextType.H1}>
              {t(`fulfillmentPage.OzonAuth.title`)}
            </Text>
          </Box>
          <OzonAuthorizeApiForm
            seller={seller}
            submitText={t("fulfillmentPage.OzonAuth.ok")}
            isLoading={isLoading}
            onSubmit={onSubmit}
            error={error}
          />
        </div>
      </div>
    </>
  );
};

export default AuthForm;
