import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Loader } from "@components/Loader";
import { Text, TextSize, TextType, TextColor, TextWeight } from "@components/Text";
import { Grid, Row, Column } from "@components/Grid";
import Box from "@components/Box";
import PromoImg from "@assets/img/promo.png";
import PromoQr from "@assets/img/promo-qr.svg";
import styles from "./Promo.module.scss";

const Promo = ({ isActive, isLoading, notFound, maxAccounts }: {
  isActive: boolean,
  isLoading: boolean,
  notFound: boolean,
  maxAccounts: number,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Grid>
          <Row>
            <Column phone={12}>
              <Loader alignment="center" size="s" />
            </Column>
          </Row>
        </Grid>
      </div>
    );
  }

  if (notFound) {
    return (
      <div className={cn(styles.root, styles.rootError)}>
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <Text
                  type={TextType.P}
                  size={TextSize.XXL}
                  color={TextColor.WHITE}
                  weight={TextWeight.BOLD}
                >
                  {t("signUp.promoNotFoundError.title")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <Text size={TextSize.S} color={TextColor.WHITE}>
                  {t("signUp.promoNotFoundError.text-1")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={1}>
                <Text size={TextSize.S} color={TextColor.WHITE}>
                  {t("signUp.promoNotFoundError.text-1-1")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={1}>
                <Text size={TextSize.S} color={TextColor.WHITE}>
                  {t("signUp.promoNotFoundError.text-1-2")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={1}>
                <Text size={TextSize.S} color={TextColor.WHITE}>
                  {t("signUp.promoNotFoundError.text-1-3")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={1}>
                <Text size={TextSize.S} color={TextColor.WHITE}>
                  {t("signUp.promoNotFoundError.text-1-4")}
                </Text>
              </Box>
            </Column>
          </Row>
        </Grid>
        <img src={PromoQr} alt="promo" className={styles.qr} />
      </div>
    );
  }

  if (isActive) {
    return (
      <div className={cn(styles.root, styles.rootActive)}>
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <Text
                  type={TextType.P}
                  size={TextSize.XXL}
                  color={TextColor.WHITE}
                  weight={TextWeight.BOLD}
                >
                  {t("signUp.promo.title")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <Text size={TextSize.S} color={TextColor.WHITE}>
                  {t("signUp.promo.text-1")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <div className={styles.panel}>
                  <Text size={TextSize.M} color={TextColor.WHITE}>
                    {t("signUp.promo.text-2")}
                  </Text>
                </div>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <Text size={TextSize.M} color={TextColor.WHITE}>
                  {t("signUp.promo.text-3")}
                </Text>
              </Box>
            </Column>
          </Row>
          {maxAccounts ? (
            <Row>
              <Column phone={12}>
                <Box pb={2}>
                  <Text size={TextSize.M} color={TextColor.WHITE}>
                    {t("signUp.promo.text-4", { maxAccounts })}
                  </Text>
                </Box>
              </Column>
            </Row>
          ) : null}
          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <Text size={TextSize.M} color={TextColor.WHITE}>
                  {t("signUp.promo.text-5")}
                </Text>
              </Box>
            </Column>
          </Row>
        </Grid>
        <img src={PromoImg} alt="promo" className={styles.img} />
      </div>
    );
  }

  return (
    <div className={cn(styles.root, styles.rootInActive)}>
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={2}>
              <Text
                type={TextType.P}
                size={TextSize.XXL}
                color={TextColor.WHITE}
                weight={TextWeight.BOLD}
              >
                {t("signUp.promoInactive.title")}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={2}>
              <Text size={TextSize.S} color={TextColor.WHITE}>
                {t("signUp.promoInactive.text")}
              </Text>
            </Box>
          </Column>
        </Row>
      </Grid>
      <div className={styles.imgContainer}>
        <img src={PromoImg} alt="promo" className={styles.img} />
      </div>
    </div>
  );
}

export default Promo;
