import { useCallback, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from 'react-router-dom';
import { Grid, Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Loader } from "@components/Loader";
import { getSellers, Seller } from "@api/apiClient";
import SellerFilter from '@containers/SellerFilterSingle';
import FulfillmentStore from "./FulfillmentStore";
import FulfillmentRequest from "./FulfillmentRequest";
import FulfillmentStores from "./FulfillmentStores";

const FulfillmentPage = () => {
  const [seller, setSeller] = useState<Seller | undefined>(undefined);
  const { warehouseId, requestId } = useParams();
  const [ isLoading, setIsLoading ] = useState<boolean>(true);

  const { refetch } = useQuery({
    queryKey: ["sellers"],
    queryFn: () => getSellers(),
    retry: 1,
    onSuccess: (data) => {
      if (data?.items?.length && !seller) {
        const sellerId = localStorage.getItem("sellerId");
        const savedSeller = sellerId && data?.items.find(v => v.id === sellerId);
        setSeller(savedSeller || data.items[0]);
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (seller) {
      localStorage.setItem("sellerId", seller.id);
    }
  }, [seller])

  const HeaderFilters = useCallback(({ disabled }: { disabled?: boolean }) => (
    <SellerFilter
      onChange={setSeller}
      initialValue={seller}
      disabled={disabled}
    />
  ), [seller?.id])

  const onApiAuth = () => {
    refetch();
    setSeller(seller ? ({ ...seller, ozonApiExists: true }) : seller);
  }

  if (isLoading) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pt={8}>
              <Loader alignment="center" size="s" />
            </Box>
          </Column>
        </Row>
      </Grid>
    )
  }

  if (requestId) {
    return (
      <FulfillmentRequest
        requestId={requestId}
        sellerId={seller?.id ?? ""}
        HeaderFilters={HeaderFilters}
        warehouseId={warehouseId ?? ""}
      />
    )
  }

  if (warehouseId) {
    return (
      <FulfillmentStore
        warehouseId={warehouseId}
        sellerId={seller?.id ?? ""}
        HeaderFilters={HeaderFilters}
      />
    )
  }

  return (
    <FulfillmentStores
      seller={seller}
      onApiAuth={onApiAuth}
      HeaderFilters={HeaderFilters}
    />
  )
};

export default FulfillmentPage;
