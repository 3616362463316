import cn from "classnames";
import "./Box.css";

interface BoxProps {
  p?: number; // Общий padding
  m?: number; // Общий margin
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  textAlign?: "left" | "right" | "center";
  children: React.ReactNode;
  beforeSpace?: boolean;
  className?: string;
  flex?: "row" | "column";
}

const Box: React.FC<BoxProps> = (props: BoxProps) => {
  const classes = cn(
    "box",
    props.beforeSpace && "box--before-space",
    props.p && `p-${props.p}`,
    props.m && `m-${props.m}`,
    props.pt && `pt-${props.pt}`,
    props.pr && `pr-${props.pr}`,
    props.pb && `pb-${props.pb}`,
    props.pl && `pl-${props.pl}`,
    props.mt && `mt-${props.mt}`,
    props.mr && `mr-${props.mr}`,
    props.mb && `mb-${props.mb}`,
    props.ml && `ml-${props.ml}`,
    props.textAlign && `text-${props.textAlign}`,
    props.flex === "column" && "box--column",
    props.flex === "row" && "box--row",
    props.className,
  );

  return <div className={classes}>{props.children}</div>;
};

export default Box;
