import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from 'react-query';
import { useForm } from "react-hook-form";
import { linkVerify, linkSendCode, Seller } from "@api/apiClient";
import ButtonWithTimer, { ButtonWithTimerType } from "@components/ButtonWithTimer/ButtonWithTimer";
import Title from "@components/Title/Title.tsx";
import { Text, TextSize, TextColor } from "@components/Text";
import { Grid, Column, Row } from "@components/Grid";
import FormErrorMessage from "@components/FormErrorMessage";
import Box from "@components/Box";
import DigitInputContainer from "@containers/DigitInputContainer";

export interface SendPinForm {
  pin: string;
}
interface ConfirmEmailProps {
  onSuccess: (data: Seller[]) => void;
  setTicketId: (ticketId: string) => void;
  ticketId: string;
  email: string;
  name: string;
  apiKey: string;
  clientId: string;
}

const ConfirmEmail = ({
  onSuccess, email, setTicketId, ticketId, name, apiKey, clientId,
}: ConfirmEmailProps) => {
  const { t } = useTranslation();
  const { control, watch } = useForm<SendPinForm>({
    defaultValues: {
      pin: "",
    },
  });
  const pinValue = watch("pin");

  const verifyMutation = useMutation({
    mutationFn: linkVerify,
    onSuccess: ({ sellers }) => {
      onSuccess(sellers);
    },
  });
  const sendLinkMutation = useMutation({
    mutationFn: linkSendCode,
    onSuccess: ({ ticketId: newTicketId }) => {
      setTicketId(newTicketId);
    },
    onMutate: () => {
      verifyMutation.reset();
    },
  });

  const onSubmit = (pin: string) => {
    verifyMutation.mutate({
      code: pin,
      ticketId,
      name: name || undefined,
      apiKey: apiKey || undefined,
      clientId: clientId || undefined,
    });
  };

  const resendPin = () => {
    sendLinkMutation.mutate({ email, apiKey, clientId });
  }

  useEffect(() => {
    verifyMutation.reset();
    if (pinValue?.trim().length === 4) {
      onSubmit(pinValue)
    }
  }, [pinValue])

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={5} pt={1}>
            <Title title={t("profileShops.addShopEnterCode")} />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={10}>
            <Text
              color={TextColor.PRIMARY}
              size={TextSize.M}
              dangerouslySetInnerHTML={{
                __html: t(
                  "profileShops.addShopCodeSent",
                  { email: `<b>${email}</b>` })
              }}
            />
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={8}>
            <DigitInputContainer
              control={control}
              name="pin"
              length={4}
              rules={{ required: t("profileShops.emailErrorText") }}
              disabled={verifyMutation.isLoading}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box>
            <ButtonWithTimer
              onClick={resendPin}
              pending={sendLinkMutation.isLoading}
              label={t("profileShops.addShopResendCode")}
              type={ButtonWithTimerType.LINK}
            />
          </Box>
        </Column>
      </Row>
      {verifyMutation.isError ? (
        <FormErrorMessage error={verifyMutation.error} />
      ) : null}
    </Grid>
  )
}

export default ConfirmEmail;
