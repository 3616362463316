import { useQuery } from "react-query";
import { sellerApiClient, getParams } from "./sellerApiClient";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { DeliveryInfo } from "./types";

export interface ProblematicParcelItem {
  id: string;
  name: string;
  quantity: number;
  url: string;
  vendorCode: string;
  declaredValue: {
    amount: number;
    currency: string;
  };
}

export interface ProblematicParcel {
  id: string;
  trackNumber: string;
  externalId: string;
  countryCode: string;
  reason: string; // OverWeight
  serviceName: string;
  items: ProblematicParcelItem[];
  status: string; // New
  createAt: Date;
  sellerId: string;
  sellerName: string;
  estimatedUtilizationDate: Date;
  isCancelable: boolean;
  isExtraSmall: boolean;
  declaredValue: {
    amount: number;
    currency: string;
  };
  weight: {
    unit: string;
    value: number;
  };
}

export const getProblematicParcels = async ({
  page,
  filters,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<ResponsePages<ProblematicParcel>> => {
  const response = await sellerApiClient.get<ResponsePages<ProblematicParcel>>(
    "/v1/problematicparcels/page",
    {
      params: getParams(page, filters),
      paramsSerializer: { indexes: null },
    },
  );

  return response.data;
};

interface DeliveryInfoWithComment extends DeliveryInfo {
  comment: string;
}

interface ReturnData {
  deliveryInfo: DeliveryInfoWithComment,
  parcelIds: string[],
  comment: string;
}

export const returnParcel = async (
  data: ReturnData,
): Promise<any> => {
  const response = await sellerApiClient.post<ReturnData>(
    "/v1/problematicparcels/return",
    data,
  );
  return response.data;
};

interface DisposeData {
  returnType: "Disposal";
  deliveryInfo: DeliveryInfoWithComment | null;
  parcelIds: string[];
  comment: string;
}

export const disposeParcel = async (
  parcelIds: string[],
): Promise<any> => {
  const response = await sellerApiClient.post<DisposeData>(
    "/v1/problematicparcels/dispose",
    { parcelIds },
  );
  return response.data;
};

export interface ResponsePages<T> {
  total: number;
  items: T[];
}

export function useProblematicPageCounter(
  status: "AwaitingDecision" | "Return" | "Disposal"
) {
  const { data } = useQuery({
    queryKey: [
      "getProblematicParcels",
      { page: 1, pageSize: 10 },
      [{ name: "Statuses", value: [status] }],
    ],
    queryFn: () => getProblematicParcels({
      page: { page: 1, pageSize: 1 },
      filters: [{ name: "Statuses", value: [status] }],
    }),
    retry: 1,
    select: ({ total }) => total,
  });

  return { data: data ?? 0 };
}

export function useHasProblematicParcels() {
  const { data, isLoading, isFetching, isFetched, refetch } = useQuery({
    queryKey: ["parcels-page", [], 1, 1],
    queryFn: () => getProblematicParcels({
      filters: [],
      page: { page: 1, pageSize: 1 },
    }),
    retry: 1,
    select: ({ total }) => Boolean(total),
  });

  return {
    hasParcels: data,
    hasNoParcels: isFetched && !data,
    isLoading,
    isFetching,
    refetch,
  };
}

export const cancelReturn = async (id: string): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    `/v1/problematicparcels/cancel`,
    { parcelId: id }
  );
  return response.data;
};
