import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcherContainer from "@containers/LanguageSwitcherContainer/LanguageSwitcherContainer.tsx";
import { Logo } from "@components/Logo";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { Text, TextSize, TextColor } from "@components/Text";
import "./GuestLayout.css";

const GuestLayout = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const key = params.get("key");
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (key) {
      navigate(`/signup?key=${key}`);
    }
  }, []);

  return (
    <div className="guest-layout">
      <div className="guest-layout__head">
        <div className="guest-layout__head-item">
          <Logo />
        </div>
        <div className="guest-layout__head-spacer" />
        {location.pathname === "/login" && (
          <div className="guest-layout__head-item guest-layout__head-item--border">
            <Button
              className={"guest-layout__link"}
              title={t("signUp.header.signupLink")}
              type={ButtonType.LINK}
              size={ButtonSize.S}
              onClick={() => {
                navigate("/signup");
              }}
            />
          </div>
        )}
        {location.pathname === "/signup" && (
          <div className="guest-layout__head-item guest-layout__head-item--border">
            <Text size={TextSize.M} color={TextColor.DISABLED}>
              {t("signUp.header.text")}
            </Text>
            <Button
              className={"guest-layout__link"}
              title={t("signUp.header.link")}
              type={ButtonType.LINK}
              size={ButtonSize.S}
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>
        )}
        <div className={"guest-layout__head-item"}>
          <LanguageSwitcherContainer />
        </div>
      </div>
      <div className={"guest-layout__content"}>
        <Outlet />
      </div>
    </div>
  );
};

export default GuestLayout;
