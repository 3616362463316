import styles from './ReturnListItem.module.scss';
import { formatSumWithCurrency } from '@utils/formatValues';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { TReturnListItemProps } from '../../types';

const ReturnListItem: React.FC<TReturnListItemProps> = (props: TReturnListItemProps): React.ReactElement => {
  const { item } = props;

  return (
      <li className={styles.listItem}>
        <span className={styles.listItemCol}>
          <Text
              color={TextColor.PRIMARY}
              size={TextSize.S}
              weight={TextWeight.BOLD}
          >
            {item.returnPostingNumber}
          </Text>
          <Text
              color={TextColor.DARK_MUTED}
              size={TextSize.XS}
          >
            {item.returnTrackingNumber}
          </Text>
        </span>
        <span className={styles.listItemCol}>
          <a
              className={styles.link}
              href={'row.index.toString()'}
              rel="noreferrer"
              target="_blank"
          >
            {item.title}
          </a>
        </span>
        <span className={styles.listItemCol}>
          <Text
              color={TextColor.PRIMARY}
              size={TextSize.S}
          >
            {formatSumWithCurrency(item.priceRub, '₽')}
          </Text>
          <Text
              color={TextColor.DARK_MUTED}
              size={TextSize.XS}
          >
            {formatSumWithCurrency(item.priceCny, '¥')}
          </Text>
        </span>
      </li>
  );
};

export default ReturnListItem;