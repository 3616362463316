import cn from "classnames";
import RcPagination from "rc-pagination";
import Dropdown, { DropdownOptionProps, DropdownValueProps } from "../Dropdown";
import Arrow from "@assets/icons/chevron-up-svgrepo-com.svg";
import styles from './Pagination.module.css';

const ValueComponent = ({ id, isOpen }: DropdownValueProps<number>) => (
  <div className={styles.sizeValue}>
    {id}
    <img
      className={cn(
        styles.sizeValueIcon,
        isOpen && styles.sizeValueIconOpen,
      )}
      src={Arrow}
      alt="prev"
    />
  </div>
)

const OptionComponent = ({ id }: DropdownOptionProps<number>) => (
  <div className={styles.sizeOption}>
    {id}
  </div>
)

interface PaginationProps {
  className?: string;
  currentPage: number;
  totalItems: number;
  pageSize: number;
  onChange: (current: number, size: number) => void;
  onSizeChange: (current: number, size: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  className,
  currentPage,
  totalItems,
  pageSize,
  onChange,
  onSizeChange,
}) => {
  return (
    <div className={cn(className, styles.root)}>
      <RcPagination
        className={cn(className, styles.pagination)}
        current={currentPage}
        total={totalItems}
        pageSize={pageSize}
        onChange={onChange}
        onShowSizeChange={onSizeChange}
        jumpNextIcon={"..."}
        jumpPrevIcon={"..."}
        prevIcon={(<img src={Arrow} alt="prev" />)}
        nextIcon={(<img src={Arrow} alt="next" />)}
        pageSizeOptions={[10, 20, 50]}
        showSizeChanger={false}
      />
      <Dropdown<number>
        onChange={(v) => onSizeChange(1, v)}
        OptionComponent={OptionComponent}
        ValueComponent={ValueComponent}
        defaultOption={{ id: pageSize || 10 }}
        options={[{ id: 10 }, { id: 20 }, { id: 50 }]}
        menuPosition="top"
      />
    </div>
  );
};

export default Pagination;
