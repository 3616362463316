import Radio from '@components/Radio';
import styles from "./ToggleButtons.module.scss";
import cn from "classnames";

interface ToggleBtn {
  id: string;
  title: string;
}

export enum ToggleButtonTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WITH_MARK = "with-mark",
}

interface ToggleButtonProps {
  items: ToggleBtn[];
  value: string;
  onChange: (v: string) => void;
  type?: ToggleButtonTypes;
  className?: string;
}

const ToggleButtons = ({
  items,
  value,
  onChange,
  className,
  type = ToggleButtonTypes.SECONDARY,
}: ToggleButtonProps) => {
  return (
    <ul className={cn(
      className,
      styles.toggleButtons,
      (type === ToggleButtonTypes.PRIMARY) && styles.toggleButtonsPrimary,
      (type === ToggleButtonTypes.SECONDARY) && styles.toggleButtonsSecondary,
      (type === ToggleButtonTypes.WITH_MARK) && styles.toggleButtonsWithMark,
    )}>
      {items.map((item) => (
        <li
          key={item.id}
          className={cn(
            styles.item,
            item.id === value && styles.itemActive,
          )}
          onClick={() => onChange(item.id)}
        >
          {(type === ToggleButtonTypes.WITH_MARK) ? (
            <Radio
              id={item.id}
              checked={item.id === value}
              onChange={() => {}}
              label=""
              markOnly
            />
          ) : null}
          {item.title}
        </li>
      ))}
    </ul>
  );
};

export default ToggleButtons;
