import "./Tabs.css";
import { ReactNode, useEffect, useState } from 'react';
import cn from "classnames";

export interface Tab {
  id: string;
  title: string;
  count?: number;
  content: ReactNode;
  disable?: boolean;
}

interface TabsProps {
  tabs: Tab[];
  defaultTab?: string;
}

interface TabsListProps {
  tabs: Tab[];
  value: string;
  onChange: (tabId: string) => void;
}

interface TabsContainerProps {
  children: React.ReactNode;
}

interface TabsContentProps {
  tab?: Tab,
}

export const TabsContainer = ({ children }: TabsContainerProps) => (
  <div className="tabs">
    {children}
  </div>
)

export const TabsList = ({ tabs, onChange, value }: TabsListProps) => {
  const handleClick = (tabId: string, disable: boolean) => {
    if (disable) return;
    onChange(tabId);
  };

  return (
    <ul className="tabs__list">
      {(tabs || []).map((tab) => (
        <li
          key={tab.id}
          className={cn({
            "tabs__list-item": true,
            "tabs__list-item--active": tab.id === value,
            "tabs__list-item--disable": tab.disable,
          })}
          onClick={() => handleClick(tab.id, tab.disable ?? false)}
        >
          {tab.title}
          {tab.count !== undefined && tab.count > 0 && (
            <div className="tabs__list-item--count">{tab.count}</div>
          )}
        </li>
      ))}
    </ul>
  )
}

export const TabsContent = ({ tab }: TabsContentProps) => {
  if (!tab) return null
  return (
    <div className="tabs__content">
      {tab ? (
        <div key={tab.id}>{tab.content}</div>
      ) : null}
    </div>
  )
}

const Tabs = ({
  tabs,
  defaultTab,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultTab || tabs[0]?.id);

  const activeTabData = tabs.find((tab) => (tab.id === activeTab));

  useEffect(() => {
    if (!activeTab || !tabs.find(t => (t.id === activeTab))) {
      setActiveTab(defaultTab || tabs[0]?.id);
    }
  }, [tabs]);

  return (
    <TabsContainer>
      <TabsList
        tabs={tabs}
        onChange={setActiveTab}
        value={activeTab}
      />

      <TabsContent tab={activeTabData} />

    </TabsContainer>
  );
};

export default Tabs;
