import "./Avatar.css";

interface AvatarProps {
  text: string;
}

const Avatar = (props: AvatarProps) => {
  const capitalizeFirstTwoLetters = (str: string) => {
    if (str.length < 2) {
      return str.toUpperCase();
    } else {
      return str.substring(0, 2).toUpperCase();
    }
  };

  return (
    <div className={"avatar"}>{capitalizeFirstTwoLetters(props.text)}</div>
  );
};

export default Avatar;
