import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AddSellerPanel } from "@components/AddSeller";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { TabsList, TabsContent } from "@components/Tabs/Tabs.tsx";
import Page from "@components/Page/Page.tsx";
import AwaitingTab from "./tabs/AwaitingTab.tsx";
import ReturnsTab from "./tabs/ReturnsTab.tsx";
import { useTranslation } from "react-i18next";
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { useSellersFound } from "@api/apiClient";
import { useProblematicPageCounter} from "@api/apiClient/problematicParcels";
import useTab from "@hooks/useTab";
import FilterIcon from "@assets/icons/filter.svg";
import styles from "./ProblematicPage.module.css";

const ProblematicPage = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [savedAwaitingSelection, setSavedAwaitingSelection] = useState<string[]>([]);
  const { sellersNotFound, isFetching } = useSellersFound();
  const queryClient = useQueryClient();

  const { data: awaitingTotal } = useProblematicPageCounter("AwaitingDecision");
  const { data: returnTotal } = useProblematicPageCounter("Return");
  const { data: disposalTotal } = useProblematicPageCounter("Disposal");

  useEffect(() => {
    if (filters[0]?.value.length) {
      setSavedAwaitingSelection([]);
    }
  }, [filters]);

  const refreshData = () => {
    queryClient.invalidateQueries(
      { queryKey: ["getProblematicParcels"] }
    );
  }

  const tabs = [
    {
      id: "awaiting",
      title: t("problematicParcelsPage.tabAwaiting"),
      content: <AwaitingTab
        filters={filters}
        pending={!isInitialized || isFetching}
        handlerSetSavedAwaitingSelection={setSavedAwaitingSelection}
        savedAwaitingSelection={savedAwaitingSelection}
        refreshData={refreshData}
      />,
      count: awaitingTotal ?? 0,
    },
    {
      id: "returns",
      title: t("problematicParcelsPage.tabReturns"),
      content: <ReturnsTab
        filters={filters}
        pending={!isInitialized || isFetching}
        status="Return"
        refreshData={refreshData}
      />,
      count: returnTotal ?? 0,
      disable: sellersNotFound,
    },
    {
      id: "disposals",
      title: t("problematicParcelsPage.tabDisposal"),
      content: <ReturnsTab
        filters={filters}
        pending={!isInitialized || isFetching}
        status="Disposal"
        refreshData={refreshData}
      />,
      count: disposalTotal ?? 0,
      disable: sellersNotFound,
    },
  ];
  const { activeTab, setActiveTab } = useTab(tabs);

  return (
    <Page
        title={t("problematicParcelsPage.title")}
    >
      <div className={styles.tabsRow}>
        <TabsList
          tabs={tabs}
          onChange={setActiveTab}
          value={activeTab || tabs[0].id}
        />

        <div className={styles.filters}>
          {sellersNotFound ? null : (
            <Filters
              key={activeTab}
              items={[
                {
                  name: "sellerIds",
                  type: FilterTypes.SELLER,
                },
              ]}
              onChange={(v) => {
                (v || []).forEach((f) => {
                  if (f.name === "sellerIds") {
                    setIsInitialized(true);
                  }
                  setFilters(v);
                })
              }}
              values={filters}
            />
          )}

          {false && <Button
            icon={FilterIcon}
            onClick={() => {}}
            size={ButtonSize.S}
            type={ButtonType.LINK}
            title={t("parcelsPage.filters")}
          />}
        </div>
      </div>

      {sellersNotFound ? (
        <AddSellerPanel onSuccess={refreshData} />
      ) : (
        <TabsContent
          tab={tabs.find((tab) => (tab.id === activeTab))}
        />
      )}
    </Page>
  );
};

export default ProblematicPage;
