import styles from './Account.module.scss';
import WeChatIcon from '@assets/icons/we-chat-green-19.svg';
import { TAccountProps } from '../../types';

const AccountPoint: React.FC<TAccountProps> = (props: TAccountProps): React.ReactElement | null => {
  const { item } = props;

  if (!item) return null;

  return (
      <div className={styles.point}>
        <span className={styles.icon}>
          <img
              alt={item.weChatId}
              src={WeChatIcon}
          />
        </span>
        <span>{item.weChatId}</span>
      </div>
  );
};

export default AccountPoint;