import { useRef, useState, useLayoutEffect } from "react";
import { useQuery } from 'react-query';
import cn from "classnames";
import { CSSTransition } from "react-transition-group";
import FormErrorMessage from "@components/FormErrorMessage";
import useOutsideClick from "@hooks/useOutsideClick";
import { Loader } from "@components/Loader";
import styles from "./SearchInput.module.css";

export const SearchableString = ({ value, search }: {
  value: string,
  search?: string,
}) => {
  const res = (search ? value.split(search) : [value]);
  return (
    <>
      {res.map((v: string, idx: number) => (
        <>
          {v}
          {(idx < res.length - 1) && (
            <b>{search ?? ""}</b>
          )}
        </>
      ))}
    </>
  )
}

function renderItemDefault({ item, search, labelOptionItem }: {
  item: any,
  search?: string,
  labelOptionItem: (item: any, search: string) => string,
}) {
  const s = labelOptionItem(item, search ?? "");
  return (<SearchableString value={s} search={search} />);
}

function labelSelectedItemDefault(_: any, search: string) {
  return search;
}

export interface SearchInputProps<T> {
  // value?: T;
  className?: string;
  getItems: (search: string) => Promise<T[]>;
  requestKey: string;
  keyExtractor: (item: T) => string;
  labelOptionItem?: (item: T | null, search: string) => string;
  renderSelectedItem?: (props: { item: T | null }) => React.ReactNode;
  labelSelectedItem?: (item: T | null, search: string) => string;
  renderItem?: (props: { item: T, search?: string }) => React.ReactNode;
  onItemSelect: (data: T) => void;
  disabled?: boolean;
  minLength?: number;
  showSelected?: boolean;
}

const SearchInput = <T extends object>(props: SearchInputProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<T | null>(null);
  const [searchString, setSearchString] = useState<string>("");
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick<HTMLDivElement>(dropdownRef, () => setIsOpen(false));

  const { data, isFetching, isError, error } = useQuery({
    queryKey: [props.requestKey, searchString],
    queryFn: () => props.getItems(searchString),
    enabled: searchString.length > (props.minLength ?? 0),
    retry: 1,
    onSuccess: (data: T[]) => (data?.length && setIsOpen(true)),
  });

  const handleSelect = (item: T) => {
    setSelectedItem(item);
    props.onItemSelect(item);
    setIsOpen(false);
  };
  const getItemLabel = props.labelOptionItem ?? labelSelectedItemDefault

  useLayoutEffect(() => {
    if (dropdownRef.current) {
      const { offsetWidth } = dropdownRef.current;
      setDropdownWidth(offsetWidth);
    }
  }, [dropdownRef.current]);

  return (
    <div className={cn(styles.search, props.className)} ref={dropdownRef}>
      <div
        className={cn(styles.selected)}
        onClick={() => (!props.disabled && setIsOpen(!isOpen))}
      >
        {(!props.showSelected || isOpen) ? (
          <input
            type="text"
            className={styles.searchInput}
            autoFocus
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        ) : (
          props.renderSelectedItem ? (
            <div className={styles.selectedItem}>
              {props.renderSelectedItem({ item: selectedItem })}
            </div>
          ) : (
            <div className={styles.selectedItem}>
              {(props.labelSelectedItem || (selectedItem && props.labelOptionItem) || labelSelectedItemDefault)(selectedItem, searchString)}
            </div>
          )
        )}
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames={{
          enter: styles.menuEnter,
          enterActive: styles.menuEnterActive,
          exit: styles.menuExit,
          exitActive: styles.menuExitActive,
          exitDone: styles.menuExitDone,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div
          className={styles.menu}
          style={{ width: dropdownWidth }}
        >
          <div className={styles.menuContent}>
            {(data || []).map((item) => (
              <div
                className={styles.item}
                key={props.keyExtractor(item)}
                onClick={() => handleSelect(item)}
              >
                {(props.renderItem
                  ? props.renderItem({ item, search: searchString })
                  : renderItemDefault({
                    item,
                    search: searchString,
                    labelOptionItem: getItemLabel,
                  })
                )}
              </div>
            ))}
          </div>
          {isFetching ? (
            <div className={styles.loader}>
              <Loader alignment="center" size="xs" />
            </div>
          ) : null}
        </div>
      </CSSTransition>
      {isError && (
        <FormErrorMessage
          error={error}
          errorPrefix="apiErrors"
        />
      )}
    </div>
  );
};

export default SearchInput;
