import { sellerApiClient } from "./sellerApiClient";

export interface NewsItem {
  id: string;
  img: string;
  title: string;
  description: string;
  content: string;
  lang: string;
  link: string;
}

function parseNews(data: string, isImportant: boolean) {
  const parser = new DOMParser();
  const xml = parser.parseFromString(data, "text/xml");
  const channel = xml?.children?.[0].children?.[0];

  const items = (channel ? [...channel.getElementsByTagName("item")] : [])
    .map(v => {
      const id = v.getElementsByTagName("link")[0]?.innerHTML;
      return {
        id,
        img: v.getElementsByTagName("enclosure")[0]?.getAttribute("url") || "",
        title: v.getElementsByTagName("title")[0]?.innerHTML,
        description: v.getElementsByTagName("description")[0]?.innerHTML,
        content: v.getElementsByTagName("turbo:content")[0]?.textContent || "",
        lang: v.getElementsByTagName("category")[0]?.innerHTML,
        link: `/news?${isImportant ? "importantNewsId" : "newsId"}=${id}`
      }
    }).reverse();

  return items;
}

export const getNews =
  async (): Promise<NewsItem[]> => {
    const response = await sellerApiClient.get<string>(
      "/news/rss-feed-546371597961.xml",
    );

    return parseNews(response.data, false);
  };

export const getImportantNews =
  async (): Promise<NewsItem[]> => {
    const response = await sellerApiClient.get<string>(
      "/news/rss-feed-399105678021.xml",
    );

    return parseNews(response.data, true);
  };
