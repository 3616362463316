import AccountPoint from '../components/Account/Account';
import Box from '@components/Box';
import SelectContainer from '../../../containers/SelectContainer';
import TextInputContainer from '@containers/TextInputContainer';
import Title from '@components/Title';
import { Button } from '@components/Button';
import { Column, Grid, Row } from '@components/Grid';
import { IWithdrawFundsFormModalWindowProps, TWechatWallet, TWithdrawFundsForm } from '../types';
import { sendWithdrawFunds } from '@api/apiClient/finance';
import { Text, TextColor, TextSize } from '@components/Text';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useProfile } from '../../../contexts/ProfileContext';
import { useTranslation } from 'react-i18next';

const WithdrawFundsFormModalWindow: React.FC<IWithdrawFundsFormModalWindowProps> = (props: IWithdrawFundsFormModalWindowProps): React.ReactElement => {
  const { handlerSetIsOpenWithdrawFundsFormWindow, refresh, wechatWallets } = props;

  const [isBtnSubmitDisabled, setIsBtnSubmitDisabled] = useState<boolean>(false);

  const { account, get } = useProfile();
  const { t } = useTranslation();

  const { isLoading, mutate } = useMutation({
    mutationFn: sendWithdrawFunds,
    onSuccess: () => {
      get();
      handlerSetIsOpenWithdrawFundsFormWindow(false);
      refresh!();
    }
  });

  const { control, handleSubmit, setValue, watch } = useForm<TWithdrawFundsForm>({
    defaultValues: {
      amount: '',
      weChatWalletId: ''
    },
    mode: 'onChange'
  });

  const submit = (form: TWithdrawFundsForm): void => {
    mutate({
      amount: +form.amount,
      weChatWalletId: form.weChatWalletId
    });
  };

  const checkAmount = (value: number) => {
    setIsBtnSubmitDisabled(false);
    const dailyLimit = 20000;
    const remainingLimit = wechatWallets![0].remainingWithdrawalAmountLimit;
    const result =
        +value > account!.balance
            ? t('WithdrawFundsFormModalWindow.error1')
            : +value > account!.weChatWithdrawalLimit
                ? t('WithdrawFundsFormModalWindow.error2').replace('{{limit}}', account!.weChatWithdrawalLimit.toString())
                : +value + dailyLimit - remainingLimit > dailyLimit
                    ? t('WithdrawFundsFormModalWindow.error3').replace('{{n}}', (remainingLimit).toString())
                    : true;
    if (typeof result === 'string') setIsBtnSubmitDisabled(true);
    return result;
  };

  const amount = watch('amount');

  useEffect(() => {
    setValue('amount', amount.replace(/^0+/, '').replace(/[^0-9]+/g, ''));
  }, [amount]);

  return (
      <Grid>
        <Row>
          <Column phone={8}>
            <Box pb={5}>
              <Title title={t('WithdrawFundsFormModalWindow.title')}/>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <Text
                  color={TextColor.PRIMARY}
                  size={TextSize.M}
              >
                {t('WithdrawFundsFormModalWindow.description')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={6}>
            <Box pb={5}>
              <TextInputContainer
                  control={control}
                  name="amount"
                  min={0}
                  rules={{
                    required: t('defaultFormErrors.required'),
                    validate: (value: string) => checkAmount(+value)
                  }}
                  title={t('WithdrawFundsFormModalWindow.input')}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <SelectContainer<TWechatWallet, TWithdrawFundsForm>
                  control={control}
                  items={wechatWallets || []}
                  keyExtractor={(item: TWechatWallet) => item.id}
                  name="weChatWalletId"
                  renderItem={AccountPoint}
                  renderSelectedItem={AccountPoint}
                  rules={{ required: t('defaultFormErrors.required') }}
                  title={t('WithdrawFundsFormModalWindow.select')}
              />
            </Box>
          </Column>
        </Row>
        <Row noWrap>
          <Box>
            <Button
                disabled={isBtnSubmitDisabled}
                onClick={handleSubmit(submit)}
                pending={isLoading}
                title={t('WithdrawFundsFormModalWindow.button')}
            />
          </Box>
        </Row>
      </Grid>
  );
};

export default WithdrawFundsFormModalWindow;