export enum TextType {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  P = "p",
  SPAN = "span",
  INLINE = "inline",
  BLOCK = "block",
}

export enum TextColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ERROR = "error",
  ERROR_DARK = "error-dark",
  DISABLED = "disabled",
  WHITE = "white",
  DARK_MUTED = "dark-muted",
  BLUE = "blue",
  LIGHT_BLUE = "light-blue",
  WARM_PINK = "warm-pink",
  GREENISH = "greenish",
}

export enum TextSize {
  XXS = "xxs",
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  XXL = "xxl",
}

export enum TextWeight {
  NORMAL = "normal",
  MEDIUM = "medium",
  SEMIBOLD = "semi-bold",
  BOLD = "bold",
}
