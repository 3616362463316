import { ReactElement, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import Page from "@components/Page/Page";
import { TabsList, TabsContent } from "@components/Tabs/Tabs";
import DataTab from "./tabs/DataTab.tsx";
import StoreTab from "./tabs/StoreTab.tsx";
// import RefTab from "./tabs/RefTab.tsx";
import FinTab from "./tabs/FinTab.tsx";
import useTab from "@hooks/useTab";
import styles from "./profilePage.module.css";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { checkBalanceReplenishment, getWechatWallets } from '@api/apiClient/finance';
import WithdrawFundsFormModalWindow from './modals/WithdrawFundsFormModalWindow';
import TopUpBalanceFormModalWindow from './modals/TopUpBalanceFormModalWindow/TopUpBalanceFormModalWindow';
import TopUpBalanceCompletedModalWindow from './modals/TopUpBalanceCompletedModalWindow/TopUpBalanceCompletedModalWindow';
import { Button } from '../../components/Button';
import { DataViewHandle } from '@containers/DataTable';
import { Modal } from '../../components/Modal';
import { Tooltip } from '../../components/Tooltip';
import { useProfile } from '../../contexts/ProfileContext';
import { ButtonType } from '@components/Button';
import AddShopModal from './components/AddShopModal';

const ProfilePage = () => {
  const { t } = useTranslation();
  const [balanceReplenishmentError, setBalanceReplenishmentError] = useState<string | undefined>(undefined);
  const [balanceReplenishmentInProcessed, setBalanceReplenishmentInProcessed] = useState<boolean>(false);
  const [isOpenAddModal, setOpenAddModal] = useState<boolean>(false);
  const [isOpenTopUpBalanceCompletedWindow, setIsOpenTopUpBalanceCompletedWindow] = useState<boolean>(false);
  const [isOpenTopUpBalanceFormWindow, setIsOpenTopUpBalanceFormWindow] = useState<boolean>(false);
  const [isOpenWithdrawFundsFormWindow, setIsOpenWithdrawFundsFormWindow] = useState<boolean>(false);
  const [replenishmentAmount, setReplenishmentAmount] = useState<number>(0);
  const queryClient = useQueryClient();

  const dataRef = useRef<DataViewHandle | null>(null);
  const { account, get: refreshProfile } = useProfile();

  const timeoutCheckBalance = useRef<number | null>(null);
  const timeoutCheckQr = useRef<number | null>(null);

  const wechatWallets = useQuery({
    queryKey: ['wechatWallets'],
    queryFn: () => getWechatWallets(),
    retry: 1
  });

  const checkBalanceReplenishmentMutation = useMutation({
    mutationFn: checkBalanceReplenishment
  });

  const waitingBalanceReplenished = (id: string): void => {
    timeoutCheckBalance.current = window.setTimeout(() => {
      checkBalanceReplenishmentMutation.mutate(id, {
        onSuccess: (data) => {
          if (data.isProcess) {
            if (!balanceReplenishmentInProcessed) setBalanceReplenishmentInProcessed(true);
            waitingBalanceReplenished(id);
          } else {
            if (balanceReplenishmentInProcessed) setBalanceReplenishmentInProcessed(false);
            if (data.isSuccess) {
              clearTimeout(timeoutCheckQr.current as number);
              refreshProfile();
              setIsOpenTopUpBalanceCompletedWindow(true);
              setIsOpenTopUpBalanceFormWindow(false);
              setReplenishmentAmount(data.amount!);
            } else {
              if (data.errorCode) {
                clearTimeout(timeoutCheckQr.current as number);
                setBalanceReplenishmentError(data.errorCode);
              } else {
                waitingBalanceReplenished(id);
              }
            }
          }
        }
      });
    }, 3000);
  };

  const setQrTimeout = (): void => {
    timeoutCheckQr.current = window.setTimeout(() => {
      setIsOpenTopUpBalanceFormWindow(false);
      clearTimeout(timeoutCheckBalance.current as number);
    }, 7200000);
  };

  const onCloseBalanceForm = (): void => {
    clearTimeout(timeoutCheckBalance.current as number);
    clearTimeout(timeoutCheckQr.current as number);
    setIsOpenTopUpBalanceFormWindow(false);
  };

  const tabs = [
    {
      id: "dataTab",
      title: t("profilePage.dataTab"),
      content: <DataTab />,
    },
    {
      id: "storeTab",
      title: t("profilePage.storeTab"),
      content: <StoreTab />,
    },
    {
      id: "finTab",
      title: t("profilePage.finTab"),
      content: (
        <FinTab
          dataRef={dataRef}
          wechatWallets={wechatWallets.data?.items}
          refetchWallets={wechatWallets.refetch}
        />
      ),
    },
  ];
  const { activeTab, setActiveTab } = useTab(tabs);

  const conditionForBtn: boolean =
    wechatWallets.data?.items.length
        ? account!.hasActiveWithdrawal
        : true;

  const textForTooltip: string =
    wechatWallets.data?.items.length
        ? account!.hasActiveWithdrawal
            ? t('profilePage.actionTooltip2')
            : ''
        : t('profilePage.actionTooltip1');

  const topUpBalanceBtn: ReactElement =
      <Button
          onClick={() => setIsOpenTopUpBalanceFormWindow(true)}
          title={t('profilePage.topUpBalanceBtnText')}
      />;

  const withdrawFundsBtn: ReactElement =
      <Button
          disabled={conditionForBtn}
          onClick={() => setIsOpenWithdrawFundsFormWindow(true)}
          title={t('profilePage.withdrawFundsBtnText')}
          type={ButtonType.SKELETON}
      />;

  const actionWrapper: ReactElement =
      <Tooltip
          action={withdrawFundsBtn}
          right={55}
          text={textForTooltip}
          top={51}
      />;

  const addBtn: ReactElement =
      <Button
          type={ButtonType.PRIMARY}
          onClick={() => setOpenAddModal(true)}
          title={`+ ${t('profileShops.addShop')}`}
      />;

  const currentAction: ReactElement | null =
    ((account?.region === "CN") && (activeTab === 'finTab'))
        ? <>{topUpBalanceBtn}{actionWrapper}</>
        : activeTab === 'storeTab'
            ? addBtn
            : null;

  return (
    <Page
        actions={currentAction}
        title={t("profilePage.title")}
    >
      <div className={styles.tabsRow}>
        <TabsList
          tabs={tabs}
          onChange={setActiveTab}
          value={activeTab}
        />
      </div>

      <TabsContent
        tab={tabs.find((tab) => (tab.id === activeTab))}
      />
      <Modal
          isOpen={isOpenWithdrawFundsFormWindow}
          onClickOverlay={() => setIsOpenWithdrawFundsFormWindow(false)}
          onClose={() => setIsOpenWithdrawFundsFormWindow(false)}
          width={567}
      >
        <WithdrawFundsFormModalWindow
            handlerSetIsOpenWithdrawFundsFormWindow={setIsOpenWithdrawFundsFormWindow}
            refresh={dataRef?.current?.refresh}
            wechatWallets={wechatWallets.data?.items}
          />
      </Modal>
      <Modal
          isOpen={isOpenTopUpBalanceFormWindow}
          onClickOverlay={balanceReplenishmentInProcessed ? () => {} : onCloseBalanceForm}
          onClose={balanceReplenishmentInProcessed ? () => {} : onCloseBalanceForm}
          width={363}
      >
        <TopUpBalanceFormModalWindow
            balanceReplenishmentError={balanceReplenishmentError}
            balanceReplenishmentInProcessed={balanceReplenishmentInProcessed}
            handlerOnCloseBalanceForm={onCloseBalanceForm}
            handlerWaitingBalanceReplenished={waitingBalanceReplenished}
            handlerSetQrTimeout={setQrTimeout}
        />
      </Modal>
      <Modal
          isOpen={isOpenTopUpBalanceCompletedWindow}
          onClickOverlay={() => setIsOpenTopUpBalanceCompletedWindow(false)}
          onClose={() => setIsOpenTopUpBalanceCompletedWindow(false)}
          width={567}
      >
        <TopUpBalanceCompletedModalWindow
            handlerSetIsOpenTopUpBalanceCompletedWindow={setIsOpenTopUpBalanceCompletedWindow}
            replenishmentAmount={replenishmentAmount}
        />
      </Modal>
      {isOpenAddModal ? (
          <AddShopModal onClose={() => {
            setOpenAddModal(false);
            queryClient.invalidateQueries(
              { queryKey: ["sellers"] }
            );
          }} />
      ) : null}
    </Page>
  );
};

export default ProfilePage;
