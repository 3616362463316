import styles from './Tab.module.scss';
import Avatar from '@components/Avatar';
import BeforeAwaitingTable from '../components/BeforeAwaitingTable/BeforeAwaitingTable';
import Box from '@components/Box';
import CardParcelModalWindow from '../modals/CardParcelModalWindow/CardParcelModalWindow';
import cn from 'classnames';
import CopyTextToClipboard from '@components/CopyTextToClipboard';
import DataTable, { BeforeProps, DataViewHandle } from '@containers/DataTable';
import DocumentLink from '@components/DocumentLink';
import PaymentReturnCompletedModalWindow
  from '../modals/PaymentReturnCompletedModalWindow/PaymentReturnCompletedModalWindow';
import PaymentReturnModalWindow from '../modals/PaymentReturnModalWindow/PaymentReturnModalWindow';
import ProgressBar from '@components/ProgressBar';
import ReturnCostModalWindow from '../modals/ReturnCostModalWindow/ReturnCostModalWindow';
import ReturnProcessingModalWindow
  from '../modals/ReturnProcessingModalWindow/ReturnProcessingModalWindow';
import TableSelectionPanel from '@components/Table/TableSelectionPanel';
import TruckIcon from '@assets/icons/truck-grey-12.svg';
import UIcon from '@assets/icons/u-blue-10.svg';
import YuanIcon from '@assets/icons/yuan-blue-18.svg';
import { AddSellerPanel } from '@components/AddSeller';
import { Button, ButtonSize, ButtonType } from '@components/Button';
import {
  checkBalanceReplenishment,
  checkBalanceReplenishmentResponse,
  getWallet,
  sendTopUpBalance,
  sendTopUpBalanceResponse
} from '@api/apiClient/finance';
import { Column, Grid, Row } from '@components/Grid';
import { FilterModel } from '@hooks/useFiltersSearchParams';
import { Filters, FilterTypes } from '@containers/Filters';
import { formatSumWithCurrency } from '@utils/formatValues';
import { getAddresses } from '@api/apiClient';
import { getAwaiting, TAwaitingParcel } from '@api/apiClient/returns';
import { getCurrentLanguage } from '@utils/lang';
import { getDaysBetweenDates } from '@utils/progress';
import { IAwaitingTabProps, TSetSubmitParams } from '../types';
import { Loader } from '@components/Loader';
import { Modal } from '@components/Modal';
import { TableColumn, TableColumnType, TableRowType } from '@components/Table/TableTypes.ts';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useProfile } from '@contexts/ProfileContext';
import { useTranslation } from 'react-i18next';
import { VITE_APP_DEV } from '@utils/config';

const AwaitingTab: React.FC<IAwaitingTabProps> = (props: IAwaitingTabProps): React.ReactElement => {
  const { isFilterOpen, onUpdate, sellersNotFound } = props;

  const [columnsSort, setColumnsSort] = useState<string[]>([]);
  const [innerFilters, setInnerFilters] = useState<FilterModel[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);
  const [isOpenPaymentReturnCompletedWindow, setIsOpenPaymentReturnCompletedWindow] = useState<boolean>(false);
  const [isOpenPaymentReturnWindow, setIsOpenPaymentReturnWindow] = useState(false);
  const [isOpenReturnCostWindow, setIsOpenReturnCostWindow] = useState(false);
  const [isOpenReturnProcessingWindow, setIsOpenReturnProcessingWindow] = useState<boolean>(false);
  const [parcel, setParcel] = useState<TAwaitingParcel | undefined>(undefined);
  const [paymentReturnError, setPaymentReturnError] = useState<string | undefined>(undefined);
  const [paymentReturnInProcessed, setPaymentReturnInProcessed] = useState<boolean>(false);
  const [selectedDelivery, setSelectedDelivery] = useState<number>(0);
  const [selectedParcels, setSelectedParcels] = useState<Map<string, TAwaitingParcel>>(new Map());
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [selectedSum, setSelectedSum] = useState<number>(0);
  const [submitParams, setSubmitParams] = useState<TSetSubmitParams>({ params: undefined });

  const dataRef = useRef<DataViewHandle>(null);
  const timeoutCheckBalance = useRef<number | null>(null);
  const timeoutCheckPayment = useRef<number | null>(null);

  const currentLanguage = getCurrentLanguage();
  const { get } = useProfile();
  const { t } = useTranslation();

  const {
    data: queryAddresses,
    isLoading: isLoadingGetAddresses
  } = useQuery({
    queryKey: ['addresses'],
    queryFn: () => getAddresses(),
    retry: 1
  });

  const handleSelectionChange = useCallback((
      selectedIds: string[],
      items: TAwaitingParcel[]
  ) => {
    setSelectedRowIds(selectedIds);

    const parcels: Map<string, TAwaitingParcel> = new Map();

    selectedParcels.forEach((parcel: TAwaitingParcel, key: string) => {
      const item = items.find((v: TAwaitingParcel) => v.id === key);
      if (!item) parcels.set(key, parcel);
    });

    selectedIds.forEach((id) => {
      const item = items.find((v: TAwaitingParcel) => (v.id === id));
      if (item) {
        parcels.set(id, item);
      } else {
        const selectedItem = selectedParcels.get(id);
        if (selectedItem) {
          parcels.set(id, selectedItem);
        }
      }
    });

    const delivery = Array.from(parcels, ([_, value]) => value).reduce((acc, v) => (acc + v.reexportPrice.amount), 0);
    const sum = Array.from(parcels, ([_, value]) => value).reduce((acc, v) => (acc + v.priceCny), 0);

    setSelectedDelivery(delivery);
    setSelectedParcels(parcels);
    setSelectedSum(sum);
  }, [selectedParcels]);

  const refreshData = () => {
    if (dataRef.current?.refresh) {
      dataRef.current.refresh();
    }
    onUpdate();
    setSelectedParcels(new Map());
    setSelectedRowIds([]);
  };

  const { mutate: mutateCheckBalanceReplenishment } = useMutation({
    mutationFn: checkBalanceReplenishment
  });

  const { mutate: mutateCheckBalance } = useMutation({
    mutationFn: getWallet,
    onSuccess: (result) => {
      if (result.balance < selectedDelivery) {
        waitingBalanceReplenished();
      } else {
        const { params } = submitParams;
        if (params) {
          const { handleSubmit, submit } = params;
          handleSubmit(submit)();
        }
      }
    }
  });

  const {
    data: dataSendTopUpBalance,
    error: errorSendTopUpBalance,
    isError: isErrorSendTopUpBalance,
    isLoading: isLoadingSendTopUpBalance,
    mutate: mutateSendTopUpBalance
  } = useMutation({
    mutationFn: sendTopUpBalance,
    onSuccess: (result: sendTopUpBalanceResponse) => {
      setIsOpenPaymentReturnWindow(true);
      setIsOpenReturnProcessingWindow(false);
      setPaymentTimeout();
      waitingBalanceReplenished(result.outTradeNo);
    }
  });

  const waitingBalanceReplenished = (id?: string): void => {
    timeoutCheckBalance.current = window.setTimeout(() => {
      if (id) {
        mutateCheckBalanceReplenishment(id, {
          onSuccess: (data: checkBalanceReplenishmentResponse) => {
            if (data.isProcess) {
              if (!paymentReturnInProcessed) setPaymentReturnInProcessed(true);
              waitingBalanceReplenished(id);
            } else {
              if (paymentReturnInProcessed) setPaymentReturnInProcessed(false);
              if (data.isSuccess) {
                clearTimeout(timeoutCheckPayment.current as number);
                get();
                setIsOpenPaymentReturnCompletedWindow(true);
                setIsOpenPaymentReturnWindow(false);
              } else {
                if (data.errorCode) {
                  clearTimeout(timeoutCheckPayment.current as number);
                  setPaymentReturnError(data.errorCode);
                } else {
                  waitingBalanceReplenished(id);
                }
              }
            }
          }
        });
      } else {
        mutateCheckBalance();
      }
    }, 3000);
  };

  const setPaymentTimeout = (): void => {
    timeoutCheckPayment.current = window.setTimeout(() => {
      setPaymentReturnInProcessed(false);
      clearTimeout(timeoutCheckBalance.current as number);
    }, 180000);
  };

  const onClosePaymentReturn = (): void => {
    if (!paymentReturnInProcessed) {
      clearTimeout(timeoutCheckBalance.current as number);
      clearTimeout(timeoutCheckPayment.current as number);
      setIsOpenPaymentReturnWindow(false);
    }
  };

  const onClickPaymentLink = () => {
    setPaymentReturnInProcessed(true);
    setPaymentTimeout();
    waitingBalanceReplenished();
    window.open('', '');
  };

  const onCloseReturnProcessing = () => {
    setIsOpenReturnProcessingWindow(false);
    setTimeout(() => setParcel(undefined), 500);
  };

  const onCloseParcel = () => {
    setIsOpenParcelWindow(false);
    setTimeout(() => setParcel(undefined), 500);
  };

  const BeforeAwaitingTableComponent = useCallback((props: BeforeProps<TAwaitingParcel>) => {
    return (
        <BeforeAwaitingTable {...props} />
    );
  }, []);

  const sortByPrice = (position: number, value: string, filters: FilterModel[]): void => {
    const newColumnsSort: string[] = columnsSort.map((item: string, index: number) => {
      if (position === index) {
        return item === 'desc' ? 'asc' : 'desc';
      } else {
        return item.length ? 'desc' : '';
      }
    });
    setColumnsSort(newColumnsSort);
    const newInnerFilters: FilterModel[] = filters.filter((item: FilterModel) =>
        item.value[0] !== 'Price' && item.value[0] !== 'SellerName');
    const filtersByPrice: FilterModel = {
      fieldNames: ['field', 'direction'],
      name: 'Sort',
      value: ['Price', value.charAt(0).toUpperCase() + value.slice(1)]
    };
    newInnerFilters.push(filtersByPrice);
    setInnerFilters(newInnerFilters);
  };

  const sortByStores = (position: number, value: string, filters: FilterModel[]): void => {
    const newColumnsSort: string[] = columnsSort.map((item: string, index: number) => {
      if (position === index) {
        return item === 'desc' ? 'asc' : 'desc';
      } else {
        return item.length ? 'desc' : '';
      }
    });
    setColumnsSort(newColumnsSort);
    const newInnerFilters: FilterModel[] = filters.filter((item: FilterModel) =>
        item.value[0] !== 'Price' && item.value[0] !== 'SellerName');
    const filtersByPrice: FilterModel = {
      fieldNames: ['field', 'direction'],
      name: 'Sort',
      value: ['SellerName', value.charAt(0).toUpperCase() + value.slice(1)]
    };
    newInnerFilters.push(filtersByPrice);
    setInnerFilters(newInnerFilters);
  };

  const columns: TableColumn<TAwaitingParcel>[] = useMemo(() => [
    {
      header: t('returnsPage.awaitingTab.trackNumber'),
      accessor: (row: TableRowType<TAwaitingParcel>) => (
          <>
            <CopyTextToClipboard
                text={row.data?.returnPostingNumber || ''}
                visibleOnHover
            >
              <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenParcelWindow(true);
                    setParcel(row.data);
                  }}
              >
                <Text
                    color={TextColor.PRIMARY}
                    onHoverColor={TextColor.BLUE}
                    size={TextSize.S}
                    weight={TextWeight.BOLD}
                >
                  {row?.data?.returnPostingNumber}
                </Text>
              </span>
            </CopyTextToClipboard>
            <CopyTextToClipboard
                text={row.data?.returnTrackingNumber || ''}
                visibleOnHover
            >
              <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenParcelWindow(true);
                    setParcel(row.data);
                  }}
              >
                <Text
                    color={TextColor.DARK_MUTED}
                    size={TextSize.XS}
                >
                  {row?.data?.returnTrackingNumber}
                </Text>
              </span>
            </CopyTextToClipboard>
          </>
      ),
      alignment: 'left',
      width: '150px'
    },
    {
      header: t('returnsPage.awaitingTab.name'),
      accessor: (row: TableRowType<TAwaitingParcel>) => (
          <div className={styles.name}>
            <a
                className={styles.link}
                href={row?.data?.url}
                rel="noreferrer"
                target="_blank"
            >
              {row?.data?.title}
            </a>
            {(row.data.productCondition === 'Bad') && (
                <div className={cn(styles.state, styles.stateInfo)}>
                  <Text
                      color={TextColor.WHITE}
                      size={TextSize.XS}
                  >
                    {t(`returnsPage.awaitingTab.states.${row.data.productCondition}`)}
                  </Text>
                </div>
            )}
          </div>
      ),
      alignment: 'left',
      width: '400px'
    },
    {
      header: t('returnsPage.awaitingTab.priceOfProduct'),
      accessor: (row: TableRowType<TAwaitingParcel>) => (
          <div className={styles.price}>
            <Text
                color={TextColor.PRIMARY}
                size={TextSize.S}
            >
              {formatSumWithCurrency(row.data.priceRub, '₽')}
            </Text>
            <Tooltip
                action={
                  <Text
                      color={TextColor.DARK_MUTED}
                      size={TextSize.XS}
                  >
                    {formatSumWithCurrency(row.data.priceCny, '¥')}
                  </Text>
                }
                left={-227}
                text={t('returnsPage.awaitingTab.tooltipText_1').replace('{{rate}}', '0.072')}
                top={-22}
            />
          </div>
      ),
      alignment: 'right',
      noOverflow: true,
      sortable: true,
      sortableFn: sortByPrice,
      width: '100px'
    },
    {
      header: t('returnsPage.awaitingTab.returnCost'),
      accessor: (row: TableRowType<TAwaitingParcel>) => (
          <>
            {row.data.directStreamProvider === 'Unitrade' ? (
                <Tooltip
                    action={
                      <div className={styles.returnPrice}>
                        <img
                            src={UIcon}
                            alt="Icon"
                        />
                        {formatSumWithCurrency(row.data.reexportPrice.amount, '¥')}
                      </div>
                    }
                    left={-227}
                    text={t('returnsPage.awaitingTab.tooltipText_2')}
                    top={-7}
                    whiteSpace="pre-wrap"
                />
            ) : (
                <Tooltip
                    action={
                      <div className={styles.returnPrice}>
                        <img
                            src={TruckIcon}
                            alt="Icon"
                        />
                        {formatSumWithCurrency(row.data.reexportPrice.amount, '¥')}
                      </div>
                    }
                    left={-227}
                    text={`${t('returnsPage.awaitingTab.tooltipText_3')}<br><br>${t('returnsPage.awaitingTab.tooltipText_2')}`}
                    top={-7}
                    whiteSpace="pre-wrap"
                />
            )
            }
          </>
      ),
      alignment: 'left',
      noOverflow: true,
      type: TableColumnType.PRIMARY,
      width: '100px'
    },
    {
      header: t('returnsPage.awaitingTab.beforeDisposal'),
      accessor: (row: TableRowType<TAwaitingParcel>) => {
        const restDays = getDaysBetweenDates(null, row.data.deadline);
        return (
            <ProgressBar
                column
                label={restDays ? `${restDays} ${t('awaitingTable.days')}` : t('awaitingTable.lastDay')}
                progress={restDays / 14 * 100}
                reverseColors
            />
        );
      },
      alignment: 'left',
      width: '100px'
    },
    {
      header: t('returnsPage.awaitingTab.store'),
      accessor: (row: TableRowType<TAwaitingParcel>) => (
          <div className={styles.store}>
            <Tooltip
                action={
                  <Avatar
                      className={styles.avatar}
                      name={row.data.seller.name}
                      id={row.data.seller.id}
                  />
                }
                left={-227}
                text={row.data.seller.name}
                top={-9}
            />
          </div>
      ),
      alignment: 'center',
      noOverflow: true,
      sortable: true,
      sortableFn: sortByStores
    }
  ], [currentLanguage, columnsSort]);

  useEffect(() => {
    const columnsSort: string[] = columns.map((item: TableColumn<TAwaitingParcel>) => item.sortable ? 'asc' : '');
    setColumnsSort(columnsSort);
  }, []);

  if (isLoadingGetAddresses) {
    return (
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pt={8}>
                <Loader
                    alignment="center"
                    size="s"
                />
              </Box>
            </Column>
          </Row>
        </Grid>
    );
  }

  return (
      <div>
        {sellersNotFound ? (
            <AddSellerPanel
                isOzonSeller
                onSuccess={refreshData}
            />
        ) : (
            <>
              <Box pb={5}>
                <Row gap={6}>
                  <div
                      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                        e.preventDefault();
                        setIsOpenReturnCostWindow(true);
                      }}
                  >
                    <DocumentLink
                        icon={YuanIcon}
                        text={t('returnsPage.awaitingTab.returnCost')}
                        {...{ url: '' }}
                    />
                  </div>
                </Row>
              </Box>
              <Box
                  pb={5}
                  className={cn(!isFilterOpen && styles.hidden)}
              >
                <Column phone={12}>
                  <Row
                      justify="right"
                      gap={5}
                  >
                    <Filters
                        items={[
                          {
                            name: 'trackNumbers',
                            type: FilterTypes.MULTI_SEARCH,
                            placeholder: t('returnsPage.awaitingTab.searchByTrackNumber')
                          },
                          {
                            name: 'productCondition',
                            type: FilterTypes.STATE,
                            placeholder: t('returnsPage.awaitingTab.state')
                          },
                          {
                            name: 'sellerIds',
                            type: FilterTypes.SELLER
                          }
                        ]}
                        onChange={(v) => {
                          if (v.find(f => (f.name === 'sellerIds'))) {
                            setIsInitialized(true);
                          }
                          setInnerFilters(v);
                        }}
                        values={innerFilters}
                        asPanel
                    />
                  </Row>
                </Column>
              </Box>
              <TableSelectionPanel
                  isShown={selectedRowIds.length > 0}
                  items={[
                    {
                      label: t('returnsPage.awaitingTab.selected'),
                      value: selectedRowIds.length
                    },
                    {
                      label: t('returnsPage.awaitingTab.forTheAmount'),
                      value: `${Intl.NumberFormat().format(selectedSum)} ¥`
                    },
                    {
                      label: t('returnsPage.awaitingTab.delivery'),
                      value: `${Intl.NumberFormat().format(selectedDelivery)} ¥`
                    }
                  ]}
                  buttons={[
                    (<Button
                        disabled={!VITE_APP_DEV}
                        key="dispose"
                        onClick={refreshData}
                        size={ButtonSize.S}
                        type={ButtonType.RED_SKELETON}
                        title={t('returnsPage.awaitingTab.dispose')}
                    />),
                    (<Button
                        disabled={!VITE_APP_DEV}
                        key="resell"
                        onClick={refreshData}
                        size={ButtonSize.S}
                        type={ButtonType.GREEN_SKELETON}
                        title={t('returnsPage.awaitingTab.resell')}
                    />),
                    (<Button
                        disabled={!VITE_APP_DEV}
                        key="returnFor"
                        onClick={() => setIsOpenReturnProcessingWindow(true)}
                        size={ButtonSize.S}
                        type={ButtonType.PRIMARY}
                        title={t('returnsPage.awaitingTab.returnFor') + ` ${formatSumWithCurrency(selectedDelivery, '¥')}`}
                    />)
                  ]}
              >
                <DataTable<TAwaitingParcel>
                    Before={BeforeAwaitingTableComponent}
                    columns={columns}
                    columnsSort={columnsSort}
                    dataRef={dataRef}
                    defaultPageSize={10}
                    filters={innerFilters}
                    getData={getAwaiting}
                    notFound={t('returnsPage.awaitingTab.noParcels')}
                    onSelectionChange={handleSelectionChange}
                    pending={!isInitialized}
                    queryKey="awaitingParcels"
                    selectable
                    selectedIds={selectedRowIds}
                />
              </TableSelectionPanel>
              <Modal
                  bodyWithoutIndentations
                  isOpen={isOpenParcelWindow}
                  onClickOverlay={onCloseParcel}
                  onClose={onCloseParcel}
                  width={900}
              >
                <CardParcelModalWindow
                    callFrom="awaitingTab"
                    handlerSetIsOpenParcelWindow={setIsOpenParcelWindow}
                    handlerSetIsOpenReturnProcessingWindow={setIsOpenReturnProcessingWindow}
                    parcel={parcel!}
                />
              </Modal>
              <Modal
                  isOpen={isOpenReturnCostWindow}
                  onClickOverlay={() => setIsOpenReturnCostWindow(false)}
                  onClose={() => setIsOpenReturnCostWindow(false)}
                  width={567}
              >
                <ReturnCostModalWindow/>
              </Modal>
              <Modal
                  isOpen={isOpenReturnProcessingWindow}
                  onClickOverlay={onCloseReturnProcessing}
                  onClose={onCloseReturnProcessing}
                  width={650}
              >
                <ReturnProcessingModalWindow
                    errorSendTopUpBalance={errorSendTopUpBalance}
                    handlerOnCloseReturnProcessing={onCloseReturnProcessing}
                    handlerRefreshData={refreshData}
                    handlerSetIsOpenPaymentReturnCompletedWindow={setIsOpenPaymentReturnCompletedWindow}
                    handlerSetIsOpenPaymentReturnWindow={setIsOpenPaymentReturnWindow}
                    handlerSetPaymentReturnInProcessed={setPaymentReturnInProcessed}
                    handlerSetSubmitParams={setSubmitParams}
                    isErrorSendTopUpBalance={isErrorSendTopUpBalance}
                    isLoadingSendTopUpBalance={isLoadingSendTopUpBalance}
                    mutateSendTopUpBalance={mutateSendTopUpBalance}
                    parcel={parcel}
                    queryAddresses={queryAddresses!.addressTemplates}
                    selectedDelivery={selectedDelivery}
                    selectedParcels={selectedParcels}
                    selectedRowIds={selectedRowIds}
                />
              </Modal>
              <Modal
                  isOpen={isOpenPaymentReturnWindow}
                  onClickOverlay={onClosePaymentReturn}
                  onClose={onClosePaymentReturn}
                  width={560}
              >
                <PaymentReturnModalWindow
                    dataSendTopUpBalance={dataSendTopUpBalance!}
                    handlerOnClickPaymentLink={onClickPaymentLink}
                    isLoadingSendTopUpBalance={isLoadingSendTopUpBalance}
                    mutateSendTopUpBalance={mutateSendTopUpBalance}
                    paymentReturnError={paymentReturnError}
                    paymentReturnInProcessed={paymentReturnInProcessed}
                    selectedDelivery={selectedDelivery}
                />
              </Modal>
              <Modal
                  isOpen={isOpenPaymentReturnCompletedWindow}
                  onClickOverlay={() => setIsOpenPaymentReturnCompletedWindow(false)}
                  onClose={() => setIsOpenPaymentReturnCompletedWindow(false)}
                  width={560}
              >
                <PaymentReturnCompletedModalWindow
                    handlerSetIsOpenPaymentReturnCompletedWindow={setIsOpenPaymentReturnCompletedWindow}
                />
              </Modal>
            </>
        )}
      </div>
  );
};

export default AwaitingTab;