import cn from "classnames";
import { Text, TextColor, TextSize, TextWeight } from "@components/Text";
import DataView, { DataViewProps } from "../DataView";
import type { DataViewHandle as DataViewHandle_ } from "../DataView";
import styles from "./DataList.module.css";

export type DataViewHandle = DataViewHandle_;

interface ViewProps<T> {
  onClick?: (data: T) => void;
  getItemKey: (data: T) => string;
  fields: ListField<T>[];
}
type DataViewExtraProps<T, BP, AP> = Omit<DataViewProps<T, ViewProps<T>, BP, AP>, "View">
type DataListProps<T, BP, AP> = DataViewExtraProps<T, BP, AP> & {
  // fields: ListField<T>[],
  // onClick?: (data: T) => void;
};

export interface ListField<T> {
  label?: React.ReactNode;
  value: (data: T) => React.ReactNode;
  withBorder?: boolean;
  titleColor?: TextColor;
  width?: string;
}
interface ViewFieldProps<T> extends ListField<T> {
  data: T;
}

interface ViewItemProps<T> {
  data: T;
  onClick?: (data: T) => void;
  fields: ListField<T>[];
}

function ViewItemField<T>({
  label,
  value,
  withBorder,
  titleColor,
  width,
  data,
}: ViewFieldProps<T>) {
  return (
    <div
      className={cn(
        styles.field,
        withBorder && styles.fieldWithBorder,
        // !width && styles.fieldAutoWidth,
      )}
      style={{ width }}
    >
      <Text
        size={TextSize.XS}
        color={titleColor || TextColor.DARK_MUTED}
        weight={TextWeight.SEMIBOLD}
      >
        {label}
      </Text>
      {value ? value(data) : ""}
    </div>
  );
}

function ViewItem<T>({
  fields,
  data,
  onClick,
}: ViewItemProps<T>) {
  const Field = ViewItemField<T>;
  return (
    <div
      className={cn(
        styles.viewItem,
        onClick && styles.viewItemClickable,
      )}
      onClick={() => (onClick && onClick(data))}
    >
      {fields.map((field: ListField<T>, idx: number) => (
        // @ts-ignore: todo
        <Field
          key={`${idx}-${field.label}`}
          {...field}
          withBorder={field.withBorder ?? idx !== fields.length}
          data={data}
        />
      ))}
    </div>
  )
}

function View<T>({ data, getItemKey, ...rest }: {
  onClick?: (data: T) => void,
  getItemKey: (data: T) => string,
  data: T[],
}) {
  const Item = ViewItem<T>;
  return (
    <div className={styles.list}>
      {data.map((item: T) => (
        // @ts-ignore: todo
        <Item
          key={getItemKey(item)}
          {...rest}
          data={item}
        />
      ))}
    </div>
  )
}

function DataList<T extends { id: string }, BP=void, AP=void>({
  // fields,
  // onClick,
  // getItemKey,
  dataRef,
  ...props
}: DataListProps<T, BP, AP>) {

  const DataViewComponent = DataView<T, ViewProps<T>, BP, AP>
  const ViewComponent = View<T>

  return (
    <DataViewComponent
      {...props}
      // dataRef={dataRef}
      View={ViewComponent}
    />
  );
}

export default DataList;
