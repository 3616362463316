import styles from './TopUpBalanceFormModalWindow.module.scss';
import Box from '../../../../components/Box';
import FormErrorMessage from '../../../../components/FormErrorMessage';
import TextInputContainer from '../../../../containers/TextInputContainer';
import Title from '../../../../components/Title';
import { Button } from '../../../../components/Button';
import { Column, Grid, Row } from '../../../../components/Grid';
import { ITopUpBalanceFormModalWindowProps, TTopUpBalanceForm } from '../../types';
import { sendTopUpBalance, sendTopUpBalanceResponse } from '@api/apiClient/finance';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Loader } from '@components/Loader';
import cn from 'classnames';

const TopUpBalanceFormModalWindow: React.FC<ITopUpBalanceFormModalWindowProps> = (props: ITopUpBalanceFormModalWindowProps): React.ReactElement => {
  const {
    balanceReplenishmentError,
    balanceReplenishmentInProcessed,
    handlerOnCloseBalanceForm,
    handlerSetQrTimeout,
    handlerWaitingBalanceReplenished
  } = props;

  const [isQrOpen, setQrOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const { data, error, isError, isLoading, mutate } = useMutation({
    mutationFn: sendTopUpBalance,
    onSuccess: (result: sendTopUpBalanceResponse) => {
      handlerWaitingBalanceReplenished(result.outTradeNo);
      handlerSetQrTimeout();
      setQrOpen(true);
    }
  });

  const { control, handleSubmit, setValue, watch } = useForm<TTopUpBalanceForm>({
    defaultValues: {
      amount: ''
    },
    mode: 'onChange'
  });

  const submit = (form: TTopUpBalanceForm): void => {
    mutate({
      amount: +form.amount
    });
  };

  const amount = watch('amount');

  useEffect(() => {
    setValue('amount', amount.replace(/^0+/, '').replace(/[^0-9]+/g, ''));
  }, [amount]);

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <Title title={t(isQrOpen ? 'TopUpBalanceFormModalWindow.qrTitle' : 'TopUpBalanceFormModalWindow.title')}/>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              {isQrOpen ? (
                  <div className={styles.qrCode}>
                    <img
                        alt="qrCode"
                        className={cn(styles.qrCodeImg, balanceReplenishmentInProcessed && styles.qrCodeImgHidden)}
                        src={`data:image/svg+xml;base64,${data?.qrCode}`}
                    />
                    {balanceReplenishmentInProcessed && <Loader alignment="center" size="xl"/>}
                  </div>
              ) : (
                  <TextInputContainer
                      control={control}
                      name="amount"
                      min={0}
                      rules={{ required: t('defaultFormErrors.required') }}
                      title={t('TopUpBalanceFormModalWindow.input')}
                  />
              )}
            </Box>
          </Column>
        </Row>
        <Row
            gap={5}
            noWrap
        >
          {isQrOpen ? (
              <Box>
                <Button
                    className={styles.action}
                    disabled={balanceReplenishmentInProcessed}
                    onClick={handlerOnCloseBalanceForm}
                    title={t('TopUpBalanceFormModalWindow.cancel')}
                />
              </Box>
          ) : (
              <Box>
                <Button
                    className={styles.action}
                    onClick={handleSubmit(submit)}
                    pending={isLoading}
                    title={t('TopUpBalanceFormModalWindow.buttonSend')}
                />
              </Box>
          )}
        </Row>
        {isError && <FormErrorMessage error={error}/>}
        {balanceReplenishmentError && <FormErrorMessage error={balanceReplenishmentError}/>}
      </Grid>
  );
};

export default TopUpBalanceFormModalWindow;