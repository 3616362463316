import { TrackEvent } from "@api/apiClient";
interface TrackEventExtended extends TrackEvent {
  typeId?: string;
}

export interface EventInfo {
  id: string;
  childIds?: number[];
  ids?: number[];
}

export const events: EventInfo[] = [
  { id: "0" },
  { id: "101", childIds: [101, 150] },
  { id: "150" },
  { id: "201", childIds: [201, 250] },
  { id: "250" },
  { id: "301", childIds: [301, 350] },
  { id: "350" },
  { id: "401", childIds: [401, 404] },
  { id: "404" },
  { id: "10000", ids: [10000, 10001] },
];

export const returnEvents = [
  { id: "20000" },
  { id: "20001", childIds: [20001, 20050], },
  { id: "20050" },
  { id: "20101", childIds: [20101, 20150], },
  { id: "20150" },
  { id: "20201", childIds: [20201, 20250], },
  { id: "20250" },
  { id: "20301", childIds: [20301, 20350], },
  { id: "20350" },
  { id: "21000", ids: [21000, 21500] },
]

export const getLastTrackEvent = (trackEvents: TrackEvent[]): TrackEventExtended | null => {
  if (!trackEvents?.length) return null;
  for (let idx = events.length - 1; idx >= 0; idx--) {
    const res = trackEvents.find(v => (
      (String(v.typeCode) === events[idx].id)
      || (events[idx].ids || []).includes(v.typeCode)
    ));
    if (res) return {
      ...res,
      typeId: String(res.typeCode),
    };
  }
  return null;
}

export const getProgressByTrackEvents = (trackEvents: TrackEvent[]): number => {
  const event = getLastTrackEvent(trackEvents);
  if (!event) return 0;
  const n = events.findIndex(v => (
    (v.id === String(event.typeCode))
    || (v.ids || []).includes(event.typeCode)
  )) + 1;
  return Math.round(100 * n / events.length);
}
