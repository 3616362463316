import { Column, Row } from "@components/Grid";
import { Text, TextColor, TextType, TextWeight, TextSize } from "@components/Text";
import Box from "@components/Box";
import styles from "./ListItem.module.scss";

const ListItem = ({ index, children, title, level }: {
  index: string,
  title?: string,
  children?: React.ReactNode,
  level?: string,
}) => {
  if (level === "2") {
    return (
      <Row>
        <Column phone={12}>
          <Box pt={1}>
            <span>
              <Text
                type={TextType.INLINE}
                weight={TextWeight.NORMAL}
                size={TextSize.S}
                color={TextColor.LIGHT_BLUE}
              >
                {index}.
              </Text>
              <Text
                type={TextType.INLINE}
                weight={TextWeight.NORMAL}
                size={TextSize.S}
              >
                {children}
              </Text>
            </span>
          </Box>
        </Column>
      </Row>
    );
  }
  return (
    <Row>
      <Column phone={12}>
        <Box pb={4} flex="row">
          <div className={styles.bullet}>{index}</div>
          <Column phone={12}>
            <Text weight={TextWeight.MEDIUM} size={TextSize.L}>
              {title}
            </Text>
            {children}
          </Column>
        </Box>
      </Column>
    </Row>
  );
}

export default ListItem
