import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from "react-hook-form";
import { Modal } from "@components/Modal";
import { Button, ButtonType } from "@components/Button";
import { Grid, Column, Row } from "@components/Grid";
import { Seller } from "@api/apiClient";
import { getOzonWarhauses, getUniWarhauses, linkWarehouses, OzonWarehouse } from "@api/apiClient/fulfillment";
import { Text, TextColor, TextType } from "@components/Text";
import Box from "@components/Box";
import FormErrorMessage from "@components/FormErrorMessage";
import SelectContainer from "@containers/SelectContainer";
import ListItem from "../ListItem";
// import styles from "./AddStoreModal.module.scss";

interface FormType {
  uniStore: string,
  ozonStore?: number,
}

const AddStoreModal = ({ onClose, seller }: {
  onClose: () => void,
  seller: Seller,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const uniWarehousesQuery = useQuery({
    queryKey: ["uniWarehouses"],
    queryFn: () => getUniWarhauses(),
    retry: 1,
  });

  const ozonWarehousesQuery = useQuery({
    queryKey: ["ozonWarehouses", seller?.id ?? ""],
    queryFn: () => getOzonWarhauses(seller?.id ?? ""),
    retry: 1,
  });

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: linkWarehouses,
    onSuccess: () => {
      queryClient.invalidateQueries(
        { queryKey: ["linkedWarehouses", seller?.id ?? ""] }
      );
      onClose();
    },
  });

  const { control, handleSubmit, watch } = useForm<FormType>({
    defaultValues: {
      uniStore: "",
      ozonStore: undefined,
    },
  });

  const uniStore = watch("uniStore");
  const uniStoreName = (uniWarehousesQuery?.data?.items || [])
    .find(v => v.id === uniStore)?.name ?? ""

  const submit = (form: FormType) => {
    mutate({
      sellerId: seller?.id ?? "",
      uniWarehouseId: form.uniStore,
      ozonWarehouseId: form.ozonStore || 0,
    });
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      onClickOverlay={onClose}
      width={567}
    >
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={5} pt={5}>
              <Text type={TextType.H2} color={TextColor.PRIMARY}>
                {t("fulfillmentPage.addStore.title")}
              </Text>
            </Box>
          </Column>
        </Row>

        <ListItem index="1" title={t("fulfillmentPage.addStore.list-1")} />

        <Row>
          <Column phone={12}>
            <Box mb={5} pr={4}>
              <SelectContainer
                control={control}
                name="uniStore"
                title={t("fulfillmentPage.addStore.storeInput")}
                items={uniWarehousesQuery?.data?.items || []}
                labelOptionItem={(item) => (item?.name ?? "")}
                keyExtractor={(item) => item.id}
                rules={{
                  required: t("defaultFormErrors.required"),
                }}
                searchable
              />
            </Box>
          </Column>
        </Row>

        <ListItem index="2" title={t("fulfillmentPage.addStore.list-2")}>
          <ListItem index="1" level="2">{t("fulfillmentPage.addStore.list-2-1")}</ListItem>
          <ListItem index="2" level="2">{t("fulfillmentPage.addStore.list-2-2")}</ListItem>
          <ListItem index="3" level="2">{t("fulfillmentPage.addStore.list-2-3")}</ListItem>
          <ListItem index="4" level="2">
            {t("fulfillmentPage.addStore.list-2-4", { name: uniStoreName })}
          </ListItem>
        </ListItem>
        <ListItem index="3" title={t("fulfillmentPage.addStore.list-3")} />

        <Row>
          <Column phone={12}>
            <Box mb={5} pr={4}>
              <SelectContainer<OzonWarehouse, FormType>
                control={control}
                name="ozonStore"
                title={t("fulfillmentPage.addStore.ozonStoreInput")}
                items={ozonWarehousesQuery?.data?.items || []}
                labelOptionItem={(item) => (item?.name ?? "")}
                keyExtractor={(item) => String(item.ozonWarehouseId)}
                rules={{
                  required: t("defaultFormErrors.required"),
                }}
                searchable
                onOpen={ozonWarehousesQuery?.refetch}
                isLoading={ozonWarehousesQuery.isFetching}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Button
              pending={isLoading}
              type={ButtonType.PRIMARY}
              onClick={handleSubmit(submit)}
              title={t("fulfillmentPage.addStore.saveBtn")}
            />
          </Column>
        </Row>

        {isError && (
          <FormErrorMessage error={error} />
        )}
      </Grid>
    </Modal>
  );
}

export default AddStoreModal;
