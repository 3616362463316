import cn from "classnames";
import useDigitInput from 'react-digit-input';
import Box from "@components/Box";
import { Text, TextColor, TextSize } from "@components/Text";
import styles from "./DigitInput.module.css";

interface DigitInputProps {
  value?: string;
  onChange: (value: string) => void;
  hasError?: boolean;
  errorMessage?: string;
  name?: string;
  length: number;
  disabled?: boolean;
}

const DigitInput = (props: DigitInputProps) => {
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: props.length,
    value: props.value ?? "",
    onChange: props.onChange,
  });

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.inputContainer, {
          [styles.inputContainerError]: props.hasError,
          [styles.inputContainerDisabled]: props.disabled,
        })}
      >
        {digits.map((digit, idx) => (
          <input
            key={idx}
            className={styles.input}
            inputMode="decimal"
            autoFocus={!idx}
            {...digit}
            disabled={props.disabled}
          />
        ))}
      </div>
      {props.hasError && (
        <Box pt={3}>
          <Text size={TextSize.M} color={TextColor.ERROR}>
            {props.errorMessage}
          </Text>
        </Box>
      )}
    </div>
  );
};

export default DigitInput;
