import { sellerApiClient } from "./sellerApiClient";

export const registration = async ({ email, token }: {
  email: string,
  token: string,
}): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    "/v1/registration/send",
    { email },
    {
      headers: {
        "X-Captcha-Token": token,
      },
    },
  );

  return response.data;
};

export const signUp = async ({ email, password, countryCode, token, promoId }: {
  email: string,
  password: string,
  countryCode: string,
  token: string,
  promoId?: string,
}): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    "/v1/registration/sign-up/link",
    { email, password, countryCode, promoId },
    {
      headers: {
        "X-Captcha-Token": token,
      },
    },
  );

  return response.data;
};

export const updateUserInfo = async ({ phone, name }: {
  phone: string,
  name: string,
}): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    "/v1/accounts/me/info",
    { phone, name },
  );

  return response.data;
};

export const verifyPhone = async ({ code }: {
  code: string,
}): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    "/v1/accounts/verify-pin",
    { pinCode: code },
  );

  return response.data;
};

export const sendCode = async (phone: string): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    `/v1/accounts/send-pin`,
    { phone },
  );

  return response.data;
};

export const verifySecurityStamp = async (securityStamp: string): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    `/v1/registration/verify/security-stamp`,
    { securityStamp },
  );

  return response.data;
};

interface PromoData {
  id: string,
  name: string,
  isActive: boolean,
  maxAccounts: number,
}

export const getPromo = async (
  id: string,
): Promise<PromoData> => {
  const response = await sellerApiClient.get<PromoData>(
    `/v1/promos/${id}`,
  );
  return response.data;
};
