import styles from './PickUpCompleted.module.scss';
import Avatar from '@components/Avatar';
import moment from 'moment';
import Table from '@components/Table/Table';
import { ErrorResponse } from '@api/Responses/ErrorResponse';
import { getOneTimePickUp, PickUpOrder, PickUpParcel } from '@api/apiClient/pickUp';
import { IPickUpCompletedProps } from '../../types';
import { Seller } from '@api/apiClient';
import { TableColumn, TableRowType } from '@components/Table/TableTypes';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const PickUpCompleted: React.FC<IPickUpCompletedProps> = (props: IPickUpCompletedProps): React.ReactElement => {
  const {
    pickUpOrder: {
      address: {
        rawLine
      },
      contactName,
      contactPhone,
      id,
      sellers,
      shipmentDate,
    }
  } = props;

  const [areAllShopsVisible, setAreAllShopsVisible] = useState<boolean>(false);
  const [shopsListHeight, setShopsListHeight] = useState<string>('');

  const { t } = useTranslation();

  const shopsListItemMB = 15;

  useEffect(() => {
    const shopsListItemHeight = document.querySelector(`.${styles.shopsListItem}`)!.clientHeight;
    const limitListItems = 4;
    const desiredHeight = areAllShopsVisible || sellers.length < limitListItems
        ? 'auto'
        : (limitListItems * shopsListItemHeight + ((limitListItems - 1) * shopsListItemMB)) + 'px';

    setShopsListHeight(desiredHeight);
  }, [areAllShopsVisible]);

  const query = useQuery<PickUpOrder, ErrorResponse>({
    queryKey: ['pickUpParcels', id],
    queryFn: () => getOneTimePickUp(id),
    retry: 1
  });

  const creationDate: string = t('pickUpCompleted.creationDate');
  const externalNumber: string = t('pickUpCompleted.externalNumber');
  const shop: string = t('pickUpCompleted.shop');
  const trackNumber: string = t('pickUpCompleted.trackNumber');

  const columns: TableColumn<PickUpParcel>[] = [
    {
      header: trackNumber,
      accessor: (row: TableRowType<PickUpParcel>) => (
          <span className={styles.tableTdText}>
            {row.data.trackNumber}
          </span>
      ),
      width: '20%',
      fixWidth: true,
      noOverflow: true
    },
    {
      header: externalNumber,
      accessor: (row: TableRowType<PickUpParcel>) => (
          <span className={styles.tableTdText}>
            {row.data.externalNumber}
          </span>
      ),
      width: '20%',
      fixWidth: true,
      noOverflow: true
    },
    {
      header: shop,
      accessor: (row: TableRowType<PickUpParcel>) => (
          <div className={styles.tableTd}>
            <Avatar
                className={styles.tableTdIcon}
                id={row.data.sellerId}
                name={row.data.sellerName}
            />
            <span className={styles.tableTdText}>
              {row.data.sellerName}
            </span>
          </div>
      ),
      width: '45%',
      fixWidth: true,
      noOverflow: true
    },
    {
      header: creationDate,
      accessor: (row: TableRowType<PickUpParcel>) => (
          <span className={styles.tableTdText}>
            {moment(row.data.createdAt).format('DD.MM, HH:mm')}
          </span>
      ),
      width: '15%',
      fixWidth: true,
      noOverflow: true
    }
  ];

  return (
      <div>
        <div className={styles.row}>
          <span className={styles.title}>
            {t('pickUpCompleted.options')}
          </span>
        </div>
        <div className={styles.row}>
          <div className={styles.info}>
            <div className={styles.point}>
              <div className={styles.pointTitle}>
                {t('pickUpCompleted.address')}
              </div>
              <div className={styles.pointText}>
                {rawLine}
              </div>
            </div>
            <div className={styles.point}>
              <div className={styles.pointTitle}>
                {t('pickUpCompleted.dateAndTimePickUp')}
              </div>
              <div className={styles.pointText}>
                {moment(shipmentDate).format('DD.MM')}, 09:00-18:00
              </div>
            </div>
            <div className={styles.point}>
              <div className={styles.pointTitle}>
                {t('pickUpCompleted.shops')}
              </div>
              <div className={styles.shops}>
                <ul
                    className={`${styles.shopsList} ${areAllShopsVisible ? '' : styles.shopsListHidden}`}
                    style={{ height: shopsListHeight }}
                >
                  {sellers.map((seller: Seller, index: number) => (
                      <li
                          className={styles.shopsListItem}
                          key={seller.id}
                          style={{ marginBottom: (index !== sellers.length - 1) ? shopsListItemMB + 'px' : '' }}
                      >
                        <Avatar
                            className={styles.shopsListItemIcon}
                            id={seller.id}
                            name={seller.name}
                        />
                        {seller.name}
                      </li>
                  ))}
                </ul>
                {sellers.length > 4 && (
                    <button
                        className={styles.shopsAction}
                        onClick={() => setAreAllShopsVisible(!areAllShopsVisible)}
                    >
                      {areAllShopsVisible ? t('pickUpCompleted.hide') : `+ ${t('pickUpCompleted.more')}`}
                    </button>
                )}
              </div>
            </div>
            <div className={styles.point}>
              <div className={styles.pointTitle}>
                {t('pickUpCompleted.contactPerson')}
              </div>
              <div className={styles.pointText}>
                {contactName}
              </div>
              <div className={styles.pointText}>
                {contactPhone}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <span className={styles.title}>
            {t('pickUpCompleted.parcels')}
          </span>
        </div>
        <div className={styles.row}>
          <Table
              className={styles.table}
              columns={columns}
              data={query?.data?.parcels || ([] as PickUpParcel[])}
              rowClassName={styles.tableRow}
          />
        </div>
      </div>
  );
};

export default PickUpCompleted;
