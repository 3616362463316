import { ReactNode, useEffect, useState } from "react";
import CounterContext from "./index.ts";
import { useQuery } from "react-query";
import { getReturnsPage, ReturnTypes } from "@api/apiClient";
import { useAuth } from "../AuthenticationContext";

interface CounterProviderProps {
  children: ReactNode;
}

const CounterProvider = (props: CounterProviderProps) => {
  const [returns, setReturns] = useState(0);
  const auth = useAuth();

  const query = useQuery({
    queryKey: [
      "returns-page",
      [ReturnTypes.NotSelected],
      1,
      1,
    ],
    queryFn: () => getReturnsPage({
      filters: [{ name: "ReturnTypes", value: [ReturnTypes.NotSelected]}],
      page: { page: 1, pageSize: 1 },
    }),
    retry: 1,
    enabled: auth.isAuthenticated,
  });

  useEffect(() => {
    setReturns(query.data?.total ?? 0);
  }, [query.data?.total]);

  const update = () => {
    query.refetch();
  };

  return (
    <CounterContext.Provider value={{ returns, update }}>
      <>{props.children}</>
    </CounterContext.Provider>
  );
};

export default CounterProvider;
