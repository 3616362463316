import "./Loader.css";
import cn from "classnames";

interface LoaderProps {
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
  alignment?: "left" | "right" | "center";
  dark?: boolean;
}

const Loader = (props: LoaderProps) => {
  const isDark = props.dark ?? props.size !== "xs";
  return (
    <div className={cn("loader-wrapper", {
      "loader-wrapper--left": props.alignment === "left",
      "loader-wrapper--right": props.alignment === "right",
      "loader-wrapper--center": !props.alignment || props.alignment === "center",
    })}>
      <div
        className={cn("loader", {
          "loader--xs": props.size === "xs",
          "loader--s": props.size === "s",
          "loader--m": props.size === "m",
          "loader--l": props.size === "l",
          "loader--xl": props.size === "xl",
          "loader--xxl": props.size === "xxl",
          "loader--light": !isDark,
        })}
      />
    </div>
  );
};

export default Loader;
