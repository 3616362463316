import { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { TabsList, TabsContent } from "@components/Tabs/Tabs";
import Page from "@components/Page";
import OrdersTab from "./tabs/OrdersTab";
import ReadyTab from "./tabs/ReadyTab";
import FilterIcon from "@assets/icons/filter.svg";
import { useSellersFound } from "@api/apiClient";
import { getPickUpParcels, getPickUpOrders } from "@api/apiClient/pickUp";
import useTab from "@hooks/useTab";
import styles from "./pickUpPage.module.css";

const ParcelsPage = (props: { isNew: boolean }) => {
  const { isNew } = props;
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const { sellersNotFound } = useSellersFound();

  const { data: parcelsTotal, refetch: parcelRefetch } = useQuery({
    queryKey: ["pickUpParcels", { page: 1, pageSize: 10 }],
    queryFn: () => getPickUpParcels({
      page: { page: 1, pageSize: 10 },
    }),
    retry: 1,
    select: ({ total }) => total,
  });

  const { data: ordersTotal, refetch: ordersRefetch } = useQuery({
    queryKey: ["pickUpOrders", { page: 1, pageSize: 10 }],
    queryFn: () => getPickUpOrders({
      page: { page: 1, pageSize: 10 },
    }),
    retry: 1,
    select: ({ total }) => total,
  });

  const onUpdate = () => {
    parcelRefetch();
    ordersRefetch();
  }

  const tabs = [
    {
      id: "ReadyTab",
      title: t("pickUp.tabReady.title"),
      content: (
        <ReadyTab
          isFilterOpen={isFilterOpen}
          isNew={isNew}
          onUpdate={onUpdate}
          sellersNotFound={sellersNotFound}
        />
      ),
      count: parcelsTotal ?? 0,
    },
    {
      id: "OrdersTab",
      title: t("pickUp.tabOrders.title"),
      content: (
        <OrdersTab
          isFilterOpen={isFilterOpen}
          isNew={isNew}
          onUpdate={onUpdate}
          sellersNotFound={sellersNotFound}
        />
      ),
      count: ordersTotal ?? 0,
      disable: sellersNotFound,
    },
  ];
  const { activeTab, setActiveTab } = useTab(tabs);

  return (
    <Page title={t("pickUp.title")}>
      <div className={styles.tabsRow}>
        <TabsList
          tabs={tabs}
          onChange={setActiveTab}
          value={activeTab}
        />
        {!sellersNotFound && (
          <div className={styles.filters}>
            <Button
              className={cn(isFilterOpen ? styles.filterBtnActive : styles.filterBtnInactive)}
              icon={FilterIcon}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              size={ButtonSize.S}
              type={ButtonType.ROUNDED}
              title={t("pickUp.filters")}
            />

            {(activeTab === "ReadyTab") && false ? (
              <Button
                onClick={() => {}}
                size={ButtonSize.S}
                type={ButtonType.LINK}
                title={t("pickUp.selectAllParcels")}
              />
            ) : null}
          </div>
        )}
      </div>

      <TabsContent
        tab={tabs.find((tab) => (tab.id === activeTab))}
      />
    </Page>
  );
};

export default ParcelsPage;
