import { Text, TextWeight, TextSize } from "@components/Text";
import styles from "./InfoPanel.module.scss";

interface Item {
  title: string,
  text: React.ReactNode,
}

const InfoPanel = ({ items, actions }: {
  items: Item[],
  actions?: React.ReactNode[],
}) => {
  return (
    <div className={styles.root}>
      {items.map((item) => (
        <div className={styles.column}>
          <Text weight={TextWeight.BOLD} size={TextSize.M}>
            {item.title}
          </Text>
          <Text weight={TextWeight.NORMAL} size={TextSize.S}>
            {item.text}
          </Text>
        </div>
      ))}
      <div className={styles.actions}>
        {(actions || []).map((item) => (
          <>{item}</>
        ))}
      </div>
    </div>
  );
}

export default InfoPanel
