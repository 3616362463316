import { createContext, useContext } from "react";

interface AuthenticationContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (data: { username: string, password: string, emailSeller?: string }) => Promise<void>;
  logout: () => void;
}

const AuthenticationContext = createContext<AuthenticationContextType | null>(
  null,
);

export const useAuth = (): AuthenticationContextType => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error("useAuth must be used within an ProfileProvider");
  }
  return context;
};

export default AuthenticationContext;
export { default as AuthenticatedProvider } from "./AuthenticatedProvider";
