import { useRef, useState } from "react";
import { getCountryCallingCode, Country } from "react-phone-number-input/input";
import "./CountrySelect.css";
import { CSSTransition } from "react-transition-group";
import useOutsideClick from "@hooks/useOutsideClick";

interface CountryType {
  value?: Country,
  label?: string,
}

interface CountrySelectProps {
  value?: string;
  name?: string;
  onChange: (value?: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  options: CountryType[];
  iconComponent: (props: { country?: string }) => React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  tabIndex?: number | string;
  className: string;
}

const CountrySelect = (props: CountrySelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick<HTMLDivElement>(dropdownRef, () => setIsOpen(false));

  const Flag = props.iconComponent

  const handleSelect = (item: CountryType) => {
    props.onChange(item.value);
    setIsOpen(false);
  };

  return (
    <div className="country-select" ref={dropdownRef}>
      <div
        className="country-select__selected"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Flag country={props.value} />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="country-select__menu"
        mountOnEnter
        unmountOnExit
      >
        <div className="country-select__menu">
          {props.options.map((item) => (
            item.value ? (
              <div
                className="country-select__item"
                key={item.value}
                onClick={() => handleSelect(item)}
              >
                <Flag country={item.value} />
                +{getCountryCallingCode(item.value)}
                <span>{item.label}</span>
              </div>
            ) : (
              <div
                className="country-select__item country-select__item--separator"
                key={item.value}
              />
            )
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default CountrySelect;
