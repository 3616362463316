import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useQuery } from "react-query";
import { getNews, getImportantNews, NewsItem } from "@api/apiClient/news";
import { Grid, Row, Column } from "@components/Grid";
import { Text, TextSize, TextType } from "@components/Text";
import { Loader } from "@components/Loader";
import InfoPanel, { InfoPanelType } from "@components/InfoPanel";
import Page from "@components/Page";
import Box from "@components/Box";
import styles from "./NewsPage.module.css";

const NewsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const newsId = searchParams.get("newsId");
  const importantNewsId = searchParams.get("importantNewsId");

  const query = useQuery({
    queryKey: ["news"],
    queryFn: () => getNews(),
    retry: 1,
    enabled: !importantNewsId,
  });

  const queryImportant = useQuery({
    queryKey: ["important-news"],
    queryFn: () => getImportantNews(),
    retry: 1,
    enabled: Boolean(importantNewsId),
  });

  const items: NewsItem[] = (query?.data || []).filter((v: NewsItem) => (
    v.lang === currentLanguage.toUpperCase()
  ));
  const itemsImportant: NewsItem[] = (queryImportant?.data || []).filter((v: NewsItem) => (
    v.lang === currentLanguage.toUpperCase()
  ));
  const currentItem = importantNewsId
    ? itemsImportant.find(v => v.id === importantNewsId)
    : newsId
      ? items.find(v => v.id === newsId)
      : null;

  useEffect(() => {
    // item not found - redirect to news page
    if (newsId && query.isFetched && !currentItem) {
      navigate("/news");
    }
    if (importantNewsId && queryImportant.isFetched && !currentItem) {
      navigate("/news");
    }
  }, [query.isFetched, newsId, currentItem])

  if (query.isFetching || queryImportant.isFetching) {
    return (
      <Page title={t("news.news")}>
        <Grid>
          <Row>
            <Column phone={12}>
              <Loader alignment="center" size="s" />
            </Column>
          </Row>
        </Grid>
      </Page>
    )
  }

  if (currentItem) {
    return (
      <Page title={currentItem.title} backLink="/news">
        <div className={styles.newsDetail}>
          <div
            dangerouslySetInnerHTML={{
              __html: currentItem.content,
            }}
          />
        </div>
      </Page>
    );
  }

  if (!items.length && !itemsImportant.length) {
    return (
      <Page title={t("news.news")}>
        <Box>
          <InfoPanel
            title={t("news.noNews")}
            type={InfoPanelType.INFO}
          />
        </Box>
      </Page>
    );
  }

  return (
    <Page title={t("news.news")}>
      <div className={styles.items}>
        {[...itemsImportant, ...items].map((item) => (
          <Link
            key={item.id}
            className={styles.item}
            to={item.link}
          >
            <img className={styles.img} src={item.img} alt="" />
            <Text size={TextSize.S} type={TextType.BLOCK}>
              {item.title}
            </Text>
            <Text size={TextSize.XS} type={TextType.BLOCK}>
              {item.description}
            </Text>
          </Link>
        ))}
      </div>
    </Page>
  );
};

export default NewsPage;
