import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Modal } from "@components/Modal";
import TextInput from "@components/TextInput/TextInput.tsx";
import TextAreaContainer from "@containers/TextAreaContainer";
import { Button, ButtonType } from "@components/Button";
import { Grid, Column, Row } from "@components/Grid";
import FormErrorMessage from "@components/FormErrorMessage";
import { Text, TextColor, TextType } from "@components/Text";
import Box from "@components/Box";
import {
  updateBunch,
  validateVendorCodes,
  createBunch,
  removeBunch,
  BunchItem,
  UpdateBunchData,
} from '@api/apiClient/bunch';
import IconCross from "@assets/icons/cross-grey-12.svg";
import IconCrossPink from "@assets/icons/cross-pink-16.svg";
import styles from "./EditBunchModal.module.scss";

interface FormType {
  name: string,
}

const CreateRequestModal = ({ data, onSuccess, onClose }: {
  data: BunchItem | null,
  onClose: () => void,
  onSuccess: () => void,
}) => {
  const { t } = useTranslation();
  const [articles, setArticles] = useState<string[]>(data?.vendorCodeValues || []);
  const [newArticle, setNewArticle] = useState<string>("");
  const [articlesError, setArticlesError] = useState<string>("");
  const [articlesErrorItems, setArticlesErrorItems] = useState<string[]>([]);
  const [noArticlesError, setNoArticlesError] = useState<boolean>(false);
  const isNew = !data?.id;

  const { control, handleSubmit } = useForm<FormType>({
    defaultValues: {
      name: data?.name || "",
    },
  });

  const validateMutation = useMutation({
    mutationFn: (data: {
      id: string,
      vendorCodeValues: string[],
    }) => validateVendorCodes(data),
  });

  const createMutation = useMutation({
    mutationFn: createBunch,
    onSuccess: () => {
      onSuccess();
    },
  });

  const removeMutation = useMutation({
    mutationFn: () => removeBunch(data?.id ?? ""),
    onSuccess: () => {
      onSuccess();
    },
  });

  const updateBunchMutation = useMutation({
    mutationFn: (data: UpdateBunchData) => updateBunch(data),
  });

  useEffect(() => {
    if (updateBunchMutation.isSuccess) {
      onSuccess();
    }
  }, [updateBunchMutation.isSuccess])

  const submit = (form: FormType) => {
    if (!articles.length) {
      setNoArticlesError(true);
      return;
    }
    if (isNew) {
      createMutation.mutate({
        vendorCodeValues: articles,
        name: form.name,
      });
    } else {
      const codesToAdd = articles.filter(v => !(data?.vendorCodeValues ?? []).includes(v));
      const codesToremove = (data?.vendorCodeValues ?? []).filter(v => !articles.includes(v));

      updateBunchMutation.mutate({
        id: data?.id ?? "",
        newName: form.name,
        addedVendorCodeValues: codesToAdd,
        removedVendorCodeValues: codesToremove,
      });
    }
  };

  const handleAddCodes = () => {
    const newArticlesArray = newArticle.trim().split(/[,\s]+/g);
    const articlesTooLong = newArticlesArray.filter(v => (v.length > 30));
    if (articlesTooLong.length) {
      setArticlesError("vendor-codes-too-long");
      setArticlesErrorItems(articlesTooLong);
      return;
    }
    validateMutation.mutate({
      id: data?.id ?? "",
      vendorCodeValues: newArticlesArray,
    }, {
      onSuccess: () => {
        setArticles([
          ...(new Set([
            ...articles,
            ...newArticlesArray,
          ])),
        ].slice(0, 50));
        setNewArticle("");
        setNoArticlesError(false);
      },
      onError: () => {
        setNoArticlesError(false);
      },
    });
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      onClickOverlay={onClose}
      width={567}
    >
      <Grid>
        <Row>
          <Column phone={12}>
            <Box>
              <Text type={TextType.H2} color={TextColor.PRIMARY}>
                {t("bunchPage.editBunchModal.title")}
              </Text>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={5}>
              <TextAreaContainer
                control={control}
                name="name"
                maxLength={400}
                rows={4}
                title={t('bunchPage.editBunchModal.name')}
                rules={{
                  required: t("defaultFormErrors.required"),
                }}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={8} flex="row">
              <Box pr={5}>
                <TextInput
                  value={newArticle}
                  onChange={(v) => {
                    setNewArticle(v);
                    validateMutation.reset();
                    setArticlesError("");
                    setArticlesErrorItems([]);
                    setNoArticlesError(false);
                  }}
                  name="newArticle"
                  title={t("bunchPage.editBunchModal.articles")}
                  hasError={validateMutation.isError || Boolean(articlesError)}
                />
              </Box>

              <Button
                type={ButtonType.PRIMARY}
                onClick={handleAddCodes}
                title={t("bunchPage.editBunchModal.addBtn")}
                disabled={!newArticle || validateMutation.isLoading || validateMutation.isError || Boolean(articlesError)}
                pending={validateMutation.isLoading}
              />
            </Box>
          </Column>
        </Row>
        {validateMutation.isError && (
          <FormErrorMessage
            error={validateMutation.error}
            context={{ codes: (data: Record<string, string>) => (
              data.codes || ""
            )}}
          />
        )}
        {(!validateMutation.isError && articlesError) ? (
          <Text color={TextColor.ERROR}>
            {t(
              `bunchPage.editBunchModal.${articlesError}`,
              { codes: articlesErrorItems.join(", ") },
            )}
          </Text>
        ) : null}

        <Row>
          <Column phone={12}>
            <Box pt={5}>
              <div className={styles.articles}>
                {articles.map((item) => (
                  <div
                    key={item}
                    className={styles.article}
                  >
                    <a href={`https://www.ozon.ru/product/${item}/`}>
                      {item}
                    </a>
                    <img
                      src={IconCross}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setArticles(articles.filter(v => (v !== item)));
                        return false;
                      }}
                    />
                  </div>
                ))}
              </div>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={8} className={styles.btns}>
              {data?.id ? (
                <Button
                  pending={removeMutation.isLoading}
                  type={ButtonType.CANCEL}
                  onClick={() => removeMutation.mutate()}
                  icon={IconCrossPink}
                  title={t("bunchPage.editBunchModal.removeBtn")}
                />
              ) : (
                <Button
                  type={ButtonType.CANCEL}
                  onClick={onClose}
                  title={t("bunchPage.editBunchModal.cancelBtn")}
                />
              )}

              <Button
                pending={createMutation.isLoading || updateBunchMutation.isLoading}
                type={ButtonType.PRIMARY}
                onClick={handleSubmit(submit)}
                title={t("bunchPage.editBunchModal.saveBtn")}
              />
            </Box>
          </Column>
        </Row>

        {noArticlesError && (
          <FormErrorMessage error={t("bunchPage.editBunchModal.noArticlesError")} />
        )}
        {createMutation.isError && (
          <FormErrorMessage error={createMutation.error} />
        )}
        {updateBunchMutation.isError && (
          <FormErrorMessage error={updateBunchMutation.error} />
        )}
      </Grid>
    </Modal>
  );
}

export default CreateRequestModal;
