import "./Grid.css";

interface ContainerProps {
  children: React.ReactNode;
}

const Grid = ({ children }: ContainerProps) => (
  <div className="grid">{children}</div>
);

export default Grid;
