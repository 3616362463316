import { useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Ru from "@assets/icons/russia.png";
import Cn from "@assets/icons/china.png";
import Tr from "@assets/icons/turkey.png";
import En from "@assets/icons/united-kingdom.png";
import Dropdown from "@components/Dropdown";
import { getLanguageId, Langs } from "@utils/lang";
import "./LanguageSwitcherContainer.css";

const langs = {
  en: { icon: En, name: "EN" },
  ru: { icon: Ru, name: "RU" },
  tr: { icon: Tr, name: "TR" },
  cn: { icon: Cn, name: "CN" },
};

const langOptions = [
  { id: Langs.en },
  { id: Langs.ru },
  { id: Langs.cn },
  { id: Langs.tr }
]

const OptionComponent = ({ id, className }: {
  id: Langs,
  className?: string,
}) => (
  <div className={cn(className, "language-switcher-item")}>
    <img src={langs[id]?.icon} alt={id} />
    <div>{langs[id]?.name}</div>
  </div>
)

const ValueComponent = ({ id }: {
  id: Langs,
}) => (
  <OptionComponent id={id} className="language-switcher-value" />
)

const LanguageSwitcherContainer = () => {
  const { i18n } = useTranslation();

  const currentLanguage = getLanguageId(i18n.language);

  const handleChange = (value: Langs) => {
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, []);

  return (
    <Dropdown<Langs>
      onChange={handleChange}
      OptionComponent={OptionComponent}
      ValueComponent={ValueComponent}
      defaultOption={{ id: currentLanguage }}
      options={langOptions}
    />
  );
};

export default LanguageSwitcherContainer;
