import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import Grid from "../../components/Grid/Grid.tsx";
import Row from "../../components/Grid/Row.tsx";
import Column from "../../components/Grid/Column.tsx";
import Box from "../../components/Box/Box.tsx";
import Title from "../../components/Title/Title.tsx";
import TextInputContainer from "../../containers/TextInputContainer.tsx";
import { Button, ButtonSize, ButtonType } from "../../components/Button";
import { Text, TextColor, TextSize, TextType } from "../../components/Text";
import { Link } from "react-router-dom";
import StrengthPasswordContainer from "../../containers/StrengthPasswordContainer.tsx";
import { getPasswordStrength } from "../../utils/passwordStrength.ts";

export interface RegistrationFormType {
  company: string;
  name: string;
  phone: string;
  email: string;
  password: string;
}

interface RegistrationFormProps {
  companyName: string;
  phone: string;
  email: string;
  name: string;
  onSubmit: (form: RegistrationFormType) => void;
  pending: boolean;
}

const RegistrationForm = (props: RegistrationFormProps) => {
  const { handleSubmit, control } = useForm<RegistrationFormType>({
    mode: "onChange",
    defaultValues: {
      company: props.companyName,
      name: props.name,
      phone: props.phone,
      email: props.email,
      password: "",
    },
  });

  const { t } = useTranslation();

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={6}>
            <Title title={t("registrationForm.title")} />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={6}>
            <Text
              size={TextSize.M}
              type={TextType.BLOCK}
              color={TextColor.DISABLED}
            >
              {t("registrationForm.description")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <TextInputContainer
              control={control}
              name={"company"}
              title={t("registrationForm.company")}
              rules={{ required: t("defaultFormErrors.required") }}
              disabled={true}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <TextInputContainer
              control={control}
              name={"name"}
              title={t("registrationForm.name")}
              rules={{ required: t("defaultFormErrors.required") }}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <TextInputContainer
              control={control}
              name="phone"
              title={t("registrationForm.phone")}
              rules={{ required: t("defaultFormErrors.required") }}
              type="phone"
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <TextInputContainer
              control={control}
              name="email"
              title={t("registrationForm.email")}
              rules={{ required: t("defaultFormErrors.required") }}
              type="email"
              disabled={true}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={0}>
            <TextInputContainer
              control={control}
              name="password"
              title={t("registrationForm.password")}
              rules={{
                required: t("defaultFormErrors.required"),
                validate: (value) =>
                  getPasswordStrength(value) > 8 && value !== ""
                    ? true
                    : t("registrationForm.lowPasswordStrength"),
              }}
              type="password"
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={10}>
            <StrengthPasswordContainer name={"password"} control={control} />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Button
              title={t("registrationForm.button")}
              type={ButtonType.BLOCK}
              size={ButtonSize.S}
              onClick={handleSubmit(props.onSubmit)}
              pending={props.pending}
            />
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Text
              size={TextSize.S}
              type={TextType.BLOCK}
              color={TextColor.DISABLED}
            >
              <Trans i18nKey="registrationForm.policy">
                Регистрируясь, вы подтверждаете, что прочитали и согласны с
                <Link to={""}> пользовательским соглашением </Link> и
                <Link to={""}>политикой конфиденциальности</Link>
              </Trans>
            </Text>
          </Box>
        </Column>
      </Row>
    </Grid>
  );
};

export default RegistrationForm;
