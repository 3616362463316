import "./Grid.css";

interface ColumnProps {
  phone?: number;
  tablet?: number;
  desktop?: number;
  largeDesktop?: number;
  children: React.ReactNode;
}

const Column = ({
  phone,
  tablet,
  desktop,
  largeDesktop,
  children,
}: ColumnProps) => {
  const baseClass = `column column--phone-${phone}`;
  const tabletClass = tablet ? ` column--tablet-${tablet}` : "";
  const desktopClass = desktop ? ` column--desktop-${desktop}` : "";
  const largeDesktopClass = largeDesktop
    ? ` column--large-desktop-${largeDesktop}`
    : "";
  return (
    <div
      className={`${baseClass}${tabletClass}${desktopClass}${largeDesktopClass}`}
    >
      {children}
    </div>
  );
};

export default Column;
