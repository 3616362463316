import styles from './PickUpEditingOptions.module.scss';
import getFullAddress from '@utils/getFullAddress';
import moment, { Moment } from 'moment';
import PickUpForm from '../PickUpForm/PickUpForm';
import FormErrorMessage from "@components/FormErrorMessage";
import PickUpOrderActions from '../PickUpOrderActions/PickUpOrderActions';
import { addAddresses, getAddresses } from '@api/apiClient';
import { changeOneTimePickUpOptions, getOneTimePickUp, PickUpOrder, PickUpOrderForm } from '@api/apiClient/pickUp';
import { IPickUpEditingOptionsProps, TAddressApplication } from '../../types';
import { getShipmentDates } from '@utils/getChineseDate';
import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const PickUpEditingOptions: React.FC<IPickUpEditingOptionsProps> = (props: IPickUpEditingOptionsProps): React.ReactElement => {
  const {
    currentTypeModalWindow,
    handlerRefreshPickUpOrders,
    handlerSetCurrentTypeModalWindow,
    handlerSetIsOpenPickUpCancelWindow,
    handlerSetIsOpenPickUpOrderWindow,
    handlerSetPickUpOrder,
    isNew,
    pickUpOrder: {
      address,
      comment,
      contactName,
      contactPhone,
      id,
      sellers,
      shipmentDate
    }
  } = props;

  const [rememberAddress, setRememberAddress] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<number>(0);
  const { t } = useTranslation();
  const shipmentDates = useMemo(() => getShipmentDates(isNew), []);

  const query = useQuery({
    queryKey: ['addresses'],
    queryFn: () => getAddresses(),
    retry: 1
  });

  const handlerSetSelectedDate = (): void => {
    setSelectedDate(shipmentDates.findIndex((item: Moment) =>
        item.format('DD.MM') === moment(shipmentDate).format('DD.MM'))
    );
  };

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: changeOneTimePickUpOptions,
    onSuccess: () => {
      handlerSetCurrentTypeModalWindow('order');
      handlerRefreshPickUpOrders();
      getOneTimePickUp(id)
        .then((item: PickUpOrder) => {
          const pickUp: PickUpOrderForm = {
            address: {
              area: item.address.area,
              city: item.address.city,
              cityDistrict: item.address.cityDistrict,
              country: item.address.country,
              countryCode: item.address.countryCode,
              flat: item.address.flat,
              house: item.address.house,
              rawLine: item.address.rawLine,
              region: item.address.region,
              settlement: item.address.settlement,
              street: item.address.street,
              zipCode: item.address.zipCode
            },
            comment: item.comment,
            contactName: item.contactName,
            contactPhone: item.contactPhone,
            createdAt: item.createdAt,
            id: item.id,
            parcelsCount: item.parcels.length,
            sellers: sellers,
            shipmentDate: item.shipmentDate,
            status: item.status
          }

          handlerSetPickUpOrder(pickUp);
        })
    },
  });

  useEffect(() => handlerSetSelectedDate(), []);

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    watch
  } = useForm<TAddressApplication>({
    defaultValues: {
      address,
      comment,
      name: contactName,
      phone: contactPhone
    },
    mode: 'onChange'
  });

  const submit = (form: TAddressApplication): void => {
    const country = t(
        `countries.${form.address.countryCode}`,
        { defaultValue: form.address.countryCode || '' }
    );
    const address = {
      area: form.address.area!,
      city: form.address.city!,
      cityDistrict: form.address.cityDistrict!,
      country,
      countryCode: form.address.countryCode!,
      flat: form.address.flat!,
      house: form.address.house!,
      rawLine: getFullAddress({ ...form.address, country }),
      region: form.address.region!,
      settlement: form.address.settlement!,
      street: form.address.street!,
      zipCode: form.address.zipCode!
    };
    const data = {
      address: address,
      comment: form.comment,
      contactName: form.name!,
      contactPhone: form.phone!,
      id,
      shipmentDate: moment(shipmentDates[selectedDate]).format('YYYY-MM-DD')
    };

    mutate(data);
    if (rememberAddress) addAddresses(address);
  };

  const formProps = {
    addressTemplates: query.data?.addressTemplates || [],
    clearErrors,
    control,
    defaultAddress: {
      address: address,
      id: ''
    },
    isThisEditing: true,
    rememberAddress,
    isLoading,
    error: isError ? error : null,
    selectedDate,
    setRememberAddress,
    setSelectedDate,
    setValue,
    shipmentDates,
    watch
  };

  const actionsProps = {
    currentTypeModalWindow,
    handlerSetCurrentTypeModalWindow,
    handlerSetIsOpenPickUpCancelWindow,
    handlerSetIsOpenPickUpOrderWindow,
    handlerSetSelectedDate,
    handleSubmit: handleSubmit(submit),
    isLoading,
    error: isError ? error : null,
    reset,
  };

  return (
      <>
        <div className={styles.row}>
          <div className={styles.options}>
            <PickUpForm {...formProps}/>
          </div>
        </div>
        <div className={styles.row}>
          <PickUpOrderActions {...actionsProps}/>
        </div>
        {isError ? (
          <FormErrorMessage
            error={error}
            errorPrefix="pickUpErrors"
          />
        ) : null}
      </>
  );
};

export default PickUpEditingOptions;
