import { Address } from './index';
import { AxiosResponse } from 'axios';
import { FilterModel } from '@hooks/useFiltersSearchParams';
import { getParams, sellerApiClient } from '@api/apiClient/sellerApiClient';
import { PaginationModel } from '@hooks/usePagingSearchParams';
import { TPhotosData } from '../../pages/ReturnsPage/types';
import { useQuery } from 'react-query';

const defaultParams: PaginationModel = { page: 1, pageSize: 1 };

export type TRequestParams = {
  filters?: FilterModel[];
  page: PaginationModel;
};

export type TAwaitingParcel = {
  deadline: Date;
  directStreamProvider: string;
  id: string;
  priceCny: number;
  priceRub: number;
  productCondition: string;
  reexportPrice: {
    amount: number;
    currency: string;
  };
  returnPostingNumber: string;
  returnTrackingNumber: string;
  seller: {
    id: string;
    name: string;
  };
  title: string;
  url: string;
};

export type TAwaitingParcelsResponse = {
  total: number;
  items: TAwaitingParcel[];
};

export type TDisposalParcel = any;

export type TDisposalParcelsResponse = {
  total: number;
  items: TDisposalParcel[];
};

export type TProcessingParcel = {
  auditObtainedDate: Date;
  changingReturnTypeBy: string;
  comments: {
    createdAt: Date;
    id: string;
    text: string;
  }[];
  createdAt: Date;
  dimensions: {
    height: {
      unit: string;
      value: number;
    };
    length: {
      unit: string;
      value: number;
    };
    width: {
      unit: string;
      value: number;
    };
  };
  directStreamProvider: string;
  events: {
    createdAt: Date;
    newStatus: number;
  }[];
  hasBattery: boolean;
  id: string;
  paidDate: Date;
  priceCny: number;
  priceRub: number;
  productCondition: string;
  quantity: {
    value: number;
  };
  reexportPrice: {
    amount: number;
    currency: string;
  };
  returnOrderNumber: string;
  returnPhotos: TPhotosData;
  returnPostingNumber: string;
  returnReason: string;
  returnType: number;
  seller: {
    address: string;
    email: string;
    id: string;
    marketplaceName: string;
    name: string;
  };
  status: string;
  title: string;
  trackingNumber: string;
  url: string;
  vendorCode: string;
  weight: {
    unit: string;
    value: number;
  };
};

export type TProcessingParcelsResponse = {
  total: number;
  items: TProcessingParcel[];
};

export type TResaleParcel = any;

export type TResaleParcelsResponse = {
  total: number;
  items: TResaleParcel[];
};

export type TReturnsParcel = any;

export type TReturnsParcelsResponse = {
  total: number;
  items: TReturnsParcel[];
};

export type TCountryTariff = {
  country: string;
  directStreamProviderType: string;
  itemCost: {
    amount: number;
    currency: string;
  };
  kilogramCost: {
    amount: number;
    currency: string;
  };
};

export type TReturnCost = {
  countryTariffs: TCountryTariff[];
};

export type TReturnCostResponse = TReturnCost;

export type TStatus = {
  id: string;
  name: string;
};

export type TStatusesResponse = TStatus[];

export type TState = {
  id: string;
  name: string;
};

export type TStatesResponse = TState[];

export type TParcel = {
  item: {
    auditObtainedDate: Date;
    changingReturnTypeBy: string;
    checkingPhotos: TPhotosData;
    comments: {
      createdAt: Date;
      id: string;
      text: string;
    }[];
    createdAt: Date;
    dimensions: {
      height: {
        unit: string;
        value: number;
      };
      length: {
        unit: string;
        value: number;
      };
      width: {
        unit: string;
        value: number;
      };
    };
    directStreamProvider: string;
    events: {
      createdAt: Date;
      newStatus: number;
    }[];
    hasBattery: boolean;
    id: string;
    paidDate: Date;
    priceCny: number;
    priceRub: number;
    productCondition: string;
    quantity: {
      value: number;
    };
    reexportPrice: {
      amount: number;
      currency: string;
    };
    returnOrderNumber: string;
    returnPhotos: TPhotosData;
    returnPostingNumber: string;
    returnReason: string;
    returnType: number;
    seller: {
      address: string;
      email: string;
      id: string;
      marketplaceName: string;
      name: string;
    };
    status: string;
    title: string;
    trackingNumber: string;
    url: string;
    vendorCode: string;
    weight: {
      unit: string;
      value: number;
    };
  }
};

export type TParcelResponse = TParcel;

export const getAwaiting = async (params: TRequestParams): Promise<TAwaitingParcelsResponse> => {
  const { filters, page } = params;
  const response = await sellerApiClient.get<TAwaitingParcelsResponse>('/v1/customer-returns/waitingfordecision/page', {
    params: getParams(page, filters),
    paramsSerializer: { indexes: null }
  });
  return response.data;
};

export const getDisposal = async (params: TRequestParams): Promise<TDisposalParcelsResponse> => {
  const { filters, page } = params;
  const response = await sellerApiClient.get<TDisposalParcelsResponse>('/v1/customer-returns/page', {
    params: getParams(page, filters),
    paramsSerializer: { indexes: null }
  });
  return response.data;
};

export const getProcessing = async (params: TRequestParams): Promise<TProcessingParcelsResponse> => {
  const { filters, page } = params;
  const response = await sellerApiClient.get<TProcessingParcelsResponse>('/v1/customer-returns/page', {
        params: getParams(page, filters),
        paramsSerializer: { indexes: null }
      }
  );
  return response.data;
};

export const getResale = async (params: TRequestParams): Promise<TResaleParcelsResponse> => {
  const { filters, page } = params;
  const response = await sellerApiClient.get<TResaleParcelsResponse>('/v1/customer-returns/page', {
        params: getParams(page, filters),
        paramsSerializer: { indexes: null }
      }
  );
  return response.data;
};

export const getReturns = async (params: TRequestParams): Promise<TReturnsParcelsResponse> => {
  const { filters, page } = params;
  const response = await sellerApiClient.get<TReturnsParcelsResponse>('/v1/customer-returns/page', {
    params: getParams(page, filters),
    paramsSerializer: { indexes: null }
  });
  return response.data;
};

export const getReturnCost = async (): Promise<TReturnCostResponse> => {
  const response = await sellerApiClient.get<TReturnCostResponse>('/v1/customer-returns/countryTariffs');
  return response.data;
};

export const getStatuses = async (): Promise<TStatusesResponse> => {
  // const response = await sellerApiClient.get<TStatusesResponse>('/v1/statuses');
  // return response.data;
  return ([
    { id: 'InProcess', name: 'InProcess' },
    { id: 'MovingToWarehouse', name: 'MovingToWarehouse' }
  ]);
};

export const getStates = async (): Promise<TStatesResponse> => {
  // const response = await sellerApiClient.get<TStatesResponse>('/v1/states');
  // return response.data;
  return ([
    { id: 'Bad', name: 'Bad' },
    { id: 'Good', name: 'Good' }
  ]);
};

export const getParcel = async (parcelId: string): Promise<TParcelResponse> => {
  const response = await sellerApiClient.get<TParcelResponse>(`/v1/customer-returns/customer-return?customerReturnId=${parcelId}`);
  return response.data;
};

export const getParcelPhoto = async (storageKey: string): Promise<AxiosResponse> => {
  return await sellerApiClient.get(`/v1/customer-returns/files/${storageKey}`, {
    responseType: 'blob'
  });
};

export type TReturnOrderParams = {
  address: Address;
  comment: string;
  contactInfo: {
    name: string;
    phone: string;
  };
  customerReturnIds: string[];
  rawLineEnglish: string;
};

export type TReturnOrderResponse = {
  reexportOrderId: string;
};

export const sendReturnOrder = async (params: TReturnOrderParams): Promise<TReturnOrderResponse> => {
  const response = await sellerApiClient.post<TReturnOrderResponse>('/v1/customer-returns/re-export/order', {
    params: params
  });
  return response.data;
};

export type TCheckReexportOrderStatusResponse = {
  id: string;
  reason?: string;
  status: string;
};

export const checkReexportOrderStatus = async (reexportOrderId: string): Promise<TCheckReexportOrderStatusResponse> => {
  const response = await sellerApiClient.get(`/v1/customer-returns/re-export/order/${reexportOrderId}/status`);
  return response.data;
};

export const useAwaitingCounter = () => {
  const { data, refetch } = useQuery({
    queryKey: ['awaiting', defaultParams],
    queryFn: () => getAwaiting({
      filters: [],
      page: defaultParams
    }),
    retry: 1,
    select: ({ total }) => total ?? 0
  });
  return { data, refetch };
};

export const useDisposalCounter = () => {
  const { data, refetch } = useQuery({
    queryKey: ['disposal', defaultParams],
    queryFn: () => getDisposal({
      filters: [],
      page: defaultParams
    }),
    retry: 1,
    select: ({ total }) => total ?? 0
  });
  return { data, refetch };
};

export const useProcessingCounter = () => {
  const { data, refetch } = useQuery({
    queryKey: ['processing', defaultParams],
    queryFn: () => getProcessing({
      filters: [],
      page: defaultParams
    }),
    retry: 1,
    select: ({ total }) => total ?? 0
  });
  return { data, refetch };
};

export const useResaleCounter = () => {
  const { data, refetch } = useQuery({
    queryKey: ['resale', defaultParams],
    queryFn: () => getResale({
      filters: [],
      page: defaultParams
    }),
    retry: 1,
    select: ({ total }) => total ?? 0
  });
  return { data, refetch };
};

export const useReturnsCounter = () => {
  const { data, refetch } = useQuery({
    queryKey: ['returns', defaultParams],
    queryFn: () => getReturns({
      filters: [],
      page: defaultParams
    }),
    retry: 1,
    select: ({ total }) => total ?? 0
  });
  return { data, refetch };
};