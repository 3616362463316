import styles from './Store.module.scss';
import './Menu.scss';
import Avatar from '@components/Avatar';
import Cam from '@assets/icons/camera.svg';
import OzonAuthorizeApiForm from '@components/OzonAuthorizeApiForm';
import OzonIcon from '@assets/img/ozon.svg';
import useOutsideClick from '@hooks/useOutsideClick';
import { CSSTransition } from 'react-transition-group';
import { addOzonApiData, deleteOzonApiData } from '@api/apiClient';
import { Modal } from '@components/Modal';
import { Text, TextColor, TextSize } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { TStoreProps } from '../../types';
import { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const Store: React.FC<TStoreProps> = (props: TStoreProps): React.ReactElement => {
  const { refetchSellers, seller } = props;

  const [isActionsVisible, setIsActionsVisible] = useState<boolean>(false);
  const [isOpenOzonApiFormWindow, setIsOpenOzonApiFormWindow] = useState<boolean>(false);

  const actionsRef = useRef<HTMLDivElement | null>(null);
  const menuActionsRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  useOutsideClick<HTMLDivElement>(actionsRef, () => setIsActionsVisible(false));

  const { error, isLoading, mutate: mutateEdit } = useMutation({
    mutationFn: addOzonApiData,
    onSuccess: () => {
      refetchSellers();
      setIsOpenOzonApiFormWindow(false);
    }
  });

  const { mutate: mutateDelete } = useMutation({
    mutationFn: () => deleteOzonApiData({
      sellerId: seller.id
    }),
    onSuccess: refetchSellers
  });

  const onSubmit = useCallback((sellerId: string, apiKey: string, apiClientId: string) => mutateEdit({
    sellerId,
    apiKey,
    apiClientId
  }), []);

  return (
      <>
        <div
            key={seller.id}
            className={styles.shop}
        >
          <div className={styles.label}>
            <Avatar
                className={styles.shopImg}
                name={seller.name}
                id={seller.id}
            >
              <button className={styles.imgBtn}>
                <img
                    alt="Cam"
                    src={Cam}
                />
              </button>
            </Avatar>
            {seller.ozonApiExists && (
                <span className={styles.ozonIcon}>
                  <img
                      alt="Ozon"
                      src={OzonIcon}
                  />
                </span>
            )}
            <div className={styles.shopName}>
              <Text
                  color={TextColor.PRIMARY}
                  size={TextSize.M}
              >
                <b>{seller.name}</b>
              </Text>
              {seller.email && (
                  <Text
                      color={TextColor.DARK_MUTED}
                      size={TextSize.S}
                  >
                    {seller.email}
                  </Text>
              )}
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.infoItems}>
              <div className={styles.infoItem}>
                <span className={styles.infoItemTitle}>
                  Client ID
                </span>
                <span className={styles.infoItemValue}>
                  {seller.apiClientId ?? ''}
                </span>
              </div>
              <div className={styles.infoItem}>
                <span className={styles.infoItemTitle}>
                  API-key
                </span>
                <span className={styles.infoItemValue}>
                  {seller.apiKey ?? ''}
                </span>
              </div>
            </div>
            <div
                className={styles.wrapper}
                ref={actionsRef}
            >
              <Tooltip
                  action={
                    <button
                        className={styles.infoAction}
                        disabled={!seller.allowedToDeleteOzon}
                        onClick={() => setIsActionsVisible(!isActionsVisible)}
                    />
                  }
                  right={0}
                  text={seller.allowedToDeleteOzon ? '' : t('profileShops.tooltip')}
                  top={40}
              />
              <CSSTransition
                  classNames="menu"
                  in={isActionsVisible}
                  mountOnEnter
                  nodeRef={menuActionsRef}
                  timeout={300}
                  unmountOnExit
              >
                <div
                    ref={menuActionsRef}
                    className="menu"
                >
                  <button
                      className="menu__item"
                      onClick={() => setIsOpenOzonApiFormWindow(true)}
                  >
                    {t('profileShops.edit')}
                  </button>
                  <button
                      className="menu__item"
                      onClick={() => mutateDelete()}
                  >
                    {t('profileShops.delete')}
                  </button>
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
        <Modal
            isOpen={isOpenOzonApiFormWindow}
            onClickOverlay={() => setIsOpenOzonApiFormWindow(false)}
            onClose={() => setIsOpenOzonApiFormWindow(false)}
        >
          <OzonAuthorizeApiForm
              error={error}
              isLoading={isLoading}
              key={seller.id}
              onSubmit={onSubmit}
              seller={seller}
          />
        </Modal>
      </>
  );
};

export default Store;