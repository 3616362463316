import { createContext, useContext } from 'react';

interface ReturnsCounterContextType {
  value: number;
  update: () => void;
}

const ReturnsCounterContext = createContext<ReturnsCounterContextType | null>(null);

export const useReturnsCounter = (): ReturnsCounterContextType => {
  const context = useContext(ReturnsCounterContext);
  if (!context) {
    throw new Error('useReturnsCounter must be used within an ReturnsCounterProvider');
  }
  return context;
};

export default ReturnsCounterContext;
export { default as ReturnsCounterProvider } from '././ReturnsCounterProvider';
