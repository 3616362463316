import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { ru, tr, zhCN, enUS } from 'date-fns/locale';
import { format, subMonths, subDays, startOfDay, endOfDay } from 'date-fns';
import { getCurrentLanguage, Langs } from "@utils/lang";
import CrossIcon from "@assets/icons/cross-grey-12.svg";
import SearchIcon from "@assets/icons/date.svg?react";
import chevronDown from "@assets/icons/chevron-down-narrow.svg?react";
import chevronUp from "@assets/icons/chevron-up-narrow.svg?react";
import { ButtonIcon } from "@components/ButtonIcon";
import { formatDate } from "@utils/formatValues";
import { CaptionProps, DayPicker, useNavigation } from "react-day-picker";
import useOutsideClick from "@hooks/useOutsideClick";
import { CSSTransition } from "react-transition-group";
import { ToggleButtons, ToggleButtonTypes } from "@components/ToggleButtons";
import 'react-day-picker/dist/style.css';
import styles from "./DateRangeFilter.module.css";

const locales = {
  cn: zhCN,
  en: enUS,
  ru: ru,
  tr: tr,
};

type InputValueType = undefined | Date
type RangeType = {
  from?: InputValueType,
  to?: InputValueType,
}
type ValueType = null | Date

const DateRangeInput = ({ onChange, value, placeholder }: {
  onChange: (dates: ValueType[]) => void,
  value: ValueType[],
  placeholder?: string,
}) => {
  const currentLanguage = getCurrentLanguage();
  const [dropdownPosition, setDropdownPosition] = useState<"right" | "left">("right");
  const [startDate, setStartDate_] = useState<InputValueType>(undefined);
  const [endDate, setEndDate_] = useState<InputValueType>(undefined);
  const elementRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [monthL, setMonthL] = useState<Date>(subMonths(new Date(), 1));
  const [monthR, setMonthR] = useState<Date>(new Date());
  const [activePreset, setActivePreset] = useState<string>("");
  useOutsideClick<HTMLDivElement>(elementRef, () => setIsOpen(false));
  const { t } = useTranslation();

  const setStartDate = (v: InputValueType) => setStartDate_(v ? startOfDay(v) : v);
  const setEndDate = (v: InputValueType) => setEndDate_(v ? endOfDay(v) : v);

  const dateFormat = currentLanguage === Langs.ru ? "d MMM, yyyy" : "MMM d, yyyy";

  const onChangeRange = (range?: RangeType) => {
    setStartDate(range?.from);
    setEndDate(range?.to);
  }

  useEffect(() => {
    setStartDate(value[0] || undefined);
    setEndDate(value[1] || undefined);
  }, [value[0], value[1]])

  useEffect(() => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      if ((screen.width - rect.right) < 580) setDropdownPosition("left")
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      if (!startDate && !endDate) {
        onChange([null, null]);
      } else if (!startDate || !endDate) {
        setStartDate(value[0] || undefined);
        setEndDate(value[1] || undefined);
      } else {
        onChange([startDate, endDate]);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (monthL > monthR) {
      const tmpMonth = monthL;
      setMonthL(monthR);
      setMonthR(tmpMonth);
    }
  }, [monthL, monthR]);

  const hanlePreset = (v: string) => {
    setActivePreset(v);
    const today = new Date();
    switch (v) {
      case "today":
        setMonthL(subMonths(today, 1));
        setMonthR(today);
        setStartDate(today);
        setEndDate(today);
        break;
      case "week":
        setMonthL(subDays(today, 6));
        setMonthR(today);
        setStartDate(subDays(today, 6));
        setEndDate(today);
        break;
      case "month":
        setMonthL(subMonths(today, 1));
        setMonthR(today);
        setStartDate(subMonths(today, 1));
        setEndDate(today);
        break;
      default:
        setMonthL(subMonths(today, 1));
        setMonthR(today);
        setStartDate(undefined);
        setEndDate(undefined);
    }
  }

  return (
    <div className={styles.inputRoot} ref={elementRef}>
      <div
        className={styles.input}
        onClick={() => setIsOpen(!isOpen)}
      >
        <SearchIcon className={styles.inputIcon} />
        {(startDate || endDate) ? (
          <span className={styles.value}>
            {`${formatDate(startDate, dateFormat)} - ${formatDate(endDate, dateFormat)}`}
          </span>
        ) : (
          <span className={styles.placeholder}>
            {placeholder}
          </span>
        )}
        {(startDate || endDate) ? (
          <ButtonIcon
            icon={CrossIcon}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const today = new Date();
              setEndDate(undefined);
              setStartDate(undefined);
              setMonthL(today);
              setMonthR(today);
              onChange([null, null]);
            }}
          />
        ) : null}
      </div>

      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames={styles.dropdown}
        mountOnEnter
        unmountOnExit
        nodeRef={dropdownRef}
      >
        <div
          className={cn(styles.dropdown, styles[`dropdown--${dropdownPosition}`])}
          ref={dropdownRef}
        >
          <div className={styles.presets}>
            <ToggleButtons
              value={activePreset}
              onChange={hanlePreset}
              items={[
                { id: "all", title: t("dateRangeInput.allDates") },
                { id: "today", title: t("dateRangeInput.today") },
                { id: "week", title: t("dateRangeInput.week") },
                { id: "month", title: t("dateRangeInput.month") },
              ]}
              type={ToggleButtonTypes.PRIMARY}
            />
          </div>

          <div className={cn(
            styles.calendars,
            (startDate === endDate) ? styles.calendarsSingleDay : styles.calendarsMultipleDays
          )}>
            <DayPicker
              mode="range"
              selected={{ from: startDate, to: endDate }}
              onSelect={onChangeRange}
              locale={locales[currentLanguage]}
              month={monthL}
              onMonthChange={setMonthL}
              components={{
                Caption: CustomCaptionComponent
              }}
            />
            <DayPicker
              mode="range"
              selected={{ from: startDate, to: endDate }}
              onSelect={onChangeRange}
              locale={locales[currentLanguage]}
              month={monthR}
              onMonthChange={setMonthR}
              components={{
                Caption: CustomCaptionComponent
              }}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

function CustomCaptionComponent(props: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const currentLanguage = getCurrentLanguage();

  return (
    <div className={styles.calendarCaption}>
      {format(props.displayMonth, "LLLL yyy", { locale: locales[currentLanguage] })}
      <div className={styles.monthBtns}>
        <ButtonIcon
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
          icon={chevronUp}
        />
        <ButtonIcon
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
          icon={chevronDown}
        />
      </div>
    </div>
  );
}

export default DateRangeInput;
