import cn from "classnames";
import styles from './Avatar.module.css';

interface AvatarProps {
  id?: string;
  name: string;
  className?: string;
  children?: React.ReactNode;
}

function getInitials(name: string): string {
  const s = name.trim();
  const res = s
    .split(/\s+/)
    .slice(0, 2)
    .map(v => v[0])
    .join("");

  return (res.length < 2 ? name.slice(0, 2) : res).toUpperCase();
}

const getColor = (name: string) => {
  const colors = ["#eace53", "#d150d5", "#e34b73", "#edb81c", "#ed1c24", "#1c80ed", "#8ecc64"];
  const idx = name.split("")
    .reduce((acc: number, v: string) => acc + v.charCodeAt(0), 0) % colors.length;
  return colors[idx];
};

const Avatar: React.FC<AvatarProps> = ({
  id,
  name,
  className,
  children,
}) => {
  const initials = getInitials(name || "");

  return (
    <div
      className={cn(className, styles.root)}
      style={{ backgroundColor: getColor(id || name || "") }}
    >
      {initials}
      {children}
    </div>
  );
};

export default Avatar;
