import { useQuery } from "react-query";
import { sellerApiClient, getParams } from "./sellerApiClient";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";

export interface FileData {
  fileName: string;
  fileExtension: string;
  fileStorageKey: string;
  fileStoragePreSignedUrl: string;
}

export interface Comment {
  text: string;
  isRead: boolean;
  managerLogin: string;
  attachments: FileData[];
}

export interface ComplaintRequest {
  id: string;
  reason: string;
  description: string;
  trackingNumbers: string[];
  status: string;
  lastComment: Comment | null;
  unreadCommentsCount: number;
  lastUpdated: string;
  attachments: FileData[];
}

export interface ComplaintHistoryItem {
  comment?: {
    text: string;
    attachments: FileData[];
  };
  compensationAmount: number,
  newStatus: null, // New, Closed, Processing
  type: string, // Comment, StatusChanged, Compensation
  isRead: boolean,
  managerLogin: string,
  createdAt: string;
}

export interface ComplaintRequestDetails {
  id: string;
  reason: string;
  description: string;
  trackingNumbers: string[];
  status: string;
  historyItems: ComplaintHistoryItem[];
  lastUpdated: string,
  attachments: FileData[];
}

export const getComplaintRequest = async (id: string): Promise<ComplaintRequestDetails> => {
  const response = await sellerApiClient.get<ComplaintRequestDetails>(
    `/v1/complaints/parcel/${id}`,
  );

  return response.data;
};

export const getComplaintRequests = async ({
  page,
  filters,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<ResponsePages<ComplaintRequest>> => {
  const response = await sellerApiClient.get<ResponsePages<ComplaintRequest>>(
    `/v1/complaints/parcel/page`,
    {
      params: getParams(page, filters),
      paramsSerializer: { indexes: null },
    },
  );

  return response.data;
};

export interface ValidationResponse {
  trackingNumberValidationResults: {
    trackingNumber: string;
    validationErrorTypes: string[];
  }[];
}

export const validateTrackingNumbers = async (
  trackingNumbers: string[]
): Promise<ValidationResponse> => {
  const response = await sellerApiClient.get<ValidationResponse>(
    `/v1/parcels/validateTrackingNumbers`,
    {
      params: { TrackingNumbers: trackingNumbers },
      paramsSerializer: { indexes: null },
    },
  );

  return response.data;
};

export interface CommentData {
  text: string;
  files: File[];
}

export const sendComment = async (id: string, data: CommentData): Promise<void> => {
  const form = new FormData();
  form.append("text", data.text);
  data.files.forEach((v: any) => {
    form.append("Files", v);
  })
  const response = await sellerApiClient.post<void>(
    `/v1/complaints/parcel/${id}/comment`,
    form,
    { headers: {'Content-Type': 'multipart/form-data'} },
  );

  return response.data;
};

export const reopenRequest = async (id: string): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    `/v1/complaints/parcel/${id}/reopen`,
  );

  return response.data;
};

export const markAsRead = async (ticketId: string): Promise<void> => {
  const response = await sellerApiClient.post<void>(
    `/v1/complaints/parcel/${ticketId}/markAsRead`,
  );

  return response.data;
};

interface CreateRequestData {
  reasonType: string; // Damage, Loss, Delay, Substitution
  trackingNumbers: string[];
  comment: string;
  attachments: File[];
}

export const createComplaintRequest = async (data: CreateRequestData): Promise<void> => {
  const form = new FormData();
  form.append("reasonType", data.reasonType);
  data.trackingNumbers.forEach((v: any) => {
    form.append("trackingNumbers", v);
  });
  form.append("comment", data.comment);
  data.attachments.forEach((v: any) => {
    form.append("Attachments", v);
  });
  const response = await sellerApiClient.post<void>(
    "/v1/complaints/parcel/create",
    form,
    { headers: {'Content-Type': 'multipart/form-data'} },
  );

  return response.data;
};

export const getComplaintMessageCountRequest = async (): Promise<{ unreadMessagesCount: number }> => {
  const response = await sellerApiClient.get(
    "/v1/complaints/parcel/unreadMessagesCount",
  );

  return response.data;
};

export function useComplaintMessagesCounter() {
  const { data, refetch } = useQuery({
    queryKey: ["getComplaintMessageCount"],
    queryFn: () => getComplaintMessageCountRequest(),
    retry: 1,
    select: ({ unreadMessagesCount }) => unreadMessagesCount,
  });

  return { data: data ?? 0, refetch };
}


// interface Response<T> {
//   items: T[];
// }

export interface ResponsePages<T> {
  total: number;
  items: T[];
}
