import { useTranslation } from "react-i18next";
import { Button, ButtonType, ButtonSize } from "@components/Button";
import { Seller } from "@api/apiClient";
import Avatar from "@components/Avatar";
import Title from "@components/Title/Title.tsx";
import { Text, TextSize, TextColor } from "@components/Text";
import { Grid, Column, Row } from "@components/Grid";
import Box from "@components/Box/Box.tsx";
import { isAdmin } from "@utils/auth";
import IconSuccess from "@assets/icons/success.svg";
import IconError from "@assets/icons/request-error.svg";
import styles from './AddShopModalStepSuccess.module.css';

interface AddShopModalStepSuccessProps {
  onClose: () => void;
  data: Seller[];
  email: string,
  errCode?: string,
}

const AddShopModalStepSuccess = ({
  onClose,
  data,
  email,
  errCode,
}: AddShopModalStepSuccessProps) => {
  const { t } = useTranslation();

  if (data.length) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={5} pt={1}>
              <Row align="center" noWrap>
                <img src={IconSuccess} className={styles.titleIcon} />
                <Title title={t("profileShops.addShopSuccessTitle")} />
              </Row>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <Column>
                {data.map((item) => (
                  <div key={item.id} className={styles.shop}>
                    <Avatar
                      className={styles.shopImg}
                      name={item.name}
                      id={item.id}
                    />

                    <div className={styles.shopName}>
                      <Text color={TextColor.PRIMARY} size={TextSize.M}>
                        <b>{item.name}</b>
                      </Text>
                    </div>
                  </div>
                ))}
              </Column>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box>
              <Button
                title={t("profileShops.addShopSuccessBtn")}
                type={ButtonType.PRIMARY}
                size={ButtonSize.S}
                onClick={onClose}
              />
            </Box>
          </Column>
        </Row>
      </Grid>
    )
  }

  const isThisAdmin = isAdmin();

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={5} pt={1}>
            <Row align="center" noWrap>
              <img src={IconError} className={styles.titleIcon} />
              <Title title={t(
                `apiErrors.${errCode}${isThisAdmin ? "-isAdmin" : ""}`,
                { defaultValue: t(`apiErrors.default${isThisAdmin ? "-isAdmin" : ""}`) }
              )} />
            </Row>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <Text
              color={TextColor.PRIMARY}
              size={TextSize.M}
              dangerouslySetInnerHTML={{
                __html: t(
                  isThisAdmin ? "apiErrors.defaultAdminDescription" : "profileShops.addShopMessageDescription",
                  { email: `<b>${email}</b>` },
                )
              }}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box>
            <Button
              title={t("profileShops.addShopMsgBtn")}
              type={ButtonType.PRIMARY}
              size={ButtonSize.S}
              onClick={onClose}
            />
          </Box>
        </Column>
      </Row>
    </Grid>
  )
}

export default AddShopModalStepSuccess;
