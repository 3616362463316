import styles from './PickUpOrderModalWindow.module.scss';
import IconTruck from '@assets/icons/truck-blue.svg';
import PickUpCompleted from '../../components/PickUpCompleted/PickUpCompleted';
import PickUpEditingOptions from '../../components/PickUpEditingOptions/PickUpEditingOptions';
import PickUpEditingParcels from '../../components/PickUpEditingParcels/PickUpEditingParcels';
import { IPickUpOrderModalWindowProps } from '../../types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PickUpOrderModalWindow: React.FC<IPickUpOrderModalWindowProps> = (props: IPickUpOrderModalWindowProps): React.ReactElement => {
  const {
    handlerRefreshPickUpOrders,
    handlerSetIsOpenPickUpCancelWindow,
    handlerSetIsOpenPickUpOrderWindow,
    handlerSetPickUpOrder,
    isNew,
    pickUpOrder
  } = props;

  const [currentTypeModalWindow, setCurrentTypeModalWindow] = useState<string>('order');

  const { t } = useTranslation();

  const pickUpOrderCommonProps = {
    currentTypeModalWindow,
    handlerSetCurrentTypeModalWindow: setCurrentTypeModalWindow,
    handlerSetIsOpenPickUpCancelWindow,
    handlerSetIsOpenPickUpOrderWindow,
    pickUpOrder
  };

  return (
      <div>
        <div className={styles.header}>
          <span className={styles.headerIcon}>
            <img
                src={IconTruck}
                alt="IconTruck"
            />
          </span>
          <span className={styles.headerTitle}>
            {t('pickUpOrderModalWindow.title')}
          </span>
        </div>
        <div className={styles.inner}>
          {currentTypeModalWindow === 'order' && (
              <PickUpCompleted {...pickUpOrderCommonProps}/>
          )}
          {currentTypeModalWindow === 'options' && (
              <PickUpEditingOptions
                  {...pickUpOrderCommonProps}
                  handlerRefreshPickUpOrders={handlerRefreshPickUpOrders}
                  handlerSetPickUpOrder={handlerSetPickUpOrder}
                  isNew={isNew}
              />
          )}
          {currentTypeModalWindow === 'parcels' && (
              <PickUpEditingParcels {...pickUpOrderCommonProps}/>
          )}
        </div>
      </div>
  );
};

export default PickUpOrderModalWindow;
