import { useTranslation } from "react-i18next";
import { useMutation } from 'react-query';
import { Button, ButtonType } from "@components/Button";
import { Grid, Column, Row } from "@components/Grid";
import { Text, TextColor, TextSize, TextType } from "@components/Text";
import Box from "@components/Box";
import FormErrorMessage from "@components/FormErrorMessage";
import { disposeParcel } from "@api/apiClient/problematicParcels";

interface DisposalModalWindowProps {
  close: () => void;
  parcelIds: string[];
  success: () => void;
}

const DisposalModalWindow = (props: DisposalModalWindowProps) => {
  const { t } = useTranslation();

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: disposeParcel,
    onSuccess: () => {
      props.success();
    },
  });

  const submit = () => {
    mutate(props.parcelIds);
  };

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={4} pt={4}>
            <Text type={TextType.H2} color={TextColor.PRIMARY}>
              {t("disposalModalWindow.description")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4} pt={4}>
            <Text
              type={TextType.SPAN}
              size={TextSize.M}
              color={TextColor.PRIMARY}
            >
              {t("disposalModalWindow.quantity")}: {props.parcelIds.length}
            </Text>
          </Box>
        </Column>
      </Row>

      {isError && (
        <>
          <Row>
            <Column phone={12}>
              <Text type={TextType.H1} color={TextColor.PRIMARY}>
                {t("error")}
              </Text>
            </Column>
          </Row>
          <FormErrorMessage error={error} />
        </>
      )}

      <Row>
        <Column phone={12}>
          <Box beforeSpace={true}>
            <Button
              type={ButtonType.SKELETON}
              onClick={props.close}
              title={t("disposalModalWindow.no")}
            />
            <div style={{ marginRight: 10 }}></div>
            <Button
              pending={isLoading}
              type={ButtonType.TERTIARY}
              onClick={submit}
              title={t("disposalModalWindow.yes")}
            />
          </Box>
        </Column>
      </Row>
    </Grid>
  );
};

export default DisposalModalWindow;
