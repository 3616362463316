import { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

function useTab(
  tabs: { id: string }[],
  // defaultTab?: string,
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab");

  const setActiveTab = (newTab: string): void => {
    setSearchParams({ tab: newTab });
  }

  useEffect(() => {
    if (!tabs.some(v => v.id === activeTab)) {
      setActiveTab(tabs[0].id);
    }
  }, [activeTab])

  // useEffect(() => {
  //   if (!activeTab && defaultTab && tabs.some(v => v.id === defaultTab)) {
  //     setActiveTab(defaultTab);
  //   }
  // }, [defaultTab, activeTab])

  return { activeTab: activeTab ?? "", setActiveTab };
}

export default useTab;
