import React from "react";
import { useRouter } from "@contexts/RouterContext";
import { Link } from "react-router-dom";
import { Text, TextType } from "../Text";
import BackIcon from "@assets/icons/back-grey.svg";
import "./Page.css";

interface PageProps {
  title: string;
  children: React.ReactNode;
  backLink?: string;
  actions?: React.ReactNode | null;
}

const Page = ({ backLink, title, children, actions }: PageProps) => {
  const { prevPath, prevSearch } = useRouter();

  return (
    <div className="page">
      <div className="page__title">
        {backLink ? (
          <Link to={(prevPath === backLink) ? `${prevPath}${prevSearch}` : backLink}>
            <img src={BackIcon} alt="" />
          </Link>
        ) : null}
        <Text type={TextType.H1}>{title}</Text>
        {actions ? (
          <div className="page__actions">{actions}</div>
        ) : null}
      </div>
      <div className="page__content">{children}</div>
    </div>
  );
};

export default Page;
