import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Avatar from "@components/Avatar";
import {
  MultiSelectOption,
  MultiSelectOptionProps,
  ValueComponentProps,
} from "@components/MultiSelect";
import { getSellers } from "@api/apiClient";
import MultiSelectFilter from "../Filter/MultiSelectFilter";
import styles from "./SellerFilter.module.css";

const OptionComponent = ({ id, value }: MultiSelectOptionProps<string>) => (
  <>
    <Avatar
      className={styles.avatar}
      id={id}
      name={value || ""}
    />
    <span>{value}</span>
  </>
)

const ValueComponent = ({ values, options }: ValueComponentProps<string>) => {
  const { t } = useTranslation();
  return (
    <>
      {(values?.length && (values?.length < (options || []).length)) ? (
        <>
          <div className={styles.avatars}>
            {values.map((v: MultiSelectOption<string>) => (
              <Avatar
                key={v.id}
                id={v.id}
                className={styles.avatar}
                name={v.value || ""}
              />
            ))}
          </div>
          <span>{values[0]?.value ?? ""}</span>
        </>
      ) : (
        <div>{t("parcelsPage.allStores")}</div>
      )}
    </>
  )
}

const SellerFilter = ({ onChange, initialValue, asPanel }: {
  onChange: (sellers: string[]) => void,
  initialValue: string[],
  asPanel?: boolean,
}) => {
  const { isFetching, data } = useQuery({
    queryKey: ["sellers"],
    queryFn: () => getSellers(),
    retry: 1,
    select: ({ items }) => items.map(item => ({ id: item.id, value: item.name })),
  });

  return (
    <MultiSelectFilter
      onChange={onChange}
      initialValue={initialValue}
      ValueComponent={ValueComponent}
      OptionComponent={OptionComponent}
      isFetching={isFetching}
      data={data || []}
      asPanel={asPanel}
    />
  );
}

export default SellerFilter;
