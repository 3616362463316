import "./StrengthPassword.css";
import { getPasswordStrength } from "../../utils/passwordStrength.ts";
import { useTranslation } from "react-i18next";

interface StrengthPasswordProps {
  password: string;
}

const StrengthPassword = (props: StrengthPasswordProps) => {
  const strength = getPasswordStrength(props.password);
  const { t } = useTranslation();

  const getStrengthText = (score: number): string => {
    let strengthName = "";
    if (score <= 4) {
      strengthName = t("strengthPassword.s");
    } else if (score <= 6) {
      strengthName = t("strengthPassword.m");
    } else if (score < 10) {
      strengthName = t("strengthPassword.l");
    } else {
      strengthName = t("strengthPassword.xl");
    }
    return strengthName;
  };

  const getProgressColor = () => {
    if (strength <= 4) return "#ee6373";
    if (strength <= 8) return "#f2af4c";
    if (strength <= 10) return "#3ecc92";
    return "#4ca36a";
  };

  const progressStyle = {
    width: `${(strength / 12) * 100}%`,
    backgroundColor: getProgressColor(),
  };

  return (
    <div>
      <div className="password-strength-bar">
        <div className="progress-container">
          <div className="progress-bar" style={progressStyle}></div>
        </div>
      </div>
      <div>
        {t("strengthPassword.title")} <b>{getStrengthText(strength)}</b>
      </div>
    </div>
  );
};

export default StrengthPassword;
