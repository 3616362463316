import { useParams } from 'react-router-dom';
import ComplaintRequest from "./ComplaintRequest";
import ComplaintList from "./ComplaintList";

const ComplaintPage = () => {
  const { requestId } = useParams();

  if (requestId) {
    return (<ComplaintRequest />);
  }

  return (<ComplaintList />);
};

export default ComplaintPage;
