import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSellersFound } from "@api/apiClient";
import { AddSellerPanel } from "@components/AddSeller";
import { Grid, Row, Column } from "@components/Grid";
import { Loader } from "@components/Loader";
import { getLinkedWarhauses } from "@api/apiClient/fulfillment";
import { Seller } from "@api/apiClient";
import { Button } from "@components/Button";
import Page from "@components/Page";
import AuthForm from "./components/AuthForm";
import Stores from "./components/Stores";
import AddStoreModal from "./components/AddStoreModal";

const FulfillmentStores = ({ seller, onApiAuth, HeaderFilters }: {
  seller: Seller | undefined,
  onApiAuth: () => void
  HeaderFilters: React.FC,
}) => {
  const { t } = useTranslation();
  const [isAddWindowOpen, setAddWindowOpen] = useState<boolean>(false);
  const [showAuth, setShowAuth] = useState<boolean>(false);
  const { sellersNotFound } = useSellersFound();

  const {
    data: linkedWarehousesCount,
    isLoading: warehouseIsLoading,
    refetch: warehouseRefetch,
  } = useQuery({
    queryKey: ["linkedWarehouses", seller?.id ?? ""],
    queryFn: () => getLinkedWarhauses(seller?.id ?? ""),
    retry: 1,
    enabled: Boolean(seller?.ozonApiExists),
    select: ({ items }) => items?.length,
  });

  useEffect(() => {
    if (!seller?.ozonApiExists) {
      setShowAuth(true);
    } else {
      setShowAuth(!linkedWarehousesCount);
    }
  }, [seller?.id, linkedWarehousesCount])

  if (sellersNotFound) {
    return (
      <Page title={t("fulfillmentPage.fulfillmentStores")}>
        <AddSellerPanel
          isOzonSeller
          onSuccess={() => warehouseRefetch()}
        />
      </Page>
    )
  }

  return (
    <Page
      title={t("fulfillmentPage.fulfillmentStores")}
      actions={(
        <>
          <HeaderFilters />
          {linkedWarehousesCount ? (
            <Button
              onClick={() => setAddWindowOpen(true)}
              title={`+ ${t("fulfillmentPage.addStoreBtn")}`}
            />
          ) : null}
        </>
      )}
    >
      {(warehouseIsLoading && !showAuth) ? (
        <Grid>
          <Row>
            <Column phone={12}>
              <Loader alignment="center" size="s" />
            </Column>
          </Row>
        </Grid>
      ) : (
        seller ? (
          (showAuth ? (
            <AuthForm
              seller={seller}
              onAuth={onApiAuth}
              onAddWarehouse={() => setAddWindowOpen(true)}
            />
          ) : (
            <Stores seller={seller} />
          ))
        ) : (null)
      )}

      {(seller && isAddWindowOpen) ? (
        <AddStoreModal seller={seller} onClose={() => setAddWindowOpen(false)} />
      ) : null}
    </Page>
  )
};

export default FulfillmentStores;
