import Table from "@components/Table/Table";
import { TableColumn, TableRowType } from "@components/Table/TableTypes";
import Page from "@components/Page/Page";

interface Product {
  name: string;
  article: string;
  price: number;
}

const data: Product[] = [
  {
    name: "Товар 1",
    article: "Артикул 1",
    price: 100,
  },
  {
    name: "Товар 2",
    article: "Артикул 2",
    price: 200,
  },
  {
    name: "Товар 3",
    article: "Артикул 3",
    price: 300,
  },
];

const columns: TableColumn<Product>[] = [
  {
    header: "Название",
    accessor: (row: TableRowType<Product>) => <b>{row.data?.name}</b>,
    alignment: "left",
    width: "120px",
  },
  {
    header: "Артикул",
    accessor: (row: TableRowType<Product>) => <b>{row.data?.article}</b>,
    alignment: "left",
    width: "120px",
  },
  {
    header: "Цена",
    accessor: (row: TableRowType<Product>) => <b>{row.data?.price}</b>,
    alignment: "left",
    width: "120px",
  },
];

const Products = () => {
  return (
    <Page title={"Товары"}>
      <Table
        rowId={(row) => row.article}
        selectable={true}
        data={data}
        columns={columns}
      />
    </Page>
  );
};

export default Products;
