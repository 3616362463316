import "./Text.css";
import { ReactNode } from "react";
import cn from "classnames";
import {
  TextColor,
  TextSize,
  TextType,
  TextWeight,
} from "./types.ts";

interface TextProps {
  children?: ReactNode;
  type?: TextType;
  color?: TextColor;
  onHoverColor?: TextColor;
  size?: TextSize;
  className?: string;
  dangerouslySetInnerHTML?: {
    __html: string | TrustedHTML;
  };
  weight?: TextWeight;
  ellipsis?: boolean;
  width?: string;
}

const Tags = {
  [TextType.H1]: "h1",
  [TextType.H2]: "h2",
  [TextType.H3]: "h3",
  [TextType.H4]: "h4",
  [TextType.P]: "p",
  [TextType.BLOCK]: "div",
  [TextType.SPAN]: "span",
  [TextType.INLINE]: "span",
};

export const Text = (props: TextProps) => {
  const {
    type = TextType.SPAN,
    color = TextColor.PRIMARY,
    onHoverColor,
    children,
    dangerouslySetInnerHTML,
    weight,
    ellipsis,
    width,
  } = props;

  // у заголовка свой размер по умолчанию, для остальных TextSize.M
  const size =
      type === TextType.H1 || type === TextType.H2 || type === TextType.H3 || type === TextType.H4
          ? null
          : (props.size || TextSize.M);

  const className = cn(
    "text",
    color && `text--${color}`,
    onHoverColor && `text--hover-${onHoverColor}`,
    size && `text--size-${size}`,
    type && `text--${type}`,
    ellipsis && "text-ellipsis",
    weight && `text--weight-${weight}`,
    props.className,
  );

  const TextTag = (Tags[type] || 'span') as keyof JSX.IntrinsicElements;

  return (
    <TextTag
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      style={{ width }}
    >
      {children}
    </TextTag>
  );
};
