import { useState, useEffect } from "react";
import cn from "classnames";
import { formatDate } from "@utils/formatValues";
import DateRangeInput from "./DateRangeInput";
import styles from "./DateRangeFilter.module.css";

type ValueType = null | Date

const DateRangeFilter = ({ onChange, initialValue, asPanel, placeholder }: {
  onChange: (dates: string[]) => void,
  initialValue: string[],
  asPanel?: boolean,
  placeholder?: string;
}) => {
  const [dateRange, setDateRange] = useState<ValueType[]>([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (initialValue?.length) {
      const newValue = initialValue.map(v => v ? (new Date(v)) : null);
      setDateRange(newValue || [null, null]);
      onChange(initialValue);
    } else {
      setDateRange([null, null]);
    }
  }, [initialValue])

  const onChangeRange = (selection: ValueType[]) => {
    setDateRange(selection);
    if (!selection[0] && !selection[1]) {
      onChange(["", ""]);
    } else {
      onChange(selection.map((v: ValueType) => v ? formatDate(v, "yyyy-MM-dd") : ""));
    }
  };

  // <DatePicker
  //   selectsRange
  //   startDate={startDate}
  //   endDate={endDate}
  //   onChange={onChangeRange}
  //   isClearable={true}
  //   locale={locales[currentLanguage]}
  //   dateFormat={currentLanguage === Langs.ru ? "d MMM, yyyy" : "MMM d, yyyy"}
  //   className={styles.input}
  //   popperClassName={styles.popper}
  //   placeholderText={t("filters.dateFilter")}
  // />

  return (
    <div className={cn(styles.root, asPanel && styles.rootForPanel)}>
      <DateRangeInput
        value={[startDate, endDate]}
        onChange={onChangeRange}
        placeholder={placeholder}
      />
    </div>
  );
}

export default DateRangeFilter;
