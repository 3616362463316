import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useQuery, useMutation } from 'react-query';
import { Modal } from "@components/Modal";
import { Grid, Column, Row } from "@components/Grid";
import {
  getDiscrepanciesById,
  getLinkedWarhauses,
  markDiscrepancyAsViewed,
  DefectDiscrepancies,
  QuantityDiscrepancies,
  UnknownProductDiscrepancies,
} from "@api/apiClient/fulfillment";
import { Loader } from "@components/Loader";
import { Text, TextColor, TextWeight, TextSize, TextType } from "@components/Text";
import { TableColumn, TableColumnType } from "@components/Table/TableTypes";
import Table from "@components/Table/Table";
import Box from "@components/Box";
import InfoPanel from "../InfoPanel";
import styles from "./DiffModal.module.scss";

const DiffModal = ({ onClose, requestId, sellerId, warehouseId }: {
  onClose: () => void,
  sellerId?: string,
  warehouseId: string,
  requestId: string,
}) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const { mutate: markAsViewed } = useMutation({
    mutationFn: () => markDiscrepancyAsViewed(requestId, warehouseId),
  });

  const {
    data,
    isFetching,
  } = useQuery({
    queryKey: ["getDiscrepanciesById", requestId, warehouseId],
    queryFn: () => getDiscrepanciesById(requestId, warehouseId),
    retry: 1,
    onSuccess: (d) => {
      if (!d.isViewed) markAsViewed();
    },
  });

  const {
    data: warehouse,
  } = useQuery({
    queryKey: ["linkedWarehouses", sellerId],
    queryFn: () => getLinkedWarhauses(sellerId ?? ""),
    retry: 1,
    enabled: Boolean(sellerId),
    select: ({ items }) => items.find(v => (v.id === warehouseId)),
  });

  const wrongNumberColumns: TableColumn<QuantityDiscrepancies>[] = useMemo(() => [
    {
      header: t("fulfillmentPage.diffModal.article"),
      accessor: (row) => row.data.ozonSku,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "80px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: "",
      accessor: (row) => (
        <img
          className={styles.producImage}
          src={row.data.primaryImage}
          alt=""
        />
      ),
      alignment: "right",
      width: "50px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.diffModal.name"),
      accessor: (row) => (
        <a
          href={String(row.data.ozonUrl)}
          target="_blank"
          rel="noreferrer"
          className={styles.productLink}
        >
          {row.data.name}
        </a>
      ),
      alignment: "left",
      width: "350px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.diffModal.declared"),
      accessor: (row) => (row.data.expectedQuantity),
      alignment: "right",
      fixWidth: true,
      width: "100px",
    },
    {
      header: t("fulfillmentPage.diffModal.discrepancy"),
      accessor: (row) => {
        const discrepancy = row.data.actualQuantity - row.data.expectedQuantity;
        return (
          <Text
            color={discrepancy > 0 ? TextColor.GREENISH : TextColor.ERROR}
            weight={TextWeight.MEDIUM}
          >
            {discrepancy > 0 ? "+ " : ""}
            {discrepancy}
          </Text>
        );
      },
      alignment: "right",
      fixWidth: true,
      width: "100px",
    },
  ], [currentLanguage, data?.quantityDiscrepancies]);

  const defectiveProductColumns: TableColumn<DefectDiscrepancies>[] = useMemo(() => [
    {
      header: t("fulfillmentPage.diffModal.article"),
      accessor: (row) => row.data.ozonSku,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "80px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: "",
      accessor: (row) => (
        <img
          className={styles.producImage}
          src={row.data.photo}
          alt=""
        />
      ),
      alignment: "right",
      width: "50px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.diffModal.nameAndComment"),
      accessor: (row) => (
        <div>
          <a
            href={String(row.data.ozonUrl)}
            target="_blank"
            rel="noreferrer"
            className={styles.productLink}
          >
            {row.data.name}
          </a>
          <div className={styles.productLink}>{row.data.description}</div>
        </div>
      ),
      alignment: "left",
      width: "550px",
      fixWidth: true,
      noOverflow: true,
    },
  ], [currentLanguage, data?.defectDiscrepancies]);

  const notSpecifiedColumns: TableColumn<UnknownProductDiscrepancies>[] = useMemo(() => [
    {
      header: t("fulfillmentPage.diffModal.name"),
      accessor: (row) => (
        <Box flex="row">
          <img
            className={styles.producImage}
            src={row.data.photo}
            alt=""
          />
          <span className={styles.notSpecifiedName}>
            {row.data.description}
          </span>
        </Box>
      ),
      alignment: "left",
      width: "650px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.diffModal.count"),
      accessor: (row) => (row.data.quantity),
      alignment: "right",
      fixWidth: true,
      width: "90px",
    },
  ], [currentLanguage, data?.unknownProductDiscrepancies]);

  if (isFetching) {
    return (
      <Modal
        isOpen
        onClose={onClose}
        onClickOverlay={onClose}
        width={900}
      >
        <Grid>
          <Row>
            <Column phone={12}>
              <Loader size="s" />
            </Column>
          </Row>
        </Grid>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      onClickOverlay={onClose}
      width={900}
    >
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <Text type={TextType.H2} weight={TextWeight.BOLD}>
                {t("fulfillmentPage.diffModal.title", { number: data?.restockRequestNumber })}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={3}>
              <Text size={TextSize.L} weight={TextWeight.MEDIUM}>
                {t("fulfillmentPage.diffModal.description")}
              </Text>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <InfoPanel
                items={[
                  {
                    title: t("fulfillmentPage.diffModal.address"),
                    text: warehouse?.uniWarehouse?.address,
                  },
                  {
                    title: t("fulfillmentPage.diffModal.phone"),
                    text: warehouse?.uniWarehouse?.phone,
                  },
                  {
                    title: t("fulfillmentPage.diffModal.contacts"),
                    text: warehouse?.uniWarehouse?.contactName,
                  },
                ]}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Text weight={TextWeight.BOLD} size={TextSize.L}>
                {t("fulfillmentPage.diffModal.wrongNumber")}
              </Text>
            </Box>
            <Box pb={8}>
              <Table<QuantityDiscrepancies>
                columns={wrongNumberColumns}
                data={data?.quantityDiscrepancies ?? []}
                rowId={row => row.productId}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Text weight={TextWeight.BOLD} size={TextSize.L}>
                {t("fulfillmentPage.diffModal.defectiveProduct")}
              </Text>
            </Box>
            <Box pb={8}>
              <Table<DefectDiscrepancies>
                columns={defectiveProductColumns}
                data={data?.defectDiscrepancies ?? []}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Text weight={TextWeight.BOLD} size={TextSize.L}>
                {t("fulfillmentPage.diffModal.notSpecified")}
              </Text>
            </Box>
            <Box>
              <Table<UnknownProductDiscrepancies>
                columns={notSpecifiedColumns}
                data={data?.unknownProductDiscrepancies ?? []}
              />
            </Box>
          </Column>
        </Row>
      </Grid>
    </Modal>
  );
}

export default DiffModal;
