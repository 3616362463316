import "./Title.css";

interface TitleProps {
  title: string;
}

const Title = (props: TitleProps) => {
  return <h1 className={"title"}>{props.title}</h1>;
};

export default Title;
