export const getPasswordStrength = (password: string): number => {
  let score = 0;
  const uniqueChars = new Set<string>();

  if (password.length >= 8) {
    score += 2;
  }
  let hasDigits = false;
  let hasSpecials = false;
  let hasLowercase = false;
  let hasUppercase = false;

  for (let i = 0; i < password.length; i++) {
    const char = password[i];
    uniqueChars.add(char);

    if (!isNaN(Number(char))) {
      hasDigits = true;
    } else if (
      char === char.toUpperCase() &&
      isNaN(Number(char)) &&
      /[A-Z]/i.test(char)
    ) {
      hasUppercase = true;
    } else if (
      char === char.toLowerCase() &&
      isNaN(Number(char)) &&
      /[a-z]/i.test(char)
    ) {
      hasLowercase = true;
    } else if (/[^a-zA-Z0-9]/.test(char)) {
      hasSpecials = true;
    }
  }

  if (hasDigits) {
    score += 2;
  }
  if (hasSpecials) {
    score += 2;
  }
  if (hasLowercase) {
    score += 2;
  }
  if (hasUppercase) {
    score += 2;
  }

  // Bonus for using various characters
  if (uniqueChars.size > 6) {
    score += 2;
  }

  return score;
};
