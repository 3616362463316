import { useMemo } from "react";
import {
  Control,
  FieldValues,
  Path,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  countries,
  CountryType,
  CountrySelectValue,
  CountrySelectOption,
  countryKeyExtractor,
} from "@components/CountrySelect/CountrySelect";
import SelectContainer from "@containers/SelectContainer";

interface CountrySelectContainerProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  title?: string;
  disabled?: boolean;
  allowedCountries?: string[];
}

const CountrySelectContainer = <TFieldValues extends FieldValues = FieldValues>(
  {
    control,
    name,
    title,
    disabled,
    allowedCountries,
  }: CountrySelectContainerProps<TFieldValues>,
) => {
  const { t } = useTranslation();

  const filteredCountries = useMemo(() => {
    return allowedCountries
      ? countries.filter(c => allowedCountries.find(v => v === c.id))
      : countries;
  }, [(allowedCountries || []).join(",")])

  return (
    <SelectContainer<CountryType, TFieldValues>
      control={control}
      name={name}
      title={title ?? t("returnsForm.country")}
      renderSelectedItem={CountrySelectValue}
      items={filteredCountries}
      keyExtractor={countryKeyExtractor}
      renderItem={CountrySelectOption}
      rules={{
        required: t("defaultFormErrors.required"),
      }}
      disabled={disabled}
    />
  );
};

export default CountrySelectContainer;
