import { SmartCaptcha } from '@yandex/smart-captcha';
import i18n from "i18next";

const Captcha = ({ onChange }: {
  onChange: (token: string) => void,
}) => {
  const currentLanguage = i18n.language;

  return (
    <SmartCaptcha
      sitekey="ysc1_QUERvB6OEnqTMUjJ8F9fi5xL90SiGjsTMqUf3XxD50c14c37"
      onSuccess={onChange}
      onTokenExpired={() => onChange("")}
      language={
        (["ru", "en", "tr"].includes(currentLanguage)
          ? currentLanguage
          : "en") as ("ru" | "en" | "tr")
      }
    />
  );
};

export default Captcha;
