export enum FilterTypes {
  SELLER = "seller",
  DATE_RANGE = "dateRange",
  SEARCH = "search",
  MULTI_SEARCH = "multiSearch",
  MULTI_SEARCH_OR_NAME = "multiSearchOrName",
  SERVICE="service",
  MULTI_SELECT = "multiSelect",
  STATUS = "status",
  STATE = "state"
}
