import { useAuth } from "../../contexts/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { useMutation } from 'react-query';
import { Button, ButtonType } from "@components/Button";
import { Grid, Row, Column } from "@components/Grid";
import Box from "@components/Box";
import ErrorIcon from "@assets/icons/request-error.svg";
import { Text, TextSize, TextType } from "@components/Text";
import { LoginFormType } from './types';
import LoginForm from "./LoginForm.tsx";
import FormErrorMessage from "@components/FormErrorMessage";

const LoginPage = (props: { isAdmin?: boolean }) => {
  const { isAdmin } = props;

  const auth = useAuth();
  const { t } = useTranslation();
  const {
    error,
    isError,
    isLoading,
    isIdle,
    mutate,
    reset,
  } = useMutation({
    mutationFn: auth.login,
  });

  const handleSubmit = (form: LoginFormType) => {
    if (isAdmin) {
      mutate({
        username: form.emailAdmin,
        password: form.password,
        emailSeller: form.emailSeller,
      });
    } else {
      mutate({ username: form.email, password: form.password });
    }
  };

  return (
    <div>
      {isError && (
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={4}>
                <Grid>
                  <Row align={"center"}>
                    <Column phone={2}>
                      <img alt={"Icon"} src={ErrorIcon} width={"100%"} />
                    </Column>
                    <Column phone={10}>
                      <Box pl={4}>
                        <Text type={TextType.H1}>
                          {t(isAdmin ? "loginPage.adminErrorTitle" : "loginPage.errorTitle")}
                        </Text>
                      </Box>
                    </Column>
                  </Row>
                </Grid>
              </Box>
            </Column>
          </Row>

          {isAdmin ? (
            <FormErrorMessage
              black
              errorPrefix="loginPage.adminErrors"
              error={error}
              context={{ email: (data: Record<string, string>) => (
                `<b>${data.email ?? ""}</b>`
              )}}
            />
          ) : (
            <Row>
              <Column phone={12}>
                <Box pb={2}>
                  <Text type={TextType.P} size={TextSize.M}>
                    {t("loginPage.errorMessage")}
                  </Text>
                </Box>
              </Column>
            </Row>
          )}

          <Row>
            <Column phone={12}>
              <Box>
                <Button
                  onClick={() => reset()}
                  title={t("loginPage.returnLink")}
                  type={ButtonType.LINK}
                />
              </Box>
            </Column>
          </Row>
        </Grid>
      )}

      {(isLoading || isIdle) && (
        <LoginForm
            isAdmin={isAdmin}
            onSubmit={handleSubmit}
            pending={isLoading}
        />
      )}
    </div>
  );
};

export default LoginPage;
