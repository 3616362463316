import axios, { AxiosInstance } from "axios";
import { KeycloakTokenResponse } from "./Responses/KeycloakTokenResponse.ts";
import { VITE_APP_AUTH_URL } from "@utils/config";

const keycloakClient: AxiosInstance = axios.create({
  baseURL: VITE_APP_AUTH_URL
});

export const getToken = async (
  userName: string,
  password: string,
): Promise<KeycloakTokenResponse> => {
  const params = new URLSearchParams({
    client_id: "sellers-dashboard-webui",
    username: userName,
    password: password,
    grant_type: "password",
  });

  const response = await keycloakClient.post<KeycloakTokenResponse>(
    "token",
    params,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  );

  response.data.received_at = new Date().getTime();

  return response.data;
};

export const refreshToken = async (
  refreshToken: string,
): Promise<KeycloakTokenResponse> => {
  const params = new URLSearchParams({
    client_id: "sellers-dashboard-webui",
    refresh_token: refreshToken,
    grant_type: "refresh_token",
  });

  try {
    const response = await keycloakClient.post<KeycloakTokenResponse>(
      "token",
      params,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
    response.data.received_at = new Date().getTime();
    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении токена:", error);
    throw error;
  }
};

//dd
