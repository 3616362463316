import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendEmailToResetPassword } from "@api/apiClient";
import { isApiErrorWithResponse } from "@api/apiClient";
import { Grid, Column, Row } from "@components/Grid";
import Box from "@components/Box";
import { Text, TextSize, TextType } from "@components/Text";
import SendEmailToResetPasswordForm, {
  SendEmailToResetPasswordFormType,
} from "./SendEmailToResetPasswordForm.tsx";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import BackIcon from "@assets/icons/back-arrow.svg?react";
import Icon from "@assets/icons/success.svg";
import ErrorIcon from "@assets/icons/request-error.svg";
import "./SendEmailToResetPassworPage.css";

const SendEmailToResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    error,
    isSuccess,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: sendEmailToResetPassword,
  });
  const handleSubmit = async (form: SendEmailToResetPasswordFormType) => {
    mutate({ email: form.email });
  };

  if (isError) {
    let errorMessage = t(`resetPasswordForm.error.defaultTitle`);
    if (isApiErrorWithResponse(error)) {
      errorMessage = t(
        `resetPasswordForm.error.${error.response.data.code || "defaultTitle"}`,
        { defaultValue: errorMessage },
      );
    }

    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Grid>
                <Row align="center">
                  <Column phone={2}>
                    <img alt={"Icon"} src={ErrorIcon} width={"100%"} />
                  </Column>
                  <Column phone={10}>
                    <Box pl={4}>
                      <Text type={TextType.H1}>
                        {t("sendEmailToResetPasswordPage.errorTitle")}
                      </Text>
                    </Box>
                  </Column>
                </Row>
              </Grid>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pb={2}>
              <Text type={TextType.P} size={TextSize.M}>
                {errorMessage}
              </Text>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Button
              title={t("sendEmailToResetPasswordPage.returnLink")}
              icon={BackIcon}
              type={ButtonType.ROUNDED_SKELETON}
              size={ButtonSize.S}
              onClick={() => {
                navigate("/login");
              }}
            />
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={3}>
              <Button
                title={t("sendEmailToResetPasswordPage.signupLink")}
                type={ButtonType.LINK}
                size={ButtonSize.S}
                onClick={() => {
                  navigate("/signup");
                }}
              />
            </Box>
          </Column>
        </Row>
      </Grid>
    )
  }

  if (isSuccess) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Grid>
                <Row align={"center"}>
                  <Column phone={2}>
                    <img alt={"Icon"} src={Icon} width={"100%"} />
                  </Column>
                  <Column phone={10}>
                    <Box pl={4}>
                      <Text type={TextType.H1}>
                        {t("sendEmailToResetPasswordPage.successTitle")}
                      </Text>
                    </Box>
                  </Column>
                </Row>
              </Grid>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pb={2}>
              <Text type={TextType.P} size={TextSize.M}>
                {t("sendEmailToResetPasswordPage.successMessage")}
              </Text>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box>
              <Link to={"/login"}>
                {t("sendEmailToResetPasswordPage.returnLink")}
              </Link>
            </Box>
          </Column>
        </Row>
      </Grid>
    )
  }

  return (
    <div>
      <SendEmailToResetPasswordForm
        onSubmit={handleSubmit}
        pending={isLoading}
      />
    </div>
  );
};

export default SendEmailToResetPasswordPage;
