import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import { Grid, Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Text, TextColor, TextSize, TextType } from "@components/Text";
import { Button, ButtonType } from "@components/Button";
import TextInputContainer from "@containers/TextInputContainer";
import { useProfile } from "../../contexts/ProfileContext";
import ButtonWithTimer from "@components/ButtonWithTimer/ButtonWithTimer";
import FormErrorMessage from "@components/FormErrorMessage";
import { sendPin, updateProfile, verifyPin } from "@api/apiClient";

interface HardConfirmFormType {
  phone: string;
  emailForNewsletter: string;
  chat: string;
  pin: string;
  codeCountry: string;
}

interface HardConfirmPhoneModalWindowProps {
  onSuccess: () => void;
  text: string;
  isEditable?: boolean;
  phone?: string;
}

const HardConfirmPhoneModalWindows = (
  props: HardConfirmPhoneModalWindowProps,
) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { handleSubmit, watch, control } = useForm<HardConfirmFormType>({
    mode: "onChange",
    defaultValues: {
      phone: props.phone || profile?.account?.phone || "",
      emailForNewsletter: profile?.account?.emailForNewsletter ?? "",
      chat: profile?.account?.chat ?? "",
    },
  });

  const updateProfileMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      props.onSuccess();
    },
  });

  const verifyMutation = useMutation({
    mutationFn: verifyPin,
    onSuccess: () => {
      profile.get();
    },
  });

  const sendPinMutation = useMutation({
    mutationFn: sendPin,
  });

  const pinValue = watch("pin");
  const phoneValue = watch("phone");

  useEffect(() => {
    if (pinValue?.length >= 6) {
      verifyMutation.mutate(parseInt(pinValue));
    }
  }, [pinValue]);

  const submit = (form: HardConfirmFormType) => {
    updateProfileMutation.mutate({
      name: profile.account?.name ?? "",
      chat: form.chat,
      emailForNewsletter: form.emailForNewsletter,
    });
  };

  const sendP = () => {
    sendPinMutation.mutate(phoneValue);
  };

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={4} pt={4}>
            <Text type={TextType.H3} color={TextColor.PRIMARY}>
              {t("hardConfirmPhoneModalWindows.description")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4} pt={4}>
            <Text
              type={TextType.SPAN}
              size={TextSize.M}
              color={TextColor.PRIMARY}
            >
              {props.text}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={6}>
          <Box pb={2} pt={4}>
            <TextInputContainer
              rules={{
                required: t("hardConfirmPhoneModalWindows.required"),
              }}
              control={control}
              name={"phone"}
              title={t("hardConfirmPhoneModalWindows.phone")}
              disabled={profile.account?.isPhoneConfirmed || !props.isEditable}
              type="phone"
            />
          </Box>
        </Column>
        {!profile.account?.isPhoneConfirmed && (
          <Column phone={3}>
            <Box pb={2} pt={4} pl={4}>
              <TextInputContainer
                control={control}
                name={"pin"}
                title={t("hardConfirmPhoneModalWindows.pin")}
                disabled={verifyMutation.isLoading}
              />
            </Box>
          </Column>
        )}
      </Row>
      {verifyMutation.isError && (
        <FormErrorMessage error={verifyMutation.error} />
      )}
      {!profile.account?.isPhoneConfirmed && (
        <>
          <Row>
            <Column phone={12}>
              <Box pb={1} pt={1}>
                <Text
                  type={TextType.P}
                  size={TextSize.S}
                  color={TextColor.PRIMARY}
                >
                  {t("hardConfirmPhoneModalWindows.descriptionPin")}
                </Text>
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <ButtonWithTimer
                onClick={sendP}
                pending={sendPinMutation.isLoading}
                label={t("hardConfirmPhoneModalWindows.getPin")}
              />
            </Column>
          </Row>
          {sendPinMutation.isError && (
            <FormErrorMessage error={sendPinMutation.error} />
          )}
        </>
      )}
      {props.isEditable ? (
        <>
          <Row>
            <Column phone={6}>
              <Box pb={2} pt={4}>
                <TextInputContainer
                  rules={{
                    required: t("hardConfirmPhoneModalWindows.required"),
                  }}
                  control={control}
                  name={"chat"}
                  title={
                    profile.account?.region === "TR"
                      ? t("hardConfirmPhoneModalWindows.chatTr")
                      : t("hardConfirmPhoneModalWindows.chatCn")
                  }
                />
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={6}>
              <Box pb={2} pt={4}>
                <TextInputContainer
                  rules={{
                    required: t("hardConfirmPhoneModalWindows.required"),
                  }}
                  control={control}
                  name={"emailForNewsletter"}
                  title={t("hardConfirmPhoneModalWindows.emailForNews")}
                />
              </Box>
            </Column>
          </Row>
        </>
      ) : null}

      {props.isEditable ? (
        <Row>
          <Column phone={12}>
            <Box beforeSpace={true}>
              <Button
                pending={updateProfileMutation.isLoading}
                type={ButtonType.TERTIARY}
                onClick={handleSubmit(submit)}
                title={t("hardConfirmPhoneModalWindows.button")}
                disabled={!profile.account?.isPhoneConfirmed}
              />
            </Box>
          </Column>
        </Row>
      ) : null}
      {updateProfileMutation.isError && (
        <FormErrorMessage error={updateProfileMutation.error} />
      )}
    </Grid>
  );
};

export default HardConfirmPhoneModalWindows;
