import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { isToday } from 'date-fns';
import cn from "classnames";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from 'react-router-dom';
import { useProfile } from "@contexts/ProfileContext";
import Page from "@components/Page";
import Avatar from "@components/Avatar";
import { Grid, Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Text, TextSize, TextColor, TextWeight } from "@components/Text";
import { Loader } from "@components/Loader";
import {
  getComplaintRequest,
  markAsRead,
  sendComment,
  reopenRequest,
  FileData,
} from "@api/apiClient/complaints";
import Textarea from "@components/Textarea";
import { Button, ButtonType, ButtonSize } from "@components/Button";
import { ButtonIcon } from "@components/ButtonIcon";
import FormErrorMessage from "@components/FormErrorMessage";
import CollapsibleList from "./components/CollapsibleList";
import FileItem from "./components/FileItem";
import { formatDate } from "@utils/formatValues";
import IconUni from "@assets/icons/uni.svg";
import IconPaperclip from "@assets/icons/paperclip.svg";
import IconSend from "@assets/icons/send.svg?react";
import styles from "./ComplaintRequest.module.css";

const ComplaintRequest = () => {
  const { requestId } = useParams();
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState<string>("");
  const [files, setFiles] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState<string>("");
  const profile = useProfile();
  const queryClient = useQueryClient();

  const handleNewFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = (event.target.files ? Array.from(event.target.files) : []);
    setFiles([...files, ...newFiles]);
  };

  const markAsReadMutation = useMutation({
    mutationFn: () => markAsRead(requestId ?? ""),
    onSuccess: () => {
      queryClient.invalidateQueries(
        { queryKey: ["getComplaintMessageCount"] }
      );
    },
  });

  const sendCommentMutation = useMutation({
    mutationFn: () => sendComment(requestId ?? "", {
      text: newComment,
      files,
    }),
    onSuccess: () => {
      setFiles([]);
      setNewComment("");
      refetch();
    },
  });

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["getComplaintRequest", requestId],
    queryFn: () => getComplaintRequest(requestId ?? ""),
    retry: 1,
    onSuccess: ({ status, historyItems }) => {
      setStatus(status);
      if (historyItems.find(c => !c.isRead)) {
        markAsReadMutation.mutate();
      }
    },
  });

  const updateStatusMutation = useMutation({
    mutationFn: () => reopenRequest(requestId ?? ""),
    onSuccess: () => {
      setStatus("Open");
    },
  });

  if (isError) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pt={8}>
              <FormErrorMessage error={error} />
            </Box>
          </Column>
        </Row>
      </Grid>
    )
  }

  if (isLoading) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pt={8}>
              <Loader alignment="center" size="s" />
            </Box>
          </Column>
        </Row>
      </Grid>
    )
  }

  const sendCommentEnabled = Boolean(newComment || files.length);

  return (
    <Page
      title={`№${data?.id} ${t(`complaintPage.types.${data?.reason}`, { defaultValue: "" })}`}
      backLink="/complaints"
    >
      <div className={styles.root}>
        <Row>
          <Column phone={12}>
            <Box>
              <Text size={TextSize.M} color={TextColor.PRIMARY}>
                {data?.description}
              </Text>
            </Box>
          </Column>
        </Row>

        <CollapsibleList<string>
          title={t("complaintPage.complaintRequest.numbers")}
          items={data?.trackingNumbers ?? []}
          limit={9}
          columns
          ItemComponent={(props: { data: string }) => (
            <div>{props.data}</div>
          )}
        />

        <CollapsibleList<FileData>
          title={t("complaintPage.complaintRequest.attachments")}
          items={data?.attachments ?? []}
          limit={3}
          ItemComponent={(props: { data: FileData }) => (
            <FileItem data={props.data} />
          )}
        />

        <Row>
          <Column phone={12}>
            <Box pt={8}>
              <div className={cn(styles.statusPanel, styles[`statusPanel${status}`])}>
                <Text size={TextSize.M} weight={TextWeight.MEDIUM}>
                  {t(`complaintPage.complaintRequest.statuses.${status}`)}
                </Text>

                {status === "Closed" ? (
                  <Button
                    type={ButtonType.ROUNDED_SKELETON}
                    size={ButtonSize.XS}
                    onClick={updateStatusMutation.mutate}
                    title={t("complaintPage.complaintRequest.returnBtn")}
                    pending={updateStatusMutation.isLoading}
                  />
                ) : null}
              </div>
            </Box>
          </Column>
        </Row>

        {updateStatusMutation.isError && (
          <FormErrorMessage error={updateStatusMutation.error} />
        )}

        {(status !== "Closed") && (
          <Row>
            <Column phone={12}>
              <Box pt={8} flex="row">
                <input
                  ref={fileInputRef}
                  className={styles.fileInput}
                  type="file"
                  multiple
                  onChange={handleNewFiles}
                />
                <ButtonIcon
                  className={styles.btnAddFile}
                  icon={IconPaperclip}
                  onClick={() => {
                    fileInputRef?.current?.click?.();
                  }}
                />
                <Textarea
                  value={newComment}
                  onChange={setNewComment}
                  title={t("complaintPage.complaintRequest.commentPlaceholder")}
                  name="comment"
                  maxLength={4000}
                  rows={2}
                />
                <ButtonIcon
                  className={cn(styles.btnSend, sendCommentEnabled && styles.btnSendActive)}
                  icon={IconSend}
                  onClick={() => sendCommentMutation.mutate()}
                  pending={sendCommentMutation.isLoading}
                  disabled={!sendCommentEnabled}
                />
              </Box>
            </Column>
          </Row>
        )}
        <Box pl={8} flex="column">
          {files.map((item, idx) => (
            <FileItem
              key={idx}
              name={item.name}
              onRemove={() => setFiles(files.filter((_, i) => i !== idx))}
            />
          ))}
        </Box>
        {sendCommentMutation.isError && (
          <FormErrorMessage error={sendCommentMutation.error} />
        )}

        <Row>
          <Column phone={12}>
            <Box pt={8}>
              <div className={styles.comments}>
                {(data?.historyItems ?? []).map(item => (
                  <div key={item.createdAt} className={styles.comment}>
                    <div className={styles.commentHeader}>
                      <div className={styles.commentAuthor}>
                        {item.managerLogin ? (
                          <img className={styles.avatar} src={IconUni} alt="" />
                        ) : (
                          <Avatar
                            className={styles.avatar}
                            name={profile?.account?.name ?? ""}
                            id={profile?.account?.name ?? ""}
                          />
                        )}
                        <Text size={TextSize.XS} color={TextColor.DARK_MUTED}>
                          {item.managerLogin ? "Unitrade support" : profile?.account?.name || ""}
                        </Text>
                      </div>
                      <Text size={TextSize.XXS} color={TextColor.DARK_MUTED}>
                        {formatDate(
                          item.createdAt,
                          isToday(new Date(item.createdAt))
                            ? "HH:mm"
                            : "dd MMM yyy HH:mm"
                        )}
                      </Text>
                    </div>
                    <Text size={TextSize.M} weight={TextWeight.NORMAL}>
                      {item.type === "Comment" ? item.comment?.text : ""}
                      {item.type === "StatusChanged"
                        ? `${t("complaintPage.statusChanged")}: ${t(`complaintPage.statuses.${item.newStatus}`)}`
                        : ""
                      }
                      {item.type === "Compensation" ? t("complaintPage.compensation", { value: item.compensationAmount }) : ""}
                    </Text>
                    {(item.type === "Comment") ? (item.comment?.attachments ?? []).map((f: FileData) => (
                      <FileItem key={f.fileStorageKey} data={f} />
                    )) : null}
                  </div>
                ))}
              </div>
            </Box>
          </Column>
        </Row>
      </div>
    </Page>
  )
};

export default ComplaintRequest;
