import { Text, TextSize, TextType } from "@components/Text";
import { Grid, Row, Column } from "@components/Grid";
import { Trans, useTranslation } from "react-i18next";
import { BeforeProps } from "@containers/DataTable";
import { Return } from "@api/apiClient";

const BeforeAwaitingTable = ({
  items,
  isLoading,
}: BeforeProps<Return>) => {
  const { t } = useTranslation();

  const recentReturnsCount =
    items.filter((item) => {
      const createAtDate = new Date(item.arrivedToDropOffAt); // Convert to Date object
      return Date.now() - createAtDate.getTime() >= 15 * 24 * 60 * 60 * 1000;
    }).length || 0;

  const resaleCount =
    items.filter((item) => {
      return item.resales.length > 0;
    }).length || 0;

  if (!isLoading) {
    if (recentReturnsCount > 0) {
      return (
          <div
              style={{
                backgroundColor: "#fce0e3",
                borderRadius: "10px",
                padding: "5px 20px",
                marginBottom: "10px",
              }}
          >
            <Grid>
              <Row>
                <Column phone={12}>
                  <Text type={TextType.P} size={TextSize.M}>
                    <b style={{ color: "rgb(88, 21, 28)" }}>
                      {t("awaitingPage.noty_returns_title")}
                    </b>
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <Text type={TextType.P} size={TextSize.M}>
                <span style={{ color: "rgb(88, 21, 28)" }}>
                  <b style={{ color: "rgb(88, 21, 28)" }}>
                    {recentReturnsCount}
                  </b>{" "}
                  -
                  <Trans i18nKey="awaitingPage.noty_returns_text" />
                </span>
                  </Text>
                </Column>
              </Row>
            </Grid>
          </div>
      );
    }
    if (resaleCount > 0) {
      return (
          <div
              style={{
                backgroundColor: "rgb(255, 243, 205)",
                borderRadius: "10px",
                padding: "5px 20px",
                marginBottom: "20px",
              }}
          >
            <Grid>
              <Row>
                <Column phone={12}>
                  <Text type={TextType.P} size={TextSize.M}>
                    <b style={{ color: "rgb(102, 77, 3)" }}>
                      {t("awaitingPage.noty_resale_title")}
                    </b>
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <Text type={TextType.P} size={TextSize.M}>
                <span style={{ color: "rgb(102, 77, 3)" }}>
                  <Trans i18nKey="awaitingPage.noty_resale_text" />
                </span>
                  </Text>
                </Column>
              </Row>
            </Grid>
          </div>
      )
    }
    if (items.length) {
      return (
          <div
              style={{
                backgroundColor: "rgb(207, 226, 255)",
                borderRadius: "10px",
                padding: "5px 20px",
                marginBottom: "10px",
              }}
          >
            <Grid>
              <Row>
                <Column phone={12}>
                  <Text type={TextType.P} size={TextSize.M}>
                    <b style={{ color: "rgb(5, 44, 101)" }}>
                      {t("awaitingPage.noty_await_title")}
                    </b>
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <Text type={TextType.P} size={TextSize.M}>
                <span style={{ color: "rgb(5, 44, 101)" }}>
                  <b style={{ color: "rgb(5, 44, 101)" }}>
                    {items.length}
                  </b>{" "}
                  -
                  <Trans i18nKey="awaitingPage.noty_await_text" />
                </span>
                  </Text>
                </Column>
              </Row>
            </Grid>
          </div>
      )
    }
  }

  return null;
}

export default BeforeAwaitingTable;
