import { Outlet, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import cn from "classnames";
import i18n from "i18next";
import { getImportantNews, NewsItem } from "@api/apiClient/news";
import { useProblematicPageCounter } from "@api/apiClient/problematicParcels";
import { useComplaintMessagesCounter } from "@api/apiClient/complaints";
import ReturnsIcon from "@assets/icons/returns-icon.svg";
import Img3 from "@assets/icons/icon-otp.svg";
import SupportIcon from "@assets/icons/support-icon.svg";
import FulfilmentIcon from "@assets/icons/fulfilment.svg";
import WarningIcon from "@assets/icons/warning-blue.svg";
import LinkIcon from "@assets/icons/link-blue.svg";
import ImgNews from "@assets/icons/fire2.svg";
import ImgParcels from "@assets/icons/parcel.svg";
import ImgPickUp from "@assets/icons/truck-blue.svg";
import LanguageSwitcherContainer from "../../containers/LanguageSwitcherContainer/LanguageSwitcherContainer.tsx";
import UserWidget from "./components/UserWidget/UserWidget.tsx";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import Navbar from "./components/Navbar/Navbar.tsx";
import { useCounter } from "../../contexts/CounterContext";
import { useReturnsCounter } from "../../contexts/ReturnsCounterContext";
import { Logo } from "@components/Logo";
import { Text, TextColor, TextSize, TextType, TextWeight } from "@components/Text";
import WalletWidget from "./components/WalletWidget/WalletWidget.tsx";
import styles from "./AuthenticatedLayout.module.css";
import UnclaimedIcon from '@assets/icons/unclaimed-blue-30.svg';

type TAuthenticatedLayout = {
  region: string;
};

const AuthenticatedLayout = (props: TAuthenticatedLayout) => {
  const { region } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const counter = useCounter();
  const returnsCounter = useReturnsCounter();
  const currentLanguage = i18n.language;

  const query = useQuery({
    queryKey: ["important-news"],
    queryFn: () => getImportantNews(),
    retry: 1,
  });

  const { data: awaitingTotal } = useProblematicPageCounter("AwaitingDecision");
  const { data: complaintsTotal } = useComplaintMessagesCounter();

  const items: NewsItem[] = (query?.data || []).filter((v: NewsItem) => (
    v.lang === currentLanguage.toUpperCase()
  ))

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={cn(styles.headerItem, styles.headerItemLogo)}>
          <Logo />
        </div>
        <div className={styles.headSpacer}></div>
        <div style={{ marginRight: "20px" }}>
          <WalletWidget />
        </div>
        <div style={{ marginRight: "20px" }}>
          <LanguageSwitcherContainer />
        </div>
        <div className={cn(styles.headerItem, styles.headerItemUser)}>
          <UserWidget />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.navigation}>
          <div className={styles.navbar}>
            <Navbar
              menu={[
                // {
                //   icon: Img1,
                //   path: "dashboard",
                //   text: t("navbar.dashboard"),
                // },
                {
                  icon: ImgParcels,
                  path: "parcels",
                  text: t("navbar.parcels"),
                  count: 0,
                  key: "parcels",
                },
                {
                  icon: UnclaimedIcon,
                  path: 'unclaimed',
                  text: t('navbar.unclaimed'),
                  count: counter.returns ?? 0,
                  key: 'unclaimed',
                },
                ...((region === "CN") ? [
                  {
                    icon: WarningIcon,
                    path: "problematic-parcels",
                    text: t("navbar.problematicParcels"),
                    key: "problematic",
                    count: awaitingTotal,
                  }
                ] : []),
                {
                  icon: ReturnsIcon,
                  path: 'returns',
                  text: t('navbar.returns'),
                  count: returnsCounter.value,
                  key: 'returns'
                },
                // {
                //   icon: Img5,
                //   path: "products",
                //   text: t("navbar.products"),
                //   count: 0,
                //   key: "returns",
                // },
                ...((region === "CN") ? [{
                   icon: Img3,
                   path: "labelling",
                   text: t("navbar.dropshipping"),
                   key: "labelling",
                 }] : []),
                 {
                   icon: FulfilmentIcon,
                   path: "fulfillment",
                   text: t("navbar.fulfillment"),
                   key: "fulfillment",
                 },
                 ...((region === "CN") ? [
                   {
                     icon: SupportIcon,
                     path: "complaints",
                     text: t("navbar.complaints"),
                     key: "complaints",
                     count: complaintsTotal,
                   },
                 ] : []),
                // {
                //   icon: Img4,
                //   path: "support",
                //   text: t("navbar.support"),
                //   key: "support",
                // },
                // {
                //   icon: Img3,
                //   path: "support",
                //   text: t("navbar.support"),
                // },
                ...((region === "CN") ? [
                  {
                    icon: ImgPickUp,
                    path: "pick-up",
                    text: t("pickUp.title"),
                    key: "pickup",
                  },
                ] : []),
                {
                  icon: LinkIcon,
                  path: "bunch",
                  text: t("bunchPage.title"),
                  key: "bunch",
                },
                {
                  icon: ImgNews,
                  path: "news",
                  text: t("news.news"),
                  key: "news",
                },
              ]}
            />
          </div>


          <div className={styles.news}>
            <Text type={TextType.H3}>
              {t("news.importantNews")}
            </Text>
            {items.map((item) => (
              <Link
                key={item.id}
                className={styles.newsItem}
                to={item.link}
              >
                <img className={styles.newsImg} src={item.img} alt="" />
                <Text size={TextSize.XS} type={TextType.BLOCK}>
                  {item.title}
                </Text>
                <Text size={TextSize.XS} type={TextType.BLOCK}>
                  {item.description}
                </Text>
              </Link>
            ))}
            <Button
              onClick={() => navigate("/news")}
              size={ButtonSize.S}
              title={(
                <Text
                  color={TextColor.WHITE}
                  size={TextSize.S}
                  type={TextType.SPAN}
                  weight={TextWeight.MEDIUM}
                >
                  {t("news.allNews")}
                </Text>
              )}
              type={ButtonType.ROUNDED}
            />
          </div>

          <div>{/*<LanguageSwitcher />*/}</div>
        </div>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedLayout;
