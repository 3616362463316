import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useQuery, useMutation } from 'react-query';
import { Text, TextWeight, TextSize } from "@components/Text";
import CountInput from "@components/CountInput";
import { ButtonIcon } from "@components/ButtonIcon";
import FormErrorMessage from "@components/FormErrorMessage";
import Page from "@components/Page";
import { Grid, Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Button, ButtonType, ButtonSize } from "@components/Button";
import { TableColumn, TableColumnType, TableRowType } from "@components/Table/TableTypes";
import Table from "@components/Table/Table";
import { Loader } from "@components/Loader";
import {
  getPictureForPrinting,
  WarehouseProduct,
  getWarehouseRefillRequest,
  updateWarehouseRefillRequest,
  finishWarehouseRefillRequest,
  getLinkedWarhauses,
  getFulfillmentProducts,
  FulfillmentProduct,
} from "@api/apiClient/fulfillment";
import SearchInput from "@components/SearchInput";
import SearchItem from "./components/SearchItem";
import InfoPanel from "./components/InfoPanel";
import ListItem from "./components/ListItem";
import { weightKg } from "@utils/formatValues";
import DelIcon from "@assets/icons/del.svg?react";
import styles from "./FulfillmentRequest.module.css";

enum STEPS {
  GET_COUNT="GET_COUNT",
  PRINT="PRINT",
  COMPLETE="COMPLETE",
}

const FulfillmentRequest = ({ requestId, HeaderFilters, warehouseId, sellerId }: {
  requestId: string,
  warehouseId: string,
  HeaderFilters: React.FC<{ disabled?: boolean }>,
  sellerId: string,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<STEPS>(STEPS.GET_COUNT);
  const [boxCount, setBoxCount] = useState<number>(1);
  const [products, setProducts] = useState<WarehouseProduct[]>([]);
  const currentLanguage = i18n.language;

  const getProducts = useCallback(
    (search: string) => getFulfillmentProducts(sellerId, search),
    [sellerId],
  );

  const addProduct = (data: FulfillmentProduct) => {
    setProducts((products: WarehouseProduct[]) => {
      if (products.find((item: WarehouseProduct) => (item.id === data.id))) {
        return products;
      }
      return [...products, { ...data, count: 1 }];
    });
  };

  const changeCount = (product: WarehouseProduct, count: number) => {
    setProducts((products: WarehouseProduct[]) => {
      const idx = products.findIndex((item: WarehouseProduct) => (item.id === product.id))
      if (idx < 0) return products;
      products[idx].count = count;
      return [...products];
    })
  }

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: updateWarehouseRefillRequest,
    onSuccess: () => {
      setStep(STEPS.PRINT);
    },
  });

  const {
    error: finishError,
    isError: finishIsError,
    isLoading: finishIsLoading,
    mutate: finishMutate,
  } = useMutation({
    mutationFn: () => finishWarehouseRefillRequest({ warehouseId, requestId }),
    onSuccess: () => {
      setStep(STEPS.COMPLETE);
    },
  });

  const { isFetching, data } = useQuery({
    queryKey: ["getWarehouseRefillRequest"],
    queryFn: () => getWarehouseRefillRequest(warehouseId, requestId),
    retry: 1,
    onSuccess: (d) => {
      setBoxCount(d.boxCount || 1);
      if ((d?.status !== "Draft") && (step !== STEPS.COMPLETE)) {
        setStep(STEPS.COMPLETE);
      }
    },
  });

  const {
    data: warehouse,
  } = useQuery({
    queryKey: ["linkedWarehouses", sellerId],
    queryFn: () => getLinkedWarhauses(sellerId),
    retry: 1,
    enabled: Boolean(sellerId),
    select: ({ items }) => items.find(v => (v.id === warehouseId)),
  });

  useEffect(() => {
    setProducts(data?.products ?? []);
  }, [data?.products]);

  const backLink = `/fulfillment/${warehouseId}?tab=OrdersTab`;
  const isSavedRequest = data?.status !== "Draft";

  const columns: TableColumn<WarehouseProduct>[] = useMemo(() => [
    {
      header: t("fulfillmentPage.requestPage.article"),
      accessor: (row) => row.data.ozonSku,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "80px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: "",
      accessor: (row) => (
        <img className={styles.producImage} src={row.data.primaryImage} alt="" />
      ),
      alignment: "right",
      width: "50px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.requestPage.name"),
      accessor: (row) => (
        <a
          href={String(row.data.ozonUrl ?? "#")}
          target="_blank"
          rel="noreferrer"
          className={styles.productLink}
        >
          {row.data.name}
        </a>
      ),
      alignment: "left",
      width: (step === STEPS.GET_COUNT) ? "400px" : "430px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.requestPage.weight"),
      accessor: (row) => weightKg(row.data.weight),
      type: TableColumnType.TERTIARY,
      alignment: "right",
      fixWidth: true,
      width: "80px",
    },
    ...(((step === STEPS.GET_COUNT) ? [
      {
        header: t("fulfillmentPage.requestPage.count"),
        accessor: (row: TableRowType<WarehouseProduct>) => (
          <CountInput
            value={row.data.count}
            onChange={(v: number) => changeCount(row.data, v)}
          />
        ),
        type: TableColumnType.TERTIARY,
        alignment: "center",
        fixWidth: true,
        width: "120px",
      },
      {
        header: "",
        accessor: (row: TableRowType<WarehouseProduct>) => (
          <div>
            <ButtonIcon
              icon={DelIcon}
              onClick={() => setProducts(products.filter(p => row.data.id !== p.id))}
            />
          </div>
        ),
        alignment: "left",
        fixWidth: true,
        width: "20px",
      },
    ] : [
      {
        header: t("fulfillmentPage.requestPage.count"),
        accessor: (row: TableRowType<WarehouseProduct>) => row.data.count,
        type: TableColumnType.TERTIARY,
        alignment: "right",
        fixWidth: true,
        width: "80px",
      },
    ]) as TableColumn<WarehouseProduct>[]),
  ], [currentLanguage, products, step]);

  let content: React.ReactNode = "";

  const { refetch } = useQuery({
    queryKey: ['pdf'],
    queryFn: () => getPictureForPrinting({
      id: requestId,
      pageCount: boxCount,
      warehouseId: warehouseId
    }),
    retry: 0,
    enabled: false,
    onSuccess(response) {
      const file = new Blob([response], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const newWindow = window.open(fileURL);
      newWindow!.onload = () => {
        newWindow!.print();
      };
    }
  });

  if (isFetching) {
    content = (
      <Grid>
        <Row>
          <Column phone={12}>
            <Loader size="s" />
          </Column>
        </Row>
      </Grid>
    )
  } else if (isSavedRequest) {
    content = (
      <Column phone={12}>
        <Box pb={4} flex="row">
          <Column phone={12}>
            <Text weight={TextWeight.MEDIUM} size={TextSize.L}>
              {t("fulfillmentPage.requestPage.step-5", { boxCount })}
            </Text>
            <Box pt={3}>
              <InfoPanel
                items={[
                  {
                    title: t("fulfillmentPage.requestPage.address"),
                    text: warehouse?.uniWarehouse?.address,
                  },
                  {
                    title: t("fulfillmentPage.requestPage.phone"),
                    text: warehouse?.uniWarehouse?.phone,
                  },
                  {
                    title: t("fulfillmentPage.requestPage.contacts"),
                    text: warehouse?.uniWarehouse?.contactName,
                  },
                ]}
                actions={[
                  <Button
                    type={ButtonType.ROUNDED}
                    size={ButtonSize.S}
                    onClick={() => refetch()}
                    title={t("fulfillmentPage.requestPage.reprint-btn")}
                  />
                ]}
              />
            </Box>
          </Column>
        </Box>
      </Column>
    )
  } else if (step === STEPS.GET_COUNT) {
     content = (
       <>
         <ListItem index="1" title={t("fulfillmentPage.requestPage.step-1")} />
         <ListItem index="2" title={t("fulfillmentPage.requestPage.step-2")}>
           <Box pt={1}>
             {t("fulfillmentPage.requestPage.step-2-description")}
           </Box>
           <Box pt={4}>
             <CountInput
               value={boxCount}
               onChange={setBoxCount}
               maxValue={5}
             />
           </Box>
         </ListItem>

         <Row>
           <Box pt={8}>
             <Column phone={12}>
               <Row gap={5}>
                 <Button
                   onClick={() => mutate({ warehouseId, requestId, data: {
                     boxCount,
                     products: (products ?? []).map((v) => ({
                       productId: v.id,
                       count: v.count,
                     })),
                   }})}
                   title={t("fulfillmentPage.requestPage.next")}
                   pending={isLoading}
                 />
                 <SearchInput<FulfillmentProduct>
                   className={styles.searchInput}
                   getItems={getProducts}
                   requestKey={`getFulfillmentProducts-${sellerId}`}
                   keyExtractor={(item: FulfillmentProduct) => item.id}
                   renderItem={SearchItem}
                   onItemSelect={addProduct}
                 />
               </Row>
             </Column>
           </Box>
           {isError && (
             <FormErrorMessage error={error} />
           )}
         </Row>
       </>
     )
  } else if (step === STEPS.PRINT) {
    content = (
      <>
        <ListItem index="3" title={t("fulfillmentPage.requestPage.step-3")}>
          <Box pt={3}>
            <Button
              type={ButtonType.ROUNDED}
              size={ButtonSize.S}
              onClick={() => refetch()}
              title={t("fulfillmentPage.requestPage.print-btn")}
            />
          </Box>
        </ListItem>
        <ListItem index="4" title={t("fulfillmentPage.requestPage.step-4")} />
        <Row>
          <Column phone={12}>
            <Box pt={8}>
              <Row gap={4}>
                <Button
                  type={ButtonType.SKELETON}
                  onClick={() => setStep(STEPS.GET_COUNT)}
                  title={t("fulfillmentPage.requestPage.back")}
                />
                <Button
                  onClick={() => finishMutate()}
                  title={t("fulfillmentPage.requestPage.complete")}
                  pending={finishIsLoading}
                />
              </Row>
            </Box>
          </Column>
          {finishIsError && (
            <FormErrorMessage error={finishError} />
          )}
        </Row>
      </>
    )
  } else {
    content = (
      <Column phone={12}>
        <ListItem index="5" title={t("fulfillmentPage.requestPage.step-5", { boxCount })}>
          <Box pt={3}>
            <InfoPanel
              items={[
                {
                  title: t("fulfillmentPage.requestPage.address"),
                  text: warehouse?.uniWarehouse?.address,
                },
                {
                  title: t("fulfillmentPage.requestPage.phone"),
                  text: warehouse?.uniWarehouse?.phone,
                },
                {
                  title: t("fulfillmentPage.requestPage.contacts"),
                  text: warehouse?.uniWarehouse?.contactName,
                },
              ]}
            />
          </Box>
        </ListItem>
      </Column>
    )
  }

  return (
    <Page
      backLink={backLink}
      title={`№${data?.number} ${t("fulfillmentPage.requestPage.title")}`}
      actions={<HeaderFilters disabled />}
    >
      {content}
      {isFetching ? null : (
        <Row>
          <Column phone={12}>
            <Box pt={8} pb={8}>
              <Table<WarehouseProduct>
                columns={columns}
                data={products}
              />
            </Box>
          </Column>
        </Row>
      )}
    </Page>
  )
};

export default FulfillmentRequest;
