import "./UserWidget.css";
import exitIcon from "../../../../assets/icons/arrow-right-to-bracket-svgrepo-com.svg";
import chevronDown from "../../../../assets/icons/chevron-down-svgrepo-com.svg";
import chevronUp from "../../../../assets/icons/chevron-up-svgrepo-com.svg";
import { CSSTransition } from "react-transition-group";
import { useAuth } from "../../../../contexts/AuthenticationContext";
import Avatar from "./Avatar.tsx";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useProfile } from "../../../../contexts/ProfileContext";
import useOutsideClick from "@hooks/useOutsideClick";

const UserWidget = () => {
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const profile = useProfile();

  useOutsideClick<HTMLDivElement>(dropdownRef, () => setIsOpen(false));

  return (
    <div className={"user-widget"} ref={dropdownRef}>
      <div
        className={"user-widget__head"}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className={"user-widget__avatar"}>
          <Avatar text={profile.account?.name ?? ""} />
        </div>
        <div className={"user-widget__name"}>{profile.account?.name ?? ""}</div>
        <div className={"user-widget__arrow"}>
          {isOpen ? (
            <img src={chevronUp} alt={""} />
          ) : (
            <img src={chevronDown} alt={""} />
          )}
        </div>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="user-widget__body"
        mountOnEnter
        unmountOnExit
        nodeRef={dropdownMenuRef}
      >
        <div ref={dropdownMenuRef} className={"user-widget__body"}>
          <div
            className={"user-widget__item"}
            onClick={() => {
              navigate("/profile");
            }}
          >
            {t("userWidget.profile")}
          </div>
          <div className={"user-widget__item"}>
            <div
              className={"user-widget-item"}
              onClick={auth.logout}
            >
              <div className="user-widget-item__text">
                {t("userWidget.logout")}
              </div>
              <div className={"user-widget-item__space"}></div>
              <div className={"user-widget-item__icon"}>
                <img src={exitIcon} alt={""} />
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default UserWidget;
