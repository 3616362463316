import Box from '@components/Box';
import { Column, Grid, Row } from '@components/Grid';
import moment from 'moment';
import { useMutation } from 'react-query';
import FormErrorMessage from "@components/FormErrorMessage";
import { Button, ButtonType } from '@components/Button';
import { cancelOneTimePickUp } from '@api/apiClient/pickUp';
import { IPickUpCancelModalWindowProps } from '../types';
import { Text, TextType } from '@components/Text';
import { useTranslation } from 'react-i18next';

const PickUpCancelModalWindow: React.FC<IPickUpCancelModalWindowProps> = (props: IPickUpCancelModalWindowProps): React.ReactElement => {
  const {
    handlerRefreshPickUpOrders,
    handlerSetIsOpenPickUpCancelWindow,
    pickUpOrder: {
      id,
      shipmentDate
    },
  } = props;

  const { t } = useTranslation();

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: cancelOneTimePickUp,
    onSuccess: () => {
      handlerSetIsOpenPickUpCancelWindow(false);
      handlerRefreshPickUpOrders();
    }
  });

  const handlerCancelOneTimePickUp = (id: string): void => {
    mutate(id);
  };

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text type={TextType.H1}>
                {t('pickUpCancelModalWindow.title')} {moment(shipmentDate).format('DD.MM')}?
              </Text>
            </Box>
          </Column>
        </Row>
        <Row gap={5}>
          <Column phone={2}>
            <Box>
              <Button
                  onClick={() => handlerSetIsOpenPickUpCancelWindow(false)}
                  title={t('pickUpCancelModalWindow.no')}
              />
            </Box>
          </Column>
          <Column phone={2}>
            <Box>
              <Button
                  onClick={() => handlerCancelOneTimePickUp(id)}
                  pending={isLoading}
                  title={t('pickUpCancelModalWindow.yes')}
                  type={ButtonType.LIGHT}
              />
            </Box>
          </Column>
        </Row>
        {isError ? (
          <FormErrorMessage
            error={error}
            errorPrefix="pickUpErrors"
          />
        ) : null}
      </Grid>
  );
};

export default PickUpCancelModalWindow;
