import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import { linkSendCode } from "@api/apiClient";
import { Grid, Column, Row } from "@components/Grid";
import { Text, TextSize, TextType, TextColor, TextWeight } from "@components/Text";
import TextInputContainer from "@containers/TextInputContainer";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import Box from "@components/Box";
import FormErrorMessage from "@components/FormErrorMessage";
import { getCurrentLanguage, Langs } from "@utils/lang";
import infoIcon from '@assets/icons/info_blue_19.svg';

export interface AddShopFormOzonType {
  email: string;
  apiKey: string;
  clientId: string;
}

interface AddShopFormOzonProps {
  onSuccess: (data: {
    email: string,
    apiKey: string,
    clientId: string,
    ticketId: string,
    isCompanyFound: boolean,
  }) => void;
}

const links = {
  [Langs.cn]: "https://docs.ozon.ru/api/seller/zh/#section/API",
  [Langs.ru]: "https://docs.ozon.ru/api/seller/#section/Kak-poluchit-API-klyuch",
  [Langs.en]: "",
  [Langs.tr]: "",
}

const AddShopFormOzon = ({
  onSuccess,
}: AddShopFormOzonProps) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const { control, handleSubmit } = useForm<AddShopFormOzonType>({
    defaultValues: {
      email: "",
    },
  });

  const {
    isLoading,
    mutate,
    error,
  } = useMutation({
    mutationFn: linkSendCode,
  });

  const onSubmit = (form: AddShopFormOzonType) => {
    mutate({
      email: form.email,
      apiKey: form.apiKey,
      clientId: form.clientId,
    }, {
      onSuccess: ({ ticketId, foundCompanies }) => {
        onSuccess({
          email: form.email,
          apiKey: form.apiKey,
          clientId: form.clientId,
          ticketId,
          isCompanyFound: Boolean(foundCompanies?.length),
        });
      },
    });
  };

  return (
    <>
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={3}>
              <Text type={TextType.H1}>
                {t(`addOzonShop.title`)}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <Text color={TextColor.PRIMARY} size={TextSize.M}>
                {t("addOzonShop.description")}
              </Text>
            </Box>
          </Column>
        </Row>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Column phone={12}>
              <Box pb={3}>
                <TextInputContainer
                  control={control}
                  name="email"
                  title={t("addOzonShop.addShopEmail")}
                  rules={{ required: t("defaultFormErrors.emailFormat") }}
                  type="email"
                />
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={8}>
                <Text color={TextColor.DISABLED} size={TextSize.S}>
                  {t("addOzonShop.emailDescription")}
                </Text>
              </Box>
            </Column>
          </Row>
          {links[currentLanguage] ? (
            <Row>
              <Column phone={12}>
                <Box pb={8}>
                  <a
                    href={links[currentLanguage]}
                    target="_blank"
                    style={{ display: "flex", alignItems: "center" }} rel="noreferrer"
                  >
                    <img src={infoIcon} alt="i" style={{ marginRight: "8px" }} />
                    <Text
                      type={TextType.SPAN}
                      size={TextSize.L}
                      color={TextColor.BLUE}
                      weight={TextWeight.MEDIUM}
                    >
                      {t("addOzonShop.infoLink")}
                    </Text>
                  </a>
                </Box>
              </Column>
            </Row>
          ) : null}
          <Row>
            <Column phone={12}>
              <Box pb={3}>
                <TextInputContainer
                  control={control}
                  name="clientId"
                  title={t("addOzonShop.clientId")}
                />
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={8}>
                <TextInputContainer
                  control={control}
                  name="apiKey"
                  title={t("addOzonShop.apiKey")}
                />
              </Box>
            </Column>
          </Row>
          {error ? (
            <Row>
              <Column phone={12}>
                <Box pb={2}>
                  <FormErrorMessage error={error} />
                </Box>
              </Column>
            </Row>
          ) : null}
          <Row>
            <Column phone={12}>
              <Box pb={5}>
                <Button
                  title={t("addOzonShop.addBtn")}
                  type={ButtonType.BLOCK}
                  size={ButtonSize.S}
                  onClick={handleSubmit(onSubmit)}
                  pending={isLoading}
                />
              </Box>
            </Column>
          </Row>
        </form>
      </Grid>
    </>
  );
};

export default AddShopFormOzon;
