import { ReactNode } from "react";
import { useQuery } from "react-query";
import { getAccountById } from "@api/apiClient";
import { useAuth } from "../AuthenticationContext";
import ProfileContext from "./index.ts";
import { getWallet } from '@api/apiClient/finance';

interface CounterProviderProps {
  children: ReactNode;
}

const ProfileProvider = (props: CounterProviderProps) => {
  const auth = useAuth();

  const query = useQuery({
    queryKey: ["account"],
    queryFn: () => getAccountById(),
    retry: 1,
    enabled: auth.isAuthenticated,
  });

  const wallet = useQuery({
    queryKey: ['wallet'],
    queryFn: () => getWallet(),
    retry: 1,
    enabled: auth.isAuthenticated
  });

  const get = () => {
    query.refetch();
    wallet.refetch();
  };

  return (
    <ProfileContext.Provider
      value={{
        account: {...query.data!, ...wallet.data!},
        get,
        isFetching: query.isFetching,
        isFetched: query.isFetched,
      }}
    >
      <>{props.children}</>
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
