import { formatDate } from "@utils/formatValues";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getDropShipping, DropShippingParcel } from "@api/apiClient/dropshipping";
import Table from "@components/Table/Table";
import { Text, TextColor, TextType } from "@components/Text";
import { AddSellerPanel } from "@components/AddSeller";
import Box from "@components/Box/Box.tsx";
import Row from "@components/Grid/Row.tsx";
import Column from "@components/Grid/Column.tsx";
import Grid from "@components/Grid/Grid.tsx";
import { Loader } from "@components/Loader";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import styles from "./NewTab.module.scss";
import { Seller } from '@api/apiClient';

const InProcessTab = ({ filter, seller, sellersNotFound }: {
  filter: "inProcess" | "complete",
  seller?: Seller | null,
  authorizedSellers: string[],
  sellersNotFound: boolean,
}) => {
  const { t } = useTranslation();
  const isComplete = filter === "complete";
  const query = useQuery({
    queryKey: ["dropShipping", isComplete, seller?.id ?? ""],
    queryFn: () => getDropShipping(isComplete, seller?.id ?? ""),
    retry: 1,
  });

  const columns: TableColumn<DropShippingParcel>[] = [
    {
      header: t("dropshipping.inProcessTab.marketplaceNumber"),
      accessor: (row: TableRowType<DropShippingParcel>) => row.data.externalNumber,
      alignment: "left",
      fixWidth: true,
      width: "160px",
      type: TableColumnType.PRIMARY,
    },
    {
      header: t("dropshipping.inProcessTab.externalNumber"),
      accessor: (row: TableRowType<DropShippingParcel>) => row.data.code,
      alignment: "left",
      fixWidth: true,
      width: "145px",
      type: TableColumnType.TERTIARY,
    },
    {
      header: "",
      accessor: (row) => {
        const imgUrl = row.data.items?.[0]?.imageUrl;
        if (!imgUrl) return null;
        return (
         <img
           className={styles.producImage}
           src={imgUrl}
           alt=""
         />
        )
      },
      alignment: "left",
      width: "50px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("dropshipping.inProcessTab.attachment"),
      accessor: (row: TableRowType<DropShippingParcel>) => {
        const firstItem = row.data.items?.[0];
        return firstItem ? (
          <a href={firstItem.url ?? ""} target="_blank" rel="noreferrer">
            {firstItem.name ?? ""}
          </a>
        ) : null;
      },
      width: "340px",
      alignment: "left",
    },
    {
      header: t("dropshipping.inProcessTab.comment"),
      accessor: (row: TableRowType<DropShippingParcel>) => row.data.message,
      alignment: "left",
      type: TableColumnType.SECONDARY,
      width: "340px",
    },
    {
      header: t("dropshipping.inProcessTab.quantity"),
      accessor: (row: TableRowType<DropShippingParcel>) => row.data.itemsCount,
      alignment: "left",
      fixWidth: true,
      width: "80px",
      type: TableColumnType.TERTIARY,
    },
    {
      header: t("dropshipping.inProcessTab.orderDate"),
      accessor: (row: TableRowType<DropShippingParcel>) => formatDate(row.data.createAt, "dd.MMM.yy HH:mm"),
      alignment: "left",
      fixWidth: true,
      width: "125px",
      type: TableColumnType.TERTIARY,
    },
  ];

  if (sellersNotFound) {
    return (
      <>
        <Box pb={5}>{t("dropshipping.inProcessTab.description")}</Box>

        <AddSellerPanel isOzonSeller />
      </>
    );
  }

  if (query.isFetching) {
    return (
      <>
        <Box pb={5}>{t("dropshipping.inProcessTab.description")}</Box>
        <Grid>
          <Row>
            <Column phone={12}>
              <Loader size="s" />
            </Column>
          </Row>
        </Grid>
      </>
    );
  }

  if (query.isFetched && !query.data?.length) {
    return (
      <>
        <Box pb={5}>{t("dropshipping.inProcessTab.description")}</Box>
        <Grid>
          <Row>
            <Column phone={12}>
              <Text color={TextColor.DISABLED} type={TextType.H2}>
                {t("dropshipping.noParcels")}
              </Text>
            </Column>
          </Row>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Box pb={5}>{t("dropshipping.inProcessTab.description")}</Box>

      <Table
        data={query.data || []}
        columns={columns}
      />
    </>
  );
};

export default InProcessTab;
