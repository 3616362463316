import styles from './PaymentReturnCompletedModalWindow.module.scss';
import Box from '@components/Box';
import CheckIcon from '@assets/icons/check_62.svg';
import { Button } from '@components/Button';
import { Column, Row } from '@components/Grid';
import { IPaymentReturnCompletedModalWindowProps } from '../../types';
import { Text, TextColor, TextSize } from '@components/Text';
import { TextWeight } from '@components/Text';
import { useTranslation } from 'react-i18next';

const PaymentReturnCompletedModalWindow: React.FC<IPaymentReturnCompletedModalWindowProps> = (props: IPaymentReturnCompletedModalWindowProps): React.ReactElement => {
  const { handlerSetIsOpenPaymentReturnCompletedWindow } = props;

  const { t } = useTranslation();

  return (
      <div className={styles.grid}>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <img
                  src={CheckIcon}
                  alt="check"
              />
            </Box>
          </Column>
        </Row>
        <Row justify={'center'}>
          <Column phone={9}>
            <Box pb={8}>
              <Text
                  className={styles.info}
                  color={TextColor.PRIMARY}
                  size={TextSize.XXL}
                  weight={TextWeight.SEMIBOLD}
              >
                {t('paymentReturnCompletedModalWindow.info')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row noWrap>
          <Box>
            <Button
                onClick={() => handlerSetIsOpenPaymentReturnCompletedWindow(false)}
                title={t('paymentReturnCompletedModalWindow.buttonFinish')}
            />
          </Box>
        </Row>
      </div>
  );
};

export default PaymentReturnCompletedModalWindow;