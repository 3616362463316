import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { formatDate } from "@utils/formatValues";
import Avatar from "@components/Avatar";
import { Text, TextColor, TextSize, TextType, TextWeight } from "@components/Text";
import { getPickUpOrders, PickUpOrderForm } from "@api/apiClient/pickUp";
import { AddSellerPanel } from "@components/AddSeller";
import { Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import DataGrid, { DataViewHandle } from "@containers/DataGrid";
// import TimeIcon from "@assets/icons/time.svg";
import TruckIcon from "@assets/icons/truck-blue-2.svg";
import styles from "./ordersTab.module.css";
import { Modal } from "@components/Modal";
import PickUpCancelModalWindow from '../modals/PickUpCancelModalWindow';
import PickUpOrderModalWindow from '../modals/PickUpOrderModalWindow/PickUpOrderModalWindow';
import { getPickUpXLSXFile } from '@api/apiClient';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { Tooltip } from '@components/Tooltip';
import CopyTextToClipboard from '@components/CopyTextToClipboard';

interface OrderViewItemProps {
  label?: React.ReactNode;
  value?: React.ReactNode;
  withBorder?: boolean;
  titleColor?: TextColor;
  width?: string;
}

const OrderViewItem = ({
  label,
  value,
  withBorder,
  titleColor,
  width,
}: OrderViewItemProps) => {
  return (
    <div
      className={cn(
        styles.orderViewItem,
        withBorder && styles.orderViewItemWithBorder,
        !width && styles.orderViewItemAutoWidth,
      )}
      style={{ width }}
    >
      <Text
        size={TextSize.XS}
        color={titleColor || TextColor.DARK_MUTED}
        weight={TextWeight.SEMIBOLD}
      >
        {label}
      </Text>
      {value}
    </div>
  );
}

const statusColors: Record<string, TextColor> = {
  "Created": TextColor.DARK_MUTED,
  "Canceled": TextColor.WARM_PINK,
  "Completed": TextColor.GREENISH,
  "InProcess": TextColor.DARK_MUTED,
}

interface OrderViewProps {
  // data: PickUpOrderForm;
  onClickOrderView: (d: PickUpOrderForm) => void;
}

const OrderView = ({
  data,
  onClickOrderView,
}: {
  data: PickUpOrderForm,
  onClickOrderView: (data: PickUpOrderForm) => void,
}): React.ReactNode => {
  const { t } = useTranslation();

  const downloadingExcelFile = (): void => {
    getPickUpXLSXFile(data.id)
        .then((response: AxiosResponse) => {
          const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const fileURL = URL.createObjectURL(file);
          const element = document.createElement('a');
          element.setAttribute('href', fileURL);
          element.setAttribute('download', `${t("pickUp.tabOrders.xlsxFileName")} ${moment(data.createdAt).format('DD/MM/YYYY HH:mm:ss')}`);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
  };

  return (
    <div
      className={styles.orderView}
      onClick={()=> onClickOrderView(data)}
    >
      <div className={styles.orderIcon}>
        <img src={TruckIcon} alt="" />
      </div>

      <OrderViewItem
        label={t(`pickUp.status.${data.status}`)}
        titleColor={statusColors[data.status]}
        value={(
          <Text size={TextSize.M} type={TextType.BLOCK}>
            {t("pickUp.oneTimePickUp")}
          </Text>
        )}
        width="210px"
      />
      <OrderViewItem
        label={t("pickUp.tabOrders.stores")}
        value={data.sellers?.length ? (
          <div className={styles.avatars}>
            {data.sellers.map((v: PickUpOrderForm["sellers"][0]) => (
              <Avatar
                key={v.id}
                id={v.id}
                className={styles.avatar}
                name={v.name}
              />
            ))}
          </div>
        ) : null}
      />
      <OrderViewItem
        label={t("pickUp.tabOrders.items")}
        value={(
            <div className={styles.wrapper}>
              <Tooltip
                  action={
                    <button
                        className={styles.download}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.stopPropagation();
                          downloadingExcelFile();
                        }}
                    />
                  }
                  text={t("pickUp.tabOrders.actionTooltip")}
                  top={33}
              />
              <Text size={TextSize.M}>
                {data.parcelsCount}
              </Text>
            </div>
        )}
        width="90px"
      />
      <OrderViewItem
        label={t("pickUp.tabOrders.address")}
        value={(
          <Text size={TextSize.M} ellipsis width="318px">
            {data.address?.rawLine}
          </Text>
        )}
        width="358px"
        withBorder
      />
      <OrderViewItem
        label={t("pickUp.tabOrders.pickUpDate")}
        value={(
          <Text size={TextSize.M}>
            {formatDate(data.shipmentDate, "dd.MM")}
          </Text>
        )}
        withBorder
        width="155px"
      />
      <OrderViewItem
          label={t('pickUp.tabOrders.trackNumber')}
          value={(
              <div onClick={(e) => e.stopPropagation()}>
                <CopyTextToClipboard
                    text={data.deliveryTrackNumber!}
                    visibleOnHover
                >
                  <Text size={TextSize.M}>
                    {data.deliveryTrackNumber}
                  </Text>
                </CopyTextToClipboard>
              </div>
          )}
          withBorder
          width="165px"
      />
    </div>
  );
}

const OrdersTab = ({
  isFilterOpen,
  isNew,
  onUpdate,
  sellersNotFound,
}: {
  isFilterOpen: boolean,
  isNew: boolean,
  sellersNotFound: boolean,
  onUpdate: () => void,
}) => {
  const [innerFilters, setInnerFilters] = useState<FilterModel[]>([]);
  const [isOpenPickUpCancelWindow, setIsOpenPickUpCancelWindow] = useState<boolean>(false);
  const [isOpenPickUpOrderWindow, setIsOpenPickUpOrderWindow] = useState<boolean>(false);
  const [pickUpOrder, setPickUpOrder] = useState<PickUpOrderForm | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const dataRef = useRef<DataViewHandle>(null);

  const { t } = useTranslation();

  const handlerSetIsOpenPickUpOrderWindow = (data: PickUpOrderForm): void => {
    setPickUpOrder(data);
    setIsOpenPickUpOrderWindow(true);
  };

  const refreshPickUpOrders = (): void => {
    if (dataRef.current?.refresh) {
      dataRef.current.refresh();
    }
    onUpdate();
  };

  const onCloseModal = () => {
    refreshPickUpOrders();
    setIsOpenPickUpOrderWindow(false);
  }

  return (
    <div>
      <Box pb={5}>
        <Row>
          <Column phone={12}>
            <Text size={TextSize.M} type={TextType.BLOCK}>
              {t("pickUp.tabOrders.description")}
            </Text>
          </Column>
        </Row>
      </Box>
      <Box pb={5} className={cn(!isFilterOpen && styles.hidden)}>
        <Column phone={12}>
          <Row justify="right" gap={5}>
            <Filters
              items={sellersNotFound ? [] : [
                {
                  name: "sellerIds",
                  type: FilterTypes.SELLER,
                },
                {
                  name: "dateRange",
                  type: FilterTypes.DATE_RANGE,
                  placeholder: t("pickUp.tabOrders.pickUpDate"),
                  fieldNames: ["startDate", "endDate"],
                },
              ]}
              onChange={(v) => {
                if (v.find(f => (f.name === "sellerIds"))) {
                  setIsInitialized(true);
                }
                setInnerFilters(v)
              }}
              values={innerFilters}
              asPanel
            />
          </Row>
        </Column>
      </Box>
      {sellersNotFound ? (
        <AddSellerPanel />
      ) : (
        <DataGrid<PickUpOrderForm, OrderViewProps, void, void>
          queryKey="pickUpOrders"
          pending={!isInitialized}
          getData={getPickUpOrders}
          ItemComponent={OrderView}
          notFound={t("pickUp.tabOrders.noOrders")}
          filters={innerFilters}
          dataRef={dataRef}
          viewProps={{
            onClickOrderView: handlerSetIsOpenPickUpOrderWindow,
          }}
        />
      )}

      <Modal
        bodyWithoutIndentations
        isOpen={isOpenPickUpOrderWindow}
        onClickOverlay={onCloseModal}
        onClose={onCloseModal}
        width={900}
      >
        <PickUpOrderModalWindow
            handlerRefreshPickUpOrders={refreshPickUpOrders}
            handlerSetIsOpenPickUpCancelWindow={setIsOpenPickUpCancelWindow}
            handlerSetIsOpenPickUpOrderWindow={setIsOpenPickUpOrderWindow}
            handlerSetPickUpOrder={setPickUpOrder}
            isNew={isNew}
            pickUpOrder={pickUpOrder!}
        />
      </Modal>

      <Modal
          isOpen={isOpenPickUpCancelWindow}
          onClickOverlay={() => setIsOpenPickUpCancelWindow(false)}
          onClose={() => setIsOpenPickUpCancelWindow(false)}
      >
        <PickUpCancelModalWindow
            handlerRefreshPickUpOrders={refreshPickUpOrders}
            handlerSetIsOpenPickUpCancelWindow={setIsOpenPickUpCancelWindow}
            pickUpOrder={pickUpOrder!}
        />
      </Modal>
    </div>
  );
};

export default OrdersTab;
