import { useMemo, useEffect, useState, useRef } from "react";
import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";
import { useProfile } from "@contexts/ProfileContext";
import DataTable, { DataViewHandle } from '@containers/DataTable';
import {
  TableColumn,
  TableColumnType,
  // TableRowType,
} from "@components/Table/TableTypes";
import { Text, TextColor, TextSize, TextType, TextWeight } from '@components/Text';
import {
  getFinancePage,
  removeWallet,
  getWalletRequestStatus,
  GetWalletOperationsItem,
} from "@api/apiClient/finance";
import { Row } from "@components/Grid";
import cn from "classnames";
import { formatDate } from "@utils/formatValues";
import styles from "./finTab.module.css";
import Box from '@components/Box';
import ConfirmModal from '@components/modals/ConfirmModal';
import { Button, ButtonType } from '@components/Button';
import QrModal from '../modals/QrModal';
import PlusIcon from "@assets/icons/plus-btn.svg";
import WeChatIcon from "@assets/icons/we-chat-green-19.svg";
import DelIcon from "@assets/icons/del.svg";
import { TWechatWallet } from '../types';

const FinTab = ({ dataRef, wechatWallets, refetchWallets }: {
  dataRef: React.MutableRefObject<DataViewHandle | null>,
  wechatWallets: TWechatWallet[] | undefined;
  refetchWallets: () => void,
}) => {
  const { t, i18n: { language } } = useTranslation();
  const [isConfirmRemoveWalletOpen, setConfirmRemoveWalletOpen] = useState<boolean>(false);
  const [walletToRemove, setWalletToRemove] = useState<TWechatWallet | null>(null);
  const [isQrOpen, setQrOpen] = useState<boolean>(false);
  const { account, get: refreshProfile } = useProfile();
  const isMountedRef = useRef(true);

  useEffect(() => {
    refreshProfile();
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, [])

  const walletStatusMutation = useMutation({
    mutationFn: getWalletRequestStatus,
  });

  const waitForRequest = (id: string) => {
    setTimeout(() => {
      walletStatusMutation.mutate(id, {
        onSuccess: (data) => {
          if (data.isProcessed) {
            refetchWallets();
          } else if (isMountedRef.current) {
            waitForRequest(id);
          }
        },
      });
    }, 15000);
  }

  const columns: TableColumn<GetWalletOperationsItem>[] = useMemo(() => [
    // {
    //   header: t("profileFinance.id"),
    //   accessor: (row) => row.data.id,
    //   type: TableColumnType.TERTIARY,
    //   alignment: "left",
    //   width: "150px",
    //   fixWidth: true,
    //   noOverflow: true,
    // },
    {
      header: t("profileFinance.date"),
      accessor: (row) => formatDate(row.data.date, "dd.MM.yy HH:mm"),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("profileFinance.type"),
      accessor: (row) => (
        t(
          `profileFinance.types.${row.data.type}`,
          { defaultValue: row.data.type || "" },
        )
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "250px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("profileFinance.status"),
      accessor: (row) => (
        <div className={cn(styles.status, styles[`status${row.data.status}`])}>
          {t(
            `profileFinance.statuses.${row.data.status}`,
            { defaultValue: row.data.status || "" },
          )}
        </div>
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "150px",
      fixWidth: true,
    },
    {
      header: t("profileFinance.sum"),
      accessor: (row) => (
        <Text
          size={TextSize.S}
          color={TextColor.PRIMARY}
          weight={TextWeight.MEDIUM}
        >
          {row.data.amount}
        </Text>
      ),
      type: TableColumnType.PRIMARY,
      alignment: "left",
      width: "120px",
      fixWidth: true,
    },
    {
      header: t("profileFinance.comment"),
      accessor: (row) => (
        t("profileFinance.commentTemplate", {
          context: row.data.type,
          trackNumber: row.data.details?.trackNumber ?? "",
          parcelComplaintId: row.data.details?.parcelComplaintId ?? "",
        })
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
    },
  ], [language]);

  const handleRemoveWallet = (wallet: TWechatWallet) => {
    setWalletToRemove(wallet);
    setConfirmRemoveWalletOpen(true);
  }

  return (
    <div>
      {(account?.region === "CN") ? (
        <Row>
          <Box pb={3}>
            <Text
                size={TextSize.L}
                type={TextType.SPAN}
                weight={TextWeight.BOLD}
            >
              {t("profileFinance.subtitle")}
            </Text>
          </Box>
          {!wechatWallets?.length ? (
              <Box pb={4}>
                <Text
                  size={TextSize.L}
                  type={TextType.SPAN}
                >
                  {t("profileFinance.description")}
                </Text>
              </Box>
          ) : null}
          <div className={styles.actions}>
            {wechatWallets?.length ? (
                <div className={styles.users}>
                  {wechatWallets.map((item: TWechatWallet) => (
                      <Button
                          className={styles.account}
                          icon={WeChatIcon}
                          iconRight={DelIcon}
                          iconRightClassName={styles.del}
                          key={item.id}
                          onClickIconRight={() => handleRemoveWallet(item)}
                          title={item.weChatId}
                          type={ButtonType.GREY}
                      />
                  ))}
                </div>
            ) : null}
            <div className={styles.add}>
              <Button
                  icon={PlusIcon}
                  title={t("profileFinance.addService")}
                  type={ButtonType.DASHED}
                  onClick={() => setQrOpen(true)}
              />
              <Text
                  className={styles.text}
                  size={TextSize.L}
                  type={TextType.SPAN}
              >
                {t("profileFinance.addServiceClue")}
                {" "}
                <a
                  className={styles.link}
                  href="https://storage.yandexcloud.net/unitrade-public/public/pay_info.docx"
                  target="_blank"
                >
                  {t("profileFinance.link")}
                </a>
                {" "}
                {t("profileFinance.addServiceClue2")}
              </Text>
            </div>
          </div>
        </Row>
      ) : null}
      <DataTable
        queryKey="financePage"
        getData={getFinancePage}
        columns={columns}
        notFound={t("profileFinance.noTransactions")}
        filters={[]}
        dataRef={dataRef}
      />
      {isConfirmRemoveWalletOpen && (
        <ConfirmModal
          title={t("profileFinance.confirmWalletRemove", { wallet: walletToRemove?.weChatId })}
          onClose={() => setConfirmRemoveWalletOpen(false)}
          onSuccess={() => refetchWallets()}
          requestFn={() => removeWallet(walletToRemove?.id ?? "")}
        />
      )}
      {isQrOpen && (
        <QrModal
          onClose={() => setQrOpen(false)}
          onSuccess={(id: string) => {
            waitForRequest(id);
            setQrOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default FinTab;
