declare global {
  function zE(a: string, b: string, c: unknown): unknown;
}

﻿class ZendeskClient {

    public showWidget(): void {
        this.callApi('messenger', 'show');
    }

    public hideWidget(): void {
        this.callApi('messenger', 'hide');
    }

    public loginUser(jwt: string): void {
        this.callApi("messenger", "loginUser", (callback: Function) => {
            callback(jwt);
        })
    }

    public logoutUser(): void {
        this.callApi("messenger", "logoutUser");
    }

    public setParcelsCount(count: number): void {
        this.callApi("messenger:set", "conversationFields", [
            { id: "19177499327762", value: count }
        ]);
    }

    private callApi(command: string, commandValue: string, args?: unknown): void {
        window.zE(command, commandValue, args);
    }
}

export const Zendesk = new ZendeskClient();