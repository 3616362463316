import { useMemo } from "react";
import { useAppendSearchParams } from "./useAppendSearchParams";

export interface PaginationModel {
  page: number;
  pageSize: number;
}

export const usePagingSearchParams = (initPage = 1, initPageSize = 10): [
  PaginationModel,
  (newPage: PaginationModel) => void,
] => {
  const [searchParams, setSearchParams] = useAppendSearchParams();

  const page = useMemo(() => {
    return {
      page: parseInt(searchParams.get('page') || initPage.toString()),
      pageSize: parseInt(searchParams.get('pageSize') || initPageSize.toString()),
    };
  }, [searchParams, initPage]);

  const setPage = (newPage: PaginationModel) => {
    const obj: Partial<PaginationModel> = {
      page: newPage.page,
      pageSize: newPage.pageSize,
    };

    setSearchParams(obj);
  };

  return [page, setPage];
}
