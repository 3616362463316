import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { isApiErrorWithResponse } from "@api/apiClient";
import { verifySecurityStamp } from "@api/apiClient/signUp";
import { Grid, Column, Row } from "@components/Grid";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { Text, TextType } from "@components/Text";
import Box from "@components/Box";
import SuccessIcon from "@assets/icons/success.svg";
import ErrorIcon from "@assets/icons/request-error.svg";
import BackIcon from "@assets/icons/back-arrow.svg?react";

interface ValidateStampProps {
  onBack: () => void;
}

const ValidateStamp = ({
  onBack,
}: ValidateStampProps) => {
  const { t } = useTranslation();
  const params = new URLSearchParams(location.search);
  const key = params.get("key");
  const navigate = useNavigate();

  const verifyMutation = useMutation({
    mutationFn: () => verifySecurityStamp(key || ""),
  });

  useEffect(() => {
    if (key) {
      verifyMutation.mutate();
    }
  }, [key])

  if (verifyMutation.isLoading) {
    return null;
  }

  if (verifyMutation.isError) {
    let errorMessage = t(`signUp.error.defaultTitle`);
    if (isApiErrorWithResponse(verifyMutation.error)) {
      errorMessage = t(
        `signUp.error.${verifyMutation.error.response.data.code || "defaultTitle"}`,
        { defaultValue: errorMessage },
      );
    }
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Grid>
                <Row align="center">
                  <Column phone={2}>
                    <img alt="Icon" src={ErrorIcon} width="100%" />
                  </Column>
                  <Column phone={10}>
                    <Box pl={4}>
                      <Text type={TextType.H1}>
                        {errorMessage}
                      </Text>
                    </Box>
                  </Column>
                </Row>
              </Grid>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Button
              title={t("signUp.back")}
              icon={BackIcon}
              type={ButtonType.ROUNDED_SKELETON}
              size={ButtonSize.S}
              onClick={onBack}
            />
          </Column>
        </Row>
      </Grid>
    )
  }

  return (
    <>
      <Grid>
        <Row>
          <Column phone={12}>
            <Row align="center">
              <Column phone={2}>
                <img alt="Icon" src={SuccessIcon} width="100%" />
              </Column>
              <Column phone={10}>
                <Box pl={4}>
                  <Text type={TextType.H1}>
                    {t("signUp.info.title")}
                  </Text>
                </Box>
              </Column>
            </Row>
          </Column>
        </Row>

        <Row>
          <Column phone={12}>
            <Box pt={4}>
              <Button
                title={t("signUp.info.authBtn")}
                type={ButtonType.BLOCK}
                size={ButtonSize.S}
                onClick={() => navigate("/login")}
              />
            </Box>
          </Column>
        </Row>

      </Grid>
    </>
  );
};

export default ValidateStamp;
