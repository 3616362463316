import Grid from "../../components/Grid/Grid.tsx";
import Column from "../../components/Grid/Column.tsx";
import Row from "../../components/Grid/Row.tsx";
import { useForm } from "react-hook-form";
import TextInputContainer from "../../containers/TextInputContainer.tsx";
import { Button, ButtonSize, ButtonType } from "../../components/Button";
import Title from "../../components/Title/Title.tsx";
import { useTranslation } from "react-i18next";
import Box from "../../components/Box/Box.tsx";
import { Link } from "react-router-dom";

export interface SendEmailToResetPasswordFormType {
  email: string;
}

interface SendEmailToResetPasswordFormProps {
  onSubmit: (data: SendEmailToResetPasswordFormType) => void;
  pending: boolean;
}

const SendEmailToResetPasswordForm = (
  props: SendEmailToResetPasswordFormProps,
) => {
  const { control, handleSubmit } = useForm<SendEmailToResetPasswordFormType>({
    defaultValues: {
      email: "",
    },
  });

  const { t } = useTranslation();

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Title title={t("resetPasswordForm.title")} />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>{t("resetPasswordForm.description")}</Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <TextInputContainer
              control={control}
              name="email"
              title={t("resetPasswordForm.email")}
              rules={{ required: t("resetPasswordForm.emailErrorText") }}
              type="email"
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Button
              title={t("resetPasswordForm.button")}
              type={ButtonType.BLOCK}
              size={ButtonSize.S}
              onClick={handleSubmit(props.onSubmit)}
              pending={props.pending}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={1} textAlign={"center"}>
            <Link to={"/login"}>{t("resetPasswordForm.back")}</Link>
          </Box>
        </Column>
      </Row>
    </Grid>
  );
};

export default SendEmailToResetPasswordForm;
