import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { verifyPhone } from "@api/apiClient/signUp";
import { Grid, Column, Row } from "@components/Grid";
import DigitInput from "@components/DigitInput";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import ButtonWithTimer, { ButtonWithTimerType } from "@components/ButtonWithTimer/ButtonWithTimer";
import FormErrorMessage from "@components/FormErrorMessage";
import { Text, TextType, TextSize } from "@components/Text";
import Box from "@components/Box";

export interface ConfirmPhoneType {
  code: string;
}

interface ConfirmPhoneProps {
  onSuccess: (phone: string) => void;
  goBack: () => void;
  phone: string;
  resend: () => void;
  isLoading: boolean;
}

const ConfirmPhone = ({
  onSuccess,
  goBack,
  phone,
  isLoading,
  resend,
}: ConfirmPhoneProps) => {
  const [code, setCode] = useState("");

  useEffect(() => {
    verifyMutation.reset();
    if (code.trim().length === 4) verifyMutation.mutate({ code })
  }, [code])

  const verifyMutation = useMutation({
    mutationFn: verifyPhone,
    onSuccess: () => onSuccess(phone),
  });

  const { t } = useTranslation();

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={3}>
            <Text type={TextType.H1}>
              {t("signUp.confirm.title")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={7}>
            <span>
              <Text
                size={TextSize.M}
                type={TextType.INLINE}
                dangerouslySetInnerHTML={{
                  __html: t("signUp.confirm.description", { phone: phone }),
                }}
              />
              {" "}
              <Button
                title={t("signUp.confirm.changeLink")}
                type={ButtonType.LINK}
                size={ButtonSize.S}
                onClick={goBack}
              />
            </span>
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <DigitInput
              name="code"
              length={4}
              value={code}
              onChange={setCode}
              disabled={verifyMutation.isLoading}
            />
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <ButtonWithTimer
            onClick={() => resend()}
            pending={isLoading}
            label={t("signUp.confirm.resend")}
            type={ButtonWithTimerType.LINK}
            timeout={30}
          />
        </Column>
      </Row>
      {verifyMutation.isError ? (
        <Column phone={12}>
          <Box pb={8}>
            <FormErrorMessage error={verifyMutation.error} />
          </Box>
        </Column>
      ) : null}
    </Grid>
  );
};

export default ConfirmPhone;
