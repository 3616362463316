export enum ButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  DANGER = "danger",
  BLOCK = "block",
  ROUNDED = "rounded",
  SKELETON = "skeleton",
  ROUNDED_SKELETON = "rounded-skeleton",
  RED_SKELETON = "red-skeleton",
  GREEN_SKELETON = "green-skeleton",
  LINK = "link",
  BLACK = "black",
  CANCEL = "cancel",
  LIGHT = "light",
  LIGHT_ROUNDED = "light-rounded",
  DASHED = "dashed",
  GREY = "grey"
}

export enum ButtonSize {
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  XXL = "xxl",
}
