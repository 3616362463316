import {getZendeskInfo, GetZendeskResponse} from "../api/apiClient";
import {Zendesk} from "../api/zendeskClient.ts";

class ZendeskUtilsContext {
    private storageKey: string = "zendesk_info";

    public async loadInfo(): Promise<void> {
      try {
        let response = await getZendeskInfo();
        this.set(this.storageKey, response);
      } catch (e) {
        console.warn(e);
      }
    }

    public async login(): Promise<void> {
        let info: GetZendeskResponse | null = null;
        info = this.get<GetZendeskResponse>(this.storageKey);
        if (!info) {
          await this.loadInfo();
          info = this.get<GetZendeskResponse>(this.storageKey);
        }
        if (info) {
          Zendesk.loginUser(info.jwt);
          Zendesk.setParcelsCount(info.parcelsCount);
        }
        Zendesk.showWidget();
    }

    public logout(): void {
        this.remove(this.storageKey);
        Zendesk.hideWidget();
        Zendesk.logoutUser();
    }

    private get<T>(key: string): T | null {
        let data = localStorage.getItem(key);
        if (data == null) return null;
        return JSON.parse(data) as T;
    }

    private set(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    private remove(key: string): void {
        localStorage.removeItem(key);
    }
}

export const ZendeskUtils = new ZendeskUtilsContext();
