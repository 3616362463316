import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RouterContext from "./index.ts";

interface RouterProviderProps {
  children: ReactNode;
}

const RouterProvider = (props: RouterProviderProps) => {
  const location = useLocation()
  const [route, setRoute] = useState({
    currentPath: location.pathname,
    prevPath: "",
    currentSearch: location.search,
    prevSearch: "",
  });
  
  useEffect(()=> {
    setRoute((prev)=> ((location.pathname === prev.currentPath) ? {
      currentPath: location.pathname,
      currentSearch: location.search,
      prevPath: prev.prevPath,
      prevSearch: prev.prevSearch,
    } : {
      currentPath: location.pathname,
      currentSearch: location.search,
      prevPath: prev.currentPath,
      prevSearch: prev.currentSearch,
    }))
  }, [location]);

  return (
    <RouterContext.Provider value={route}>
      <>{props.children}</>
    </RouterContext.Provider>
  );
};

export default RouterProvider;
