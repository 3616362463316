import DataView, { DataViewProps } from "./DataView";
import type { DataViewHandle as DataViewHandle_ } from "./DataView";

export type DataViewHandle = DataViewHandle_;

interface ViewItemProps<T> {
  data: T;
}
type DataViewExtraProps<T, V, BP, AP> = Omit<DataViewProps<T, V, BP, AP>, "View">
type DataGridProps<T, V, BP, AP> = DataViewExtraProps<T, V, BP, AP> & {
  ItemComponent: React.FC<V & ViewItemProps<T>>,
};

function DataGrid<T extends { id: string }, V, BP=void, AP=void>({
  ItemComponent,
  dataRef,
  ...props
}: DataGridProps<T, V, BP, AP>) {
  const View = ({ data, ...rest }: V & {
    data: T[],
  }) => (
    <>
      {data.map((item: T) => (
        // @ts-ignore: todo
        <ItemComponent
          key={item.id}
          {...rest}
          data={item}
        />
      ))}
    </>
  )

  const DataViewComponent = DataView<T, V, BP, AP>

  return (
    <DataViewComponent
      {...props}
      dataRef={dataRef}
      View={View}
    />
  );
}

export default DataGrid;
