import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getCurrentLanguage, Langs } from "@utils/lang";
import { Seller } from "@api/apiClient";
import { Text, TextColor, TextSize, TextType, TextWeight } from "@components/Text";
import { Button } from "@components/Button";
import { Row } from "@components/Grid";
import TextInputContainer from "@containers/TextInputContainer";
import Box from "@components/Box";
import FormErrorMessage from "@components/FormErrorMessage";
import infoIcon from '@assets/icons/info_blue_19.svg';

const links = {
  [Langs.cn]: "https://docs.ozon.ru/api/seller/zh/#section/API",
  [Langs.ru]: "https://docs.ozon.ru/api/seller/#section/Kak-poluchit-API-klyuch",
  [Langs.en]: "",
  [Langs.tr]: "",
}

interface FormType {
  apiKey: string;
  clientId: string;
}

interface OzonAuthorizeApiFormProps {
  onSubmit: (
    companyId: string,
    apiKey: string,
    clientId: string,
  ) => void;
  submitText?: string;
  isLoading: boolean;
  seller: Seller;
  error: any;
}

const OzonAuthorizeApiForm = ({
  onSubmit,
  submitText,
  isLoading,
  seller,
  error,
}: OzonAuthorizeApiFormProps) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const { handleSubmit, control } = useForm<FormType>({
    mode: "onChange",
    defaultValues: {
      apiKey: "",
      clientId: "",
    },
  });

  const submit = (form: FormType) => {
    onSubmit(seller.id, form.apiKey, form.clientId);
  };

  return (
    <>
      <Box pb={5}>
        <Text
          type={TextType.SPAN} weight={TextWeight.MEDIUM}>
          {seller.name}
        </Text>
      </Box>

      <Box pb={5}>
        <TextInputContainer
          rules={{
            required: t("defaultFormErrors.required"),
          }}
          control={control}
          name="apiKey"
          title={t("ozonAuthForm.apiKeyInput")}
        />
      </Box>
      <Box pb={5}>
        <TextInputContainer
          rules={{
            required: t("defaultFormErrors.required"),
          }}
          control={control}
          name="clientId"
          title={t("ozonAuthForm.clientIdInput")}
        />
      </Box>

      <Box>
        <Row gap={6}>
          <Button
            onClick={handleSubmit(submit)}
            title={submitText ?? t("ozonAuthForm.submit")}
            pending={isLoading}
          />
          {links[currentLanguage] ? (
            <a
              href={links[currentLanguage]}
              target="_blank"
              style={{ display: "flex", alignItems: "center" }} rel="noreferrer"
            >
              <img src={infoIcon} alt="i" style={{ marginRight: "8px" }} />
              <Text
                type={TextType.SPAN}
                size={TextSize.L}
                color={TextColor.BLUE}
                weight={TextWeight.MEDIUM}
              >
                {t("ozonAuthForm.instructionLink")}
              </Text>
            </a>
          ) : null}
        </Row>
      </Box>
      {error ? (
        <FormErrorMessage error={error} />
      ) : null}
    </>
  );
};

export default OzonAuthorizeApiForm;
