import { useEffect } from 'react';

function useOutsideClick<T extends HTMLElement>(
  ref: React.RefObject<T>,
  eventHandler: () => void,
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref?.current &&
        !ref?.current?.contains?.(event.target as Node)
      ) {
        eventHandler();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [eventHandler]);
}

export default useOutsideClick;
