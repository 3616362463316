import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import zxcvbn from "zxcvbn";
import { Grid, Column, Row } from "@components/Grid";
import Captcha from "@components/Captcha";
import TextInputContainer from "@containers/TextInputContainer";
import CountrySelectContainer from "@containers/CountrySelectContainer";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import Title from "@components/Title";
import { Text, TextSize, TextWeight, TextColor } from "@components/Text";
import { Tooltip } from '@components/Tooltip';
import Box from "@components/Box";
import ProgressBar from "@components/ProgressBar"
import { getCurrentLanguage, Langs } from "@utils/lang";
import TooltipIcon from "@assets/icons/tooltip.svg";

export interface SignUpFormType {
  email: string;
  password: string;
  countryCode: string;
  token: string;
}

interface SignUpFormProps {
  onSubmit: (data: SignUpFormType) => void;
  pending: boolean;
}

const urlPrefix = "/docs/offerContract/"; // "https://storage.yandexcloud.net/unitrade-public/public/";
const links: Record<Langs, string> = {
  [Langs.cn]: `${urlPrefix}cn/合同（3 项服务).docx`,
  [Langs.en]: `${urlPrefix}en/Agreement_uni_fintech offer (for 3 services).en.docx`,
  [Langs.ru]: `${urlPrefix}ru/Договор_оферта_ЮНИ_ФИНТЕХ (на 3 услуги) рус_версия.docx`,
  [Langs.tr]: `${urlPrefix}tr/Sözleşme teklifi UNI_ FINTECH (3 hizmet için).tr.docx`,
}
const links4: Record<Langs, string> = {
  [Langs.cn]: `${urlPrefix}cn/应用程序 №4 表格 - 商品类别确定.CN.xlsx`,
  [Langs.en]: `${urlPrefix}en/Appendix No. 4 Table - Definitions of the category товаров.en.xlsx`,
  [Langs.ru]: `${urlPrefix}ru/Приложение №4 Таблица - Определение категории товаров.xlsx`,
  [Langs.tr]: `${urlPrefix}tr/Ek No. 4 Tablo - Ürün kategorilerinin tanımı.tr.xlsx`,
}
const links5: Record<Langs, string> = {
  [Langs.cn]: `${urlPrefix}cn/应用程序 №5 表格 - 实现商品价格确定顺序.CN.xlsx`,
  [Langs.en]: `${urlPrefix}en/Appendix No. 5 Table - Procedure for determining the price of goods sold.en.xlsx`,
  [Langs.ru]: `${urlPrefix}ru/Приложение №5 Таблица - Порядок определения цены реализуемых товаров.xlsx`,
  [Langs.tr]: `${urlPrefix}tr/Ek No. 5 Tablo - Satılan Malın Fiyatı Belirleme Prosedürü.tr`,
}
const links6: Record<Langs, string> = {
  [Langs.cn]: `${urlPrefix}cn/应用程序 №6 - 运输代理服务费率.CN.xlsx`,
  [Langs.en]: `${urlPrefix}en/Appendix No. 6 - Tariffs for freight forwarding services.en.xlsx`,
  [Langs.ru]: `${urlPrefix}ru/Приложение №6 - Тарифы на транспортно-экспедиционные услуги.xlsx`,
  [Langs.tr]: `${urlPrefix}tr/Ek No. 6 - Taşıma ve nakliye hizmetlerine ilişkin tarifeler.xlsx`,
}

const SignUpForm = ({
  pending,
  onSubmit,
}: SignUpFormProps) => {
  const { control, handleSubmit, watch, setValue } = useForm<SignUpFormType>({
    defaultValues: {
      email: "",
      password: "",
      countryCode: "",
      token: "",
    },
  });

  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const password = watch("password");
  const passScore: number = useMemo(() => zxcvbn(password).score, [password]);
  const token = watch("token");

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={6}>
            <Title title={t("signUp.email.title")} />
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={2}>
            <TextInputContainer
              control={control}
              name="email"
              title={t("signUp.email.inputEmail")}
              rules={{ required: t("signUp.email.emailErrorText") }}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={7}>
            <Text size={TextSize.M} color={TextColor.DISABLED}>
              {t("signUp.email.emailDescription")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={2}>
            <TextInputContainer
              control={control}
              name="password"
              type="password"
              title={t("signUp.email.inputPass")}
              rules={{ required: t("signUp.email.emailErrorText") }}
            />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <ProgressBar
              progress={25 * +passScore}
              colors={[
                { value: 25, main: "#EE6373" },
                { value: 50, main: "#F2AF4C" },
                { value: 75, main: "#3ECC92" },
                { value: 100, main: "#4CA36A" },
              ]}
              label={(
                <Text size={TextSize.S}>
                  {t("signUp.email.passStrengthTitle")}
                  {": "}
                  <b>{t("signUp.email.passStrengthValue", { context: passScore })}</b>
                  {"  "}
                  <span>
                    <Tooltip
                      action={
                        <img src={TooltipIcon} alt="" />
                      }
                      text={(
                        <div style={{ display: "flex", flexDirection: "column", gap: "12px", width: "280px" }}>
                          <Text size={TextSize.XS} weight={TextWeight.BOLD} color={TextColor.WHITE}>
                            {t("signUp.email.passwordDescription")}:
                          </Text>
                          <Text size={TextSize.XS} color={TextColor.WHITE}>
                            - {t("signUp.email.passwordDescription2")}
                          </Text>
                          <Text size={TextSize.XS} color={TextColor.WHITE}>
                            - {t("signUp.email.passwordDescription3")}
                          </Text>
                          <Text size={TextSize.XS} color={TextColor.WHITE}>
                            - {t("signUp.email.passwordDescription4")}
                          </Text>
                          <Text size={TextSize.XS} color={TextColor.WHITE}>
                            - {t("signUp.email.passwordDescription5")}
                          </Text>
                        </div>
                      )}
                      top={33}
                    />
                  </span>
                </Text>
              )}
              column
              rounded
            />
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={5}>
            <CountrySelectContainer
              control={control}
              name="countryCode"
              allowedCountries={["CN", "TR"]}
            />
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={8}>
            <Captcha
              onChange={(t) => setValue("token", t)}
            />
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Button
              title={t("signUp.email.getAcc")}
              type={ButtonType.BLOCK}
              size={ButtonSize.S}
              onClick={handleSubmit(onSubmit)}
              pending={pending}
              disabled={!token}
            />
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box>
            <Text
              size={TextSize.S}
              dangerouslySetInnerHTML={{
                __html: t("signUp.email.footer", {
                  link: links[currentLanguage],
                  link4: links4[currentLanguage],
                  link5: links5[currentLanguage],
                  link6: links6[currentLanguage],
                }),
              }}
            />
          </Box>
        </Column>
      </Row>
    </Grid>
  );
};

export default SignUpForm;
