import { Text, TextSize, TextColor } from "@components/Text";
import { FileData } from "@api/apiClient/complaints";
import { ButtonIcon } from "@components/ButtonIcon";
import IconDoc from "@assets/icons/blue-doc.svg";
import IconCross from "@assets/icons/cross-grey-12.svg";
import styles from "./FileItem.module.scss";

function FileItem ({ data, name, onRemove }: {
  data?: FileData,
  name?: string,
  onRemove?: () => void,
}) {
  const fileNameFull = name || data?.fileName || "";
  const fileName = fileNameFull.length > 30
    ? `${fileNameFull.slice(0, 10)}...${fileNameFull.slice(-10)}`
    : fileNameFull;
  const content = (
    <>
      <img src={IconDoc} alt="" />
      <Text size={TextSize.S} color={TextColor.BLUE}>
        {fileName}
      </Text>
      {onRemove ? (
        <ButtonIcon
          icon={IconCross}
          onClick={onRemove}
        />
      ): null}
    </>
  )
  if (data?.fileStoragePreSignedUrl) {
    return (
      <a
        target="_blank"
        href={data?.fileStoragePreSignedUrl}
        className={styles.fileItem}
      >
        {content}
      </a>
    )
  }
  return (
    <div className={styles.fileItem}>
      {content}
    </div>
  )
}

export default FileItem;
