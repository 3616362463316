import ResetPasswordForm, {
  SetPasswordFormType,
} from "./ResetPasswordForm.tsx";
import { useEffect } from "react";
import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Grid, Row, Column } from "@components/Grid";
import Box from "../../components/Box/Box.tsx";
import SuccessIcon from "../../assets/icons/success.svg";
import ErrorIcon from "../../assets/icons/request-error.svg";
import { Text, TextSize, TextType } from "../../components/Text";
import FormErrorMessage from "@components/FormErrorMessage";
import { resetPassword } from "../../api/apiClient";

const ResetPasswordPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const securityStamp = params.get("securityStamp");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!securityStamp) {
      navigate(`/login`);
    }
  }, []);

  const {
    error,
    isError,
    isLoading,
    isSuccess,
    isIdle,
    mutate,
  } = useMutation({
    mutationFn: resetPassword,
  });

  const handleSubmit = (form: SetPasswordFormType) => {
    mutate({
      newPassword: form.password,
      securityStamp: securityStamp!,
    });
  };

  return (
    <div>
      {isError && (
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={4}>
                <Grid>
                  <Row align={"center"}>
                    <Column phone={2}>
                      <img alt={"Icon"} src={ErrorIcon} width={"100%"} />
                    </Column>
                    <Column phone={10}>
                      <Box pl={4}>
                        <Text type={TextType.H1}>
                          {t("resetPasswordPage.errorTitle")}
                        </Text>
                      </Box>
                    </Column>
                  </Row>
                </Grid>
              </Box>
            </Column>
          </Row>

          <FormErrorMessage
            errorPrefix="resetPasswordForm.error"
            error={error}
            defaultError="resetPasswordPage.errorMessage"
            black
          />

          <Row>
            <Column phone={12}>
              <Box pt={2}>
                {" "}
                <Link to={"/login"}>{t("resetPasswordPage.returnLink")}</Link>
              </Box>
            </Column>
          </Row>
        </Grid>
      )}
      {isSuccess && (
        <Grid>
          <Row>
            <Column phone={12}>
              <Box pb={4}>
                <Grid>
                  <Row align={"center"}>
                    <Column phone={2}>
                      <img alt={"Icon"} src={SuccessIcon} width={"100%"} />
                    </Column>
                    <Column phone={10}>
                      <Box pl={4}>
                        <Text type={TextType.H1}>
                          {t("resetPasswordPage.successTitle")}
                        </Text>
                      </Box>
                    </Column>
                  </Row>
                </Grid>
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Box pb={2}>
                <Text type={TextType.P} size={TextSize.M}>
                  {t("resetPasswordPage.successMessage")}
                </Text>
              </Box>
            </Column>
          </Row>

          <Row>
            <Column phone={12}>
              <Box>
                {" "}
                <Link to={"/login"}>{t("resetPasswordPage.returnLink")}</Link>
              </Box>
            </Column>
          </Row>
        </Grid>
      )}
      {(isLoading || isIdle) && (
        <ResetPasswordForm
          onSubmit={handleSubmit}
          pending={isLoading}
        />
      )}
    </div>
  );
};

export default ResetPasswordPage;
