import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Row, Column } from "@components/Grid";
import { useMutation } from 'react-query';
import Box from "@components/Box";
import Title from "@components/Title";
import TextInputContainer from "@containers/TextInputContainer";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { useProfile } from "../../../contexts/ProfileContext";
import { hardConfirmPhone, updateProfile } from "@api/apiClient";
import Photo from "@components/Photo/Photo";
import { Modal } from "@components/Modal";
import HardConfirmPhoneModalWindow from "@components/modals/HardConfirmPhoneModalWindow";
import FormErrorMessage from "@components/FormErrorMessage";

export interface ProfileDataFormType {
  name: string;
  phone: string;
  email: string;
  wechat: string;
  emailForNewsletter: string;
}

const DataTab = () => {
  const { account, get, isFetching } = useProfile();

  const confirmPhoneMutation = useMutation({
    mutationFn: hardConfirmPhone,
  });

  const updateProfileMutation = useMutation({
    mutationFn: updateProfile,
  });

  const { control, handleSubmit, formState, watch, reset } = useForm<ProfileDataFormType>({
    defaultValues: {
      name: account?.name ?? "",
      phone: account?.phone ?? "",
      email: account?.email ?? "",
      wechat: account?.chat ?? "",
      emailForNewsletter: account?.emailForNewsletter ?? "",
    },
  });
  const phoneValue = watch("phone");

  const [isHardConfirmOpen, setIsHardConfirmOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (
      (
        updateProfileMutation.isSuccess || confirmPhoneMutation.isSuccess
      ) && !(
        updateProfileMutation.isLoading || confirmPhoneMutation.isLoading
      )
    ) {
      get();
    }
  }, [
    updateProfileMutation.isSuccess,
    confirmPhoneMutation.isSuccess,
    updateProfileMutation.isLoading,
    confirmPhoneMutation.isLoading,
  ]);

  useEffect(() => {
    if (!isFetching) {
      reset({
        name: account?.name ?? "",
        phone: account?.phone ?? "",
        email: account?.email ?? "",
        wechat: account?.chat ?? "",
        emailForNewsletter: account?.emailForNewsletter ?? "",
      });
    }
  }, [isFetching]);

  const onSubmit = (data: ProfileDataFormType) => {
    if (!account) return;

    updateProfileMutation.reset();
    confirmPhoneMutation.reset();

    if (account.isPhoneConfirmed && account.phone !== data.phone) {
      confirmPhoneMutation.mutate(data.phone);
    }

    if (
      account.name !== data.name ||
      account.chat !== data.wechat ||
      account.emailForNewsletter !== data.emailForNewsletter
    ) {
      updateProfileMutation.mutate({
        name: data.name,
        chat: data.wechat,
        emailForNewsletter: data.emailForNewsletter,
      });
    }
  };

  return (
    <div>
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Photo />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={6} desktop={4} largeDesktop={3}>
            <Box pb={4}>
              <TextInputContainer
                control={control}
                name={"name"}
                title={t("profileData.name")}
                rules={{ required: t('defaultFormErrors.required') }}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={6} desktop={4} largeDesktop={3}>
            <Box pb={4}>
              <TextInputContainer
                control={control}
                name="phone"
                type="phone"
                title={t("profileData.phone")}
                rules={{ required: t('defaultFormErrors.required') }}
                disabled={account?.isPhoneConfirmed}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={6} desktop={4} largeDesktop={3}>
            <Box pb={4}>
              <TextInputContainer
                control={control}
                name={"emailForNewsletter"}
                title={t("profileData.emailForNewsletter")}
                rules={{ required: t('defaultFormErrors.required') }}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={6} desktop={4} largeDesktop={3}>
            <Box pb={4}>
              <TextInputContainer
                control={control}
                name={"wechat"}
                title={
                  account?.region === "TR"
                    ? t("profileData.chatTr")
                    : t("profileData.chatCn")
                }
                rules={{ required: t('defaultFormErrors.required') }}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={3}>
            <Button
              title={t("profileData.button")}
              type={ButtonType.PRIMARY}
              size={ButtonSize.S}
              onClick={() => {
                if (formState.dirtyFields.phone) {
                  setIsHardConfirmOpen(true);
                } else {
                  handleSubmit(onSubmit)();
                }
              }}
              pending={updateProfileMutation.isLoading || confirmPhoneMutation.isLoading}
              disabled={!formState.isDirty}
            />
          </Column>
        </Row>
        {updateProfileMutation.isError ? (
          <FormErrorMessage error={updateProfileMutation.error} />
        ) : null}
        {(!updateProfileMutation.isError && confirmPhoneMutation.isError) ? (
          <FormErrorMessage error={confirmPhoneMutation.error} />
        ) : null}
        <Row>
          <Column phone={12}>
            <Box pt={8} pb={4}>
              <Title title={t("profileData.loginData")} />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={6} desktop={4} largeDesktop={3}>
            <Box pb={4}>
              <TextInputContainer
                control={control}
                name="email"
                title={t("profileData.email")}
                rules={{ required: t('defaultFormErrors.required') }}
                disabled={true}
                type="email"
              />
            </Box>
          </Column>
        </Row>
      </Grid>

      <Modal
        isOpen={isHardConfirmOpen}
        onClose={() => {
          setIsHardConfirmOpen(false);
        }}
        onClickOverlay={() => {
          setIsHardConfirmOpen(false);
        }}
      >
        <HardConfirmPhoneModalWindow
          onSuccess={() => {
            handleSubmit(onSubmit)();
            setIsHardConfirmOpen(false);
          }}
          text={t("hardConfirmPhoneModalWindows.textProfile")}
          phone={phoneValue}
        />
      </Modal>
    </div>
  );
};

export default DataTab;
