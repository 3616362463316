import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import moment from "moment";
import styles from "./ButtonWithTimer.module.css";
import { Button, ButtonType } from "../Button";

export enum ButtonWithTimerType {
  LINK = "link",
}

interface ButtonWithTimerProps {
  label: string;
  onClick: () => void;
  pending?: boolean;
  type?: ButtonWithTimerType;
  timeout?: number;
  startTimerFromOutside?: boolean;
}

const TIMEOUT = 120;

const formatTime = (time: number) => {
  return moment(time * 1000).format("mm:ss");
}

const ButtonWithTimer: React.ComponentType<ButtonWithTimerProps> = ({
  onClick,
  type,
  label,
  pending,
  timeout,
  startTimerFromOutside
}: ButtonWithTimerProps) => {
  const { t } = useTranslation();
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [timer, setTimer] = useState(timeout || TIMEOUT);

  useEffect(()=> {
    startTimerFromOutside && handlerStartTimer();
  }, [startTimerFromOutside]);

  const handlerStartTimer = () => {
    setIsButtonVisible(false);
    let counter = timeout || TIMEOUT;
    const interval = setInterval(() => {
      counter--;
      setTimer(counter);
      if (counter === 0) {
        clearInterval(interval);
        setIsButtonVisible(true);
      }
    }, 1000);
  };

  const handleClick = () => {
    handlerStartTimer();
    onClick();
  };

  if (type === ButtonWithTimerType.LINK) {
    return (
      <div className={styles.rootLink}>
        <Button
          onClick={handleClick}
          title={label}
          disabled={!isButtonVisible}
          type={ButtonType.LINK}
        />
        {isButtonVisible ? null : (
          <div className={styles.timer}>
            {formatTime(timer)}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {isButtonVisible ? (
        <Button
          pending={pending}
          onClick={handleClick}
          title={label}
        />
      ) : (
        <div className={styles.message}>
          {t(
            "buttonWithTimer.timerMsg",
            { timer },
          )}
        </div>
      )}
    </div>
  );
};

export default ButtonWithTimer;
