import axios, { AxiosInstance, AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { toast } from 'react-toastify';
import { Trans } from "react-i18next";
import { isAdmin } from "@utils/auth";
import { getStoredToken } from "@utils/tokenUtils";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { VITE_APP_API_URL, VITE_APP_DEV } from "@utils/config";

export const sellerApiClient: AxiosInstance = axios.create({
  baseURL: VITE_APP_API_URL,
});

export function getPost<T = any, R = AxiosResponse<T, any>, D = any>(
  url: string, config?: AxiosRequestConfig<D> | undefined
): Promise<R> {
  if (config && shouldBePostRequest<D>(config)) {
    return sellerApiClient.post(url, preparePostConfig(config.params));
  }
  return sellerApiClient.get(url, config);
}

sellerApiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 422) {
      if (VITE_APP_DEV) {
        console.error("request error", error?.config?.url, error?.response?.data)
      }
      return Promise.reject(error);
    }
    if (isAdmin() && (error?.response?.status === 403)) {
      toast.error(
        <>
          <Trans i18nKey="apiErrors.default-isAdmin" />
          <br />
          <Trans i18nKey="apiErrors.defaultAdminDescription" />
        </>
        // `${t("apiErrors.default-isAdmin")} ${t("apiErrors.defaultAdminDescription")}`
      );
      return Promise.reject(error);
    }
    const data = error?.response?.data;
    if (data) {
      if (data.message) {
        toast.error(data.message)
      }
      const errors = Object.values(data.errors || {})
      if (errors?.length) {
        errors.map(err => {
          if (typeof err === "string") toast.error(err)
          if (Array.isArray(err)) err.map(errItem => toast.error(errItem))
        })
      }
    } else {
      toast.error(error.message || error.code)
    }

    if (VITE_APP_DEV) {
      console.error("API error", error?.config?.url, error?.code, error)
    }

    return Promise.reject(error);
  },
);

export const updateToken = (newToken?: string) => {
  if (VITE_APP_DEV) {
    console.log("update token", (newToken || "").substr(-10));
  }
  sellerApiClient.defaults.headers.common["Authorization"] =
    newToken ? `Bearer ${newToken}` : "";
};

export const updateAccountIdHeader = (accountId: string) => {
  sellerApiClient.defaults.headers.common["X-Account-Id"] = accountId;
}

export const clearAccountIdHeader = () => {
  delete sellerApiClient.defaults.headers.common["X-Account-Id"];
};

export function isZendErrorWithResponse(e: unknown): e is ZendErrorType {
  return axios.isAxiosError(e) && Boolean(e?.response);
};

export function isApiErrorWithResponse(e: unknown): e is ApiErrorType {
  return axios.isAxiosError(e) && Boolean(e?.response);
};

export function isApiError(e: unknown): e is AxiosError<ApiErrorResponse> {
  return axios.isAxiosError(e);
};

export function getZendError(error: unknown, defaultMsg?: string): string {
  let errorMessage = defaultMsg || "";
  if (isZendErrorWithResponse(error)) {
    errorMessage = error.response?.data?.error || errorMessage;
  }
  return errorMessage;
}

export function getErrorCode(error: unknown, defaultMsg?: string): string {
  let errorMessage = defaultMsg || "";
  if (isApiErrorWithResponse(error)) {
    errorMessage = error.response.data.code || errorMessage;
  }
  return errorMessage;
}

export function getErrorArguments(error: unknown): Record<string, string> {
  if (isApiErrorWithResponse(error)) {
    return error.response.data.arguments || {};
  }
  return {};
}

export function getErrorMessage(error: unknown, defaultMsg?: string): string {
  let errorMessage = defaultMsg || "";
  if (isApiErrorWithResponse(error)) {
    errorMessage = error.response.data.message || errorMessage;
  }
  return errorMessage;
}

export type Params = Record<string, (string | string[] | number)>

function shouldBePostRequest<D>(config: AxiosRequestConfig<D>): boolean {
  if (config?.params?.["Filters.trackOrExternalNumbers"]?.[0]?.length > 6600) return true
  return false;
}

function preparePostConfig(config?: Record<string, any>): Record<string, any> {
  if (!config) return {};
  const res: Record<string, any> = {};
  Object.keys(config).forEach((k) => {
    if (k.slice(0, 8) === "Filters.") {
      res.filters = res.filters || {};
      const name = k.slice(8);
      let value = config[k];
      if (name === "trackOrExternalNumbers") {
        value = (value[0] || "").split(/[\s,]/);
      }
      if (value && value.length !== 0) {
        res.filters[name] = value;
      }
    }
    if (k.slice(0, 7) === "Paging.") {
      res.paging = res.paging || {};
      const name = k.slice(7);
      let value = config[k];
      res.paging[name] = value;
    }
  })
  return res;
}

export function getParams(
  page: PaginationModel,
  filters: FilterModel[] = [],
): Params {
  const params: Params = {
    "Paging.Page": page.page - 1,
    "Paging.Size": page.pageSize,
  };
  filters.forEach((f) => {
    if (f.fieldNames?.length && Array.isArray(f.value)) {
      f.fieldNames.forEach((fieldName, idx) => {
        params[`${f.name === 'Sort' ? f.name : 'Filters'}.${fieldName}`] = f.value[idx] || "";
      })
    } else {
      params[`Filters.${f.name}`] = f.value;
    }
  })
  return params;
}

updateToken(getStoredToken()?.access_token);

export interface Response<T> {
  data: T;
}

export interface ApiErrorResponse {
  message: string;
  code: string;
  arguments?: Record<string, string>;
}

export interface ApiErrorType extends AxiosError<ApiErrorResponse> {
  response: AxiosResponse<ApiErrorResponse>;
}

export interface ZendErrorType extends AxiosError<{ error?: string }> {
  response: AxiosResponse<{ error?: string }>;
}
