import { useRef, useState, useEffect, useLayoutEffect } from "react";
import cn from "classnames";
import { CSSTransition } from "react-transition-group";
import useOutsideClick from "@hooks/useOutsideClick";
import chevronDown from "@assets/icons/chevron-down-svgrepo-com.svg";
import chevronUp from "@assets/icons/chevron-up-svgrepo-com.svg";
import { Loader } from "@components/Loader";
import "./SelectDropdown.css";

function renderItemDefault({ item, search, labelOptionItem }: {
  item: any,
  search?: string,
  labelOptionItem: (item: any) => string,
}) {
  const s = labelOptionItem(item);
  const res = (search ? s.split(search) : [s])

  return (
    <>{res.map((v: string, idx: number) => (
      <>
        {v}
        {(idx < res.length - 1) && (
          <b>{search}</b>
        )}
      </>
    ))}</>
  )
}

function labelSelectedItemDefault(item: any) {
  return item?.value ?? "";
}

function defaultSearchFunc(
  v: any,
  searchString: string,
) {
  if (!v) return false
  return v.includes(searchString);
}

export interface SelectDropdownProps<T> {
  defaultSelectedItem?: T;
  value?: string | null;
  items: T[];
  keyExtractor: (item: T) => string;
  labelOptionItem?: (item: T | null) => string;
  renderSelectedItem?: (props: { item: T | null }) => React.ReactNode;
  labelSelectedItem?: (item: T | null) => string;
  renderItem?: (props: { item: T, search?: string }) => React.ReactNode;
  onItemSelect: (id: string) => void;
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  searchable?: boolean;
  searchFunc?: (item: T, search: string) => boolean;
  isLoading?: boolean,
  onOpen?: () => void,
}

const SelectDropdown = <T extends object>(props: SelectDropdownProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<T | null>(null);
  const [searchString, setSearchString] = useState<string>("");
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick<HTMLDivElement>(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (!selectedItem && props.defaultSelectedItem) setSelectedItem(props.defaultSelectedItem);
  }, [props.defaultSelectedItem])

  useEffect(() => {
    setSearchString("");
    if (isOpen) props.onOpen && props.onOpen();
  }, [isOpen])

  const value = (props.value === undefined
    ? selectedItem
    : props.items.find(v => (props.value === props.keyExtractor(v)))
  ) || null;

  const handleSelect = (item: T) => {
    if (value === undefined) setSelectedItem(item);
    props.onItemSelect(props.keyExtractor(item));
    setIsOpen(false);
  };
  const isFilled = Boolean(value);
  const getItemLabel = props.labelOptionItem ?? labelSelectedItemDefault

  const items = searchString ? props.items.filter(
    (v) => (props?.searchFunc
      ? props?.searchFunc(v, searchString)
      : defaultSearchFunc(getItemLabel(v), searchString)
    )
  ) : props.items;

  useLayoutEffect(() => {
    if (dropdownRef.current) {
      const { offsetWidth } = dropdownRef.current;
      setDropdownWidth(offsetWidth);
    }
  }, [dropdownRef.current]);

  return (
    <div className={"select-dropdown"} ref={dropdownRef}>
      <div
        className={cn("select-dropdown__selected", {
          "select-dropdown__selected--active": isFilled,
          "select-dropdown__selected--error": props.hasError,
          "select-dropdown__selected--disabled": props.disabled,
        })}
        onClick={() => (!props.disabled && setIsOpen(!isOpen))}
      >
        {props.label && (
          <div className={"select-dropdown__title"}>{props.label}</div>
        )}
        {(isOpen && props.searchable) ? (
          <input
            type="text"
            className={"select-dropdown__search-input"}
            autoFocus
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        ) : (
          props.renderSelectedItem ? (
            <div className={"select-dropdown__selected-item"}>
              {props.renderSelectedItem({ item: value })}
            </div>
          ) : (
            <div className={"select-dropdown__selected-item"}>
              {(props.labelSelectedItem || (value && props.labelOptionItem) || labelSelectedItemDefault)(value)}
            </div>
          )
        )}
        <img
          src={isOpen ? chevronUp : chevronDown}
          className="select-dropdown__chevron"
          alt=""
        />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="select-dropdown__menu"
        mountOnEnter
        unmountOnExit
      >
        <div
          className={"select-dropdown__menu"}
          style={{ width: dropdownWidth }}
        >
          <div className={"select-dropdown__menu-content"}>
            {items.map((item) => (
              <div
                className={"select-dropdown__item"}
                key={props.keyExtractor(item)}
                onClick={() => handleSelect(item)}
              >
                {(props.renderItem
                  ? props.renderItem({ item, search: searchString })
                  : renderItemDefault({
                    item,
                    search: searchString,
                    labelOptionItem: getItemLabel,
                  })
                )}
              </div>
            ))}
          </div>
          {props.isLoading ? (
            <div className={"select-dropdown__loader"}>
              <Loader alignment="center" size="xs" />
            </div>
          ) : null}
        </div>
      </CSSTransition>
      {props.hasError && (
        <div className="select-dropdown-error">{props.errorMessage}</div>
      )}
    </div>
  );
};

export default SelectDropdown;
